import { gql } from '@apollo/client'
import { NamedPoints } from '@curvewise/common-types'
import { Vector3 } from 'polliwog-types'

import { ExportDatasetAnnotationsQuery } from '../../common/generated'

export const EXPORT_DATASET_ANNOTATIONS = gql`
  query ExportDatasetAnnotations($id: Int!) {
    datasetById(id: $id) {
      id
      name
      subjectsByDatasetId {
        nodes {
          name
          geometrySeriesBySubjectId {
            nodes {
              poseTypeByPoseTypeId {
                name
              }
              geometriesByGeometrySeriesId {
                nodes {
                  id
                  geometryAnnotationsByGeometryId {
                    nodes {
                      geometryAnnotationTypeByGeometryAnnotationTypeId {
                        name
                      }
                      point
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface SerializedAnnotations {
  dataset: {
    id: number
    name: string
  }
  annotations: {
    geometryId: number
    pose: string
    subjectName: string
    annotations: NamedPoints
  }[]
}

export function transformAnnotationsForExport(
  data: ExportDatasetAnnotationsQuery
): SerializedAnnotations {
  const { id, name } = data.datasetById
  return {
    dataset: { id, name },
    annotations: data.datasetById.subjectsByDatasetId.nodes.flatMap(subject =>
      subject.geometrySeriesBySubjectId.nodes
        .flatMap(gs =>
          gs.geometriesByGeometrySeriesId.nodes.map(geometry => ({
            geometryId: geometry.id,
            subjectName: subject.name,
            pose: gs.poseTypeByPoseTypeId.name,
            annotations: geometry.geometryAnnotationsByGeometryId.nodes
              .filter(annotation => annotation.point !== null)
              .map(annotation => ({
                name: annotation
                  .geometryAnnotationTypeByGeometryAnnotationTypeId.name,
                point: annotation.point as Vector3,
              })),
          }))
        )
        .filter(geometry => geometry.annotations.length > 0)
    ),
  }
}
