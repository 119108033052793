import {
  TABLE_BACKGROUND,
  TABLE_BACKGROUND_SELECTED,
} from '@unpublished/common-components'
import React from 'react'
import styled from 'styled-components'

import { FlexColumn, StatisticalDiv } from './common-components'
import { Progress, ProgressBar } from './progress-bar'

export const CellRow = styled.div<{ selected?: boolean; inFocus?: boolean }>`
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  flex-direction: column;
  padding: ${({ inFocus }) => (inFocus ? '11px' : '13px')};
  ${({ inFocus }) => inFocus && 'border: 2px solid #1D7575;'}}
  ${({ selected }) =>
    selected ? TABLE_BACKGROUND_SELECTED : TABLE_BACKGROUND}}
`

const CenteredCellRow = styled(CellRow)`
  justify-content: center;
  align-items: center;
`
const ColumnHeader = styled(CenteredCellRow)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  height: 24px;
`
const ProgressContainer = styled(CenteredCellRow)`
  padding-top: 12.5px;
  padding-bottom: 10px;
`
const LightGreyText = styled(StatisticalDiv)`
  font-size: 12px;
  margin-top: 6px;
`
const ScrollingFlexColumn = styled(FlexColumn)`
  overflow: auto;
`
const MaxHeightColumn = styled(FlexColumn)`
  max-height: calc(100vh - 220px);
`
const BorderedFlexColumnWrapper = styled(FlexColumn)`
  margin: 2em 2em 0 0;
  width: 233px;
  height: min-content;
  border: 1px solid #c4c4c4;
  border-bottom: none;
`
export const JustifiedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const SubjectName = styled.div`
  font-size: 14px;
  color: #666666;
`

function ProgressStatus({ progress }: { progress: Progress }): JSX.Element {
  return (
    <ProgressContainer>
      <ProgressBar progress={progress} />
      <LightGreyText>{`${progress.filledUnits} of ${progress.totalUnits} set`}</LightGreyText>
    </ProgressContainer>
  )
}

export function BorderedFlexColumn({
  columnHeaderName,
  columnHeaderIsSelected,
  columnHeaderOnClick,
  isDataLoading,
  progress,
  children,
}: {
  columnHeaderName: string
  columnHeaderIsSelected?: boolean
  columnHeaderOnClick?: () => void
  isDataLoading?: boolean
  progress: Progress
  children: (JSX.Element | null)[] | undefined
}): JSX.Element {
  return (
    <BorderedFlexColumnWrapper>
      <ColumnHeader
        selected={columnHeaderIsSelected}
        onClick={columnHeaderOnClick}
      >
        {columnHeaderName}
      </ColumnHeader>
      {isDataLoading && <p>Loading &hellip;</p>}
      <ProgressStatus progress={progress} />
      <MaxHeightColumn>
        <ScrollingFlexColumn>{children}</ScrollingFlexColumn>
      </MaxHeightColumn>
    </BorderedFlexColumnWrapper>
  )
}
