import { Button } from '@unpublished/common-components'
import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer'

const ToggleButtonContainer = styled.ul<{
  direction?: 'row' | 'column'
  columns?: number
}>`
  list-style-type: none;
  padding-inline-start: 1em;
  min-width: max-content;
  ${({ columns }) => (columns ? `columns:${columns};` : '')}
  ${({ direction }) => (direction === 'row' ? 'margin:0;' : '')}
  > li {
    display: ${({ direction }) => (direction === 'row' ? 'inline' : 'block')};
  }
`
const ToggleButton = styled(Button)<{
  selected: boolean
}>`
  ${({ selected }) =>
    selected
      ? `
    background : rgba(204, 204, 204, 0.7);
    > span:before {
        content: "\\2713\\00A0"; 
    }
    `
      : 'background : rgba(204, 204, 204, 0.15);'}
`

const StyledButton = styled.button`
  padding: 0.5em;
  cursor: pointer;
  background: none;
  color: Gray;
  border: none;
  font-size: 14px;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
  &:hover {
    color: #303030;
    text-decoration: none;
  }
  &:disabled {
    text-decoration: none;
    cursor: default;
    color: Gray;
  }
`

export interface Option<ValueType> {
  label: string
  value: ValueType
}

// To improve rendering performance, the caller should memoize `options`.
export function ToggleableButtonList<ValueType>({
  options,
  onChange,
  selectedOptionValues,
  showSelectAllNoneButtons = true,
  direction = 'column',
  disabled = false,
}: {
  options: Option<ValueType>[]
  onChange: (optionValues: Set<ValueType>) => void
  selectedOptionValues: Set<ValueType>
  showSelectAllNoneButtons?: boolean
  direction?: 'row' | 'column'
  disabled?: boolean
}): JSX.Element {
  // Re-render when container size changes.
  const { ref: containerRef, width } = useResizeObserver<HTMLDivElement>()

  const buttonContainerRef = useRef<HTMLUListElement>(null)
  const [widthOfWidestChild, setWidthOfWidestChild] = useState(0)

  const numColumns =
    options.length > 5 && width !== undefined
      ? Math.round(width / widthOfWidestChild)
      : undefined

  function toggleSelectedOptions(value: ValueType): void {
    const newSelectedOptions = new Set(selectedOptionValues)
    if (newSelectedOptions.has(value)) {
      newSelectedOptions.delete(value)
    } else {
      newSelectedOptions.add(value)
    }
    onChange(newSelectedOptions)
  }

  function selectAllOptions(): void {
    onChange(new Set(Array.from(options).map(s => s.value)))
  }

  function deselectAllOptions(): void {
    onChange(new Set())
  }

  // List must be initially rendered to surface the element widths
  useLayoutEffect(() => {
    if (buttonContainerRef.current) {
      const buttonWidths = Array.from(buttonContainerRef.current.children).map(
        // `child.firstChild` is the <button> inside the <li>.
        child => (child.firstChild as HTMLButtonElement)?.offsetWidth
      )
      setWidthOfWidestChild(Math.max(...buttonWidths))
    }
  }, [buttonContainerRef, options])

  if (new Set(options.map(option => option.label)).size !== options.length) {
    throw Error('Option labels must be unique')
  }

  return (
    <div ref={containerRef}>
      <ToggleButtonContainer
        ref={buttonContainerRef}
        direction={direction}
        columns={direction === 'column' ? numColumns : undefined}
      >
        {options.map(option => (
          <li key={option.label}>
            <ToggleButton
              selected={selectedOptionValues?.has(option.value)}
              onClick={() => toggleSelectedOptions(option.value)}
              disabled={disabled}
            >
              <span>{option.label}</span>
            </ToggleButton>
          </li>
        ))}
      </ToggleButtonContainer>
      {showSelectAllNoneButtons && options.length > 1 ? (
        <div>
          <StyledButton onClick={selectAllOptions} disabled={disabled}>
            Select All
          </StyledButton>
          <StyledButton onClick={deselectAllOptions} disabled={disabled}>
            Select None
          </StyledButton>
        </div>
      ) : undefined}
    </div>
  )
}
