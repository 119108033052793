export const PATH_DIFFERENCE_RATIO_EPSILON = 1e-6

interface IterationPerformance {
  numInvocations: number
  median: number
  ninetiethPercentile: number
  max: number
}

export interface MeasurementPerformance {
  measurementName: string
  leftMeasurementInvocationIteration: number | null
  left?: IterationPerformance
  right?: IterationPerformance
  diff?: {
    diffMedian: number
    diffNinetiethPercentile: number
    diffMax: number
  }
}

export function transformMeasurementPerformance(
  data: {
    measurementName: string | null
    leftMeasurementInvocationIteration: number | null
    leftNumInvocations: any | null
    leftMedianDurationSeconds: number | null
    leftNinetiethPctDurationSeconds: number | null
    leftMaxDurationSeconds: number | null
    rightNumInvocations: any | null
    rightMedianDurationSeconds: number | null
    rightNinetiethPctDurationSeconds: number | null
    rightMaxDurationSeconds: number | null
  }[]
): MeasurementPerformance[] {
  return data.map(
    ({
      measurementName,
      leftMeasurementInvocationIteration,
      leftNumInvocations,
      leftMedianDurationSeconds,
      leftNinetiethPctDurationSeconds,
      leftMaxDurationSeconds,
      rightNumInvocations,
      rightMedianDurationSeconds,
      rightNinetiethPctDurationSeconds,
      rightMaxDurationSeconds,
    }) => ({
      measurementName: measurementName as string,
      leftMeasurementInvocationIteration,
      left:
        leftMedianDurationSeconds === null ||
        leftNinetiethPctDurationSeconds === null ||
        leftMaxDurationSeconds === null
          ? undefined
          : {
              numInvocations: leftNumInvocations,
              median: leftMedianDurationSeconds,
              ninetiethPercentile: leftNinetiethPctDurationSeconds,
              max: leftMaxDurationSeconds,
            },
      right:
        rightMedianDurationSeconds === null ||
        rightNinetiethPctDurationSeconds === null ||
        rightMaxDurationSeconds === null
          ? undefined
          : {
              numInvocations: rightNumInvocations,
              median: rightMedianDurationSeconds,
              ninetiethPercentile: rightNinetiethPctDurationSeconds,
              max: rightMaxDurationSeconds,
            },
      diff:
        leftMedianDurationSeconds === null ||
        leftNinetiethPctDurationSeconds === null ||
        leftMaxDurationSeconds === null ||
        rightMedianDurationSeconds === null ||
        rightNinetiethPctDurationSeconds === null ||
        rightMaxDurationSeconds === null
          ? undefined
          : {
              diffMedian:
                leftMedianDurationSeconds - rightMedianDurationSeconds,
              diffNinetiethPercentile:
                leftNinetiethPctDurationSeconds -
                rightNinetiethPctDurationSeconds,
              diffMax: leftMaxDurationSeconds - rightMaxDurationSeconds,
            },
    })
  )
}
