import { gql, useQuery } from '@apollo/client'
import { Button, FlexRow } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  CenteredDiv,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  RightAlignedButton,
  StatisticalSpan,
} from '../common/common-components'
import { countUnique } from '../common/data-transforms'
import {
  BasicContainer,
  FixedWidthTable,
  FullWidthRow,
  NoWrapDiv,
  TableRow,
} from '../common/flex-table'
import { ListDatasetsAndUploadBucketContentsQuery } from '../common/generated'
import { useOptionalNumericParam } from '../common/use-numeric-param'
import { useRefresh } from '../common/use-refresh'

const SpacedBetweenRow = styled(FlexRow)`
  justify-content: space-between;
`
const FixedWidthContainer = styled(BasicContainer)`
  width: 700px;
`
const GridWithThreeFixedWidthColumns = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  > div {
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 10px;
  }
`

export const LIST_DATASETS_QUERY = gql`
  query ListDatasetsAndUploadBucketContents {
    allDatasets {
      nodes {
        id
        name
        subjectsByDatasetId {
          nodes {
            geometrySeriesBySubjectId {
              nodes {
                poseTypeByPoseTypeId {
                  name
                }
              }
            }
          }
          totalCount
        }
      }
    }
    uploadBucketList {
      Contents {
        Key
      }
    }
    serverSettings {
      uploadBucketName
      uploadBucketS3ConsoleUploadUrl
      awsConsoleSignInUrl
    }
  }
`

export function ChooseDatasetForImport(): JSX.Element {
  const selectedDataset = useOptionalNumericParam('selectedDataset')
  const [userClickedUploadMore, setUserClickedUploadMore] = useState<boolean>()
  const navigate = useNavigate()

  const { isInitiallyLoading, error, data, renderRefreshButton } = useRefresh(
    useQuery<ListDatasetsAndUploadBucketContentsQuery>(LIST_DATASETS_QUERY, {
      notifyOnNetworkStatusChange: true,
    }),
    { autoRefreshIntervalSeconds: 30 }
  )

  const numberOfFilesInBucket = data?.uploadBucketList?.Contents?.length
  const showingFiles = numberOfFilesInBucket && !userClickedUploadMore

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} Import
      </Breadcrumb>
      <h1>Import</h1>
      {error && <p>Oh no! {error.message}</p>}
      {isInitiallyLoading && <p>Loading ...</p>}
      {data && (
        <div>
          <h2>Upload geometries</h2>
          <FixedWidthContainer>
            <SpacedBetweenRow>
              <div>
                <span>
                  {numberOfFilesInBucket ? numberOfFilesInBucket : 'No'}
                </span>{' '}
                geometries uploaded
              </div>
              <div>
                {renderRefreshButton(RightAlignedButton)}{' '}
                {!showingFiles && numberOfFilesInBucket ? (
                  <RightAlignedButton
                    onClick={() => setUserClickedUploadMore(false)}
                  >
                    Show Files
                  </RightAlignedButton>
                ) : null}
              </div>
            </SpacedBetweenRow>
            {showingFiles ? (
              <GridWithThreeFixedWidthColumns>
                {data.uploadBucketList?.Contents.map((contents, index) => (
                  <div key={index}>{contents.Key}</div>
                ))}
              </GridWithThreeFixedWidthColumns>
            ) : (
              <ol>
                <li>
                  Log in to the{' '}
                  <a
                    href={data.serverSettings.awsConsoleSignInUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    AWS Console
                  </a>
                  .
                </li>
                <li>
                  Upload OBJ files to the{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data.serverSettings.uploadBucketS3ConsoleUploadUrl}
                  >
                    {data.serverSettings.uploadBucketName}
                  </a>{' '}
                  bucket
                </li>
              </ol>
            )}
            <CenteredDiv>
              {showingFiles ? (
                <Button onClick={() => setUserClickedUploadMore(true)}>
                  + Upload More
                </Button>
              ) : null}
            </CenteredDiv>
          </FixedWidthContainer>

          <h2>Choose a dataset</h2>
          <FixedWidthTable width="700px">
            {data.allDatasets.nodes.map(node => (
              <TableRow selected={selectedDataset === node.id} key={node.id}>
                <div>{node.name}</div>
                <NoWrapDiv>
                  <div>
                    {node.subjectsByDatasetId.totalCount}{' '}
                    <StatisticalSpan>subjects</StatisticalSpan>
                  </div>
                  <div>
                    {countUnique(
                      node.subjectsByDatasetId.nodes.flatMap(subject =>
                        subject.geometrySeriesBySubjectId.nodes.map(
                          geometrySeries =>
                            geometrySeries.poseTypeByPoseTypeId.name
                        )
                      )
                    )}{' '}
                    <StatisticalSpan>poses</StatisticalSpan>
                  </div>
                </NoWrapDiv>
                <NoWrapDiv>
                  <FlexRow>
                    <Button
                      onClick={() => navigate(`/datasets/${node.id}/subjects`)}
                    >
                      View
                    </Button>
                    <Button
                      disabled={node.id === selectedDataset}
                      onClick={() => navigate(`/import/${node.id}`)}
                    >
                      Select
                    </Button>
                  </FlexRow>
                </NoWrapDiv>
              </TableRow>
            ))}
            <FullWidthRow position="bottom">
              <Button onClick={() => navigate('/import/new-dataset')}>
                + New Dataset
              </Button>
            </FullWidthRow>
          </FixedWidthTable>
          <FixedWidthFooterNavContainer>
            <Button onClick={() => navigate('/')}>Cancel</Button>
            <span />
            <RightAlignedButton
              onClick={() =>
                navigate(`/import/${selectedDataset}/assign-subject-id`)
              }
              disabled={selectedDataset === undefined}
            >
              Next
            </RightAlignedButton>
          </FixedWidthFooterNavContainer>
        </div>
      )}
    </FixedWidthPageContainer>
  )
}
