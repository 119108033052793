export const EXTRACT_SUBJECT_ID_REGEXES = {
  baseFilename: /^(?:.*\/)?([^/.][^/]*?)(?:\.[^./]*)?$/,
  firstDirectory: /^([^/]+)\//,
  lastDirectory: /(?:.*\/)?([^/]+)\//,
}

export function checkMatch(
  input: string,
  regex: RegExp
): { match?: string; hasExtraCaptureGroups: boolean } {
  const match = regex.exec(input)
  if (!match) {
    return { hasExtraCaptureGroups: false }
    // if there's no capture groups return matching expression
  } else if (match.length === 1) {
    return { match: match[0], hasExtraCaptureGroups: false }
  } else return { match: match[1], hasExtraCaptureGroups: match.length > 2 }
}
