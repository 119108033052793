import { Canvas as ThreeCanvas, useThree } from '@react-three/fiber'
import { CanvasSize } from '@unpublished/four'
import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { CSSCanvas } from '../2d/2d-css-canvas'
import { ControlAdapter } from '../interaction/control-adapter'
import { ControlKind, Controls } from '../interaction/controls'
import { DEFAULT_INITIAL_VIEW } from '../scene-content/scene-constants'
import { ReactCanvasContext } from './canvas-context'

export class CanvasAdapter {
  readonly canvasElement: HTMLCanvasElement

  constructor(canvas: HTMLCanvasElement) {
    this.canvasElement = canvas
  }

  getSize(): CanvasSize {
    const { width, height } = this.canvasElement
    return { width, height }
  }
}

function useGlTweaks(): void {
  const gl = useThree(state => state.gl)
  useEffect(() => {
    const context = gl.getContext()
    context.enable(context.BLEND)
    context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA)
  }, [gl])
}

function GlTweaks(): JSX.Element | null {
  useGlTweaks()
  return null
}

export function Canvas({
  preserveDrawingBuffer,
  controlKind,
  render,
  onMouseMove,
  onDoubleClick,
}: {
  preserveDrawingBuffer?: boolean
  controlKind: ControlKind
  render: () => JSX.Element | null
  onMouseMove?: (evt: MouseEvent) => void
  onDoubleClick?: (evt: MouseEvent) => void
}): JSX.Element {
  const canvasContext = useContext(ReactCanvasContext)

  const ourCameraRef = useRef<THREE.OrthographicCamera>()
  const ourMouseMoveRef = useRef<(evt: MouseEvent) => void>()
  const ourDoubleClickRef = useRef<(evt: MouseEvent) => void>()
  const ourCanvasRef = useRef<HTMLCanvasElement>(null)

  ourMouseMoveRef.current = onMouseMove
  ourDoubleClickRef.current = onDoubleClick

  function _onMouseMove(evt: MouseEvent): void {
    if (ourMouseMoveRef.current) {
      ourMouseMoveRef.current(evt)
    }
  }

  function _onDoubleClick(evt: MouseEvent): void {
    if (ourDoubleClickRef.current) {
      ourDoubleClickRef.current(evt)
    }
  }

  return (
    <ThreeCanvas
      ref={ourCanvasRef}
      orthographic
      camera={{
        left: -1,
        right: 1,
        top: -1,
        bottom: 1,
        near: -1000,
        far: 1000,
      }}
      gl={{ preserveDrawingBuffer }}
      onCreated={context => {
        if (!ourCanvasRef.current) {
          throw Error('Expected canvas ref to be populated')
        }
        canvasContext.emit('setCanvas', new CanvasAdapter(ourCanvasRef.current))
        // This is why we are registering event handlers on the canvas using DOM:
        // https://github.com/pmndrs/react-three-fiber/issues/1808
        // TODO: clean up these callbacks when the component is unmounted
        ourCanvasRef.current.addEventListener('mousemove', _onMouseMove)
        ourCanvasRef.current.addEventListener('dblclick', _onDoubleClick)
        const camera = context.camera as THREE.OrthographicCamera | undefined
        if (!camera) {
          throw Error('Expected a camera in onCreated()')
        }
        camera.position.z = DEFAULT_INITIAL_VIEW.position[2]
        ourCameraRef.current = camera
        canvasContext.emit('setCamera', camera)
      }}
    >
      {/*
      Since contexts don't inherit across the renderer boundary (i.e. between
      ReactDOM and r3f) it's necessary to insert a new context provider here to
      bridge the gap. The CanvasContext provided in ReactDOM-land and r3f-land
      will use the same CanvasContext instance.
      */}
      <ReactCanvasContext.Provider value={canvasContext}>
        <GlTweaks />
        <Controls
          kind={controlKind}
          ref={useCallback(
            (controls: ControlAdapter) => {
              // TODO: Maybe emit this event in a useEffect in <Controls /> ?
              canvasContext.emit('setControls', controls)
            },
            [canvasContext]
          )}
        />
        <CSSCanvas />
        {render()}
      </ReactCanvasContext.Provider>
    </ThreeCanvas>
  )
}
