import { useEffect, useRef } from 'react'

export function useKeyDown({
  onKeyDown,
  disabled,
}: {
  onKeyDown: (e: KeyboardEvent) => void
  disabled?: boolean
}): void {
  // Register the event listener only once, and update the ref on each render.

  const onKeyDownRef = useRef<(e: KeyboardEvent) => void>(onKeyDown)
  useEffect(() => {
    onKeyDownRef.current = onKeyDown
  })

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent): void {
      onKeyDownRef.current(e)
    }
    if (!disabled) {
      window.addEventListener('keydown', onKeyDown)
      return () => window.removeEventListener('keydown', onKeyDown)
    }
  }, [disabled, onKeyDown])
}
