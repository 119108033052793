import { Vector3 } from 'polliwog-types'
import * as THREE from 'three'

type TypedArray =
  | Int8Array
  | Uint8Array
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Uint8ClampedArray
  | Float32Array
  | Float64Array

export function computeCentroidFromThreeMesh(
  targetMesh: THREE.Mesh
): THREE.Vector3 {
  targetMesh.updateMatrixWorld()
  const center = new THREE.Vector3(...computeCentroid(targetMesh.geometry))
  targetMesh.localToWorld(center)
  return center
}

export function computeBoundingSphereFromThreeMesh(
  targetMesh: THREE.Mesh
): THREE.Sphere | null {
  targetMesh.updateMatrixWorld()
  targetMesh.geometry.computeBoundingSphere()
  const boundingSphere = targetMesh.geometry.boundingSphere?.clone()
  if (!boundingSphere) {
    throw Error('boundingSphere should be defined')
  }
  boundingSphere.applyMatrix4(targetMesh.matrixWorld)
  return boundingSphere
}

export function computeCentroid(geometry: THREE.BufferGeometry): Vector3 {
  const vertices = geometry.getAttribute('position').array as TypedArray

  let accumX = 0,
    accumY = 0,
    accumZ = 0
  let count = 0
  for (let i = 0; i < vertices.length; i += 3, ++count) {
    const thisVertex = vertices.subarray(i)
    accumX += thisVertex[0]
    accumY += thisVertex[1]
    accumZ += thisVertex[2]
  }
  if (count === 0) {
    throw Error('No vertices')
  }
  return [accumX / count, accumY / count, accumZ / count]
}

export function mirrorView(
  {
    position: [positionX, positionY, positionZ],
    target: [targetX, targetY, targetZ],
    zoom,
  }: {
    position: Vector3
    target: Vector3
    zoom: number
  },
  centerX: number
): {
  position: Vector3
  target: Vector3
  zoom: number
} {
  return {
    position: [-(positionX - centerX), positionY, positionZ],
    target: [-(targetX - centerX), targetY, targetZ],
    zoom,
  }
}

const cameraMatrix = new THREE.Matrix4()
const zeroPadded = new THREE.Vector4()

export function applyMatrixToVector(
  vector: THREE.Vector3,
  matrix: THREE.Matrix4
): THREE.Vector3 {
  zeroPadded
    .set(
      vector.x,
      vector.y,
      vector.z,
      // When using homogeneous coordinates, vectors must be padded with 0 and points must be padded with 1.
      0
    )
    .applyMatrix4(matrix)
  return vector.set(zeroPadded.x, zeroPadded.y, zeroPadded.z)
}

export function unprojectVector(
  vector: THREE.Vector3,
  camera: THREE.OrthographicCamera | THREE.PerspectiveCamera
): THREE.Vector3 {
  cameraMatrix.multiplyMatrices(
    camera.matrixWorld,
    camera.projectionMatrixInverse
  )
  // Clone the vector, since `applyMatrixToVector()` mutates the input.
  const result = new THREE.Vector3().copy(vector)
  applyMatrixToVector(result, cameraMatrix)
  return result
}

export function isUnitLength(
  vector: THREE.Vector3,
  epsilon: number = 1e-6
): boolean {
  const length = vector.length()
  return length > 1.0 - epsilon && length < 1.0 + epsilon
}

export function assertUnitLength(
  vector: THREE.Vector3,
  epsilon: number = 1e-6
): void {
  if (!isUnitLength(vector, epsilon)) {
    throw Error('Vector should be normalized')
  }
}
