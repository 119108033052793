import { gql, useMutation, useQuery } from '@apollo/client'
import { adaptView, LengthUnits, PRESET_VIEWS } from '@curvewise/common-types'
import {
  Action,
  ActionsSidebar,
  AnnotationDelta,
  BorderedFlexColumn,
  Button,
  CellRow,
  FlexRow,
  JustifiedDiv,
  PickedPointAction,
  pickedPointReducer,
  SubjectName,
  usePoints,
} from '@unpublished/common-components'
import {
  ControlState,
  Nudging,
  useCanvasEvent,
  useObjLoader,
} from '@unpublished/scene'
import { maybePluralize } from '@unpublished/victorinox'
import humanizeString from 'humanize-string'
import { Vector3 } from 'polliwog-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStorageReducer } from 'react-storage-hooks'
import styled, { createGlobalStyle, css } from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
} from '../../common/common-components'
import {
  CommitAnnotationsMutation,
  CommitAnnotationsMutationVariables,
  ViewAnnotationsDetailQuery,
  ViewAnnotationsDetailQueryVariables,
  ViewDatasetAnnotationsQuery,
  ViewDatasetAnnotationsQueryVariables,
} from '../../common/generated'
import { ReactComponent as Check } from '../../common/images/check.svg'
import { ReactComponent as LightningIcon } from '../../common/images/lightning.svg'
import { ReactComponent as ClearIcon } from '../../common/images/x-icon.svg'
import { isCtrlKey, useArrowKeys } from '../../common/use-arrow-keys'
import { useKeyDown } from '../../common/use-key-down'
import { useNumericParam } from '../../common/use-numeric-param'
import { Viewer } from '../../common/viewer'
import {
  createAnnotationsViewModel,
  VIEW_DATASET_ANNOTATIONS_DETAIL_QUERY,
  VIEW_DATASET_ANNOTATIONS_QUERY,
} from './view-model'

const FlexRowWithMargin = styled(FlexRow)`
  padding-top: 20px;
`
export const PageHeader = styled(JustifiedDiv)`
  max-width: 1502px;
`
const DivWithLeftPadding = styled.div`
  padding-top: 7px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #00000080;
  span {
    padding-left: 5px;
  }
`
const STATS_CLASS_NAME = 'goldie-drei-stats'

const StatsGlobalStyle = createGlobalStyle`
  .${STATS_CLASS_NAME} {
    top: inherit !important;
    bottom: 25px;
    left: 36px !important;
  }
`
export type NavFocus = 'subjects' | 'annotations'

export function PointAnnotations(): JSX.Element {
  const selectedDataset = useNumericParam('selectedDataset')
  const viewAnnotationsQuery = useQuery<
    ViewDatasetAnnotationsQuery,
    ViewDatasetAnnotationsQueryVariables
  >(VIEW_DATASET_ANNOTATIONS_QUERY, {
    variables: { id: selectedDataset },
  })
  const [selectedGeometryId, setSelectedGeometryId] = useState<number>()
  const [selectedAnnotationTypeId, setSelectedAnnotationTypeId] =
    useState<number>()
  const [navFocus, setNavFocus] = useState<NavFocus>('subjects')
  const [showLabels, setShowLabels] = useState<boolean>(false)

  const detailQuery = useQuery<
    ViewAnnotationsDetailQuery,
    ViewAnnotationsDetailQueryVariables
  >(VIEW_DATASET_ANNOTATIONS_DETAIL_QUERY, {
    variables: { id: selectedGeometryId || 0 },
    onCompleted: () => {
      setCommittedChangeCount(undefined)
    },
  })
  // this describes how we are using generics on the reducer's type signature: https://earezki.com/react-hooks-usereducer-generics-params-in-typescript/
  // also, this describes the type signature for useStorageReducer: https://stackleap.io/js/react-storage-hooks#user-content-signature-1
  const [annotationDeltas, dispatchAnnotationDeltas] = useStorageReducer<
    AnnotationDelta<number>[],
    PickedPointAction<number>
  >(
    localStorage,
    `uncommittedAnnotationChanges${selectedDataset}`,
    pickedPointReducer,
    []
  )
  const [nudging, setNudging] = useState<Nudging>()
  const viewModel = createAnnotationsViewModel({
    data: viewAnnotationsQuery.data,
    localDeltas: annotationDeltas,
    detailQueryData: detailQuery.data,
    selectedAnnotationTypeId,
    selectedGeometryId,
    navFocus,
  })
  const [isCommitting, setIsCommitting] = useState(false)
  const [committedChangeCount, setCommittedChangeCount] = useState<number>()
  const [commitAnnotations, { error: commitAnnotationsError }] = useMutation<
    CommitAnnotationsMutation,
    CommitAnnotationsMutationVariables
  >(
    gql`
      mutation CommitAnnotations($input: mnUpdateGeometryAnnotationByIdInput!) {
        mnUpdateGeometryAnnotationById(input: $input) {
          clientMutationId
          geometryAnnotation {
            geometryId
            id
            nodeId
            point
          }
        }
      }
    `,
    {
      variables: {
        input: {
          mnGeometryAnnotationPatch: annotationDeltas,
        },
      },
      refetchQueries: [VIEW_DATASET_ANNOTATIONS_QUERY],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        setIsCommitting(false)
        setCommittedChangeCount(annotationDeltas.length)
        if (viewAnnotationsQuery.data) {
          const { data } = await viewAnnotationsQuery.refetch()
          dispatchAnnotationDeltas({
            type: 'discard-saved-deltas',
            deltas: data.datasetById.subjectsByDatasetId.nodes.flatMap(s =>
              s.geometrySeriesBySubjectId.nodes.flatMap(gs =>
                gs.geometriesByGeometrySeriesId.nodes.flatMap(ga =>
                  ga.geometryAnnotationsByGeometryId.nodes.map(node => ({
                    id: node.id,
                    point: node.point as Vector3,
                  }))
                )
              )
            ),
          })
        }
      },
      onError() {
        setIsCommitting(false)
      },
    }
  )

  useEffect(() => {
    if (selectedGeometryId === undefined && viewModel.geometries.length > 0) {
      setSelectedGeometryId(viewModel.geometries[0].id)
    }
  }, [selectedGeometryId, viewModel])
  useEffect(() => {
    if (selectedAnnotationTypeId === undefined && viewModel.points.length > 0) {
      setSelectedAnnotationTypeId(viewModel.points[0].annotationTypeId)
    }
  }, [selectedAnnotationTypeId, viewModel])
  const { onMouseMove, onDoubleClick, lastHoveredPointOnMesh } =
    usePoints<number>({
      currentPoint: viewModel.selectedGeometry.selectedPoint,
      nudging,
      selectedAnnotationId: viewModel?.selectedGeometry.selectedAnnotationId,
      dispatch: dispatchAnnotationDeltas,
    })

  function handleCommit(): void {
    setIsCommitting(true)
    setNudging(undefined)
    commitAnnotations()
  }

  function initializeNudgeForSelectedPoint(): void {
    const selectedPoint = viewModel.selectedGeometry.selectedPoint
    if (selectedPoint) {
      setNudging({
        originalPoint: selectedPoint,
      })
    }
  }

  function handleNudge(): void {
    if (nudging === undefined) {
      initializeNudgeForSelectedPoint()
    } else setNudging(undefined)
  }

  const { body, errorMessage } = useObjLoader({
    s3Key: detailQuery.data?.geometryById.s3Key,
    signedURL: detailQuery.data?.geometryById.signedURL,
    enabled: !!detailQuery.data,
  })

  function primaryNavUpdate(id: number): void {
    if (navFocus === 'subjects') {
      return setSelectedGeometryId(id)
    } else {
      return setSelectedAnnotationTypeId(id)
    }
  }
  function secondaryNavUpdate(id: number): void {
    if (navFocus === 'subjects') {
      return setSelectedAnnotationTypeId(id)
    } else {
      return setSelectedGeometryId(id)
    }
  }

  useArrowKeys({
    disabled: nudging !== undefined,
    onArrowUp() {
      const target = viewModel.adjacentNavIds.primaryPrevious
      if (target) {
        primaryNavUpdate(target)
      }
    },
    onArrowDown() {
      const target = viewModel.adjacentNavIds.primaryNext
      if (target) {
        primaryNavUpdate(target)
      }
    },
    onCtrlArrowUp() {
      const target = viewModel.adjacentNavIds.secondaryPrevious
      if (target) {
        secondaryNavUpdate(target)
      }
    },
    onCtrlArrowDown() {
      const target = viewModel.adjacentNavIds.secondaryNext
      if (target) {
        secondaryNavUpdate(target)
      }
    },
  })

  useKeyDown({
    onKeyDown: (e: KeyboardEvent) => {
      if (isCtrlKey(e) && e.key === 'z' && nudging) {
        return dispatchAnnotationDeltas({
          type: 'update-delta',
          point: nudging.originalPoint,
          id: viewModel?.selectedGeometry.selectedAnnotationId,
        })
      }
    },
  })

  const [zoom, setZoom] = useState(1)
  useCanvasEvent(
    'cameraDidUpdate',
    useCallback(({ zoom }: ControlState) => {
      setZoom(zoom)
    }, [])
  )

  return (
    <FixedWidthPageContainer>
      <StatsGlobalStyle />
      <PageHeader>
        <Breadcrumb>
          <Link to="/">Home</Link> {'> '} <Link to="/datasets">Datasets</Link>{' '}
          {'>'}{' '}
          <Link to={`/datasets/${selectedDataset}/subjects`}>
            {viewAnnotationsQuery.data?.datasetById.name}
          </Link>{' '}
          {'>'} Annotations
        </Breadcrumb>
        {viewAnnotationsQuery.error && viewAnnotationsQuery.error.message}
        {commitAnnotationsError && commitAnnotationsError.message}
        {committedChangeCount !== undefined &&
          `${committedChangeCount} ${maybePluralize(
            'change',
            committedChangeCount
          )} committed.`}
        <FlexRow>
          <Button
            onClick={() =>
              dispatchAnnotationDeltas({ type: 'discard-changes' })
            }
            disabled={annotationDeltas.length === 0}
          >
            Discard changes
          </Button>
          <Button
            onClick={() => handleCommit()}
            disabled={isCommitting || annotationDeltas.length === 0}
          >
            Commit changes
          </Button>
        </FlexRow>
      </PageHeader>
      <FlexRowWithMargin>
        <ActionsSidebar>
          <Action
            isActive={nudging !== undefined}
            onClick={() => handleNudge()}
          >
            <span>Edit point</span>
          </Action>
          <Action
            onClick={() => {
              dispatchAnnotationDeltas({
                type: 'clear-point',
                id: viewModel.selectedGeometry.selectedAnnotationId,
              })
              setNudging(undefined)
            }}
          >
            <ClearIcon fillOpacity={'0.3'} />
            <span>Clear point</span>
          </Action>
          <Action
            isActive={showLabels}
            onClick={() => setShowLabels(!showLabels)}
          >
            <span>Show labels</span>
          </Action>
        </ActionsSidebar>
        {errorMessage && <span>{errorMessage}</span>}
        <Viewer
          containerCSS={css`
            width: 775px;
            padding: 10px 35px;
          `}
          initialView={adaptView({
            view: PRESET_VIEWS.views[0],
            height: 592,
            width: 775,
          })}
          viewerCSS={css`
            height: 592px;
          `}
          body={body}
          url={viewModel.selectedGeometry.signedURL}
          bodyS3Key={viewModel.selectedGeometry.s3Key}
          pointsWithRenderDetails={{
            points: viewModel.selectedGeometry.points,
            showPointLabels: showLabels,
          }}
          show3DView={true}
          setShow3DView={() => null}
          onMouseMove={onMouseMove}
          onDoubleClick={onDoubleClick}
          cursorPosition={lastHoveredPointOnMesh}
          withStats={{ withStats: true, statsClassName: STATS_CLASS_NAME }}
          pickPointRadiusCm={3.5 / zoom}
          superiorAxis={viewModel.dataset.superiorAxis}
          anteriorAxis={viewModel.dataset.anteriorAxis}
          units={viewModel.dataset.units?.toLowerCase() as LengthUnits}
        />
        <BorderedFlexColumn
          columnHeaderName="Subjects"
          columnHeaderIsSelected={navFocus === 'subjects'}
          columnHeaderOnClick={() => setNavFocus('subjects')}
          isDataLoading={viewAnnotationsQuery.loading}
          progress={viewModel.geometryProgress}
        >
          {viewModel.geometries.map(geometry => (
            <CellRow
              key={geometry.id}
              selected={geometry.id === selectedGeometryId}
              inFocus={
                geometry.id === selectedGeometryId && navFocus === 'subjects'
              }
              onClick={() => {
                setSelectedGeometryId(geometry.id)
                nudging && initializeNudgeForSelectedPoint()
              }}
            >
              <JustifiedDiv>
                <SubjectName>{geometry.subjectName}</SubjectName>
                {geometry.hasPoint && (
                  <Check fillOpacity={geometry.pointIsDirty ? '1.0' : '0.56'} />
                )}
                {geometry.pointIsDirty && !geometry.hasPoint && <ClearIcon />}
              </JustifiedDiv>
              {viewModel.hasMultiplePoses && (
                <DivWithLeftPadding>
                  <LightningIcon fillOpacity={'0.3'} />
                  <span>{geometry.pose}</span>
                </DivWithLeftPadding>
              )}
            </CellRow>
          ))}
        </BorderedFlexColumn>
        <BorderedFlexColumn
          columnHeaderName="Annotations"
          columnHeaderIsSelected={navFocus === 'annotations'}
          columnHeaderOnClick={() => setNavFocus('annotations')}
          isDataLoading={viewAnnotationsQuery.loading}
          progress={viewModel.pointProgress}
        >
          {viewModel.points.map(point => (
            <CellRow
              key={point.pointName}
              selected={point.annotationTypeId === selectedAnnotationTypeId}
              onClick={() => {
                setSelectedAnnotationTypeId(point.annotationTypeId)
                nudging && initializeNudgeForSelectedPoint()
              }}
              inFocus={
                point.annotationTypeId === selectedAnnotationTypeId &&
                navFocus === 'annotations'
              }
            >
              <JustifiedDiv>
                <SubjectName>{humanizeString(point.pointName)}</SubjectName>
                {point.isSetForGeometry && (
                  <Check fillOpacity={point.pointIsDirty ? '1.0' : '0.56'} />
                )}
                {point.pointIsDirty && !point.isSetForGeometry && <ClearIcon />}
              </JustifiedDiv>
            </CellRow>
          ))}
        </BorderedFlexColumn>
      </FlexRowWithMargin>
    </FixedWidthPageContainer>
  )
}
