import { useLoader } from '@react-three/fiber'
import * as THREE from 'three'

import { BUMP_URL, getPreset, getTextureUrl } from './scene-appearance'

export function preloadDefaultAssets(): void {
  useLoader.preload(THREE.TextureLoader, BUMP_URL)

  // This breaks abstraction a bit. If we start using different textures we may
  // want to generalize this.
  const { texture } = getPreset('Gray fabric')
  if (texture) {
    useLoader.preload(THREE.TextureLoader, getTextureUrl(texture))
  }
}
