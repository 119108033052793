import { FlexColumn } from '@unpublished/common-components'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { StyledInput } from '../../common/common-components'
import { ReactComponent as _NewLabelIcon } from '../../common/images/new-tag.svg'
import { Label, listContainsLabel } from '../labels'
import { LabelContainerForSubjects, StyledLabel } from './display-label'
import { stopPropagationOfKeyboardShortcuts } from './use-keyboard-shortcuts'

const LABEL_MAX_LENGTH = 140

const VerticallyCenterAlignedFlexRow = styled.div`
  display: flex;
  align-items: center;
`
const NewLabelIcon = styled(_NewLabelIcon)`
  margin-right: 5px;
`
const ClickableText = styled.span<{ disabled: boolean }>`
  font-size: 12px;
  cursor: pointer;
  padding-right: 10px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: '';
    `};
`
const NoWrapStyledLabel = styled(StyledLabel)`
  white-space: nowrap;
`
type LabelKind = 'failure' | 'success'
interface CreatingLabel {
  kind: LabelKind
  text: string
}

export function LabelCreationComponent({
  onCreate,
  disabled,
  labelChoices,
}: {
  onCreate: (label: Label) => void
  disabled: boolean
  labelChoices: Label[]
}): JSX.Element {
  const [creatingLabel, setCreatingLabel] = useState<CreatingLabel>()

  const creatingLabelIsValid =
    creatingLabel !== undefined &&
    creatingLabel.text.length > 0 &&
    creatingLabel.text.length <= LABEL_MAX_LENGTH &&
    !listContainsLabel(labelChoices, {
      name: creatingLabel.text,
      isFailure: creatingLabel.kind === 'failure',
    })

  function performCreate(): void {
    if (creatingLabel && creatingLabelIsValid) {
      onCreate({
        name: creatingLabel.text,
        isFailure: creatingLabel.kind === 'failure',
      })
      setCreatingLabel(undefined)
    }
  }

  if (creatingLabel) {
    return (
      <FlexColumn>
        <StyledLabel
          $isFailure={creatingLabel.kind === 'failure'}
          disabled={false}
          height="40px"
        >
          <VerticallyCenterAlignedFlexRow>
            <NewLabelIcon />{' '}
            <form>
              <StyledInput
                placeholder="too short"
                value={creatingLabel.text}
                invalid={creatingLabel.text.length > 0 && !creatingLabelIsValid}
                onKeyDown={e => {
                  // To avoid conflict with Enter shortcut key, handle
                  // enter-to-submit with `<input onKeyDown{}>` instead of
                  // `<form onSubmit{}>`.
                  stopPropagationOfKeyboardShortcuts(e)
                  if (e.key === 'Enter') {
                    performCreate()
                  }
                }}
                onChange={e =>
                  setCreatingLabel({
                    kind: creatingLabel.kind,
                    text: e.target.value,
                  })
                }
              />
            </form>
          </VerticallyCenterAlignedFlexRow>
        </StyledLabel>
        <LabelContainerForSubjects>
          <ClickableText
            disabled={!creatingLabelIsValid}
            onClick={performCreate}
          >
            add
          </ClickableText>
          <ClickableText
            disabled={false}
            onClick={() => {
              setCreatingLabel(undefined)
            }}
          >
            cancel
          </ClickableText>
        </LabelContainerForSubjects>
      </FlexColumn>
    )
  } else {
    return (
      <>
        {(['failure', 'success'] as LabelKind[]).map(kind => (
          <NoWrapStyledLabel
            key={kind}
            $isFailure={kind === 'failure'}
            disabled={disabled}
            onClick={() => setCreatingLabel({ kind, text: '' })}
          >
            <NewLabelIcon /> {`add ${kind}`}
          </NoWrapStyledLabel>
        ))}
      </>
    )
  }
}
