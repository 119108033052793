"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var common_1 = require("./common");
var FORCE_STATE_ACTION = '__FORCE_STATE_INTERNAL_API__';
function isForceStateAction(action) {
    return (typeof action === 'object' &&
        action !== null &&
        'type' in action &&
        action.type === FORCE_STATE_ACTION);
}
function addForceStateActionToReducer(reducer) {
    return function (state, action) {
        if (isForceStateAction(action))
            return action.payload;
        return reducer(state, action);
    };
}
function useStorageReducer(storage, key, reducer, defaultInitialArg, defaultInit) {
    if (defaultInit === void 0) { defaultInit = function (x) { return x; }; }
    var defaultState = defaultInit(defaultInitialArg);
    var _a = react_1.useReducer(addForceStateActionToReducer(reducer), common_1.useInitialState(storage, key, defaultState)), state = _a[0], dispatch = _a[1];
    common_1.useStorageListener(storage, key, defaultState, function (newValue) {
        dispatch({ type: FORCE_STATE_ACTION, payload: newValue });
    });
    var writeError = common_1.useStorageWriter(storage, key, state);
    return [state, dispatch, writeError];
}
exports.default = useStorageReducer;
