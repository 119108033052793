import CameraControls from 'camera-controls'
import { Vector3 } from 'polliwog-types'
import * as THREE from 'three'

import { CanvasContext } from '../canvas/canvas-context'
// @ts-ignore
import { ArcballControls } from './ArcballControls.js'

export type ControlType = CameraControls | ArcballControls

// TODO: This interface is the same as `InMemoryBodyView`. Define this once
// somewhere -- probably by renaming it to CameraState and adding it to
// common-types in place of BodyView. Then consume that type everywhere else.
export interface ControlState {
  position: Vector3
  target: Vector3
  up: Vector3
  zoom: number
}

function normalizeAndRoundAngle(
  angleDeg: number,
  { decimals = 1 }: { decimals?: number } = {}
): number {
  while (angleDeg > 180) {
    angleDeg -= 360
  }
  while (angleDeg < -180) {
    angleDeg += 360
  }
  return +angleDeg.toFixed(decimals)
}

function degToRad(angleDeg: number): number {
  return (angleDeg * Math.PI) / 90
}

function radToDeg(angleRad: number): number {
  return (angleRad / Math.PI) * 90
}

const THREE_Y_BASIS = new THREE.Vector3(0, 1, 0)
const Y_BASIS: Vector3 = [0, 1, 0]

export abstract class AbstractControlAdapter<InstanceType extends ControlType> {
  protected readonly controls: InstanceType
  protected readonly camera: THREE.Camera

  constructor({
    camera,
    controls,
  }: {
    camera: THREE.Camera
    controls: InstanceType
  }) {
    this.controls = controls
    this.camera = camera
  }

  abstract bindToContext(canvasContext: CanvasContext): () => void

  abstract getState(): ControlState

  abstract getAzimuthAngleDeg(options?: { decimals?: number }): number

  abstract goToView(
    view: ControlState,
    options?: { enableTransition?: boolean }
  ): void

  abstract setAzimuthAngleDeg(
    azimuthAngleDeg: number,
    options?: { enableTransition?: boolean }
  ): Promise<void>

  abstract setRotationTarget(target: THREE.Vector3): void

  abstract setRotationConstraints(verticalAngleIsConstrained: boolean): void

  abstract waitForUpdate(): Promise<void>

  abstract disable(): void
  abstract enable(): void
  abstract update(delta: number): void
}

export class YomotsuControlAdapter extends AbstractControlAdapter<CameraControls> {
  bindToContext(canvasContext: CanvasContext): () => void {
    const update = (): void => {
      canvasContext.emit('cameraDidUpdate', this.getState())
    }
    const sleep = (): void => {
      canvasContext.emit('controlDidSleep', this.getState())
    }
    const controlstart = (): void => {
      canvasContext.emit('controlDidStart')
    }
    const controlend = (): void => {
      canvasContext.emit('controlDidEnd')
    }

    const { controls } = this

    controls.addEventListener('update', update)
    controls.addEventListener('sleep', sleep)
    controls.addEventListener('controlstart', controlstart)
    controls.addEventListener('controlend', controlend)

    return () => {
      controls.removeEventListener('update', update)
      controls.removeEventListener('sleep', sleep)
      controls.removeEventListener('controlstart', controlstart)
      controls.removeEventListener('controlend', controlend)
    }
  }

  getState(): ControlState {
    const { controls } = this
    return {
      position: controls.getPosition(new THREE.Vector3()).toArray(),
      target: controls.getTarget(new THREE.Vector3()).toArray(),
      up: Y_BASIS,
      zoom: (this.camera as THREE.PerspectiveCamera).zoom,
    }
  }

  getAzimuthAngleDeg(options?: { decimals?: number }): number {
    const { theta } = (this.controls as any)._spherical
    return normalizeAndRoundAngle(radToDeg(theta), options)
  }

  goToView(
    { position, target, zoom }: ControlState,
    { enableTransition = true }: { enableTransition?: boolean } = {}
  ): void {
    const { controls } = this
    controls.setLookAt(...position, ...target, enableTransition)
    controls.zoomTo(zoom, enableTransition)
  }

  setAzimuthAngleDeg(
    azimuthAngleDeg: number,
    { enableTransition = true }: { enableTransition?: boolean } = {}
  ): Promise<void> {
    const polarAngleRad = Math.PI / 2
    return this.controls.rotateTo(
      degToRad(azimuthAngleDeg),
      polarAngleRad,
      enableTransition
    )
  }

  setRotationTarget(target: THREE.Vector3): void {
    // Not implemented for OrbitControls.
  }

  setRotationConstraints(verticalAngleIsConstrained: boolean): void {
    const { controls } = this
    if (verticalAngleIsConstrained) {
      controls.minPolarAngle = Math.PI / 2.0
      controls.maxPolarAngle = Math.PI / 2.0
    } else {
      controls.minPolarAngle = 0
      controls.maxPolarAngle = Math.PI
    }
  }

  waitForUpdate(): Promise<void> {
    const { controls } = this
    return new Promise(resolve => {
      const onUpdate = (): void => {
        controls.removeEventListener('update', onUpdate)
        resolve()
      }
      controls.addEventListener('update', onUpdate)
    })
  }

  enable(): void {
    this.controls.enabled = true
  }

  disable(): void {
    this.controls.enabled = false
  }

  update(delta: number): void {
    this.controls.update(delta)
  }
}

export class ArcballControlAdapter extends AbstractControlAdapter<ArcballControls> {
  // TODO: Check if this guard is necessary to prevent extraneous events during
  // calls to `goToView()` -- which should not trigger events. If it's not
  // necessary, remove it.
  private isPaused: boolean = false

  // There is no sleep event for ArcballControls.
  bindToContext(canvasContext: CanvasContext): () => void {
    const start = (): void => {
      canvasContext.emit('controlDidStart')
    }
    const end = (): void => {
      canvasContext.emit('controlDidEnd')
    }
    const change = (): void => {
      if (this.isPaused) {
        return
      }
      canvasContext.emit('cameraDidUpdate', this.getState())
    }

    const { controls } = this

    controls.addEventListener('start', start)
    controls.addEventListener('end', end)
    controls.addEventListener('change', change)

    return () => {
      controls.removeEventListener('start', start)
      controls.removeEventListener('end', end)
      controls.removeEventListener('change', change)
    }
  }

  getState(): ControlState {
    const { camera, controls } = this
    return {
      position: camera.position.toArray(),
      target: controls.target.toArray(),
      up: camera.up.toArray(),
      zoom: (this.camera as THREE.PerspectiveCamera).zoom,
    }
  }

  getAzimuthAngleDeg(options?: { decimals?: number }): number {
    // Not implemented for ArcballControls.
    return 0
  }

  goToView(
    { position, target, up, zoom }: ControlState,
    { enableTransition = true }: { enableTransition?: boolean } = {}
  ): void {
    const { camera, controls } = this
    this.isPaused = true
    try {
      controls.reset()
      ;(camera as THREE.PerspectiveCamera).zoom = zoom
      camera.position.set(...position)
      controls.target.set(...target)
      controls.setCamera(camera)
      // TODO: Check if this can be done before `setCamera()`.
      camera.up.set(...up)
      ;(camera as any).updateMatrix()
      ;(camera as any).updateProjectionMatrix()
    } finally {
      this.isPaused = false
    }
    controls.update()
  }

  setAzimuthAngleDeg(
    azimuthAngleDeg: number,
    options: { enableTransition?: boolean } = {}
  ): Promise<void> {
    this.controls.camera.setRotationFromAxisAngle(
      THREE_Y_BASIS,
      degToRad(azimuthAngleDeg)
    )
    return Promise.resolve()
  }

  setRotationTarget(target: THREE.Vector3): void {
    this.controls.rotationTarget = new THREE.Vector3().copy(target)
  }

  setRotationConstraints(verticalAngleIsConstrained: boolean): void {
    if (verticalAngleIsConstrained) {
      throw Error(
        'Rotation constraints are not implemented for ArcballControls'
      )
    }
  }

  waitForUpdate(): Promise<void> {
    throw Error('Not implemented for ArcballControls')
  }

  enable(): void {
    this.controls.enabled = true
  }

  disable(): void {
    this.controls.enabled = false
  }

  update(delta: number): void {
    // Not necessary for ArcballControls.
  }
}

export type ControlAdapter = AbstractControlAdapter<any>
