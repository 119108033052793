import { gql, useMutation, useQuery } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { Button, Picker } from '@unpublished/common-components'
import { groupBy } from 'lodash'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  IterationDisplay,
} from '../common/common-components'
import { humanizeTopology } from '../common/data-transforms'
import {
  AggregateQuery,
  AggregateQueryVariables,
  ErrorInvocationsQuery,
  ErrorInvocationsQueryVariables,
  RerunFailedOrStuckMeasurementInvocationsMutation,
  RerunFailedOrStuckMeasurementInvocationsMutationVariables,
  SelectQuery,
  SelectQueryVariables,
} from '../common/generated'
import { MeasurementPicker } from '../common/measurement-picker'
import {
  PromptActionButton,
  PromptContainer,
  PromptTextContainer,
} from '../common/prompt'
import { useNumericParam } from '../common/use-numeric-param'
import { IterationPicker } from './iteration-picker'

export const SELECT_QUERY = gql`
  query Select($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      name
    }
    allIncompleteMeasurementInvocationsSubjectNames(
      condition: { measurementStudyId: $measurementStudyId }
    ) {
      nodes {
        subjectName
      }
    }
    allIncompleteMeasurementInvocationsMeasurementNames(
      condition: { measurementStudyId: $measurementStudyId }
    ) {
      nodes {
        measurementName
      }
    }
    allIncompleteMeasurementInvocationsIterations(
      condition: { measurementStudyId: $measurementStudyId }
    ) {
      nodes {
        measurementInvocationIteration
      }
    }
  }
`

// TODO: rename this query to MEASUREMENT_INVOCATION_ERRORS_QUERY
export const ERROR_INVOCATIONS_QUERY = gql`
  query ErrorInvocations(
    $measurementStudyId: Int!
    $measurementInvocationIteration: Int
    $measurementName: String
    $subjectName: String
  ) {
    allFlattenedIncompleteMeasurementInvocations(
      condition: {
        measurementStudyId: $measurementStudyId
        measurementInvocationIteration: $measurementInvocationIteration
        measurementName: $measurementName
        subjectName: $subjectName
      }
    ) {
      nodes {
        measurementInvocationIteration
        measurementStudyId
        measurementInvocationId
        measurementName
        stateMachineStateId
        subjectName
        error
        poseName
        topology
      }
    }
  }
`

export const AGGREGATE_QUERY = gql`
  query Aggregate(
    $measurementStudyId: Int
    $measurementInvocationIteration: Int
    $measurementName: String
    $subjectName: String
  ) {
    flattenedMeasurementInvocationCountByStateMachineState(
      measurementInvocationIteration: $measurementInvocationIteration
      measurementName: $measurementName
      measurementStudyId: $measurementStudyId
      subjectName: $subjectName
    ) {
      nodes {
        stateMachineStateId
        stateMachineStateIdCount
      }
    }
  }
`

interface Invocation {
  measurementInvocationId: number
  measurementInvocationIteration: number
  subjectName: string
  measurementName: string
  stateMachineStateId: string
  error: (string | null)[] | null
  poseName: string
  topology: string | null
}

function createViewModel({
  selectData,
  errorsData,
  selectedIteration,
  selectedMeasurement,
  selectedSubject,
  aggregateData,
}: {
  selectData?: SelectQuery
  errorsData?: ErrorInvocationsQuery
  selectedIteration?: number
  selectedMeasurement?: string
  selectedSubject?: string
  aggregateData?: AggregateQuery
}): {
  name?: string
  iterationChoices: number[]
  measurementChoices: string[]
  subjectChoices: string[]
  errorCount: number
  errorInvocationsByIterationAndMeasurement: {
    measurementInvocationIteration: number
    measurements: {
      measurementName: string
      invocations: Invocation[]
    }[]
  }[]
  numFailedOrStuckMeasurementInvocations?: number
} {
  const iterationChoices =
    selectData?.allIncompleteMeasurementInvocationsIterations.nodes.map(
      node => node.measurementInvocationIteration as number
    ) || []

  const errorInvocationsByIterationAndMeasurement = Object.values(
    // First nest by iteration...
    groupBy(
      errorsData?.allFlattenedIncompleteMeasurementInvocations
        .nodes as Invocation[],
      'measurementInvocationIteration'
    )
  ).map(invocations => ({
    measurementInvocationIteration:
      invocations[0].measurementInvocationIteration,
    // ...then by measurement.
    measurements: Object.entries(groupBy(invocations, 'measurementName')).map(
      ([measurementName, invocations]) => ({
        measurementName,
        invocations,
      })
    ),
  }))

  return {
    name: selectData?.measurementStudyById?.name,
    iterationChoices,
    measurementChoices:
      selectData?.allIncompleteMeasurementInvocationsMeasurementNames.nodes.map(
        node => node.measurementName as string
      ) || [],
    subjectChoices:
      selectData?.allIncompleteMeasurementInvocationsSubjectNames.nodes.map(
        node => node.subjectName as string
      ) || [],
    errorCount:
      errorsData?.allFlattenedIncompleteMeasurementInvocations.nodes.length ||
      0,
    errorInvocationsByIterationAndMeasurement,
    numFailedOrStuckMeasurementInvocations:
      selectedIteration &&
      aggregateData?.flattenedMeasurementInvocationCountByStateMachineState?.nodes.reduce(
        (aggCount, { stateMachineStateId, stateMachineStateIdCount }) =>
          aggCount +
          ((stateMachineStateId === 'STARTED' ||
            stateMachineStateId === 'ERROR') &&
          stateMachineStateIdCount
            ? stateMachineStateIdCount
            : 0),
        0
      ),
  }
}

const Filters = styled.ul`
  padding: 0;

  > li {
    display: inline-block;
    margin-right: 20px;
    list-style: none;
    vertical-align: top;
  }
`

const NarrowFilter = styled.li`
  width: 100px;
`

const WideFilter = styled.li`
  width: 300px;
`

const ErrorContainer = styled.div`
  margin-left: 10px;
`

const Stacktrace = styled.div`
  white-space: pre-wrap;
  margin-left: 10px;
`

export function MeasurementErrors(): JSX.Element {
  const selectedStudy = useNumericParam('selectedStudy')
  const [selectedIteration, setSelectedIteration] = useState<
    number | undefined
  >()
  const [selectedMeasurement, setSelectedMeasurement] = useState<
    string | undefined
  >()
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>()

  const {
    loading: selectLoading,
    error: selectError,
    data: selectData,
  } = useQuery<SelectQuery, SelectQueryVariables>(SELECT_QUERY, {
    variables: { measurementStudyId: selectedStudy },
  })

  const noDropdownIsSelected =
    !selectedSubject && !selectedIteration && !selectedMeasurement

  const {
    loading: errorsLoading,
    error: errorsError,
    data: errorsData,
  } = useQuery<ErrorInvocationsQuery, ErrorInvocationsQueryVariables>(
    ERROR_INVOCATIONS_QUERY,
    {
      variables: {
        measurementStudyId: selectedStudy,
        measurementInvocationIteration: selectedIteration,
        measurementName: selectedMeasurement,
        subjectName: selectedSubject,
      },
      skip: noDropdownIsSelected,
    }
  )

  const {
    loading: aggregateLoading,
    error: aggregateError,
    data: aggregateData,
  } = useQuery<AggregateQuery, AggregateQueryVariables>(AGGREGATE_QUERY, {
    variables: {
      measurementStudyId: selectedStudy,
      measurementInvocationIteration: selectedIteration || null,
      measurementName: selectedMeasurement || null,
      subjectName: selectedSubject || null,
    },
  })

  const [
    rerunFailedOrStuckMeasurementInvocations,
    {
      error: rerunFailedOrStuckMeasurementInvocationsError,
      data: rerunFailedOrStuckMeasurementInvocationsData,
    },
  ] = useMutation<
    RerunFailedOrStuckMeasurementInvocationsMutation,
    RerunFailedOrStuckMeasurementInvocationsMutationVariables
  >(
    gql`
      mutation RerunFailedOrStuckMeasurementInvocations(
        $measurementStudyId: Int!
        $measurementInvocationIteration: Int!
      ) {
        rerunFailedOrStuckMeasurementInvocations(
          input: {
            measurementStudyId: $measurementStudyId
            measurementInvocationIteration: $measurementInvocationIteration
          }
        ) {
          integer
        }
      }
    `,
    {
      variables: {
        measurementStudyId: selectedStudy,
        measurementInvocationIteration: selectedIteration as number,
      },
    }
  )

  const viewModel = createViewModel({
    selectData,
    errorsData,
    selectedIteration,
    selectedMeasurement,
    selectedSubject,
    aggregateData,
  })

  const canReRunMeasurementInvocations =
    !!viewModel?.numFailedOrStuckMeasurementInvocations

  // TODO: refactor this out into a hook?
  const navigate = useNavigate()
  function goToStudy(): void {
    navigate(`/studies/${selectedStudy}`)
  }

  function handleReRunMeasurementInvocations(): void {
    rerunFailedOrStuckMeasurementInvocations()
  }

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <Link to={`/studies/${selectedStudy}`}>{viewModel.name ?? ''}</Link>{' '}
        {'>'} Measurement errors
      </Breadcrumb>
      <h1>Measurement errors</h1>
      {noDropdownIsSelected && (
        <p>
          <em>Choose an iteration, measurement name, or subject name </em>
        </p>
      )}
      {(selectError || errorsError || aggregateError) && (
        <p>
          Oh no!{' '}
          {selectError?.message ||
            errorsError?.message ||
            aggregateError?.message}
        </p>
      )}
      {(selectLoading || errorsLoading || aggregateLoading) && (
        <p>Loading ...</p>
      )}
      <Filters>
        <NarrowFilter>
          <h4>Iteration</h4>
          <IterationPicker
            iterationChoices={viewModel.iterationChoices}
            selectedIteration={selectedIteration}
            onChange={setSelectedIteration}
            onClear={() => setSelectedIteration(undefined)}
            isClearable
          />
        </NarrowFilter>
        <WideFilter>
          <h4>Measurement</h4>
          <MeasurementPicker
            measurementChoices={viewModel.measurementChoices}
            selectedMeasurement={selectedMeasurement}
            onChange={setSelectedMeasurement}
            onClear={() => setSelectedMeasurement(undefined)}
            isClearable
          />
        </WideFilter>
        <WideFilter>
          <h4>Subject</h4>
          <Picker
            choices={viewModel.subjectChoices}
            value={selectedSubject}
            onChange={setSelectedSubject}
            onClear={() => setSelectedSubject(undefined)}
            isClearable
          />
        </WideFilter>
      </Filters>
      <h2>Invocations by state</h2>
      <table>
        <thead>
          <tr>
            <td>State</td>
            <td>Invocations</td>
          </tr>
        </thead>
        <tbody>
          {aggregateData?.flattenedMeasurementInvocationCountByStateMachineState?.nodes.map(
            ({ stateMachineStateId, stateMachineStateIdCount }) => (
              <tr key={stateMachineStateId}>
                <td>{stateMachineStateId}</td>
                <td>{stateMachineStateIdCount}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <PromptContainer>
        <PromptTextContainer>
          {rerunFailedOrStuckMeasurementInvocationsData
            ? `Re-running ${rerunFailedOrStuckMeasurementInvocationsData.rerunFailedOrStuckMeasurementInvocations?.integer} iterations`
            : rerunFailedOrStuckMeasurementInvocationsError
            ? `Encountered an error re-running measurement invocations: ${rerunFailedOrStuckMeasurementInvocationsError}`
            : selectedIteration
            ? `${viewModel?.numFailedOrStuckMeasurementInvocations} failed or stuck measurement invocations will be re-run for iteration ${selectedIteration}`
            : 'Select an iteration to re-run failed or stuck measurement invocations'}
        </PromptTextContainer>
        {!rerunFailedOrStuckMeasurementInvocationsError &&
        !rerunFailedOrStuckMeasurementInvocationsData ? (
          <span>
            <Button
              onClick={goToStudy}
              disabled={!canReRunMeasurementInvocations}
            >
              No, don't re-run
            </Button>
            <PromptActionButton
              onClick={handleReRunMeasurementInvocations}
              disabled={!canReRunMeasurementInvocations}
            >
              Re-run
            </PromptActionButton>
          </span>
        ) : undefined}
      </PromptContainer>
      {errorsData && viewModel.errorCount === 0 && (
        <div>No measurement errors.</div>
      )}
      {errorsData &&
        viewModel.errorCount > 0 &&
        viewModel.errorInvocationsByIterationAndMeasurement.map(iteration => (
          <section key={`${iteration.measurementInvocationIteration}`}>
            <h2>
              <IterationDisplay
                iteration={iteration.measurementInvocationIteration}
              />
            </h2>
            {iteration.measurements.map(measurements => (
              <div key={measurements.measurementName}>
                <h3>
                  {humanizeMeasurementName({
                    name: measurements.measurementName,
                    index: 0,
                  })}
                </h3>
                {measurements.invocations.map(invocation => (
                  <ErrorContainer key={`${invocation.measurementInvocationId}`}>
                    <h4>
                      {invocation.subjectName} (ID:{' '}
                      {invocation.measurementInvocationId})
                    </h4>
                    <h5>
                      <span>Pose: </span>
                      <span>{invocation.poseName}</span>
                      <br />
                      <span>Topology: </span>
                      <span>{humanizeTopology(invocation.topology)}</span>
                    </h5>
                    <span>Status: {invocation.stateMachineStateId}</span>
                    <Stacktrace>{invocation.error?.join('')}</Stacktrace>
                  </ErrorContainer>
                ))}
              </div>
            ))}
          </section>
        ))}
    </FixedWidthPageContainer>
  )
}
