import { AnyUnits, LegacyMeasuredBody } from '@curvewise/common-types'
import { Polyline } from 'hyla'

export function generateLegacyMeasuredBody({
  measurementName,
  value,
  units,
  curve,
  tapeWidth,
}: {
  measurementName: string
  value: number
  units: AnyUnits
  curve: Polyline
  tapeWidth: number
}): LegacyMeasuredBody {
  return {
    values: {
      [measurementName]: { value, units },
    },
    curves: {
      [measurementName]: {
        vertices: curve.vertices.map(v => v.coords),
        is_closed: curve.isClosed,
      },
    },
    tape_width: tapeWidth,
    landmarks: {},
    computed_points: {},
  }
}
