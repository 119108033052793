export class Asset<T> {
  private promiseFn: () => Promise<T>
  promise?: Promise<T>
  result?: T

  constructor(promiseFn: () => Promise<T>) {
    this.promiseFn = promiseFn
  }

  private ensurePromise(): Promise<T> {
    if (!this.promise) {
      this.promise = this.promiseFn()
    }
    return this.promise
  }

  preload(): void {
    this.ensurePromise()
  }

  async read(): Promise<T> {
    return this.ensurePromise()
  }
}
