import styled, { css } from 'styled-components'

interface IconProps {
  disabled: boolean
  $rotate?: boolean
}

export const StyledIcon = styled.img<IconProps>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: '';
    `};
  transform: ${({ $rotate }) => ($rotate ? 'rotate(180deg)' : '')};
`
