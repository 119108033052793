import styled from 'styled-components'

import { STATISTICAL_TEXT } from './common-styles'

export const StatisticalDiv = styled.div`
  ${STATISTICAL_TEXT};
`
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexRow = styled.div`
  display: flex;
`

export const FlexRowWithPadding = styled(FlexRow)`
  align-items: center;
  > * {
    padding-right: 5px;
  }
`

// Use to fill a `position: relative` parent component without taking up any
// space. This is useful for wrapping canvases and other elements which are
// being overlaid into a single container.
export const Overlay = styled.div<{ wantPointerEvents?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: ${({ wantPointerEvents }) =>
    wantPointerEvents ? 'auto' : 'none'};
`
