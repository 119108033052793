import {
  applyFaceGroups,
  applyFaceVertexUvs,
  reindex,
  withNormals,
} from '@unpublished/four'
import { readStringFromFile } from '@unpublished/victorinox'
import { useCallback, useEffect } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import { createFaceGroupAsset, createFloat32ArrayAsset } from './create-asset'
import { detectTopology } from './known-topologies'

const meshcapadeSm4FaceGroupAsset = createFaceGroupAsset(
  '/meshcapade_sm4_mid_curvewise_face_groups_for_threejs.json'
)
const meshcapadeSm4TextureMapAsset = createFloat32ArrayAsset(
  '/meshcapade_sm4_mid_texture_map.json'
)
const mpiiTextureMapAsset = createFloat32ArrayAsset(
  '/mpii_human_shape_1.0_texture_map.json'
)
const assets = [
  meshcapadeSm4FaceGroupAsset,
  meshcapadeSm4TextureMapAsset,
  mpiiTextureMapAsset,
]

export function usePrepareMesh({
  preload = true,
}: { preload?: boolean } = {}): (
  parseResult: THREE.Group
) => Promise<THREE.BufferGeometry> {
  useEffect(() => {
    if (preload) {
      assets.forEach(asset => asset.preload())
    }
  }, [preload])

  return useCallback(async function (
    parseResult: THREE.Group
  ): Promise<THREE.BufferGeometry> {
    const mesh = parseResult.children[0]

    if (!(mesh instanceof THREE.Mesh)) {
      throw Error('Expected loader to return a THREE.Mesh')
    }
    const geometry = mesh.geometry
    if (!(geometry instanceof THREE.BufferGeometry)) {
      throw Error('Expected loader to return a THREE.BufferGeometry')
    }
    switch (detectTopology(geometry)) {
      case 'meshcapade-sm4-mid':
        // TODO: Make this work correctly when preload is off.
        applyFaceGroups(geometry, await meshcapadeSm4FaceGroupAsset.read())
        applyFaceVertexUvs(
          geometry,
          reindex({
            array: await meshcapadeSm4TextureMapAsset.read(),
            faceOrdering: (await meshcapadeSm4FaceGroupAsset.read())
              .faceOrdering,
            indicesPerFace: 6,
          })
        )
        break
      case 'mpii-human-shape-1.0':
        // TODO: Make this work correctly when preload is off.
        applyFaceVertexUvs(geometry, await mpiiTextureMapAsset.read())
        break
    }

    // Compute our own normals. It seems like meshes do get loaded with a
    // `.normal` attribute correctly computed, however unless `.mergeVertices()`
    // is called, the mesh will be renderd with flat shading regardless.
    return withNormals(geometry)
  },
  [])
}

export function useLoadMesh({ preload = true }: { preload?: boolean } = {}): (
  file: File
) => Promise<THREE.BufferGeometry> {
  const prepareMesh = usePrepareMesh({ preload })

  return useCallback(
    async function loadMesh(file: File): Promise<THREE.BufferGeometry> {
      const contents = await readStringFromFile(file)
      const parsed = new OBJLoader().parse(contents)
      return prepareMesh(parsed)
    },
    [prepareMesh]
  )
}
