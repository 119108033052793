import { pick } from 'lodash'

import { ReviewMeasurementsDetailQuery } from '../../common/generated'
import {
  Label,
  labelMatches,
  listContainsLabel,
  sortedUniqueLabels,
} from '../labels'
import { IterationViewModel, SubjectViewModel } from './view-model'

export type AddOrRemoveLabel = 'addLabel' | 'removeLabel'

export type Action =
  | {
      type: AddOrRemoveLabel
      measurementInvocationId: number
      label: Label
      isFresh?: boolean
    }
  | {
      type: 'addLabels' | 'removeLabels'
      measurementInvocationId: number
      labels: Label[]
    }
  | { type: 'resetDeltas' }
  | {
      type: 'discardDeltasWhichHaveAlreadyBeenCommitted'
      detailData?: ReviewMeasurementsDetailQuery
    }
  | {
      type: 'addLabelToEachInvocation'
      invocations: IterationViewModel[]
      label: Label
    }
  | {
      type: 'copyAllLabels'
      fromIteration: number
      subjects: SubjectViewModel[]
    }

export interface LabelDeltasForMeasurementInvocation {
  measurementInvocationId: number
  added: Label[]
  removed: Label[]
}

// Each measurement study is assigned a unique local storage key variable, which
// is why measurementStudyId does not appear in State.
export interface State {
  version: 2
  allLocalChangesToWorkingCopy: LabelDeltasForMeasurementInvocation[]
  // All the labels which were added through label creation, by typing in a
  // name, which have not yet been committed.
  freshLabels?: Label[]
}

export const INITIAL_STATE: State = {
  version: 2,
  allLocalChangesToWorkingCopy: [],
  freshLabels: [],
}

function addOrRemoveLabel({
  state,
  type,
  measurementInvocationId,
  label,
  isFresh,
}: {
  state: State
  type: AddOrRemoveLabel
  measurementInvocationId: number
  label: Label
  isFresh?: boolean
}): State {
  // Make sure other fields such as `__typename` are not considered.
  label = pick(label, ['name', 'isFailure'])

  let labelDeltasForThisMeasurementInvocation =
    state.allLocalChangesToWorkingCopy.find(
      m => m.measurementInvocationId === measurementInvocationId
    )
  let { added, removed } = labelDeltasForThisMeasurementInvocation ?? {
    added: [] as Label[],
    removed: [] as Label[],
  }

  if (type === 'addLabel') {
    if (listContainsLabel(removed, label)) {
      removed = removed.filter(item => item.name !== label.name)
    } else {
      added = [label, ...added]
    }
  } else {
    if (listContainsLabel(added, label)) {
      added = added.filter(item => item.name !== label.name)
    } else {
      removed = [label, ...removed]
    }
  }

  // Create a new item and patch it into the list.
  const newItem = { measurementInvocationId, added, removed }
  let allLocalChangesToWorkingCopy
  if (labelDeltasForThisMeasurementInvocation === undefined) {
    allLocalChangesToWorkingCopy = [
      ...state.allLocalChangesToWorkingCopy,
      newItem,
    ]
  } else {
    allLocalChangesToWorkingCopy = state.allLocalChangesToWorkingCopy.map(
      item =>
        item === labelDeltasForThisMeasurementInvocation ? newItem : item
    )
  }

  let freshLabels = state.freshLabels ?? []
  if (type === 'addLabel' && isFresh) {
    freshLabels = sortedUniqueLabels(freshLabels.concat([label]))
  }

  return { ...state, allLocalChangesToWorkingCopy, freshLabels }
}

function compact(state: State): State {
  return {
    ...state,
    allLocalChangesToWorkingCopy: state.allLocalChangesToWorkingCopy.filter(
      item => item.added.length > 0 || item.removed.length > 0
    ),
  }
}

export function localStateReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addLabel':
    case 'removeLabel': {
      return compact(
        addOrRemoveLabel({
          state,
          type: action.type,
          measurementInvocationId: action.measurementInvocationId,
          label: action.label,
          isFresh: action.isFresh,
        })
      )
    }
    case 'addLabels':
    case 'removeLabels': {
      return compact(
        action.labels.reduce(
          (state, label) =>
            addOrRemoveLabel({
              state,
              type: action.type === 'addLabels' ? 'addLabel' : 'removeLabel',
              measurementInvocationId: action.measurementInvocationId,
              label,
            }),
          state
        )
      )
    }
    case 'addLabelToEachInvocation':
      return compact(
        action.invocations
          .filter(item => {
            const existingLabel = item.labels.find(item =>
              labelMatches(item.label, action.label)
            )
            return (
              existingLabel === undefined ||
              existingLabel.commitState === 'removed'
            )
          })
          .reduce(
            (state, invocation) =>
              addOrRemoveLabel({
                state,
                type: 'addLabel',
                measurementInvocationId: invocation.measurementInvocationId,
                label: action.label,
              }),
            state
          )
      )
    case 'copyAllLabels':
      return compact(
        action.subjects.reduce((state, subject) => {
          const copyToIteration = subject.iterations[0]
          const copyFromIteration = subject.iterations.find(
            item => item.measurementInvocationIteration === action.fromIteration
          )
          if (copyToIteration && copyFromIteration) {
            return copyFromIteration.labels
              .filter(srcLabel =>
                ['committed', 'added'].includes(srcLabel.commitState)
              )
              .filter(srcLabel => {
                const existingDstLabel = copyToIteration.labels.find(item =>
                  labelMatches(item.label, srcLabel.label)
                )
                return (
                  existingDstLabel === undefined ||
                  !['committed', 'added'].includes(existingDstLabel.commitState)
                )
              })
              .reduce((state, label) => {
                return addOrRemoveLabel({
                  state,
                  type: 'addLabel',
                  measurementInvocationId:
                    copyToIteration.measurementInvocationId,
                  label: label.label,
                })
              }, state)
          } else {
            return state
          }
        }, state)
      )
    case 'discardDeltasWhichHaveAlreadyBeenCommitted': {
      return compact({
        ...state,
        allLocalChangesToWorkingCopy: state.allLocalChangesToWorkingCopy.map(
          item => {
            const committedLabels = (
              action.detailData?.measurementStudy.labels.nodes ?? []
            )
              .filter(
                label =>
                  label.measurementInvocationId === item.measurementInvocationId
              )
              .map(label => label.labelByLabelId)

            return {
              ...item,
              added: item.added.filter(
                added => !listContainsLabel(committedLabels, added)
              ),
              removed: item.removed.filter(removed =>
                listContainsLabel(committedLabels, removed)
              ),
            }
          }
        ),
      })
    }
    case 'resetDeltas':
      return INITIAL_STATE
    default:
      return state
  }
}
