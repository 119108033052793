import { MouseEvent } from 'react'

import { useKeyDown } from './use-key-down'

export function isCtrlKey(e: KeyboardEvent | MouseEvent): boolean {
  const isMac = window.navigator.appVersion.includes('Mac')
  return isMac ? e.metaKey : e.ctrlKey
}

export function useArrowKeys({
  onArrowUp,
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onCtrlArrowUp,
  onCtrlArrowDown,
  disabled,
}: {
  onArrowUp: () => void
  onArrowDown: () => void
  onArrowLeft?: () => void
  onArrowRight?: () => void
  onCtrlArrowUp?: () => void
  onCtrlArrowDown?: () => void
  disabled?: boolean
}): void {
  useKeyDown({
    disabled,
    onKeyDown: (e: KeyboardEvent) => {
      let onKeypress: (() => void) | undefined
      let onCtrlKeypress: (() => void) | undefined
      switch (e.key) {
        case 'ArrowUp':
          onKeypress = onArrowUp
          onCtrlKeypress = onCtrlArrowUp
          break
        case 'ArrowDown':
          onKeypress = onArrowDown
          onCtrlKeypress = onCtrlArrowDown
          break
        case 'ArrowLeft':
          onKeypress = onArrowLeft
          break
        case 'ArrowRight':
          onKeypress = onArrowRight
          break
        default:
          return
      }
      e.preventDefault()
      if (onCtrlKeypress && isCtrlKey(e)) {
        onCtrlKeypress()
      } else {
        onKeypress && onKeypress()
      }
    },
  })
}
