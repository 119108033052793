import chroma, { scale } from 'chroma-js'
import { Point, Polyline, scalar, Segment } from 'hyla'

export interface AppearanceProps {
  radius: number
  // Though it does not eliminate it, polygon offset significantly reduces
  // surface z-fighting artifacts between collinear curves.
  polygonOffset?: boolean
  polygonOffsetFactor?: number
  polygonOffsetUnits?: number
  colorWrite: boolean
  color: number
  opacity: number
  transparent: boolean
}

export type AppearanceStyle =
  // Full-size, solid-colored, opaque.
  | 'solo'
  // Full-size, gradient-colored, variably opaque, and covers up other
  // transparent tapes behind it.
  | 'compareFromUsingGradient'
  // Full-size, solid-colored, partially transparent, and covers up transparent
  // tapes behind it.
  | 'compareFromUsingSolidColor'
  // Undersized, solid-colored, partially transparent, and covers up transparent
  // tapes behind it.
  | 'compareTo'

// To help avoid z-fighting along collinear tape surfaces, shrink the radius on
// the compareToCurve.
function transformRadiusForAppearanceStyle({
  radius,
  appearanceStyle,
}: {
  radius: number
  appearanceStyle: AppearanceStyle
}): number {
  if (appearanceStyle === 'compareTo') {
    return 0.8 * radius
  } else {
    return radius
  }
}

const POLYGON_OFFSET_FACTOR: Record<AppearanceStyle, number | undefined> = {
  compareFromUsingGradient: -2,
  compareFromUsingSolidColor: -2,
  compareTo: 2,
  solo: undefined,
}

interface OffsetProps {
  polygonOffset?: boolean
  polygonOffsetFactor?: number
  polygonOffsetUnits?: number
}

function polygonOffsetPropsForAppearanceStyle(
  appearanceStyle: AppearanceStyle
): OffsetProps {
  if (!(appearanceStyle in POLYGON_OFFSET_FACTOR)) {
    throw Error(`Unknown appearance style: ${appearanceStyle}`)
  }

  const polygonOffsetFactor = POLYGON_OFFSET_FACTOR[appearanceStyle]
  if (polygonOffsetFactor === undefined) {
    return {}
  } else {
    return { polygonOffset: true, polygonOffsetFactor, polygonOffsetUnits: 1 }
  }
}

// To avoid z-fighting between the atoms and bonds, push the atoms back further.
export function adjustPolygonOffsetForAtoms(
  offsetProps: OffsetProps
): OffsetProps {
  const { polygonOffsetFactor } = offsetProps
  return {
    ...offsetProps,
    polygonOffsetFactor:
      polygonOffsetFactor === undefined
        ? polygonOffsetFactor
        : polygonOffsetFactor + 2,
  }
}

function createLogScaleFn({
  minValue,
  maxValue,
}: {
  minValue: number
  maxValue: number
}): (value: number) => number {
  const denominator = Math.log(maxValue / minValue)
  return value =>
    Math.log(scalar.clamp(value, { min: minValue, max: maxValue }) / minValue) /
    denominator
}

export interface AtomProps extends AppearanceProps {
  point: Point
}

export interface BondProps extends AppearanceProps {
  segment: Segment
}

// Compute the appearance of gradient-colored tapes.
export class Gradient {
  static NEAR_APPEARANCE = {
    color: 0x3f5bd4,
  }
  static FAR_APPEARANCE = {
    color: 0x00ffff,
    opacity: 0.9,
    transparent: true,
  }

  static colorScaleFn = scale([
    chroma(Gradient.NEAR_APPEARANCE.color),
    chroma(Gradient.FAR_APPEARANCE.color),
  ])

  static distanceMetricLogScaleFn = createLogScaleFn({
    minValue: 0.001,
    maxValue: 0.03754512929851417,
  })

  readonly curve: Polyline
  readonly compareToCurve: Polyline
  readonly meanLength: number
  readonly geometryProps: {
    radius: number
    polygonOffset?: boolean
    polygonOffsetFactor?: number
    polygonOffsetUnits?: number
  }

  constructor({
    curve,
    compareToCurve,
    radius,
  }: {
    curve: Polyline
    compareToCurve: Polyline
    radius: number
  }) {
    this.curve = curve
    this.compareToCurve = compareToCurve
    this.meanLength = 0.5 * (curve.length + compareToCurve.length)
    this.geometryProps = {
      // This radius transform is a no-op for the gradient, but is included here
      // for logical clarity.
      radius: transformRadiusForAppearanceStyle({
        radius,
        appearanceStyle: 'compareFromUsingGradient',
      }),
      ...polygonOffsetPropsForAppearanceStyle('compareFromUsingGradient'),
    }
  }

  appearanceForPointOnCurve(point: Point): AppearanceProps {
    const { meanLength, compareToCurve, geometryProps } = this

    const distanceMetric =
      point.euclideanDistance(compareToCurve.nearest(point)) / meanLength
    const logDistanceMetric = Gradient.distanceMetricLogScaleFn(distanceMetric)

    return {
      ...geometryProps,
      colorWrite: true,
      color: Gradient.colorScaleFn(logDistanceMetric).num(),
      opacity: Math.min(1.2 - 0.55 * logDistanceMetric, 1.0),
      transparent: true,
    }
  }

  computeAtomProps(): AtomProps[] {
    return this.curve.vertices.map(point => ({
      point,
      ...this.appearanceForPointOnCurve(point),
    }))
  }

  computeBondProps(): BondProps[] {
    return (
      this.curve.segments
        // filter degenerate segments to prevent crashes caused by normalzing zero-length vectors.
        .filter(segment => segment.length > 0)
        .map(segment => ({
          segment,
          ...this.appearanceForPointOnCurve(segment.midpoint),
        }))
    )
  }
}

export const COMPARE_TO_APPEARANCE = {
  color: 0xcbe2e2,
  opacity: 0.8,
  transparent: true,
}

// Compute the appearance of solid-color or invisible tapes.
export function appearancePropsForStyle({
  appearanceStyle,
  radius,
  soloColor,
  depthOnlyFirstPass,
}: {
  appearanceStyle: AppearanceStyle
  radius: number
  soloColor?: number
  depthOnlyFirstPass: boolean
}): AppearanceProps & { radius: number } {
  const geometryProps = {
    radius: transformRadiusForAppearanceStyle({ radius, appearanceStyle }),
    ...polygonOffsetPropsForAppearanceStyle(appearanceStyle),
  }

  if (depthOnlyFirstPass) {
    // Since we're only writing the depth buffer, the values do not matter for
    // these color and opacity properties.
    return {
      ...geometryProps,
      colorWrite: false,
      color: 0.0,
      opacity: 1.0,
      transparent: false,
    }
  }

  switch (appearanceStyle) {
    case 'compareFromUsingSolidColor':
      return { ...geometryProps, colorWrite: true, ...Gradient.FAR_APPEARANCE }
    case 'compareTo':
      return { ...geometryProps, colorWrite: true, ...COMPARE_TO_APPEARANCE }
    case 'solo':
      if (soloColor === undefined) {
        throw Error('With `style="solo"`, `soloColor` is required')
      }
      return {
        ...geometryProps,
        colorWrite: true,
        color: soloColor,
        opacity: 1.0,
        transparent: false,
      }
    case 'compareFromUsingGradient':
      throw Error('To compute gradient appearance, use Gradient.prepareBonds()')
    default:
      throw Error(`Unknown appearance style ${appearanceStyle}`)
  }
}
