import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
} from '../common/common-components'
import { ListMeasurementStudiesQuery } from '../common/generated'
import {
  LIST_MEASUREMENT_STUDIES_QUERY,
  ListMeasurementStudiesTable,
} from './list-studies-table'

export function ListMeasurementStudies(): JSX.Element {
  const { loading, error, data } = useQuery<ListMeasurementStudiesQuery>(
    LIST_MEASUREMENT_STUDIES_QUERY
  )
  const navigate = useNavigate()
  // TODO: Move this condition to the query.
  const activeStudies = data?.allMeasurementStudies.nodes.filter(
    ({ isArchived }) => !isArchived
  )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} Measurement studies
      </Breadcrumb>
      <h1>Measurement studies</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {activeStudies && activeStudies.length === 0 && (
        <div>No active studies.</div>
      )}
      {activeStudies && activeStudies.length > 0 && (
        <ListMeasurementStudiesTable data={activeStudies} />
      )}
      <FixedWidthFooterNavContainer>
        <Button onClick={() => navigate('/studies/new')}>
          <span>+</span> New Study
        </Button>
        <Button onClick={() => navigate('/studies/archive')}>
          Archived Studies
        </Button>
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
