import humanizeString from 'humanize-string'
import React, { Dispatch, useCallback, useEffect, useReducer } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const SUPPORTED_TEXTURE_EXTENSIONS = ['.jpg', '.jpeg', '.png']

export interface DropzoneState {
  mesh?: File
  texture?: File
  measurements?: File
  views?: File
  points?: File
  supportedFeatures?: File
}

type Action =
  | { kind: 'set-file'; name: keyof DropzoneState; file: File }
  | { kind: 'clear-file'; name: keyof DropzoneState }

function reducer(state: DropzoneState, action: Action): DropzoneState {
  return {
    ...state,
    [action.name]: action.kind === 'set-file' ? action.file : undefined,
  }
}

const CheckmarkElement = styled.span`
  padding-left: 3px;
`

function Checkmark(): JSX.Element {
  return (
    <CheckmarkElement role="img" aria-label="Selected">
      ✅
    </CheckmarkElement>
  )
}

// TODO: This component CSS relies on class names. Please do not follow this
// pattern when creating new components.
const ThumbnailElement = styled.div<{
  isSelected: boolean
}>`
  width: 175px;
  margin: 10px;
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  text-align: center;
  position: relative;

  > div {
    margin: 20px;
  }

  .label {
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }

  .filename {
    color: ${({ isSelected }) => (isSelected ? 'green' : undefined)};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : undefined)};
  }

  .clear-button {
    position: absolute;

    bottom: 0;
    right: 0;
    margin: 5px;

    outline: none;
  }
`

function Thumbnail({
  state,
  dispatch,
  name,
  label,
}: {
  state: DropzoneState
  dispatch: Dispatch<Action>
  name: keyof DropzoneState
  label?: string
}): JSX.Element {
  const file = state[name]
  if (label === undefined) {
    label = humanizeString(name)
  }

  function onClick(event: any): void {
    event.stopPropagation()
    dispatch({ kind: 'clear-file', name })
  }

  // TODO: This component CSS relies on class names. Please do not follow this
  // pattern when creating new components.
  return (
    <ThumbnailElement isSelected={Boolean(file)}>
      {file && (
        <button className="clear-button" onClick={onClick}>
          <span role="img" aria-label="clear">
            ❌
          </span>
        </button>
      )}
      <div className="label">{label}</div>
      {file ? (
        <div className="filename">
          {file.name} <Checkmark />{' '}
        </div>
      ) : (
        <div className="filename">(not selected)</div>
      )}
    </ThumbnailElement>
  )
}

interface StyledDropZoneProps {
  isDragAccept: boolean
  isDragReject: boolean
  isDragActive: boolean
}

function dropzoneColor({
  isDragAccept,
  isDragReject,
  isDragActive,
}: StyledDropZoneProps): string {
  // Furthermore, at this moment it's not possible to read file names (and
  // thus, file extensions) during the drag operation.
  if (isDragAccept || isDragReject) {
    return '#00e676'
  } else if (isDragActive) {
    return '#2196f3'
  } else {
    return '#eeeeee'
  }
}

const StyledDropzone = styled.div<StyledDropZoneProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${dropzoneColor};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

const ThumbnailContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`

const DropTarget = styled.div`
  break-before: always;
`

export function Dropzone({
  enableMesh = false,
  enableTexture = false,
  enableMeasurements = false,
  enableViews = false,
  enablePoints = false,
  enableSupportedFeatures = false,
  measurementLabel = 'Measurements',
  onChange,
}: {
  enableMesh?: boolean
  enableTexture?: boolean
  enableMeasurements?: boolean
  enableViews?: boolean
  enablePoints?: boolean
  enableSupportedFeatures?: boolean
  measurementLabel?: string
  onChange: (state: DropzoneState) => void
}): JSX.Element {
  const [state, dispatch] = useReducer(reducer, {})

  useEffect(() => {
    onChange(state)
  }, [onChange, state])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        const { name: inName } = file
        let outName: keyof DropzoneState | undefined
        // It might be better to do some JSON validation here.
        if (enableMesh && inName.endsWith('.obj')) {
          outName = 'mesh'
        } else if (
          enableTexture &&
          SUPPORTED_TEXTURE_EXTENSIONS.some(extension =>
            inName.endsWith(extension)
          )
        ) {
          outName = 'texture'
        } else if (
          enableViews &&
          inName.startsWith('views') &&
          inName.endsWith('.json')
        ) {
          outName = 'views'
        } else if (
          enablePoints &&
          (inName.startsWith('points') ||
            inName.toLowerCase().includes('landmarks')) &&
          inName.endsWith('.json')
        ) {
          outName = 'points'
        } else if (enableMeasurements && inName.endsWith('.json')) {
          outName = 'measurements'
        } else if (enableSupportedFeatures && inName.endsWith('.json')) {
          outName = 'supportedFeatures'
        }

        if (outName) {
          dispatch({ kind: 'set-file', name: outName, file })
        }
      }
    },
    [
      enableMesh,
      enableTexture,
      enableViews,
      enablePoints,
      enableMeasurements,
      enableSupportedFeatures,
    ]
  )

  const acceptedExtensions = [enableMesh ? ['.obj'] : []]
    .concat(enableViews || enableMeasurements || enablePoints ? ['.json'] : [])
    .concat(enableTexture ? SUPPORTED_TEXTURE_EXTENSIONS : [])
    .filter(Boolean)
    .join(',')

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: acceptedExtensions })

  const commonProps = { state, dispatch }

  return (
    <div>
      {/*
      // @ts-ignore */}
      <StyledDropzone
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <ThumbnailContainer>
          {enableMesh && <Thumbnail {...commonProps} name="mesh" />}
          {enableTexture && <Thumbnail {...commonProps} name="texture" />}
          {enableMeasurements && (
            <Thumbnail
              {...commonProps}
              name="measurements"
              label={measurementLabel}
            />
          )}
          {enableViews && <Thumbnail {...commonProps} name="views" />}
          {enablePoints && <Thumbnail {...commonProps} name="points" />}
          {enableSupportedFeatures && (
            <Thumbnail {...commonProps} name="supportedFeatures" />
          )}
        </ThumbnailContainer>
        <input {...getInputProps()} />
        <DropTarget>
          {isDragActive ? (
            <p>Drop the mesh and curves here &hellip;</p>
          ) : (
            <p>
              Click to select files, or drag and drop the mesh and curves here
              &hellip;
            </p>
          )}
        </DropTarget>
      </StyledDropzone>
    </div>
  )
}
