import { Button, LinkButton } from '@unpublished/common-components'
import { downloadJsonContent } from '@unpublished/victorinox'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
} from '../../common/common-components'
import { importLandmarksFromTsv } from './import-landmarks'

const ClearBothContainer = styled.div`
  clear: both;
`

export function ImportLandmarkPage(): JSX.Element {
  const [landmarkTsvText, setLandmarkTsvText] = useState<string>()

  const loaded = useMemo(
    () =>
      landmarkTsvText ? importLandmarksFromTsv(landmarkTsvText) : undefined,
    [landmarkTsvText]
  )
  const loadedJson = useMemo(() => JSON.stringify(loaded, null, 2), [loaded])

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} <Link to="/extras">Extras</Link> {'>'}{' '}
        Supported features
      </Breadcrumb>
      <h1>Landmarks</h1>
      <h2>Input TSV</h2>
      <textarea
        value={landmarkTsvText}
        onChange={e => setLandmarkTsvText(e.target.value)}
        rows={12}
        cols={80}
      />
      {loaded && (
        <div>
          <h2>Output JSON</h2>
          <textarea value={loadedJson} rows={12} cols={80} />
        </div>
      )}
      <ClearBothContainer />
      <Button
        disabled={!loadedJson}
        onClick={() =>
          downloadJsonContent({ data: loaded, filename: 'points.json' })
        }
      >
        Save
      </Button>
      <LinkButton href="/">Close</LinkButton>
    </FixedWidthPageContainer>
  )
}
