import { Side } from '@curvewise/common-types'
import useLocalStorageState, {
  LocalStorageState,
} from 'use-local-storage-state'

import { Gender } from '../common/data-transforms'

export interface StoredProperties {
  Key: string
  ETag: string
  gender?: Gender
  pose?: number
  subjectId?: string
  side?: Side
  texture?: string
}

export function useUploadBucketProperties(): LocalStorageState<
  StoredProperties[]
> {
  return useLocalStorageState<StoredProperties[]>('useUploadBucketProperties', {
    defaultValue: [],
  })
}
