import { LengthUnits } from '@curvewise/common-types'
import { transform, Vector } from 'hyla'
import * as THREE from 'three'

export function eulerFromUpAndLook({
  superiorAxis,
  anteriorAxis,
}: {
  superiorAxis: Vector
  anteriorAxis: Vector
}): THREE.Euler {
  const matrix = transform.rotationFromUpAndLook(superiorAxis, anteriorAxis)
  const matrix4 = new THREE.Matrix4().set(
    ...matrix[0].coords,
    0,
    ...matrix[1].coords,
    0,
    ...matrix[2].coords,
    0,
    0,
    0,
    0,
    1
  )
  return new THREE.Euler().setFromRotationMatrix(matrix4)
}

export function conversionFactorToCM(fromUnits: LengthUnits): number {
  switch (fromUnits) {
    case 'cm':
      return 1
    case 'mm':
      return 0.1
    case 'm':
      return 100
    case 'in':
      return 2.54
    default:
      throw Error('units must be in valid unit')
  }
}
