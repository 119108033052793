import { usePrepareMesh } from '@unpublished/body-mesh'
import { useEffect, useState } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

export function useObjLoader({
  s3Key,
  signedURL,
  enabled = true,
}: {
  s3Key?: string
  signedURL?: string
  enabled?: boolean
}): {
  body?: THREE.BufferGeometry
  errorMessage?: string
} {
  const prepareMesh = usePrepareMesh()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [body, setBody] = useState<THREE.BufferGeometry>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loadedS3Key, setLoadedS3Key] = useState<string>()

  useEffect(() => {
    if (enabled && s3Key !== loadedS3Key && signedURL && !isLoading) {
      setIsLoading(true)
      if (body) {
        body.dispose()
      }
      setBody(undefined)
      setErrorMessage(undefined)
      new OBJLoader().load(
        signedURL,
        (group: THREE.Group) => {
          prepareMesh(group).then((mesh: THREE.BufferGeometry) => {
            setBody(mesh)
            setLoadedS3Key(s3Key)
            setIsLoading(false)
          })
        },
        undefined,
        error => {
          setErrorMessage(error.message || `Error: ${error.type}`)
          setLoadedS3Key(s3Key)
          setIsLoading(false)
        }
      )
    }
  }, [s3Key, signedURL, body, loadedS3Key, isLoading, enabled, prepareMesh])

  return { body: enabled ? body : undefined, errorMessage }
}
