import React from 'react'
import styled, { css } from 'styled-components'

import { FlexColumn } from './common-components'

export const ActionsSidebar = styled(FlexColumn)`
  margin: 0px 36px;
`

const ActionDiv = styled.div<{
  disabled?: boolean
  isActive?: boolean
  withHover?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143px;
  border: 1px solid;
  font-size: 12px;
  text-transform: uppercase;
  background-color: white;
  padding: 12px;
  margin-bottom: 50px;
  > svg {
    margin-right: 10px;
  }
  user-select: none;
  ${({ disabled }) =>
    disabled
      ? css`
          color: #ccc;
          border-color: #ccc;
          cursor: default;
        `
      : css`
          color: #8f8f8f;
          border-color: #8f8f8f;
          cursor: pointer;
          &:hover {
            color: ${'black'};
            svg path {
              fill-opacity: ${'1.0'};
            }
          }
        `}
  ${({ isActive }) =>
    isActive &&
    css`
      background: #b8b8b8;
      color: #303030;
    `}
`

export function Action({
  disabled,
  isActive,
  withHover,
  onClick,
  children,
}: {
  disabled?: boolean
  isActive?: boolean
  withHover?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  children: React.ReactNode
}): JSX.Element {
  return (
    <ActionDiv
      {...{ disabled, isActive, withHover }}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </ActionDiv>
  )
}
