import { fetchToDataUrl } from '@unpublished/victorinox'
import { useCallback, useEffect, useState } from 'react'
import * as THREE from 'three'

export function useTextureLoader(url?: string): THREE.Texture | undefined {
  const [loadedTexture, setLoadedTexture] = useState<THREE.Texture>()

  const fetchTexture = useCallback(async (): Promise<void> => {
    if (url) {
      const dataUri = await fetchToDataUrl(url)
      const texture = dataUri
        ? new THREE.TextureLoader().load(dataUri)
        : undefined
      setLoadedTexture(texture)
    } else {
      setLoadedTexture(undefined)
    }
  }, [url])

  useEffect(() => {
    fetchTexture()
  }, [fetchTexture])

  return loadedTexture
}
