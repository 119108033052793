import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'

export function CSSCanvas(): JSX.Element {
  const gl = useThree(state => state.gl)
  const scene = useThree(state => state.scene)
  const camera = useThree(state => state.camera)

  const [labelRenderer] = useState(() => {
    const labelRenderer = new CSS2DRenderer()
    labelRenderer.setSize(gl.domElement.clientWidth, gl.domElement.clientHeight)
    labelRenderer.domElement.style.position = 'absolute'
    labelRenderer.domElement.style.top = '0px'
    labelRenderer.domElement.style.left = '0px'
    labelRenderer.domElement.style.pointerEvents = 'none'
    return labelRenderer
  })
  const target = gl.domElement.parentNode

  useEffect(() => {
    if (target) {
      target.appendChild(labelRenderer.domElement)
    }
    return () => {
      if (target) target.removeChild(labelRenderer.domElement)
      ReactDOM.unmountComponentAtNode(labelRenderer.domElement)
    }
  }, [target, labelRenderer])

  useFrame(() => {
    labelRenderer.render(scene, camera)
  })

  return <group />
}
