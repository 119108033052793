import { gql, useMutation, useQuery } from '@apollo/client'
import {
  assertLegacyMeasuredBody,
  assertMeasuredBodyViews,
} from '@curvewise/measured-body'
import {
  Button,
  Dropzone,
  DropzoneState,
  LinkButton,
} from '@unpublished/common-components'
import {
  downloadContent,
  downloadJsonContent,
  readJsonFromFile,
} from '@unpublished/victorinox'
import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  NextButton,
} from '../common/common-components'
import {
  UpdateMeasurementViewsMutation,
  UpdateMeasurementViewsMutationVariables,
  ViewsQuery,
} from '../common/generated'
import { createViewModel, VIEWS_QUERY } from './view-model'

export function ImportExportViews(): JSX.Element {
  const navigate = useNavigate()
  const {
    loading,
    error: loadError,
    data,
    refetch,
  } = useQuery<ViewsQuery>(VIEWS_QUERY)

  const [openedFiles, setOpenedFiles] = useState<
    { referenceMeasurementJson: string; referenceViewJson: string } | undefined
  >()

  const handleDropzoneChange = useCallback(
    async (dropzoneState: DropzoneState) => {
      const { measurements: inMeasurements, views: inViews } = dropzoneState
      if (inMeasurements && inViews) {
        const measurements = await readJsonFromFile(inMeasurements)
        const views = await readJsonFromFile(inViews)
        try {
          assertLegacyMeasuredBody(measurements)
          assertMeasuredBodyViews(views)
        } catch (e) {
          setOpenedFiles(undefined)
          return
        }
        setOpenedFiles({
          referenceMeasurementJson: JSON.stringify(measurements),
          referenceViewJson: JSON.stringify(views),
        })
      } else {
        setOpenedFiles(undefined)
      }
    },
    []
  )

  const [updateMeasurementViews, { error: updateMeasurementViewsError }] =
    useMutation<
      UpdateMeasurementViewsMutation,
      UpdateMeasurementViewsMutationVariables
    >(
      gql`
        mutation UpdateMeasurementViews(
          $referenceMeasurementJson: String!
          $referenceViewJson: String!
        ) {
          updateMeasurementViews(
            input: {
              referenceMeasurementJson: $referenceMeasurementJson
              referenceViewJson: $referenceViewJson
            }
          )
        }
      `,
      {
        variables: openedFiles,
        onCompleted() {
          navigate(`/`)
        },
        refetchQueries: [VIEWS_QUERY],
      }
    )

  const viewModel = createViewModel({ data })

  const err = loadError || updateMeasurementViewsError

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} <Link to="/views">Views</Link>
      </Breadcrumb>
      <h1>Views</h1>
      {err && <p>Oh no! {err.message}</p>}
      {loading && <p>Loading ...</p>}
      {!loadError && !loading && viewModel && (
        <>
          <h2>Choose Views</h2>
          <p>
            To choose view angles, open the mesh, measurements, and views in{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://4dviz.curvewise.com"
            >
              4dviz
            </a>
            .
          </p>
          <div>
            <Button
              onClick={async () => {
                const { data } = await refetch()
                const _viewModel = createViewModel({ data })
                const url = _viewModel?.signedUrlForReferenceObj
                if (!url) {
                  throw Error('Url must exist')
                }
                downloadContent({
                  contents: await (await fetch(url)).text(),
                  filename: 'reference-body.obj',
                  contentType: 'text/prs.wavefront-obj',
                })
              }}
            >
              Mesh
            </Button>
            <Button
              onClick={() =>
                downloadJsonContent({
                  data: viewModel?.exportedMeasurements,
                  filename: 'measurements-for-reference-body.json',
                })
              }
            >
              Measurements
            </Button>
            <Button
              onClick={() =>
                downloadJsonContent({
                  data: viewModel?.exportedViews,
                  filename: 'views-for-reference-body.json',
                })
              }
            >
              Views
            </Button>
          </div>
          <h2>Update Views</h2>
          <Dropzone
            onChange={handleDropzoneChange}
            enableMeasurements
            enableViews
          />
          <p>
            Updated views automatically apply to future measurement results. To
            regenerate views for existing results, go to the measurement study
            and select <strong>Regenerate Views</strong>.
          </p>
          <LinkButton href="/">Cancel</LinkButton>
          <NextButton
            onClick={() => updateMeasurementViews()}
            disabled={openedFiles === undefined}
          >
            Update
          </NextButton>
        </>
      )}
    </FixedWidthPageContainer>
  )
}
