import { isShallowEqualOmitting } from '@unpublished/victorinox'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as Check } from '../../common/images/check.svg'
import approved from '../../common/images/thumbs-up.svg'
import approvedBold from '../../common/images/thumbs-up-bold.svg'
import approvedStrikethru from '../../common/images/thumbs-up-strikethru.svg'
import { Label, labelMatches } from '../labels'

interface LabelProps {
  $isFailure: boolean
  $isUpdated?: boolean
  $isSelected?: boolean
  $isRemoved?: boolean
  disabled: boolean
  height?: string
}

export const StyledLabel = styled.div<LabelProps>`
  font-size: 12px;
  margin: 3.5px;
  color: #404040;
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: '';
    `};
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 2px solid black;
      padding: 3px;
    `};
  text-decoration: ${({ $isRemoved }) =>
    $isRemoved ? 'line-through' : 'none'};
  background: ${({ $isFailure, $isUpdated }) =>
    $isFailure
      ? $isUpdated
        ? 'rgba(245, 147, 147 )'
        : 'rgba(245, 147, 147, 0.47)'
      : $isUpdated
      ? '#C3FF86'
      : 'rgba(198, 230, 166, 0.47)'};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
`
export const StyledApprovedIcon = styled.img<Omit<LabelProps, '$isFailure'>>`
  height: 25px;
  margin: 3.5px;
  display: inline-block;
  padding: ${({ $isSelected, $isRemoved }) =>
    $isSelected || $isRemoved ? '3px' : '5px'};
  background: ${({ $isUpdated }) =>
    $isUpdated ? '#C3FF86' : 'rgba(198, 230, 166, 0.47)'};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: '';
    `};
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 2px solid black;
    `};
`

export const LabelContainerForSubjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: right;
  width: 100%;
`

function thumbsUpIconPicker({
  isSelected,
  isRemoved,
}: {
  isSelected?: boolean
  isRemoved?: boolean
}): string {
  if (isSelected) {
    return approvedBold
  } else if (isRemoved) {
    return approvedStrikethru
  } else {
    return approved
  }
}

function _DisplayLabel({
  $isUpdated,
  $isSelected,
  $isRemoved,
  disabled,
  label,
  height,
  onClick,
}: {
  $isUpdated?: boolean
  $isSelected?: boolean
  $isRemoved?: boolean
  disabled: boolean
  label: Label
  height?: string
  onClick?: () => void
}): JSX.Element {
  return label.name ? (
    <StyledLabel
      $isFailure={label.isFailure}
      $isUpdated={$isUpdated}
      $isSelected={$isSelected}
      $isRemoved={$isRemoved ?? false}
      disabled={disabled}
      onClick={onClick}
      height={height}
    >
      {$isSelected ? (
        <Fragment>
          <Check /> {label.name}
        </Fragment>
      ) : (
        label.name
      )}
    </StyledLabel>
  ) : (
    <StyledApprovedIcon
      src={thumbsUpIconPicker({
        isRemoved: $isRemoved,
        isSelected: $isSelected,
      })}
      $isUpdated={$isUpdated}
      $isRemoved={$isRemoved}
      $isSelected={$isSelected}
      onClick={onClick}
      disabled={disabled}
    />
  )
}
export const DisplayLabel = React.memo(
  _DisplayLabel,
  (
    firstProps: Parameters<typeof _DisplayLabel>[0],
    secondProps: Parameters<typeof _DisplayLabel>[0]
  ) =>
    labelMatches(firstProps.label, secondProps.label) &&
    isShallowEqualOmitting(firstProps, secondProps, ['label'])
)
