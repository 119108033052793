import { gql } from '@apollo/client'
import {
  Button,
  FlexColumn,
  StatisticalDiv,
} from '@unpublished/common-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { CenteredDiv, StatisticalSpan } from '../common/common-components'
import { humanizedDurationFromNow } from '../common/data-transforms'
import { FixedWidthTable, NoWrapDiv, TableRow } from '../common/flex-table'
import { ListMeasurementStudiesQuery } from '../common/generated'

export const LIST_MEASUREMENT_STUDIES_QUERY = gql`
  query ListMeasurementStudies {
    allMeasurementStudies {
      nodes {
        id
        name
        isArchived
        commitByHeadCommitId {
          created
          commitsGeometriesLookupsByCommitId {
            totalCount
          }
        }
      }
    }
  }
`

const NoWrapColumn = styled(FlexColumn)`
  white-space: nowrap;
`

export function ListMeasurementStudiesTable({
  data,
}: {
  data: ListMeasurementStudiesQuery['allMeasurementStudies']['nodes']
}): JSX.Element {
  const navigate = useNavigate()
  return (
    <FixedWidthTable>
      {data.map(
        ({
          id,
          name,
          commitByHeadCommitId: { created, commitsGeometriesLookupsByCommitId },
        }) => (
          <TableRow key={id}>
            <div>{name}</div>
            <NoWrapDiv>
              <StatisticalDiv>
                last modified {humanizedDurationFromNow(created)}
              </StatisticalDiv>
            </NoWrapDiv>
            <NoWrapColumn>
              <CenteredDiv>
                <div>
                  {commitsGeometriesLookupsByCommitId.totalCount}{' '}
                  <StatisticalSpan>geometries</StatisticalSpan>
                </div>
                <div>
                  {'? '}
                  <StatisticalSpan>measurements</StatisticalSpan>
                </div>
              </CenteredDiv>
            </NoWrapColumn>
            <NoWrapDiv>
              <Button onClick={() => navigate(`/studies/${id}`)}>View</Button>
            </NoWrapDiv>
          </TableRow>
        )
      )}
    </FixedWidthTable>
  )
}
