export async function fetchToDataUrl(
  url: string
): Promise<string | null | undefined> {
  return new Promise(async resolve => {
    const response = await fetch(url)
    const blob = await response.blob()

    const reader = new FileReader()
    reader.onload = function (e) {
      resolve(e?.target?.result?.toString())
    }
    reader.readAsDataURL(blob)
  })
}
