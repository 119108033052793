import {
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'
import { useMemo, useRef } from 'react'

export function createClient({
  url,
  authLink,
}: {
  url: string
  authLink?: ApolloLink
}): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: ApolloLink.from(
      [
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            )
          }
          if (networkError) {
            // Don't try to parse JSON for error responses.
            // See https://github.com/apollographql/apollo-feature-requests/issues/153
            if ('bodyText' in networkError) {
              try {
                JSON.parse(networkError.bodyText)
              } catch (e) {
                networkError.message = networkError.bodyText
              }
            }

            console.log(`[Network error]: ${networkError}`)
          }
        }),
      ]
        .concat(authLink ? [authLink] : [])
        .concat([new HttpLink({ uri: url })])
    ),
    cache: new InMemoryCache(),
  })
}

export function useClient({
  url,
  withAuth0Credentials,
}: {
  url: string
  withAuth0Credentials?: boolean
}): ApolloClient<NormalizedCacheObject> {
  const { getAccessTokenSilently } = useAuth0()

  // Reference:
  // https://www.apollographql.com/docs/link/overview/
  // https://www.apollographql.com/docs/react/api/link/apollo-link-context/
  const setContextWithAuthToken = useRef(
    setContext(async (operation: GraphQLRequest) => {
      const accessToken = await getAccessTokenSilently()
      return {
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      }
    })
  )

  const client = useMemo(
    () =>
      createClient({
        url,
        authLink: withAuth0Credentials
          ? setContextWithAuthToken.current
          : undefined,
      }),
    [url, withAuth0Credentials, setContextWithAuthToken]
  )

  return client
}
