import React, { Dispatch } from 'react'

import { useArrowKeys } from '../../common/use-arrow-keys'
import { useKeyDown } from '../../common/use-key-down'
import { NavigateToParameters } from '../../common/use-navigate-to'
import { Accordioned, Action as AccordionAction } from './accordion'
import { IterationViewModel, ViewModel } from './view-model'

const ALL_SHORTCUT_KEYS = [
  'ArrowRight',
  'ArrowLeft',
  'ArrowUp',
  'ArrowDown',
  'Escape',
  'Enter',
]

export function stopPropagationOfKeyboardShortcuts(
  e: React.KeyboardEvent<HTMLElement>
): void {
  if (ALL_SHORTCUT_KEYS.includes(e.key)) {
    e.stopPropagation()
  }
}

export function selectedAccordionedIteration(
  viewModel: ViewModel
): Accordioned<IterationViewModel> | undefined {
  return viewModel.visibleSubjects
    .find(subject => subject === viewModel.selection.subject)
    ?.visibleIterations.find(
      iteration =>
        iteration.item.measurementInvocationIteration ===
        viewModel.selection.iteration?.measurementInvocationIteration
    )
}

export function useKeyboardShortcuts({
  viewModel,
  show3DView,
  inCombinedViewMode,
  setInCombinedViewMode,
  setShow3DView,
  navigateToReviewMeasurements,
  performApproveOrUnapprove,
  dispatchAccordionAction,
}: {
  viewModel: ViewModel
  show3DView: boolean
  inCombinedViewMode: boolean
  setInCombinedViewMode: (value: boolean) => void
  setShow3DView: (value: boolean) => void
  navigateToReviewMeasurements: (params: NavigateToParameters) => void
  performApproveOrUnapprove: (kind: 'approve' | 'unapprove') => void
  dispatchAccordionAction: Dispatch<AccordionAction>
}): void {
  useArrowKeys({
    onArrowUp() {
      const target = viewModel.selection.previousIteration
      if (target) {
        const { measurementInvocationId } = target
        navigateToReviewMeasurements({ measurementInvocationId })
      }
    },
    onArrowDown() {
      const target = viewModel.selection.nextIteration
      if (target) {
        const { measurementInvocationId } = target
        navigateToReviewMeasurements({ measurementInvocationId })
      }
    },
    onArrowRight() {
      const iteration = selectedAccordionedIteration(viewModel)
      if (iteration && !iteration.overrideNesting) {
        dispatchAccordionAction({
          type: 'expandItems',
          items: iteration.nestedSuccessors.map(
            iteration => iteration.measurementInvocationId
          ),
        })
      }
    },
    onArrowLeft() {
      const iteration = selectedAccordionedIteration(viewModel)
      if (iteration && iteration.overrideNesting) {
        dispatchAccordionAction({
          type: 'hideItems',
          items: iteration.nestedSuccessors.map(
            iteration => iteration.measurementInvocationId
          ),
        })
      }
    },
    onCtrlArrowUp() {
      const target = viewModel.selection.sameIterationOnPreviousSubject
      if (target) {
        const { measurementInvocationId } = target
        navigateToReviewMeasurements({ measurementInvocationId })
      }
    },
    onCtrlArrowDown() {
      const target = viewModel.selection.sameIterationOnNextSubject
      if (target) {
        const { measurementInvocationId } = target
        navigateToReviewMeasurements({ measurementInvocationId })
      }
    },
  })

  useKeyDown({
    onKeyDown: (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        performApproveOrUnapprove(e.shiftKey ? 'unapprove' : 'approve')
      }
      if (show3DView && e.key === 'Escape') {
        if (inCombinedViewMode) {
          setInCombinedViewMode(false)
        }
        setShow3DView(false)
      }
    },
  })
}
