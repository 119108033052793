import React from 'react'
import styled from 'styled-components'

import { ReactComponent as HashIcon } from '../common/images/hash.svg'
import { ReactComponent as TimeHistoryIcon } from '../common/images/time-history.svg'
import { MeasurerVersion, measurerVersionMatches } from './measurer-version'

const VersionContainer = styled.div`
  display: inline-block;

  a {
    text-decoration: none;
  }
  :hover a {
    text-decoration: underline;
  }
`

const VersionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e3e3e3;
  border: 1px solid #7f7f7f;
  padding: 6px 4px;

  & > * {
    margin: 0 2px;
  }
`

const Tag = styled.span`
  font-weight: bold;
  color: black;
`

const Sha = styled.span`
  font-family: Lucida Console, monospace;
  color: #7f7f7f;
`

function _MeasurerVersionDisplay({
  measurerVersion,
}: {
  measurerVersion: MeasurerVersion
}): JSX.Element {
  const repoBaseUrl = measurerVersion.repoSlug
    ? `https://github.com/${measurerVersion.repoSlug}`
    : undefined

  let url: string | undefined = undefined
  let innerContent: JSX.Element
  switch (measurerVersion.kind) {
    case 'tag': {
      const { functionName, tagName } = measurerVersion
      if (repoBaseUrl && functionName) {
        const qualifiedTagName = `${functionName}@${tagName}`
        url = `${repoBaseUrl}/releases/tag/${encodeURIComponent(
          qualifiedTagName
        )}`
      }
      innerContent = (
        <>
          <TimeHistoryIcon />
          <Tag>v{tagName}</Tag>
        </>
      )
      break
    }
    case 'commit': {
      const { sha1 } = measurerVersion
      if (repoBaseUrl) {
        url = `${repoBaseUrl}/commits/${sha1}`
      }
      innerContent = (
        <>
          <HashIcon />
          <Sha>{sha1.substring(0, 7)}</Sha>
        </>
      )
      break
    }
    default:
      throw Error(`Unknown kind: ${(measurerVersion as any).kind}`)
  }
  return (
    <VersionContainer>
      {url ? (
        <a href={url}>
          <VersionBox>{innerContent}</VersionBox>
        </a>
      ) : (
        <VersionBox>{innerContent}</VersionBox>
      )}
    </VersionContainer>
  )
}
export const MeasurerVersionDisplay = React.memo(
  _MeasurerVersionDisplay,
  (
    firstProps: Parameters<typeof _MeasurerVersionDisplay>[0],
    secondProps: Parameters<typeof _MeasurerVersionDisplay>[0]
  ) =>
    measurerVersionMatches(
      firstProps.measurerVersion,
      secondProps.measurerVersion
    )
)
