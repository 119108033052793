import React from 'react'
import styled from 'styled-components'

import {
  UNCHANGED_TEXT,
  UPDATED_DECREASED_VALUE_TEXT,
  UPDATED_VALUE_TEXT,
} from '../../common/common-styles'
import { ReactComponent as ChangedIcon } from '../../common/images/changed.svg'
import { ReactComponent as DecreasedIcon } from '../../common/images/decreased.svg'
import { ReactComponent as EqualIcon } from '../../common/images/equal.svg'
import { ReactComponent as IncreasedIcon } from '../../common/images/increased.svg'
import { DivWithSizedText } from '../common-components'

const DivWithUnchangedText = styled(DivWithSizedText)`
  ${UNCHANGED_TEXT}
`
const DivWithChangedText = styled(DivWithSizedText)`
  ${UPDATED_VALUE_TEXT}
`
const DivWithDecreasedValueText = styled(DivWithSizedText)`
  ${UPDATED_DECREASED_VALUE_TEXT}
`

export function PercentChangedWithIcon({
  initialValue,
  compareValue,
}: {
  initialValue: number
  compareValue: number
}): JSX.Element {
  const percentChanged =
    Math.abs((initialValue - compareValue) / compareValue) * 100
  const displayMetric =
    percentChanged < 1 ? '< 1' : `${percentChanged.toFixed(0)}`
  if (initialValue === compareValue) {
    return (
      <DivWithUnchangedText>
        <EqualIcon />
      </DivWithUnchangedText>
    )
  } else if (initialValue > compareValue) {
    return (
      <DivWithChangedText>
        <IncreasedIcon /> {displayMetric}%
      </DivWithChangedText>
    )
  } else {
    return (
      <DivWithDecreasedValueText>
        <DecreasedIcon /> {displayMetric}%
      </DivWithDecreasedValueText>
    )
  }
}

export function MetricComparisonWithIcon({
  displayMetric,
}: {
  displayMetric: string
}): JSX.Element {
  if (displayMetric === '0') {
    return (
      <DivWithUnchangedText>
        <EqualIcon /> Unchanged
      </DivWithUnchangedText>
    )
  } else {
    return (
      <DivWithChangedText>
        <ChangedIcon /> {displayMetric}% changed
      </DivWithChangedText>
    )
  }
}
