import { TABLE_BACKGROUND } from '@unpublished/common-components'
import { css } from 'styled-components'

export const TABLE_BACKGROUND_AND_BORDER = css`
  border: 1px solid black;
  ${TABLE_BACKGROUND};
`
export const ROW_HEIGHT = css`
  height: 57px;
`
export const UNCHANGED_TEXT = css`
  color: #7080cb;
`
export const UPDATED_VALUE_TEXT = css`
  color: #008a8a;
`
export const UPDATED_DECREASED_VALUE_TEXT = css`
  color: rgba(0, 164, 164, 0.5);
`
