import { ReviewMeasurementsHeaderQuery } from '../../common/generated'

export interface HeaderViewModel {
  measurementChoices: string[]
  measurementSelectionIsValid: boolean
}

export function createHeaderViewModel({
  headerData,
  selectedMeasurement,
}: {
  headerData?: ReviewMeasurementsHeaderQuery
  selectedMeasurement?: string
}): HeaderViewModel {
  const measurementChoices =
    (headerData?.measurementStudy.allMeasurementNames.nodes
      .map(node => node.measurementName)
      .filter(measurementName => measurementName !== null) as string[]) ?? []

  const measurementSelectionIsValid =
    selectedMeasurement !== undefined &&
    measurementChoices.includes(selectedMeasurement)

  return { measurementChoices, measurementSelectionIsValid }
}
