/*
To use this hook + custom component, place a single `<ScrollTarget />` inside
the element you want to be scrolled to, and invoke the function returned by
`useScrollIntoView()`.

This module attempts to do the right thing regardless of the order in which
these things happen. For example, if you request scroll into view before the
`<ScrollTarget />` is mounted, the scrolling is deferred until it's mounted.
Similarly if the `<ScrollTarget />` is moved during a render in which scroll
into view is requested, it will scroll to the new target, not the old one.
*/

import React, { useCallback, useEffect, useRef } from 'react'

let needsScrollIntoView = false

const listeners: Set<() => void> = new Set()
function notifyListeners(): void {
  listeners.forEach(fn => fn())
}

export function useScrollIntoView(): () => void {
  useEffect(() => {
    if (needsScrollIntoView) {
      notifyListeners()
    }
  })

  return useCallback(function scrollIntoView(): void {
    needsScrollIntoView = true
  }, [])
}

export function ScrollTarget(props: ScrollIntoViewOptions): JSX.Element {
  const elementRef = useRef<HTMLSpanElement>()

  function maybeScrollIntoView(): void {
    if (needsScrollIntoView && elementRef.current?.parentElement) {
      elementRef.current.parentElement.scrollIntoView(props)
      needsScrollIntoView = false
    }
  }

  // Handle the case where needsScrollIntoView is set before <ScrollTarget />
  // is mounted.
  useEffect(maybeScrollIntoView)

  // Handle the case where <ScrollTarget /> is mounted before
  // needsScrollIntoView is set.
  useEffect(() => {
    listeners.add(maybeScrollIntoView)
    return () => {
      listeners.delete(maybeScrollIntoView)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: There is a type issue with elementRef.
  // @ts-ignore
  return <span style={{ display: 'hidden' }} ref={elementRef} />
}
