import {
  Button,
  FlexRow,
  STATISTICAL_TEXT,
} from '@unpublished/common-components'
import React from 'react'
import Select, { components } from 'react-select'
import styled, { css } from 'styled-components'

import { WrappingFlexDiv } from './flex-table'
import { ReactComponent as LightningIcon } from './images/lightning.svg'

const { Option } = components

export const RightAlignedButton = styled(Button)`
  float: right;
`
export const FixedWidthPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 32px;
`

export const FixedWidthFooterNavContainer = styled.div`
  width: 800px;
  margin-top: 40px;
  margin-bottom: 20px;
`

export const StatisticalSpan = styled.span`
  ${STATISTICAL_TEXT};
`
export const Breadcrumb = StatisticalSpan

export const ErrorText = styled.p`
  color: red;
`

export const GenericInput = styled.input`
  font-size: 14px;
  padding: 7px;
`

export const CenteredDiv = styled.div`
  text-align: center;
`

export const FlexRowWithMargin = styled(FlexRow)`
  align-items: center;
  > * {
    margin-right: 5px;
  }
`

export const Table = styled.table`
  margin-top: 2em;

  th {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.56);
    padding-right: 5em;
    text-align: left;
    width: 33%;
  }

  td {
    vertical-align: top;
  }

  td ul {
    list-style-type: none;
    padding: 0;
  }

  span {
    padding: 0.5em;
    cursor: pointer;
  }
`

const ITERATION_ACTIVE_AND_HOVER_COLOR = '#66acb4'

const IterationDisplayContainer = styled.div<{
  color: string
  isActive?: boolean
  withHover?: boolean
  isLight?: boolean
}>`
  display: flex;
  align-items: center;
  padding: 3px;
  > * {
    margin-right: 5px;
  }
  ${({ isLight }) =>
    isLight
      ? css`
          > span {
            opacity: 0.35;
          }
        `
      : undefined}
  svg path {
    fill-opacity: ${({ isLight }) => (isLight ? 0.35 : 0.6)};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${ITERATION_ACTIVE_AND_HOVER_COLOR};
      svg path {
        fill: ${ITERATION_ACTIVE_AND_HOVER_COLOR};
      }
    `}
  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        color: ${ITERATION_ACTIVE_AND_HOVER_COLOR};
        svg path {
          fill: ${ITERATION_ACTIVE_AND_HOVER_COLOR};
        }
      }
    `}
`

export function IterationDisplay({
  iteration,
  color = 'black',
  isActive,
  onClick,
  isLight = false,
}: {
  iteration: number | null
  color?: string
  isActive?: boolean
  onClick?: () => void
  isLight?: boolean
}): JSX.Element {
  return (
    <IterationDisplayContainer
      color={color}
      withHover={Boolean(onClick)}
      isActive={isActive}
      onClick={e => {
        if (onClick) {
          e.stopPropagation()
          onClick()
        }
      }}
      isLight={isLight}
    >
      <LightningIcon />
      <span>i{iteration}</span>
    </IterationDisplayContainer>
  )
}

export const NextButton = styled(Button)`
  float: right;
`

const ConfirmationContainer = styled.div<{ stacked: boolean }>`
  border: 1px solid black;
  ${({ stacked }) =>
    stacked
      ? css`
          > * {
            margin: 5px 15px;
          }
        `
      : css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          > * {
            margin: 0 15px;
          }
        `}
`

const ConfirmButton = styled(Button)`
  background-color: saddlebrown;
  color: white;
  &:hover {
    background-color: #bd8760;
  }
`

export function Confirm({
  message,
  actionLabel,
  stacked = false,
  disabled = false,
  onConfirm,
  onCancel,
}: {
  message: string
  actionLabel: string
  stacked?: boolean
  disabled?: boolean
  onConfirm: () => void
  onCancel: () => void
}): JSX.Element {
  return (
    <ConfirmationContainer stacked={stacked}>
      <div>{message}</div>
      <div>
        <Button onClick={onCancel}>Cancel</Button>
        <ConfirmButton onClick={onConfirm} disabled={disabled}>
          {actionLabel}
        </ConfirmButton>
      </div>
    </ConfirmationContainer>
  )
}

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`

export function IconOption(props: any): JSX.Element {
  return (
    <Option {...props}>
      <OptionContainer>
        <div>
          <LightningIcon fillOpacity="0.35" /> &nbsp;
        </div>
        <div> {props.data.label} </div>
      </OptionContainer>
    </Option>
  )
}

export const SpanIndentedWrappingFlexDiv = styled(WrappingFlexDiv)`
  > span {
    padding-right: 1em;
  }
`

export const FloatRightButton = styled(Button)`
  float: right;
`

export const ButtonContainer = styled.div`
  margin-top: 10em;
`

export const NarrowSelect = styled(Select)`
  width: 15em;
  display: inline-block;
`

export const StyledInput = styled.input<{ invalid?: boolean }>`
  border: 1px solid #bdbdbd;
  width: 209px;
  height: 30px;
  padding: 10px;
  ${({ invalid }) =>
    invalid &&
    css`
      border-color: red;
    `};
  :invalid {
    border-color: red;
  }
`
