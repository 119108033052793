import styled, { css } from 'styled-components'

const BUTTON_COMMON_STYLE = css`
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 20px;
  border: 1px solid #dedede;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8f8f8f;
  text-align: center;
  text-transform: uppercase;
  background-color: #ebebeb;
  &:hover {
    background-color: rgba(196, 196, 196, 0);
    border: 1px solid #8f8f8f;
  }
  &:disabled {
    background-color: rgba(196, 196, 196, 0);
    border: 1px solid #dedede;
    color: LightGray;
    cursor: default;
  }
  cursor: pointer;
`

const BUTTON_DYNAMIC_HEIGHT_STYLE = css`
  padding: 0.5em 1.2em;
`

export const ButtonStyledInput = styled.input`
  padding: 0.5em 1.2em;
  ${BUTTON_COMMON_STYLE}
`

export const Button = styled.button<{ isFixedHeight?: boolean }>`
  ${({ isFixedHeight }) =>
    isFixedHeight
      ? css`
          height: 33px;
          vertical-align: top;
          padding: 0 1.2em;
          display: inline-flex;
          align-items: center;
        `
      : BUTTON_DYNAMIC_HEIGHT_STYLE}
  ${BUTTON_COMMON_STYLE}
`

export const LinkButton = styled.a`
  ${BUTTON_DYNAMIC_HEIGHT_STYLE}
  ${BUTTON_COMMON_STYLE}
  text-decoration: none;
`
