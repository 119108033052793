export function readStringFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = ({ target }: ProgressEvent<FileReader>) => {
      if (!target) {
        reject(Error('FileReader target should be truthy'))
        return
      }
      const { result } = target
      if (typeof result !== 'string') {
        reject(Error('FileReader text result should be a string'))
        return
      }
      resolve(result)
    }

    reader.readAsText(file)
  })
}

export async function readJsonFromFile(file: File): Promise<any> {
  const contents = await readStringFromFile(file)
  return JSON.parse(contents)
}

export async function readDataUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = ({ target }: ProgressEvent<FileReader>) => {
      if (!target) {
        reject(Error('FileReader target should be truthy'))
        return
      }
      const { result } = target
      if (typeof result !== 'string') {
        reject(Error('FileReader data URL result should be a string'))
        return
      }
      resolve(result)
    }

    reader.readAsDataURL(file)
  })
}
