// These highly optimized functions were adapted from
// https://github.com/metabolize/Underscore.eachN
// Copyright 2014, Body Labs, Inc.
// BSD 2-Clause License

export type Iterator<Input, Output> = (
  slice: Input[],
  firstIndex: number,
  list: Input[]
) => Output

// Iterates over a list of elements by slicing the it, n at a time, yielding
// those n items to an iterator function. The iterator is bound to the context
// object, if one is passed. Each invocation of iterator is called with three
// arguments: (slice, firstIndex, list), where firstIndex is the index of the
// beginning of the slice. If elements.length it not divisible by n, the last
// invocation to iterator will have fewer than n elements. Returns the original
// list for chaining.
export function eachN<Input>(
  obj: Input[],
  n: number,
  iterator: Iterator<Input, void>,
  context?: any
): Input[] {
  if (!isFinite(n) || n % 1 !== 0) {
    throw new TypeError('n should be an integer!')
  }

  for (let i = 0, length = obj.length; i < length; i += n) {
    const slice = obj.slice(i, i + n)
    iterator.call(context, slice, i, obj)
  }

  return obj
}

export function mapN<Input, Output>(
  obj: Input[],
  n: number,
  iterator: Iterator<Input, Output>,
  context?: any
): Output[] {
  const results: Output[] = []

  eachN(obj, n, function (slice: Input[], firstIndex: number, list: Input[]) {
    results.push(iterator.call(context, slice, firstIndex, list))
  })

  return results
}
