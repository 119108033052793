import { gql } from '@apollo/client'

export const MEASUREMENT_STUDY_NAME_QUERY = gql`
  query MeasurementStudyName($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      id
      name
    }
  }
`
