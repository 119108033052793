import { NamedPoints } from '@curvewise/common-types'
import { parse } from 'csv-parse/browser/esm/sync'

/*
Name	Visibility	Position			Pair Pos.			Dev.				Pos. Tol.			
		X	Y	Z	X	Y	Z	Value	X	Y	Z	Value	X	Y	Z
dipp2	TRUE	40.6276	19.8952	-159.7184											
dipp3	TRUE	-2.8035	10.1957	-167.6177											
*/

export function importLandmarksFromTsv(tsv: string): NamedPoints | undefined {
  if (!tsv.startsWith('Name\tVisibility\tPosition')) {
    return undefined
  }

  // The duplicate key names in row 2 prevent `columns: true` from working correctly.
  const records = parse(tsv, { delimiter: '\t', from_line: 3 })

  const namedPoints: NamedPoints = records.map(
    ([name, _, x, y, z]: string[]) => ({
      name,
      point: [+x, +y, +z],
    })
  )

  return namedPoints
}
