import { Polyline } from 'hyla'
import React from 'react'
import * as THREE from 'three'

import {
  adjustPolygonOffsetForAtoms,
  appearancePropsForStyle,
  AppearanceStyle,
  AtomProps,
  BondProps,
  Gradient,
} from './tape-appearance'

const sphereGeometry = new THREE.SphereGeometry(1, 32, 32)
const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 1, 32, 32, true)

function Atom({
  point,
  radius,
  polygonOffset,
  polygonOffsetFactor,
  polygonOffsetUnits,
  colorWrite,
  color,
  opacity,
  transparent,
}: AtomProps): JSX.Element {
  ;({ polygonOffset, polygonOffsetFactor, polygonOffsetUnits } =
    adjustPolygonOffsetForAtoms({
      polygonOffset,
      polygonOffsetFactor,
      polygonOffsetUnits,
    }))

  return (
    <mesh
      geometry={sphereGeometry}
      scale={[radius, radius, radius]}
      position={point.coords}
    >
      <meshLambertMaterial
        color={color}
        opacity={opacity}
        transparent={transparent}
        colorWrite={colorWrite}
        polygonOffset={polygonOffset}
        polygonOffsetFactor={polygonOffsetFactor}
        polygonOffsetUnits={polygonOffsetUnits}
      />
    </mesh>
  )
}

function Bond({
  segment,
  radius,
  polygonOffset,
  polygonOffsetFactor,
  polygonOffsetUnits,
  colorWrite,
  color,
  opacity,
  transparent,
}: BondProps): JSX.Element {
  // The computation in this function seems inefficient, however it turns out
  // not to be performance-sensitive. Since `<Tape />` is memoized, this
  // component only runs when the tape is changed.
  const arrow = new THREE.ArrowHelper(
    new THREE.Vector3().fromArray(segment.vector.normalized().coords),
    new THREE.Vector3().fromArray(segment.first.coords)
  )
  const rotation = new THREE.Euler().setFromQuaternion(arrow.quaternion)

  return (
    <mesh
      geometry={cylinderGeometry}
      scale={[radius, segment.length, radius]}
      rotation={rotation}
      position={segment.midpoint.coords}
    >
      <meshLambertMaterial
        color={color}
        opacity={opacity}
        transparent={transparent}
        colorWrite={colorWrite}
        polygonOffset={polygonOffset}
        polygonOffsetFactor={polygonOffsetFactor}
        polygonOffsetUnits={polygonOffsetUnits}
      />
    </mesh>
  )
}

export const Tape = React.memo(function Tape({
  appearanceStyle,
  curve,
  tapeWidth,
  compareToCurve,
  soloColor,
  depthOnlyFirstPass = false,
}: {
  appearanceStyle: AppearanceStyle
  curve: Polyline
  tapeWidth: number
  compareToCurve?: Polyline
  soloColor?: number
  depthOnlyFirstPass?: boolean
}): JSX.Element {
  const radius = tapeWidth / 2.0

  // Since `depthOnlyFirstPass={true}` means no color information is written, on
  // the depth-only pass we never compute the gradient.
  const shouldComputeGradient =
    appearanceStyle === 'compareFromUsingGradient' && !depthOnlyFirstPass

  let atoms: AtomProps[]
  let bonds: BondProps[]
  if (shouldComputeGradient) {
    if (compareToCurve === undefined) {
      throw Error(
        'With `appearanceStyle="compareFromUsingGradient"`, `compareToCurve` is required'
      )
    }
    const gradient = new Gradient({ curve, compareToCurve, radius })
    atoms = gradient.computeAtomProps()
    bonds = gradient.computeBondProps()
  } else {
    const appearance = appearancePropsForStyle({
      appearanceStyle,
      radius,
      depthOnlyFirstPass,
      soloColor,
    })
    atoms = curve.vertices.map(point => ({ point, ...appearance }))
    bonds = curve.segments
      // filter degenerate segments to prevent crashes caused by normalzing zero-length vectors.
      .filter(segment => segment.length > 0)
      .map(segment => ({ segment, ...appearance }))
  }

  return (
    <group>
      {atoms.map((atom, i) => (
        <Atom key={i} {...atom} />
      ))}
      {bonds.map((bond, i) => (
        <Bond key={i} {...bond} />
      ))}
    </group>
  )
})
