import { ApolloError, DocumentNode, gql, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'

import {
  IterationChoicesQuery,
  IterationChoicesQueryVariables,
} from '../common/generated'
import { IterationPicker, LATEST_ITERATION } from './iteration-picker'

export const ITERATION_CHOICES_QUERY = gql`
  query IterationChoices($measurementStudyId: Int!) {
    allMeasurementInvocationIterations(
      condition: { measurementStudyId: $measurementStudyId }
      orderBy: MEASUREMENT_INVOCATION_ITERATION_DESC
    ) {
      nodes {
        measurementInvocationIteration
      }
    }
  }
`

export function usePickCompareIterations({
  measurementStudyId,
  includeLatest = true,
}: {
  measurementStudyId: number
  includeLatest?: boolean
}): {
  leftIteration?: number
  rightIteration?: number
  usingLatestIteration: boolean
  renderLeftIterationPicker: () => JSX.Element
  renderRightIterationPicker: () => JSX.Element
} {
  const [leftIteration, setLeftIteration] = useState<number>()
  const [rightIteration, setRightIteration] = useState<number>()

  const { data } = useQuery<
    IterationChoicesQuery,
    IterationChoicesQueryVariables
  >(ITERATION_CHOICES_QUERY, {
    variables: { measurementStudyId },
  })

  const iterationChoices = useMemo(
    () =>
      data?.allMeasurementInvocationIterations.nodes.map(
        node => node.measurementInvocationIteration as number
      ) ?? [],
    [data]
  )

  useEffect(() => {
    if (leftIteration === undefined && iterationChoices.length > 0) {
      setLeftIteration(includeLatest ? LATEST_ITERATION : iterationChoices[0])
    }
  }, [includeLatest, leftIteration, iterationChoices])

  useEffect(() => {
    if (rightIteration === undefined && iterationChoices.length > 0) {
      setRightIteration(iterationChoices[iterationChoices.length - 1])
    }
  }, [rightIteration, iterationChoices])

  return {
    leftIteration,
    rightIteration,
    usingLatestIteration: leftIteration === LATEST_ITERATION,
    renderLeftIterationPicker: () => (
      <IterationPicker
        includeLatest={includeLatest}
        iterationChoices={iterationChoices}
        selectedIteration={leftIteration}
        onChange={setLeftIteration}
      />
    ),
    renderRightIterationPicker: () => (
      <IterationPicker
        iterationChoices={iterationChoices}
        selectedIteration={rightIteration}
        onChange={setRightIteration}
      />
    ),
  }
}

interface ExpectedLatestIterationQueryVariables {
  measurementStudyId: number
  rightMeasurementInvocationIteration: number
}

interface ExpectedSpecificIterationQueryVariables
  extends ExpectedLatestIterationQueryVariables {
  leftMeasurementInvocationIteration: number
}

export function useIterationQueries<
  LatestIterationQuery,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  LatestIterationQueryVariables extends ExpectedLatestIterationQueryVariables,
  SpecificIterationQuery,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SpecificIterationQueryVariables extends ExpectedSpecificIterationQueryVariables
>({
  measurementStudyId,
  latestIterationQuery: latestIterationQueryNode,
  specificIterationQuery: specificIterationQueryNode,
  leftIteration,
  rightIteration,
}: {
  latestIterationQuery: DocumentNode
  specificIterationQuery: DocumentNode
  measurementStudyId: number
  leftIteration?: number
  rightIteration?: number
}): {
  loading: boolean
  error?: ApolloError
  data: {
    latestIterationData?: LatestIterationQuery
    specificIterationData?: SpecificIterationQuery
  }
} {
  const usingLatestIteration = leftIteration === LATEST_ITERATION

  const latestIterationQuery = useQuery<
    LatestIterationQuery,
    ExpectedLatestIterationQueryVariables
  >(latestIterationQueryNode, {
    variables: {
      measurementStudyId,
      rightMeasurementInvocationIteration: rightIteration as number,
    },
    skip: !usingLatestIteration || rightIteration === undefined,
  })
  const specificIterationQuery = useQuery<
    SpecificIterationQuery,
    ExpectedSpecificIterationQueryVariables
  >(specificIterationQueryNode, {
    variables: {
      measurementStudyId,
      leftMeasurementInvocationIteration: leftIteration as number,
      rightMeasurementInvocationIteration: rightIteration as number,
    },
    skip:
      usingLatestIteration ||
      leftIteration === undefined ||
      rightIteration === undefined,
  })

  return {
    error: latestIterationQuery.error ?? specificIterationQuery.error,
    loading: latestIterationQuery.loading || specificIterationQuery.loading,
    data: {
      latestIterationData: latestIterationQuery?.data,
      specificIterationData: specificIterationQuery?.data,
    },
  }
}
