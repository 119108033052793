export function downloadContent({
  contents,
  filename,
  contentType = 'text/plain',
}: {
  contents: string
  filename: string
  contentType?: string
}): void {
  const blob = new Blob([contents], { type: contentType })
  const url = window.URL.createObjectURL(blob)

  const element = document.createElement('a')
  document.body.appendChild(element)
  element.style.display = 'none'

  element.href = url
  element.download = filename
  element.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(element)
}

export function downloadJsonContent({
  data,
  filename,
}: {
  data: any
  filename: string
}): void {
  downloadContent({
    contents: JSON.stringify(data, undefined, 4),
    filename,
    contentType: 'application/json',
  })
}
