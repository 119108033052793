import { uniqWith } from 'lodash'

export interface Label {
  // Use null for GraphQL compatibility.
  name: string | null
  isFailure: boolean
}

export const BLANK_APPROVED_LABEL: Label = { isFailure: false, name: null }

export function hashKeyForLabel({
  name,
  isFailure,
}: {
  name: string | null
  isFailure: boolean
}): string {
  return `${isFailure}: ${name}`
}

export function labelMatches(first: Label, second: Label): boolean {
  return first.name === second.name && first.isFailure === second.isFailure
}

export function listContainsLabel(list: Label[], label: Label): boolean {
  return list.some(thisLabel => labelMatches(thisLabel, label))
}

// Sorting order: thumbs up, then alphabetized successes, then alphabetized failures.
export function orderLabels(first: Label, second: Label): number {
  const firstName = first.name ?? ''
  const secondName = second.name ?? ''
  if (+first.isFailure - +second.isFailure === 0) {
    return firstName < secondName ? -1 : 1
  } else {
    return +first.isFailure - +second.isFailure
  }
}

export function sortedUniqueLabels(labels: Label[]): Label[] {
  return uniqWith(labels, labelMatches).sort(orderLabels)
}
