import { ApolloProvider as GenericApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { makeOnRedirectCallback } from '@unpublished/auth0-helpers'
import { ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useClient } from './client'

// Since the client consumes the Auth0 provider, it must be created within a
// child component of `<Auth0Provider />`.
function ApolloProvider({
  url,
  withAuth0Credentials,
  children,
}: {
  url: string
  withAuth0Credentials?: boolean
  children: ReactNode
}): JSX.Element {
  const client = useClient({ url, withAuth0Credentials })

  return (
    <GenericApolloProvider client={client}>{children}</GenericApolloProvider>
  )
}

// Provide Auth0 and Apollo contexts and glue them together.
export function ApolloAuth0Provider({
  withRedirectCallback = true,
  withAuth0Credentials,
  url,
  authConfig,
  scope,
  children,
}: {
  withRedirectCallback?: boolean
  withAuth0Credentials?: boolean
  url: string
  authConfig?: {
    domain: string
    clientId: string
    audience: string
  }
  scope?: string
  children: ReactNode
}): JSX.Element {
  const navigate = useNavigate()
  const onRedirectCallback = useMemo(() => {
    if (withRedirectCallback) {
      return makeOnRedirectCallback(navigate)
    } else {
      return undefined
    }
  }, [withRedirectCallback, navigate])

  const apolloProvider = (
    <ApolloProvider url={url} withAuth0Credentials={withAuth0Credentials}>
      {children}
    </ApolloProvider>
  )

  if (withAuth0Credentials) {
    if (!authConfig) {
      throw Error('When withAuth0Credentials is true, auth0Config is required')
    }

    const { domain, clientId, audience } = authConfig

    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={audience}
        scope={scope}
        onRedirectCallback={onRedirectCallback}
      >
        {apolloProvider}
      </Auth0Provider>
    )
  } else {
    return apolloProvider
  }
}
