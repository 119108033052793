import { gql } from '@apollo/client'
import {
  AnnotationDelta,
  Progress,
  progressForItems,
} from '@unpublished/common-components'
import { ColoredPoint } from '@unpublished/scene'
import humanizeString from 'humanize-string'
import { Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'

import {
  transformCoordinatesToTHREEVector3,
  uniqueValues,
} from '../../common/data-transforms'
import {
  UnitsType,
  ViewAnnotationsDetailQuery,
  ViewDatasetAnnotationsQuery,
} from '../../common/generated'
import { NavFocus } from '.'

export const VIEW_DATASET_ANNOTATIONS_DETAIL_QUERY = gql`
  query ViewAnnotationsDetail($id: Int!) {
    geometryById(id: $id) {
      signedURL
      s3Key
    }
  }
`

export const VIEW_DATASET_ANNOTATIONS_QUERY = gql`
  query ViewDatasetAnnotations($id: Int!) {
    datasetById(id: $id) {
      name
      superiorDirectionCoordinates
      anteriorDirectionCoordinates
      units
      geometryAnnotationTypesByDatasetId {
        nodes {
          name
          id
        }
      }
      subjectsByDatasetId {
        nodes {
          name
          geometrySeriesBySubjectId {
            nodes {
              poseTypeByPoseTypeId {
                name
              }
              geometriesByGeometrySeriesId {
                nodes {
                  id
                  geometryAnnotationsByGeometryId {
                    nodes {
                      geometryAnnotationTypeByGeometryAnnotationTypeId {
                        name
                      }
                      point
                      geometryAnnotationTypeId
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface Geometry {
  id: number
  nextGeometryId?: number
  previousGeometryId?: number
  subjectName: string
  pose: string
  hasPoint: boolean
  pointIsDirty: boolean
}

interface DisplayPoint {
  annotationTypeId: number
  nextATypeId?: number
  previousATypeId?: number
  pointName: string
  isSetForGeometry: boolean
  pointIsDirty: boolean
}

export interface ViewModel {
  dataset: {
    superiorAxis?: Vector
    anteriorAxis?: Vector
    units?: UnitsType
  }
  geometries: Geometry[]
  geometryProgress: Progress
  points: DisplayPoint[]
  pointProgress: Progress
  hasMultiplePoses: boolean
  selectedGeometry: {
    selectedAnnotationId: number
    selectedPoint?: Vector3
    s3Key?: string
    signedURL?: string
    points: ColoredPoint[]
  }
  adjacentNavIds: {
    primaryNext?: number
    secondaryNext?: number
    primaryPrevious?: number
    secondaryPrevious?: number
  }
}
export function createAnnotationsViewModel({
  data,
  detailQueryData,
  localDeltas,
  selectedAnnotationTypeId,
  selectedGeometryId,
  navFocus,
}: {
  data?: ViewDatasetAnnotationsQuery
  detailQueryData?: ViewAnnotationsDetailQuery
  localDeltas: AnnotationDelta<number>[]
  selectedAnnotationTypeId?: number
  selectedGeometryId?: number
  navFocus: NavFocus
}): ViewModel {
  const geometriesData = data?.datasetById.subjectsByDatasetId.nodes.flatMap(
    subject =>
      subject.geometrySeriesBySubjectId.nodes.flatMap(geometrySeries =>
        geometrySeries.geometriesByGeometrySeriesId.nodes.map(geometry => {
          const committedPoints =
            geometry.geometryAnnotationsByGeometryId.nodes.map(annotation => {
              const name = humanizeString(
                annotation.geometryAnnotationTypeByGeometryAnnotationTypeId.name
              )
              return {
                annotationId: annotation.id,
                annotationTypeId: annotation.geometryAnnotationTypeId,
                name,
                point:
                  annotation.point === null
                    ? null
                    : (annotation.point as Vector3),
              }
            })
          return {
            id: geometry.id,
            subjectName: subject.name,
            pose: geometrySeries.poseTypeByPoseTypeId.name,
            committedPoints,
            reconciledPoints: committedPoints.map(
              ({ annotationId, annotationTypeId, name, point }) => {
                const pointFromDeltas = localDeltas.find(
                  d => d.id === annotationId
                )?.point
                return {
                  annotationId,
                  annotationTypeId,
                  name: humanizeString(name),
                  point:
                    pointFromDeltas === undefined ? point : pointFromDeltas,
                }
              }
            ),
          }
        })
      )
  )
  const flattenedPoints =
    data?.datasetById.geometryAnnotationTypesByDatasetId.nodes
  const selectedGeometryData = geometriesData?.find(
    g => g.id === selectedGeometryId
  )

  const geometries =
    geometriesData?.map(
      ({ id, subjectName, pose, reconciledPoints, committedPoints }, i) => {
        const selectedPoint = reconciledPoints?.find(
          p => p.annotationTypeId === selectedAnnotationTypeId
        )
        return {
          id,
          previousGeometryId: geometriesData[i - 1] && geometriesData[i - 1].id,
          nextGeometryId: geometriesData[i + 1] && geometriesData[i + 1].id,
          subjectName,
          pose,
          hasPoint: !!selectedPoint?.point,
          pointIsDirty: localDeltas
            .map(d => d.id)
            .includes(selectedPoint?.annotationId ?? -1),
        }
      }
    ) ?? []

  const points: DisplayPoint[] = flattenedPoints
    ? flattenedPoints.map(({ id, name }, i) => {
        const selectedPoint = selectedGeometryData?.reconciledPoints?.find(
          p => p.annotationTypeId === id
        )
        return {
          annotationTypeId: id,
          previousATypeId: flattenedPoints[i - 1] && flattenedPoints[i - 1].id,
          nextATypeId: flattenedPoints[i + 1] && flattenedPoints[i + 1].id,
          pointName: name,
          isSetForGeometry: selectedPoint?.point !== null,
          pointIsDirty: localDeltas
            .map(d => d.id)
            .includes(selectedPoint?.annotationId ?? -1),
        }
      })
    : []

  const nextGeometryId = geometries.find(
    g => g.id === selectedGeometryId
  )?.nextGeometryId
  const previousGeometryId = geometries.find(
    g => g.id === selectedGeometryId
  )?.previousGeometryId
  const nextATypeId = points.find(
    p => p.annotationTypeId === selectedAnnotationTypeId
  )?.nextATypeId
  const previousATypeId = points.find(
    p => p.annotationTypeId === selectedAnnotationTypeId
  )?.previousATypeId

  return {
    dataset: {
      superiorAxis: data?.datasetById.superiorDirectionCoordinates
        ? transformCoordinatesToTHREEVector3(
            data?.datasetById.superiorDirectionCoordinates
          )
        : undefined,
      anteriorAxis: data?.datasetById.anteriorDirectionCoordinates
        ? transformCoordinatesToTHREEVector3(
            data?.datasetById.anteriorDirectionCoordinates
          )
        : undefined,
      units: data?.datasetById.units,
    },
    geometries,
    geometryProgress: progressForItems(
      geometries,
      geometry => geometry.hasPoint
    ),
    points,
    pointProgress: progressForItems(points, point => point.isSetForGeometry),
    hasMultiplePoses:
      uniqueValues(geometriesData?.map(geometry => geometry.pose)).length > 1,
    selectedGeometry: {
      s3Key: detailQueryData?.geometryById.s3Key,
      signedURL: detailQueryData?.geometryById.signedURL,
      points:
        selectedGeometryData?.reconciledPoints
          ?.filter(p => p.point !== null)
          .map(point => ({
            name: point.name,
            point: point.point as Vector3,
            color:
              point.annotationTypeId === selectedAnnotationTypeId
                ? 'darkGreen'
                : 'paleGreen',
          })) ?? [],
      selectedAnnotationId:
        selectedGeometryData?.reconciledPoints.find(
          p => p.annotationTypeId === selectedAnnotationTypeId
        )?.annotationId ?? -1,
      selectedPoint:
        selectedGeometryData?.reconciledPoints.find(
          p => p.annotationTypeId === selectedAnnotationTypeId
        )?.point ?? undefined,
    },
    adjacentNavIds: {
      primaryNext: navFocus === 'subjects' ? nextGeometryId : nextATypeId,
      primaryPrevious:
        navFocus === 'subjects' ? previousGeometryId : previousATypeId,
      secondaryNext: navFocus === 'subjects' ? nextATypeId : nextGeometryId,
      secondaryPrevious:
        navFocus === 'subjects' ? previousATypeId : previousGeometryId,
    },
  }
}
