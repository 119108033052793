import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import { App } from './app/app'

const appElement = document.getElementById('root')
if (!appElement) {
  throw Error('Root element not found')
}

ReactDOM.render(<App />, appElement)

Modal.setAppElement(appElement)
