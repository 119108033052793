import { LengthUnits } from '@curvewise/common-types'
import { Vector3 } from 'polliwog-types'
import React from 'react'
import * as THREE from 'three'

import { CSS2DObject } from '../2d/2d-css-object'
import { conversionFactorToCM } from './transform'

const GEOMETRY = new THREE.SphereGeometry(1)

const MATERIALS = {
  red: new THREE.MeshBasicMaterial({ color: 'red' }),
  green: new THREE.MeshBasicMaterial({ color: '#39ff14' }),
  blue: new THREE.MeshBasicMaterial({ color: 'blue' }),
  darkGreen: new THREE.MeshBasicMaterial({ color: '#0d3535' }),
  paleGreen: new THREE.MeshBasicMaterial({ color: '#54eded' }),
  darkGray: new THREE.MeshBasicMaterial({ color: '#111' }),
}

export type PointColor = keyof typeof MATERIALS

export function Point({
  point,
  label,
  color,
  radiusCm = 1 / 4,
  units = 'cm',
}: {
  point: Vector3
  label?: string
  color: PointColor
  radiusCm?: number
  units?: LengthUnits
}): JSX.Element {
  return (
    <mesh
      geometry={GEOMETRY}
      material={MATERIALS[color]}
      position={point}
      scale={radiusCm / conversionFactorToCM(units)}
    >
      {label && <CSS2DObject label={label} />})
    </mesh>
  )
}
