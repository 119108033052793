import { Button } from '@unpublished/common-components'
import styled from 'styled-components'

export const PromptContainer = styled.div`
  margin-top: 2em;
  padding: 1em;
  border: 1px solid black;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

export const PromptTextContainer = styled.span`
  padding-top: 1em;
`

export const PromptActionButton = styled(Button)`
  background: #824820;
  border: 1px solid #dedede;
  color: white;
  &:hover {
    color: #8f8f8f;
  }
`
