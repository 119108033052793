import React from 'react'
import styled from 'styled-components'

const MessageOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #999;
`

const MessageInnerContainer = styled.div`
  width: 40%;
  border: 1px dashed #333;
  padding: 0 20px 10px;
`

export function Messages({ messages }: { messages: string[] }): JSX.Element {
  return (
    <MessageOuterContainer>
      <MessageInnerContainer>
        <h1>Messages</h1>
        <ol>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ol>
      </MessageInnerContainer>
    </MessageOuterContainer>
  )
}
