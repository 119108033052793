import { useLazyQuery, useQuery } from '@apollo/client'
import { humanizeBodyPart } from '@curvewise/measured-body'
import { Button, FlexColumn, FlexRow } from '@unpublished/common-components'
import { downloadJsonContent } from '@unpublished/victorinox'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
} from '../../common/common-components'
import { TABLE_BACKGROUND_AND_BORDER } from '../../common/common-styles'
import {
  humanizeAxis,
  humanizedDurationFromNow,
  humanizeGender,
  humanizeTopology,
} from '../../common/data-transforms'
import {
  ExportDatasetAnnotationsQuery,
  ExportDatasetAnnotationsQueryVariables,
  ViewDataSetsWithDetailsQuery,
  ViewDataSetsWithDetailsQueryVariables,
} from '../../common/generated'
import { ReactComponent as EditLogo } from '../../common/images/pencil-edit.svg'
import { useNumericParam } from '../../common/use-numeric-param'
import { AnnotationTypesList } from './annotation-types-list'
import {
  EXPORT_DATASET_ANNOTATIONS,
  transformAnnotationsForExport,
} from './export-annotations'
import {
  createViewModel,
  SubjectViewModel,
  VIEW_DATASET_WITH_DETAIL_QUERY,
} from './view-model'

const DatasetProperties = styled.table`
  width: 1100px;
  table-layout: fixed;
  td {
    vertical-align: top;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 14px;
    color: gray;
    line-height: 0.2;
  }
`
const AttributeOptionList = styled.ul`
  padding-left: 20px;
  list-style-type: none;

  li {
    padding-bottom: 5px;
  }
  li:before {
    content: '-';
    position: absolute;
    margin-left: -20px;
  }
`

const EditButton = styled(EditLogo)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  visibility: hidden;
  cursor: pointer;
`

const SubjectContainer = styled.div`
  ${TABLE_BACKGROUND_AND_BORDER}
  position: relative;
  display: inline-block;
  padding: 12px;
  margin: 2px 22px 22px 2px;
  text-align: center;

  &:hover {
    background: #c4c4c4;
  }

  &:hover ${EditButton} {
    visibility: visible;
  }
`

const GeometrySeriesContainer = styled(FlexRow)`
  gap: 8px;
`

const SubjectFooter = styled.div`
  margin: 3px auto;
  display: block;
`

const PreviewImage = styled.img`
  border: 1px solid #8f8f8f;
  width: 110px;
  height: 110px;
  margin: 6px;
  display: inline-block;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`

function Subject({
  datasetId,
  subject,
  shouldRenderPose,
  shouldRenderTopology,
}: {
  datasetId: number
  subject: SubjectViewModel
  shouldRenderPose: boolean
  shouldRenderTopology: boolean
}): JSX.Element {
  const navigate = useNavigate()

  return (
    <SubjectContainer key={subject.id}>
      <GeometrySeriesContainer>
        {subject.geometrySeries.map(geometrySeries => (
          <FlexColumn key={geometrySeries.slug}>
            <PreviewImage
              onClick={() =>
                navigate(
                  `/datasets/${datasetId}/subjects/${subject.name}/${geometrySeries.poseType.id}`
                )
              }
              src={geometrySeries.latestGeometry.screenshotUrl ?? ''}
            />
            {(shouldRenderPose || shouldRenderTopology) && (
              <SubjectFooter>
                {shouldRenderPose && (
                  <span>{geometrySeries.poseType.name}</span>
                )}
                {shouldRenderPose && shouldRenderTopology && <br />}
                {shouldRenderTopology && (
                  <span>{humanizeTopology(geometrySeries.topology)}</span>
                )}
              </SubjectFooter>
            )}
          </FlexColumn>
        ))}
      </GeometrySeriesContainer>
      <SubjectFooter>
        {subject.name}
        <EditButton />
      </SubjectFooter>
    </SubjectContainer>
  )
}

function Subjects({
  datasetId,
  subjects,
  shouldRenderPose,
  shouldRenderTopology,
}: {
  datasetId: number
  subjects: SubjectViewModel[]
  shouldRenderPose: boolean
  shouldRenderTopology: boolean
}): JSX.Element {
  const commonSubjectAttrs = {
    datasetId,
    shouldRenderPose,
    shouldRenderTopology,
  }

  return (
    <div>
      {subjects.map(subject => (
        <Subject {...commonSubjectAttrs} key={subject.id} subject={subject} />
      ))}
    </div>
  )
}

export function ViewDataset(): JSX.Element {
  const selectedDataset = useNumericParam('selectedDataset')
  const navigate = useNavigate()

  const { loading, error, data, refetch } = useQuery<
    ViewDataSetsWithDetailsQuery,
    ViewDataSetsWithDetailsQueryVariables
  >(VIEW_DATASET_WITH_DETAIL_QUERY, {
    variables: { datasetId: selectedDataset },
  })

  const [getAnnotationsDetailQuery, annotationsDetailQuery] = useLazyQuery<
    ExportDatasetAnnotationsQuery,
    ExportDatasetAnnotationsQueryVariables
  >(EXPORT_DATASET_ANNOTATIONS, {
    variables: { id: selectedDataset },
    onCompleted(data) {
      downloadJsonContent({
        data: transformAnnotationsForExport(data),
        filename: `${selectedDataset}_annotations.json`,
      })
    },
  })

  const viewModel = createViewModel(data)

  const commonSubjectAttrs = {
    datasetId: selectedDataset,
    shouldRenderPose: viewModel.shouldRenderPose,
    shouldRenderTopology: viewModel.shouldRenderTopology,
  }

  return (
    <FixedWidthPageContainer>
      {error && <p>Oh no! {error.message}</p>}
      {annotationsDetailQuery.error && (
        <p>Oh no! {annotationsDetailQuery.error.message}</p>
      )}
      {loading && <p>Loading &hellip;</p>}
      {viewModel.dataset && (
        <div>
          <Breadcrumb>
            <Link to="/">Home</Link> {'>'} <Link to="/datasets">Datasets</Link>{' '}
            {'>'} {viewModel.dataset.name}
          </Breadcrumb>
          <h1>{viewModel.dataset.name}</h1>
          <DatasetProperties>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <h3>Last Modified</h3>
                  <span>
                    {humanizedDurationFromNow(viewModel.dataset.lastModified)}
                  </span>
                </td>
                <td>
                  {' '}
                  <h3>Units</h3>
                  <span>{viewModel.dataset.units.toLowerCase()}</span>
                </td>
                <td rowSpan={3}>
                  <h3>Topologies</h3>
                  <AttributeOptionList>
                    {viewModel.topologies.map(topology => (
                      <li key={topology ?? 'None'}>
                        {humanizeTopology(topology)}
                      </li>
                    ))}
                  </AttributeOptionList>
                  <h3>Active topology</h3>
                  <span>
                    {humanizeTopology(viewModel.dataset.activeTopology)}
                  </span>
                </td>
                <td rowSpan={3} colSpan={2}>
                  <AnnotationTypesList
                    annotationTypes={viewModel.annotationTypes}
                    datasetId={selectedDataset}
                    onSaveSuccess={() => refetch()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  <h3>Body Part</h3>
                  <span>
                    {humanizeBodyPart(viewModel.dataset.bodyPartType)}
                  </span>
                </td>
                <td>
                  {' '}
                  <h3>Superior (up) direction</h3>
                  <span>
                    {humanizeAxis(viewModel.dataset.superiorDirection)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Poses</h3>
                  <AttributeOptionList>
                    {viewModel.poseNames.map(poseName => (
                      <li key={poseName}>{poseName}</li>
                    ))}
                  </AttributeOptionList>
                </td>
                <td>
                  <h3>Anterior (look) direction</h3>
                  <span>
                    {humanizeAxis(viewModel.dataset.anteriorDirection)}
                  </span>
                </td>
              </tr>
            </tbody>
          </DatasetProperties>
          {viewModel.dataset.bodyPartType === 'BODY' &&
            viewModel.subjectsByGender.map(subjectsByGender => (
              <div key={subjectsByGender.gender}>
                <h2>
                  {humanizeGender(subjectsByGender.gender)} (
                  {subjectsByGender.subjects.length})
                </h2>
                <Subjects
                  {...commonSubjectAttrs}
                  subjects={subjectsByGender.subjects}
                />
              </div>
            ))}
          {viewModel.dataset.bodyPartType === 'HAND' && (
            <div>
              <h2>Subjects ({viewModel.allSubjects.length})</h2>
              <Subjects
                {...commonSubjectAttrs}
                subjects={viewModel.allSubjects}
              />
            </div>
          )}
          <FixedWidthFooterNavContainer>
            <Button onClick={() => navigate('/')}>Back</Button>
            <Button onClick={() => navigate(`/import/${selectedDataset}`)}>
              Import
            </Button>

            <Button
              onClick={() =>
                navigate(`/datasets/${selectedDataset}/annotations`)
              }
              disabled={viewModel.annotationTypes.length === 0}
            >
              Choose Annotated Points
            </Button>
            <Button onClick={() => getAnnotationsDetailQuery()}>
              Export Point Annotations
            </Button>
            <Button
              onClick={() => navigate(`/datasets/${selectedDataset}/checkout`)}
            >
              Checkout Geometries
            </Button>
          </FixedWidthFooterNavContainer>
        </div>
      )}
    </FixedWidthPageContainer>
  )
}
