import { gql, useQuery } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { FlexRow } from '@unpublished/common-components'
import React from 'react'
import Boxplot, { computeBoxplotStats } from 'react-boxplot'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  IterationDisplay,
} from '../../common/common-components'
import {
  ROW_HEIGHT,
  TABLE_BACKGROUND_AND_BORDER,
} from '../../common/common-styles'
import {
  WhatsChangedHeaderQuery,
  WhatsChangedHeaderQueryVariables,
  WhatsChangedLatestIterationQuery,
  WhatsChangedLatestIterationQueryVariables,
  WhatsChangedSpecificIterationQuery,
  WhatsChangedSpecificIterationQueryVariables,
} from '../../common/generated'
import { useNumericParam } from '../../common/use-numeric-param'
import {
  useIterationQueries,
  usePickCompareIterations,
} from '../use-pick-compare-iterations'
import {
  createViewModel,
  WHATS_CHANGED_LATEST_ITERATION_QUERY,
  WHATS_CHANGED_SPECIFIC_ITERATION_QUERY,
} from './view-model'

const FixedWidthTable = styled.table<{ width?: string }>`
  width: ${({ width }) => width ?? '800px'};
  border-collapse: collapse;
  ${TABLE_BACKGROUND_AND_BORDER}
`
const TableRow = styled.tr`
  ${ROW_HEIGHT};
  border-bottom: 1px solid black;
  > td,
  th {
    vertical-align: middle;
    padding: 0px 10px;
    text-align: center;
  }
`

const BottomCaption = styled.div`
  display: table-caption;
  caption-side: bottom;
  ${TABLE_BACKGROUND_AND_BORDER}
  border-top: none;
  border-collapse: collapse;
  padding: 10px;
`

const FormattedDiff = styled.div<{
  withMarginBottom?: boolean
  isLight?: boolean
}>`
  font-size: 11px;
  ${({ withMarginBottom }) => (withMarginBottom ? 'margin-bottom: 1em;' : '')}
  ${({ isLight }) => (isLight ? 'color: #999;' : '')}
`

const FormattedDiffFaster = styled(FormattedDiff)`
  color: #75b236;
`

const FormattedDiffSlower = styled(FormattedDiff)`
  color: #f56262;
`

function ChangedCount({ count }: { count?: number }): JSX.Element | null {
  if (count === undefined) return null

  if (count === 0) {
    return <FormattedDiff isLight>Unchanged</FormattedDiff>
  } else {
    return <FormattedDiff>{count} changed</FormattedDiff>
  }
}

function Diff({
  diff,
  withMarginBottom,
}: {
  diff?: number
  withMarginBottom?: boolean
}): JSX.Element | null {
  if (diff === undefined) return null

  if (diff === 0) {
    return (
      <FormattedDiff isLight withMarginBottom={withMarginBottom}>
        No difference
      </FormattedDiff>
    )
  } else if (diff < 0) {
    return (
      <FormattedDiffFaster withMarginBottom={withMarginBottom}>
        {Math.abs(diff).toFixed(2)} faster
      </FormattedDiffFaster>
    )
  } else {
    return (
      <FormattedDiffSlower withMarginBottom={withMarginBottom}>
        {diff.toFixed(2)} slower
      </FormattedDiffSlower>
    )
  }
}

export const HEADER_QUERY = gql`
  query WhatsChangedHeader($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      name
    }
  }
`

export function WhatsChanged(): JSX.Element {
  const selectedStudy = useNumericParam('selectedStudy')
  const {
    leftIteration,
    rightIteration,
    usingLatestIteration,
    renderLeftIterationPicker,
    renderRightIterationPicker,
  } = usePickCompareIterations({ measurementStudyId: selectedStudy })

  const headerQuery = useQuery<
    WhatsChangedHeaderQuery,
    WhatsChangedHeaderQueryVariables
  >(HEADER_QUERY, { variables: { measurementStudyId: selectedStudy } })

  const iterationQueries = useIterationQueries<
    WhatsChangedLatestIterationQuery,
    WhatsChangedLatestIterationQueryVariables,
    WhatsChangedSpecificIterationQuery,
    WhatsChangedSpecificIterationQueryVariables
  >({
    latestIterationQuery: WHATS_CHANGED_LATEST_ITERATION_QUERY,
    specificIterationQuery: WHATS_CHANGED_SPECIFIC_ITERATION_QUERY,
    measurementStudyId: selectedStudy,
    rightIteration,
    leftIteration,
  })

  const measurementStudyName = headerQuery.data?.measurementStudyById.name

  const measurementViewModel = createViewModel(iterationQueries.data)

  return (
    <FixedWidthPageContainer>
      <FlexRow>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${selectedStudy}`}>{measurementStudyName}</Link>{' '}
          {'>'} What's changed
        </Breadcrumb>
      </FlexRow>
      {headerQuery.error && <p>Oh no! {headerQuery.error.message}</p>}
      {iterationQueries.error && <p>Oh no! {iterationQueries.error.message}</p>}
      <h1>{measurementStudyName}</h1>
      <h2>What's changed</h2>
      <FixedWidthTable width="1000px">
        <thead>
          <TableRow>
            <th />
            <th colSpan={usingLatestIteration ? 2 : 1}>
              {renderLeftIterationPicker()}
            </th>
            <th>{renderRightIterationPicker()}</th>
            <th colSpan={2}>Curve</th>
            <th colSpan={2}>Value</th>
            <th>Median performance</th>
          </TableRow>
        </thead>
        {iterationQueries.loading && (
          <BottomCaption>Loading&hellip;</BottomCaption>
        )}
        <tbody>
          {measurementViewModel.measurements.map(item => {
            const {
              measurementName,
              numChangedCurves,
              pathDifferencePercents,
              numMissingComparisonMetrics,
              numValueDifferences,
              valueDifferencePercents,
              performance: {
                leftMeasurementInvocationIteration,
                left,
                right,
                diff,
              },
            } = item
            return (
              <TableRow key={item.measurementName}>
                <td>
                  {humanizeMeasurementName({ name: measurementName, index: 0 })}
                </td>
                {usingLatestIteration && (
                  <td>
                    <IterationDisplay
                      iteration={leftMeasurementInvocationIteration}
                    />
                  </td>
                )}
                {left ? (
                  <td>
                    <FormattedDiff isLight>
                      {left.numInvocations} measured
                    </FormattedDiff>
                  </td>
                ) : (
                  <td />
                )}
                {right ? (
                  <td>
                    <FormattedDiff isLight>
                      {right.numInvocations} measured
                    </FormattedDiff>
                  </td>
                ) : (
                  <td />
                )}
                {left && right ? (
                  <td>
                    <ChangedCount count={numChangedCurves} />
                    {numMissingComparisonMetrics > 0 && (
                      <span>{numMissingComparisonMetrics} missing</span>
                    )}
                  </td>
                ) : (
                  <td />
                )}
                {pathDifferencePercents.length ? (
                  <td>
                    <Boxplot
                      orientation="horizontal"
                      height={20}
                      width={100}
                      min={0}
                      max={10}
                      stats={computeBoxplotStats(pathDifferencePercents)}
                    />
                  </td>
                ) : (
                  <td />
                )}
                {left && right ? (
                  <td>
                    <ChangedCount count={numValueDifferences} />
                  </td>
                ) : (
                  <td />
                )}
                {valueDifferencePercents.length ? (
                  <td>
                    <Boxplot
                      orientation="horizontal"
                      height={20}
                      width={100}
                      min={-30}
                      max={30}
                      stats={computeBoxplotStats(valueDifferencePercents)}
                    />
                  </td>
                ) : (
                  <td />
                )}
                {left ? (
                  <td>
                    <Diff diff={diff?.diffMedian} />
                    {left.median.toFixed(2)} sec
                  </td>
                ) : (
                  <td />
                )}
              </TableRow>
            )
          })}
        </tbody>
      </FixedWidthTable>
    </FixedWidthPageContainer>
  )
}
