import { humanizeMeasurementName } from '@curvewise/measured-body'
import React, { useMemo } from 'react'
import Select from 'react-select'

import { stopPropagationOfKeyboardShortcuts } from '../measurement-studies/review-measurements/use-keyboard-shortcuts'

export function MeasurementPicker({
  id,
  measurementChoices,
  selectedMeasurement,
  onChange,
  onClear,
  isClearable = false,
}: {
  id?: string
  measurementChoices: string[]
  selectedMeasurement?: string
  onChange: (measurementName: string) => void
  onClear?: () => void
  isClearable?: boolean
}): JSX.Element {
  const choices = useMemo(
    () =>
      measurementChoices.map(name => ({
        value: name,
        label: humanizeMeasurementName({ name, index: 0 }),
      })),
    [measurementChoices]
  )

  return (
    <Select
      id={id}
      onKeyDown={e => stopPropagationOfKeyboardShortcuts(e)}
      isClearable={isClearable}
      onChange={(option: any, { action }) => {
        if (option && action === 'select-option') {
          onChange(option.value)
        } else if (action === 'clear' && onClear !== undefined) {
          onClear()
        }
      }}
      value={
        choices.find(option => option.value === selectedMeasurement) ?? null
      }
      options={choices}
    />
  )
}
