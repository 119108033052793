import { gql } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { uniq } from 'lodash'
import semverCompareBuild from 'semver/functions/compare-build'

import {
  MeasureAgainQuery,
  SupportedMeasurementsByMeasurerSha1Query,
  SupportedMeasurementsByMeasurerTagNameQuery,
} from '../../common/generated'
import { PosesAndTopologiesPair } from '../../common/select-pose-topology'
import { Option } from '../../common/toggleable-button-list'

export const MEASURE_AGAIN_QUERY = gql`
  query MeasureAgain($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      name
      measurementStudyMeasurementsByMeasurementStudyId {
        nodes {
          measurementName
        }
      }
      commitByHeadCommitId {
        commitsGeometriesLookupsByCommitId {
          edges {
            node {
              geometryId
              geometryByGeometryId {
                geometrySeriesByGeometrySeriesId {
                  poseTypeByPoseTypeId {
                    name
                  }
                  topology
                }
              }
            }
          }
        }
      }
    }
    allMeasurers {
      nodes {
        id
        name
      }
    }
    github {
      branches {
        branchName
        sha1
        measurerName
      }
    }
    allDeployableMeasurerVersions {
      versions {
        tagName
        measurerName
      }
    }
    measurementStudyById(id: $measurementStudyId) {
      commitByHeadCommitId {
        commitsGeometriesLookupsByCommitId {
          nodes {
            geometryByGeometryId {
              geometrySeriesByGeometrySeriesId {
                poseTypeByPoseTypeId {
                  name
                }
                topology
              }
            }
          }
        }
      }
    }
  }
`

export const SUPPORTED_MEASUREMENTS_BY_MEASURER_TAG_NAME_QUERY = gql`
  query SupportedMeasurementsByMeasurerTagName(
    $tagName: String!
    $measurerName: DataLayerMeasurerName!
  ) {
    supportedMeasurementsByMeasurerTagName(
      tagName: $tagName
      measurerName: $measurerName
    ) {
      supportedMeasurements {
        measurements {
          measurementName
        }
      }
    }
  }
`

export const SUPPORTED_MEASUREMENTS_BY_MEASURER_SHA1_QUERY = gql`
  query SupportedMeasurementsByMeasurerSha1(
    $sha1: String!
    $measurerName: DataLayerMeasurerName!
  ) {
    supportedMeasurementsByMeasurerSha1(
      measurerName: $measurerName
      sha1: $sha1
    ) {
      supportedMeasurements {
        measurements {
          measurementName
        }
      }
    }
  }
`

export type MeasurerId = number
export type MeasurerName = string
export type MeasurementName = string
export type MeasurementVersionId = number
export type GeometryId = number

export const TagMeasurerVersionType = 'tagMeasurerVersion'
export const BranchMeasurerVersionType = 'branchMeasurerVersion'

export interface TagMeasurerVersion {
  tagName: string
  type: typeof TagMeasurerVersionType
}

export interface BranchMeasurerVersion {
  branchName: string
  sha1: string
  type: typeof BranchMeasurerVersionType
}

export type MeasurerVersion = TagMeasurerVersion | BranchMeasurerVersion

export interface MeasurerVersions {
  tagVersions: TagMeasurerVersion[]
  branchVersions: BranchMeasurerVersion[]
}

export interface Measurer {
  name: MeasurerName
  id: MeasurerId
}

export interface MeasureAgainViewModel {
  measurementStudyName: string
  measurers: Measurer[]
  measurerVersionsByMeasurerId: Record<MeasurerId, MeasurerVersions>
  measurerIdToName: Record<MeasurerId, MeasurerName>
  allUniqueMeasurementNames: MeasurementName[]
  allUniqueMeasurementNamesOptions: Option<string>[]
  allUniqueGeometryIds: GeometryId[]
  buildForMeasurerVersionIsReady: boolean
  posesAndTopologiesPair: PosesAndTopologiesPair
}

export function createViewModel({
  data,
  supportedMeasurementsByMeasurerTagNameData,
  supportedMeasurementsByMeasurerSha1Data,
  selectedPose,
  selectedTopology,
}: {
  data: MeasureAgainQuery
  supportedMeasurementsByMeasurerTagNameData?: SupportedMeasurementsByMeasurerTagNameQuery
  supportedMeasurementsByMeasurerSha1Data?: SupportedMeasurementsByMeasurerSha1Query
  selectedPose?: string
  selectedTopology?: string
}): MeasureAgainViewModel {
  const allUniqueMeasurementNames = Array.from(
    new Set(
      // When remeasuring, the preferred behavior is that the user may choos from the measurements supported by the selected measurer version. However, the CI process takes a few minutes to complete the build and place the JSON manifest with the supported measurements on S3, and it would be inconvenient for the user to block their ability to remeasure on the running build. This is particularly true because the most likely case is that the supported measurements are the same as they were for the previous commit. In other words, it's only in rare cases  that we've added a new measurement which needs to be surfaced to the user. So, when the correct list of measurements isn't available yet, we default to doing the helpful thing, even though it's not 100% accurate. (If the user selects measurements which have been removed in the measurer version, they will result in errors.)
      supportedMeasurementsByMeasurerTagNameData?.supportedMeasurementsByMeasurerTagName?.supportedMeasurements?.measurements?.map(
        measurement => measurement.measurementName
      ) ||
        supportedMeasurementsByMeasurerSha1Data?.supportedMeasurementsByMeasurerSha1?.supportedMeasurements?.measurements?.map(
          measurement => measurement.measurementName
        ) ||
        // Fall back on measurementStudyMeasurementsByMeasurementStudyId.
        (data.measurementStudyById.measurementStudyMeasurementsByMeasurementStudyId.nodes
          .filter(node => node.measurementName !== null)
          .map(node => node.measurementName) as string[])
    )
  ).sort()

  const allUniqueMeasurementNamesOptions = allUniqueMeasurementNames.map(
    measurementName => ({
      label: humanizeMeasurementName({ name: measurementName, index: 0 }),
      value: measurementName,
    })
  )

  return {
    buildForMeasurerVersionIsReady: !!(
      supportedMeasurementsByMeasurerTagNameData
        ?.supportedMeasurementsByMeasurerTagName?.supportedMeasurements
        ?.measurements ||
      supportedMeasurementsByMeasurerSha1Data
        ?.supportedMeasurementsByMeasurerSha1?.supportedMeasurements
        ?.measurements
    ),
    measurementStudyName: data.measurementStudyById.name,
    measurers: data.allMeasurers.nodes.flatMap(node => ({
      name: node.name,
      id: node.id,
    })),
    measurerIdToName: (() => {
      const measurerIdToName = {} as Record<MeasurerId, MeasurerName>
      data.allMeasurers.nodes.forEach(measurer => {
        measurerIdToName[measurer.id] = measurer.name
      })
      return measurerIdToName
    })(),
    measurerVersionsByMeasurerId: (() => {
      const measurerVersionsByMeasurerId = {} as Record<
        MeasurerId,
        MeasurerVersions
      >

      data.allMeasurers.nodes.forEach(measurer => {
        measurerVersionsByMeasurerId[measurer.id] = {
          tagVersions: data.allDeployableMeasurerVersions.versions
            .filter(({ measurerName }) => measurerName === measurer.name)
            .map(
              ({ measurerName, tagName }) =>
                ({
                  tagName,
                  type: TagMeasurerVersionType,
                } as TagMeasurerVersion)
            )
            .sort((a: TagMeasurerVersion, b: TagMeasurerVersion) =>
              semverCompareBuild(b.tagName, a.tagName)
            ),
          branchVersions: data.github.branches
            .filter(({ measurerName }) => measurerName === measurer.name)
            .map(
              ({ measurerName, sha1, branchName }) =>
                ({
                  sha1,
                  branchName,
                  type: BranchMeasurerVersionType,
                } as BranchMeasurerVersion)
            )
            .sort((a: BranchMeasurerVersion, b: BranchMeasurerVersion) =>
              a.branchName < b.branchName ? -1 : 1
            ),
        }
      })

      return measurerVersionsByMeasurerId
    })(),
    allUniqueMeasurementNames,
    allUniqueMeasurementNamesOptions,
    allUniqueGeometryIds:
      data.measurementStudyById.commitByHeadCommitId.commitsGeometriesLookupsByCommitId.edges
        .filter(
          commitsGeometriesLookupsByCommitId =>
            commitsGeometriesLookupsByCommitId.node.geometryByGeometryId
              .geometrySeriesByGeometrySeriesId.poseTypeByPoseTypeId.name ===
              selectedPose &&
            commitsGeometriesLookupsByCommitId.node.geometryByGeometryId
              .geometrySeriesByGeometrySeriesId.topology === selectedTopology
        )
        .map(
          commitsGeometriesLookupsByCommitId =>
            commitsGeometriesLookupsByCommitId.node.geometryId
        ),
    posesAndTopologiesPair: [
      uniq(
        data?.measurementStudyById.commitByHeadCommitId.commitsGeometriesLookupsByCommitId.nodes.map(
          node =>
            node.geometryByGeometryId.geometrySeriesByGeometrySeriesId
              .poseTypeByPoseTypeId.name
        )
      ),
      uniq(
        data?.measurementStudyById.commitByHeadCommitId.commitsGeometriesLookupsByCommitId.nodes
          .filter(
            node =>
              node.geometryByGeometryId.geometrySeriesByGeometrySeriesId
                .poseTypeByPoseTypeId.name === selectedPose
          )
          .filter(
            node =>
              node.geometryByGeometryId.geometrySeriesByGeometrySeriesId
                .topology
          )
          .map(
            node =>
              node.geometryByGeometryId.geometrySeriesByGeometrySeriesId
                .topology as string
          )
      ),
    ],
  }
}
