import {
  nudgeAndReproject,
  Raycaster,
  unprojectVector,
} from '@unpublished/four'
import { Vector3 } from 'polliwog-types'
import { useCallback, useEffect, useMemo } from 'react'
import * as THREE from 'three'

import { useGetCanvasContextState } from '../canvas/canvas-context'

const Z_BASIS = new THREE.Vector3(0, 0, 1)

const raycaster = new Raycaster()

export interface Nudging {
  originalPoint: Vector3
}

export function useNudge({
  currentPoint,
  state,
  updatePoint,
  // Default to nudging 0.33% of the canvas viewport height. Passing 1 here
  // gives 1% of the viewport height.
  nudgeDistanceVh = 0.3333,
}: {
  currentPoint?: Vector3
  state?: Nudging
  updatePoint: (point: Vector3) => void
  nudgeDistanceVh?: number
}): void {
  const getCanvasContextState = useGetCanvasContextState()

  const normalizedNudgeDistanceVector = useMemo(
    () => new THREE.Vector3(0, 0.01 * nudgeDistanceVh, 0),
    [nudgeDistanceVh]
  )

  const keyDownHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      const { camera, mainObject } = getCanvasContextState()

      if (!camera) {
        throw Error('Expected a camera')
      }

      if (!mainObject) {
        return
      }

      const canvasNudgeDirection = {
        ArrowDown: [0, -1, 0],
        ArrowUp: [0, 1, 0],
        ArrowLeft: [-1, 0, 0],
        ArrowRight: [1, 0, 0],
      }[key]

      if (canvasNudgeDirection === undefined) {
        return
      }

      if (!currentPoint) {
        throw Error('Expected currentPoint to be set')
      }

      const reprojectedPoint = nudgeAndReproject({
        raycaster,
        originalPoint: new THREE.Vector3(...currentPoint),
        nudgeDirection: unprojectVector(
          new THREE.Vector3(...canvasNudgeDirection),
          camera
        ).normalize(),
        nudgeDistance: unprojectVector(
          normalizedNudgeDistanceVector,
          camera
        ).length(),
        lookVector: unprojectVector(Z_BASIS, camera).normalize(),
        mesh: mainObject,
      })

      // Cap the maximum distance the point can move to prevent it from
      // jumping from one part of the body to another.  (ex: arm to waist)
      if (reprojectedPoint !== undefined) {
        updatePoint(reprojectedPoint.toArray())
      }
    },
    [
      getCanvasContextState,
      updatePoint,
      currentPoint,
      normalizedNudgeDistanceVector,
    ]
  )
  useEffect(() => {
    if (state !== undefined) {
      window.addEventListener('keydown', keyDownHandler)
      return () => {
        window.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [keyDownHandler, state])
}
