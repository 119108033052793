import { useParams } from 'react-router-dom'

export function useSafeParam(name: string): string {
  const params = useParams()
  const value = params[name]
  if (value !== undefined) {
    return value
  } else {
    throw Error(
      `Expected a ${name} parameter, got ${Object.keys(params).join(', ')}`
    )
  }
}
