import { gql } from '@apollo/client'

import { CreateNewMeasurementStudyChooseSubjectsQuery } from '../../../common/generated'
import { Option } from '../../../common/toggleable-button-list'
import { SubjectNameGeometryIdPair } from '../../add-geometries/view-model'

export const CREATE_NEW_MEASUREMENT_STUDY_QUERY = gql`
  query CreateNewMeasurementStudyChooseSubjects {
    allDatasets {
      nodes {
        id
        name
        bodyPart
        subjectsByDatasetId {
          nodes {
            id
            name
            gender
            geometrySeriesBySubjectId {
              nodes {
                topology
                poseTypeByPoseTypeId {
                  id
                  name
                }
                geometriesByGeometrySeriesId {
                  nodes {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export type DatasetId = number

interface ViewModel {
  allDatasets: CreateNewMeasurementStudyChooseSubjectsQuery['allDatasets']['nodes']
  allAvailableDatasetOptions: Option<number>[]
  currentFemaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  currentMaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  numberOfAllSubjectsForDataset: Record<DatasetId, number>
}

type SubjectType =
  CreateNewMeasurementStudyChooseSubjectsQuery['allDatasets']['nodes'][number]['subjectsByDatasetId']['nodes'][number]

function subjectToSubjectNameGeometryIdPairs(
  selectedPose: string,
  selectedTopology: string,
  subject: SubjectType
): SubjectNameGeometryIdPair[] {
  return subject.geometrySeriesBySubjectId.nodes
    .filter(
      node =>
        node.poseTypeByPoseTypeId.name === selectedPose &&
        node.topology === selectedTopology
    )
    .flatMap(node =>
      node.geometriesByGeometrySeriesId.nodes.map(
        geometryNode =>
          [subject.name, geometryNode.id] as SubjectNameGeometryIdPair
      )
    )
}

export function createViewModel({
  data,
  datasetIdBeingEdited,
  chosenDatasetIds,
  studyBodyPart,
  selectedPose,
  selectedTopology,
}: {
  data?: CreateNewMeasurementStudyChooseSubjectsQuery
  datasetIdBeingEdited?: DatasetId
  chosenDatasetIds: DatasetId[]
  studyBodyPart: string
  selectedPose: string
  selectedTopology: string
}): ViewModel {
  function filterSubjectsBySelectedPoseAndTopology(
    subject: SubjectType
  ): boolean {
    return subject.geometrySeriesBySubjectId.nodes.some(
      geometrySeries =>
        geometrySeries.poseTypeByPoseTypeId.name === selectedPose &&
        geometrySeries.topology === selectedTopology
    )
  }

  const allDatasets = data?.allDatasets
  const datasetBeingEdited =
    allDatasets && datasetIdBeingEdited !== undefined
      ? allDatasets.nodes.filter(
          dataset => dataset.id === datasetIdBeingEdited
        )[0]
      : undefined

  return {
    allDatasets: allDatasets?.nodes || [],
    allAvailableDatasetOptions:
      allDatasets?.nodes
        .filter(dataset => chosenDatasetIds.indexOf(dataset.id) === -1)
        .filter(dataset => dataset.bodyPart === studyBodyPart)
        .filter(dataset =>
          dataset.subjectsByDatasetId.nodes.some(
            filterSubjectsBySelectedPoseAndTopology
          )
        )
        .map(dataset => ({
          label: dataset.name,
          value: dataset.id,
        })) || [],
    numberOfAllSubjectsForDataset:
      allDatasets?.nodes.reduce((a, b) => {
        a[b.id] = b.subjectsByDatasetId.nodes.filter(
          filterSubjectsBySelectedPoseAndTopology
        ).length
        return a
      }, {} as Record<DatasetId, number>) || {},
    currentMaleSubjectNameGeometryIdPairs: datasetBeingEdited
      ? datasetBeingEdited.subjectsByDatasetId.nodes
          .filter(subject => subject.gender === 'M')
          .flatMap(subject =>
            subjectToSubjectNameGeometryIdPairs(
              selectedPose,
              selectedTopology,
              subject
            )
          )
      : [],
    currentFemaleSubjectNameGeometryIdPairs: datasetBeingEdited
      ? datasetBeingEdited.subjectsByDatasetId.nodes
          .filter(subject => subject.gender === 'F')
          .filter(filterSubjectsBySelectedPoseAndTopology)
          .flatMap(subject =>
            subjectToSubjectNameGeometryIdPairs(
              selectedPose,
              selectedTopology,
              subject
            )
          )
      : [],
  }
}
