import { css } from 'styled-components'

export const STATISTICAL_TEXT = css`
  color: rgba(0, 0, 0, 0.56);
`
export const TABLE_BACKGROUND = css`
  background: rgba(196, 196, 196, 0.19);
`
export const TABLE_BACKGROUND_SELECTED = css`
  background: rgba(196, 196, 196, 0.87);
`
