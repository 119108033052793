import React from 'react'

export interface Progress {
  filledUnits: number
  totalUnits: number
}

export function progressForItems<T>(
  items: T[],
  itemIsFilled: (t: T) => boolean
): Progress {
  return {
    filledUnits: items.filter(itemIsFilled).length,
    totalUnits: items.length,
  }
}

export function ProgressBar({
  progress: { totalUnits, filledUnits },
}: {
  progress: Progress
}): JSX.Element {
  const percentFilled = totalUnits > 0 ? (filledUnits * 196) / totalUnits : 0
  const maskID = 'mask0_1773_937' + percentFilled.toString()
  const shouldDrawSegmentLines = totalUnits < 20 && totalUnits > 1
  return (
    <svg
      width="196"
      height="34"
      viewBox="0 0 196 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.52734 15.6957V12.3913C4.52734 9.90603 6.54206 7.89131 9.02734 7.89131H190.986C193.472 7.89131 195.486 9.90603 195.486 12.3913V15.6957C195.486 18.1809 193.472 20.1957 190.986 20.1957H9.02734C6.54206 20.1957 4.52734 18.1809 4.52734 15.6957Z"
        fill="#F9F9F9"
        stroke="#999999"
      />
      {shouldDrawSegmentLines &&
        totalUnits > filledUnits &&
        Array(Math.min(Math.max(totalUnits - filledUnits, 0), totalUnits - 1))
          .fill(undefined)
          .map((_, i) => {
            const x = 196 - ((i + 1) * 196) / totalUnits
            return (
              <line
                x1={x}
                y1="7.39131"
                x2={x}
                y2="20.6957"
                stroke="#999999"
                key={i}
              />
            )
          })}
      <mask
        id={maskID}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={percentFilled}
        height="34"
      >
        <rect width={percentFilled} height="34" fill="#C4C4C4" />
      </mask>
      <g mask={'url(#' + maskID + ')'}>
        <path
          d="M4.52734 15.6957V12.3913C4.52734 9.90603 6.54206 7.89131 9.02734 7.89131H190.986C193.472 7.89131 195.486 9.90603 195.486 12.3913V15.6957C195.486 18.1809 193.472 20.1957 190.986 20.1957H9.02734C6.54206 20.1957 4.52734 18.1809 4.52734 15.6957Z"
          fill="#BBBBBB"
          stroke="#999999"
        />
        {shouldDrawSegmentLines &&
          filledUnits > 0 &&
          Array(Math.min(Math.max(filledUnits, 0), totalUnits - 1))
            .fill(undefined)
            .map((_, i) => {
              const x = ((i + 1) * 196) / totalUnits
              return (
                <line
                  key={i}
                  x1={x}
                  y1="7.39131"
                  x2={x}
                  y2="20.6957"
                  stroke="#999999"
                />
              )
            })}
      </g>
    </svg>
  )
}
