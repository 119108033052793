import React from 'react'
import styled from 'styled-components'

import { ResultValueType } from '../../common/generated'
import { DivWithSizedText } from '../common-components'
import { PercentChangedWithIcon } from './comparison-display'

const DivWithLightText = styled.div`
  opacity: 0.35;
`

export function ViewerFooter({
  displayValue,
  units,
  compareTo,
  displayCompareValue,
}: {
  displayValue: number
  units: ResultValueType
  compareTo?: number
  displayCompareValue?: number
}): JSX.Element {
  const roundedDisplayValue = +displayValue.toFixed(2)
  const roundedDisplayCompareValue =
    displayCompareValue === undefined
      ? undefined
      : +displayCompareValue.toFixed(2)

  const roundedCompareToValue =
    compareTo === undefined ? undefined : +compareTo.toFixed(2)

  return (
    <DivWithSizedText>
      {displayCompareValue && (
        <DivWithLightText>{`${roundedDisplayCompareValue} ${units.toLowerCase()}`}</DivWithLightText>
      )}
      <div>
        {roundedDisplayValue} {units.toLowerCase()}
      </div>
      {roundedCompareToValue !== undefined && (
        <PercentChangedWithIcon
          initialValue={roundedDisplayValue}
          compareValue={roundedCompareToValue}
        />
      )}
    </DivWithSizedText>
  )
}
