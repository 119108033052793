import React, { useEffect, useState } from 'react'
import { CSS2DObject as ThreeCSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'

export function CSS2DObject({
  label,
}: {
  label: string | number | null
}): JSX.Element {
  const [div] = useState(() => {
    const div = document.createElement('div')
    div.className = 'label'
    div.style.marginTop = '-1em'
    div.style.marginLeft = '1em'
    return div
  })

  const [css2dObject] = useState(new ThreeCSS2DObject(div))

  useEffect(() => {
    div.textContent =
      label !== undefined && label !== null ? label.toString() : ''
  }, [div, label])

  return <primitive object={css2dObject} position={[0, 0, 0]} />
}
