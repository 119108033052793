import { gql } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'

import { CreateNewMeasurementStudyChooseMeasurementsQuery } from '../../../common/generated'
import { Option } from '../../../common/toggleable-button-list'

export const CREATE_NEW_MEASUREMENT_STUDY_QUERY = gql`
  query CreateNewMeasurementStudyChooseMeasurements(
    $tagName: String!
    $measurerName: DataLayerMeasurerName!
  ) {
    supportedMeasurementsByMeasurerTagName(
      tagName: $tagName
      measurerName: $measurerName
    ) {
      supportedMeasurements {
        measurements {
          measurementName
        }
      }
    }
  }
`

interface ViewModel {
  measurementOptions: Option<string>[]
}

export function createViewModel({
  data,
}: {
  data?: CreateNewMeasurementStudyChooseMeasurementsQuery
}): ViewModel {
  return {
    measurementOptions:
      data?.supportedMeasurementsByMeasurerTagName?.supportedMeasurements?.measurements
        ?.map(measurement => measurement.measurementName)
        .sort()
        .map(measurementName => ({
          label: humanizeMeasurementName({
            name: measurementName,
            index: 0,
          }),
          value: measurementName,
        })) || [],
  }
}
