import { isEqual } from 'lodash'
import { Vector3 } from 'polliwog-types'

export type AnnotationDeltaId = number | string

export interface AnnotationDelta<T extends AnnotationDeltaId> {
  id: T
  point: Vector3 | null
}

export type PickedPointAction<T extends AnnotationDeltaId> =
  | { type: 'discard-changes' }
  | { type: 'discard-saved-deltas'; deltas: AnnotationDelta<T>[] }
  | { type: 'update-delta'; point: Vector3 | null; id: T }
  | { type: 'clear-point'; id: T }

export function pickedPointReducer<T extends AnnotationDeltaId>(
  state: AnnotationDelta<T>[],
  action: PickedPointAction<T>
): AnnotationDelta<T>[] {
  switch (action.type) {
    case 'discard-changes':
      return []
    case 'update-delta':
      return [
        ...state.filter(delta => delta.id !== action.id),
        {
          id: action.id,
          point: action.point,
        },
      ]
    case 'clear-point':
      return [
        ...state.filter(delta => delta.id !== action.id),
        {
          id: action.id,
          point: null,
        },
      ]
    case 'discard-saved-deltas':
      return [
        ...state.filter(
          delta =>
            !isEqual(
              action.deltas.find(ga => ga.id === delta.id)?.point,
              delta.point
            )
        ),
      ]
    default:
      throw Error('Unknown action kind')
  }
}
