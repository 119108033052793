import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useQueryParams(): URLSearchParams {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

/*
 * Handle a boolean query param like `?sorted`. If e.g. `?sorted` or `?sorted=`
 * is present, return `true`, otherwise return `false`.
 */
export function useBooleanQueryParam(
  name: string,
  { safe = true }: { safe?: boolean } = {}
): boolean {
  const queryParams = useQueryParams()

  const asString = queryParams.get(name)
  if (asString === '') {
    return true
  } else if (asString === null || !safe) {
    return false
  } else {
    throw Error(`Unexpected value ${asString} for boolean parameter ${name}`)
  }
}

export function useNumericQueryParam(name: string): number | undefined {
  const queryParams = useQueryParams()

  const asString = queryParams.get(name)
  if (asString === null) {
    return undefined
  }

  const asNumber = +asString
  if (Number.isNaN(asNumber)) {
    return undefined
  }

  return asNumber
}

export enum SortBy {
  PERCENT_CHANGED = 'percentChanged',
  VALUE_DIFFERENCE = 'valueDifference',
}

export function useSortByQueryParam(name: string): SortBy | undefined {
  const sortedQueryParam = useQueryParams().get(name)
  return sortedQueryParam ? (sortedQueryParam as SortBy) : undefined // FIXME: is this the best way to convert string to an enum?
}

export function useStringArrayQueryParam(name: string): string[] {
  const string = useQueryParams().get(name)
  return string ? string.split(`|`) : []
}
