interface ColorOption {
  label: string
  color: number
}
// TODO: can we improve this interface?
// Explanation: we have two different color systems - one for tape and one for
// picked points. We should consider whether we can unify them, or at least
// make them more similar.
export const colorOptions = [
  { label: 'Silver lake blue', color: 0x5c80bc },
  { label: 'Gunmetal', color: 0x30323d },
  { label: 'Pale turquoise', color: 0xbcf8ec },
  { label: 'Waterspout', color: 0x71f4da },
  { label: 'Gargoyle gas', color: 0xf9d14a },
  { label: 'Pale spring bud', color: 0xe9eac5 },
  { label: 'Very pale yellow', color: 0xfafcc4 },
  { label: 'Anti-flash white', color: 0xf1e4f3 },
  { label: 'Azureish white', color: 0xe1e5ee },
  { label: 'Isabelline', color: 0xededed },
  { label: 'Ruby red', color: 0x9b1d20 },
] as ColorOption[]
export function getColor(tapeColor: string): number {
  const { color } = colorOptions.find(
    ({ label }) => label === tapeColor
  ) as ColorOption
  return color
}

interface TextureOption {
  label: string
  url: string
}
export const textureOptions = [
  {
    label: 'Gray fabric',
    url: '/textures/wildtextures-old_book_cloth_cover.jpg',
  },
  {
    label: 'Teal fabric',
    url: '/textures/wildtextures-seamless-blue-fabric-texture.jpg',
  },
  {
    label: 'Sandstone',
    url: '/textures/wildtextures_front-sand-stone-elevation.jpg',
  },
  {
    label: 'Granite',
    url: '/textures/wildtextures_regular-seamless-granite-texture.jpg',
  },
] as TextureOption[]
export function getTextureUrl(texture: string): string {
  switch (texture) {
    case 'Gray fabric':
      return '/textures/wildtextures-old_book_cloth_cover.jpg'
    case 'Teal fabric':
      return '/textures/wildtextures-seamless-blue-fabric-texture.jpg'
    case 'Sandstone':
      return '/textures/wildtextures_front-sand-stone-elevation.jpg'
    case 'Granite':
      return '/textures/wildtextures_regular-seamless-granite-texture.jpg'
    case undefined:
      throw Error('A texture is required')
    default:
      throw Error(`Unknown texture: ${texture}`)
  }
}
export const BUMP_URL = '/textures/955-bump.jpg'

export interface ScenePreset {
  label: string
  lightingIntensity: number
  texture?: string
  textureRepeats: number
  bump: boolean
  bumpRepeats: number
  bumpScale: number
  tapeColor: string
}

export const presets = [
  {
    label: 'Flat gray',
    texture: undefined,
    textureRepeats: 1,
    bump: false,
    bumpRepeats: 10,
    bumpScale: 1.0,
    lightingIntensity: 0.9,
    tapeColor: 'Silver lake blue',
  },
  {
    label: 'Sandpaper gray',
    texture: undefined,
    textureRepeats: 1,
    bump: true,
    bumpRepeats: 16,
    bumpScale: 1.0,
    lightingIntensity: 0.9,
    tapeColor: 'Silver lake blue',
  },
  {
    label: 'Gray fabric',
    texture: 'Gray fabric',
    textureRepeats: 6,
    bump: true,
    bumpRepeats: 20,
    bumpScale: 0.75,
    lightingIntensity: 0.5,
    tapeColor: 'Waterspout',
  },
  {
    label: 'Teal fabric',
    texture: 'Teal fabric',
    textureRepeats: 12,
    bump: true,
    bumpRepeats: 30,
    bumpScale: 1.0,
    lightingIntensity: 1.05,
    tapeColor: 'Very pale yellow',
  },
  {
    label: 'Sandstone',
    texture: 'Sandstone',
    textureRepeats: 5,
    bump: false,
    bumpRepeats: 10,
    bumpScale: 0.5,
    lightingIntensity: 1.0,
    tapeColor: 'Waterspout',
  },
  {
    label: 'Granite',
    texture: 'Granite',
    textureRepeats: 12,
    bump: true,
    bumpRepeats: 20,
    bumpScale: 1.0,
    lightingIntensity: 1.0,
    tapeColor: 'Ruby red',
  },
] as ScenePreset[]
export function getPreset(preset: string): ScenePreset {
  const result = presets.find(({ label }) => label === preset) as ScenePreset
  return result
}

export const defaultSettings = {
  texture: undefined,
  lightingIntensity: 1.0,
  showPointLabels: false,
  pickedPointSize: 3.5,
  textureRepeats: 1,
  bump: false,
  bumpRepeats: undefined,
  bumpScale: undefined,
  tapeColor: colorOptions[0].label,
  flatShading: false,
  doubleSided: false,
}
