import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
} from '../common/common-components'
import { ListMeasurementStudiesQuery } from '../common/generated'
import {
  LIST_MEASUREMENT_STUDIES_QUERY,
  ListMeasurementStudiesTable,
} from './list-studies-table'

export function ListArchivedMeasurementStudies(): JSX.Element {
  const { loading, error, data } = useQuery<ListMeasurementStudiesQuery>(
    LIST_MEASUREMENT_STUDIES_QUERY
  )
  const navigate = useNavigate()

  // TODO: Move this condition into the query.
  const archivedStudies = data?.allMeasurementStudies.nodes.filter(
    ({ isArchived }) => isArchived
  )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'} Archive
      </Breadcrumb>
      <h1>Archived measurement studies</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {archivedStudies && archivedStudies.length === 0 && (
        <div>No archived studies.</div>
      )}
      {archivedStudies && archivedStudies.length > 0 && (
        <ListMeasurementStudiesTable data={archivedStudies} />
      )}
      <FixedWidthFooterNavContainer>
        <Button onClick={() => navigate(-1)}>Back</Button>
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
