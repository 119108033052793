import {
  FlexColumn,
  FlexRow,
  FlexRowWithPadding,
  StatisticalDiv,
  TABLE_BACKGROUND,
  TABLE_BACKGROUND_SELECTED,
} from '@unpublished/common-components'
import { isShallowEqualOmitting } from '@unpublished/victorinox'
import { isEqual } from 'lodash'
import React, { Dispatch, useCallback, useReducer, useRef } from 'react'
import styled from 'styled-components'

import { IterationDisplay } from '../../common/common-components'
import { ReactComponent as CircleChevronsBottomIcon } from '../../common/images/circle-chevrons-bottom.svg'
import { ReactComponent as CommentTextIcon } from '../../common/images/comment-text.svg'
import { ReactComponent as CopyIcon } from '../../common/images/copy.svg'
import { ReactComponent as NewIcon } from '../../common/images/new-icon.svg'
import { isCtrlKey } from '../../common/use-arrow-keys'
import { NavigateToParameters } from '../../common/use-navigate-to'
import { SortBy } from '../../common/use-query-params'
import { ScrollTarget } from '../../common/use-scroll-into-view'
import { hashKeyForLabel } from '../labels'
import { MeasurerVersion, measurerVersionMatches } from '../measurer-version'
import { MeasurerVersionDisplay } from '../measurer-version-display'
import { Accordioned, Action as AccordionAction } from './accordion'
import { MetricComparisonWithIcon } from './comparison-display'
import { DisplayLabel, LabelContainerForSubjects } from './display-label'
import { Action as LocalStorageAction } from './local-storage-reducer'
import {
  AccordionedSubjectViewModel,
  CompareToViewModel,
  IterationViewModel,
} from './view-model'

interface State {
  showMeasurerVersionForSubjectId?: number
}

const INITIAL_STATE: State = {}

type Action =
  | { kind: 'show-measurer-version'; subjectId: number }
  | { kind: 'hide-measurer-version' }

function transientStateReducer(state: State, action: Action): State {
  switch (action.kind) {
    case 'show-measurer-version':
      return { ...state, showMeasurerVersionForSubjectId: action.subjectId }
    case 'hide-measurer-version':
      return { ...state, showMeasurerVersionForSubjectId: undefined }
    default:
      return state
  }
}

const ButtonDownContainer = styled.div`
  cursor: pointer;
  padding-left: 2em;
`

const BottomRightAlignedDiv = styled(StatisticalDiv)`
  position: absolute;
  bottom: 6px;
  right: 6px;
  font-size: 11px;
  cursor: pointer;
`

const SubjectHeader = styled.div`
  font-size: 14px;
  padding: 6.5px;
`

const SubjectRow = styled.div`
  padding-bottom: 0.5em;
`

const MeasurementInvocationRow = styled.div<{
  selected: boolean
  isLatest: boolean
}>`
  display: flex;
  flex-direction: column;
  border-width: ${({ isLatest }) => (isLatest ? '1px' : '0 1px 1px 1px')};
  border-color: black;
  border-style: solid;
  margin-left: ${({ isLatest }) => (isLatest ? '' : '1em')};
  ${({ selected }) =>
    selected ? TABLE_BACKGROUND_SELECTED : TABLE_BACKGROUND}};
  padding: 3.5px;
      `

const MeasurementInvocationDetail = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`

const SUBJECT_DETAIL_COLOR = '#7f7f7f'
const SubjectDetails = styled.div`
  font-size: 11px;
  color: ${SUBJECT_DETAIL_COLOR};
  padding: 0 0 6px 20px;
  display: flex;
  width: max-content;
  flex-direction: column;
  > * {
    margin: inherit 3px;
  }
`

const IterationDetail = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 5px;
  }
`

const NewIconContainer = styled(FlexRow)`
  color: #28a745;
  font-size: 11px;
  align-items: center;
  > * {
    padding-right: 5px;
  }
`

function InitialStatus(): JSX.Element {
  return (
    <NewIconContainer>
      <NewIcon /> Initial
    </NewIconContainer>
  )
}

type HandleSubjectClick = (params: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
  geometryIsSelected: boolean
  clickedMeasurementInvocationId: number
}) => void

function _DisplayInvocation({
  subject,
  iteration,
  measurerVersion,
  isLatest,
  isSelected,
  isSelectedForComparison,
  geometryIsSelected,
  dispatchLocalStorageAction,
  dispatchAccordionAction,
  dispatchTransientStateAction,
  handleSubjectClick,
  sortBy,
}: {
  subject: AccordionedSubjectViewModel
  iteration: Accordioned<IterationViewModel>
  measurerVersion?: MeasurerVersion
  isLatest: boolean
  isSelected: boolean
  isSelectedForComparison: boolean
  geometryIsSelected: boolean
  dispatchLocalStorageAction: Dispatch<LocalStorageAction>
  dispatchAccordionAction: Dispatch<AccordionAction>
  dispatchTransientStateAction: Dispatch<Action>
  handleSubjectClick: HandleSubjectClick
  sortBy?: SortBy
}): JSX.Element {
  const { measurementInvocationId } = iteration.item

  const canCopy =
    isLatest &&
    subject.previousLabeledIteration &&
    iteration.item.labels.length === 0

  return (
    <MeasurementInvocationRow
      key={measurementInvocationId}
      isLatest={isLatest}
      selected={isSelected || isSelectedForComparison}
      onClick={event =>
        handleSubjectClick({
          event,
          geometryIsSelected,
          clickedMeasurementInvocationId: measurementInvocationId,
        })
      }
    >
      {isSelected && <ScrollTarget behavior="smooth" block="nearest" />}
      <MeasurementInvocationDetail>
        <FlexColumn>
          <SubjectHeader>{subject.subjectName}</SubjectHeader>
          <SubjectDetails>
            {isLatest && (
              <FlexRowWithPadding>
                <CommentTextIcon /> {iteration.item.geometry.poseName}
              </FlexRowWithPadding>
            )}
            <FlexRowWithPadding>
              <IterationDisplay
                color={SUBJECT_DETAIL_COLOR}
                iteration={iteration.item.measurementInvocationIteration}
                isActive={measurerVersion !== undefined}
                onClick={() =>
                  dispatchTransientStateAction(
                    measurerVersion === undefined
                      ? {
                          kind: 'show-measurer-version',
                          subjectId: subject.subjectId,
                        }
                      : { kind: 'hide-measurer-version' }
                  )
                }
              />
              {iteration.nestedSuccessors.length > 0 && (
                <>
                  <ButtonDownContainer
                    onClick={() => {
                      dispatchAccordionAction({
                        type: iteration.overrideNesting
                          ? 'hideItems'
                          : 'expandItems',
                        items: iteration.nestedSuccessors.map(
                          iteration => iteration.measurementInvocationId
                        ),
                      })
                    }}
                  >
                    <CircleChevronsBottomIcon />
                  </ButtonDownContainer>
                  <div>
                    {iteration.nestedSuccessors.length}
                    &nbsp;
                    {iteration.overrideNesting ? 'fewer' : 'more'}
                  </div>
                </>
              )}
            </FlexRowWithPadding>
          </SubjectDetails>
        </FlexColumn>
        <LabelContainerForSubjects>
          {isLatest &&
            subject.iterations.length === 1 &&
            !subject.hasLabels && <InitialStatus />}
          {isLatest &&
            iteration.item.labels.length === 0 &&
            subject.compareTo && (
              <MetricComparisonWithIcon
                displayMetric={
                  sortBy === SortBy.VALUE_DIFFERENCE
                    ? subject.compareTo.valueDifferenceDisplayValue
                    : subject.compareTo.pathDifferenceDisplayValue
                }
              />
            )}
          {iteration.item.labels.map(({ label, commitState }) => (
            <DisplayLabel
              key={hashKeyForLabel(label)}
              label={label}
              $isUpdated={commitState === 'added' || commitState === 'removed'}
              $isRemoved={commitState === 'removed'}
              disabled={true}
            />
          ))}
        </LabelContainerForSubjects>
        {canCopy && (
          <BottomRightAlignedDiv
            onClick={e => {
              e.stopPropagation()
              if (subject.previousLabeledIteration) {
                dispatchLocalStorageAction({
                  type: 'addLabels',
                  labels: subject.previousLabeledIteration.labels
                    .filter(label => label.commitState !== 'removed')
                    .map(label => label.label),
                  measurementInvocationId,
                })
              }
            }}
          >
            <FlexRowWithPadding>
              <CopyIcon /> Copy
            </FlexRowWithPadding>
          </BottomRightAlignedDiv>
        )}
      </MeasurementInvocationDetail>
      {measurerVersion && (
        <IterationDetail>
          <MeasurerVersionDisplay measurerVersion={measurerVersion} />
          {measurerVersion.kind === 'commit' && (
            <div>{measurerVersion.commitMessage}</div>
          )}
        </IterationDetail>
      )}
    </MeasurementInvocationRow>
  )
}
function subjectMatches(
  first: AccordionedSubjectViewModel,
  second: AccordionedSubjectViewModel
): boolean {
  return (
    first.subjectId === second.subjectId &&
    first.iterations.length === second.iterations.length &&
    first.previousLabeledIteration?.hash ===
      second.previousLabeledIteration?.hash &&
    isEqual(first.compareTo, second.compareTo)
  )
}
function iterationMatches(
  first: Accordioned<IterationViewModel>,
  second: Accordioned<IterationViewModel>
): boolean {
  return (
    first.item.hash === second.item.hash &&
    first.nestedSuccessors.length === second.nestedSuccessors.length &&
    first.overrideNesting === second.overrideNesting
  )
}
const DisplayInvocation = React.memo(
  _DisplayInvocation,
  (
    firstProps: Parameters<typeof _DisplayInvocation>[0],
    secondProps: Parameters<typeof _DisplayInvocation>[0]
  ) =>
    subjectMatches(firstProps.subject, secondProps.subject) &&
    iterationMatches(firstProps.iteration, secondProps.iteration) &&
    measurerVersionMatches(
      firstProps.measurerVersion,
      secondProps.measurerVersion
    ) &&
    isShallowEqualOmitting(firstProps, secondProps, [
      'geometry',
      'iteration',
      'measurerVersion',
    ])
)

export function Subjects({
  subjects,
  compareToViewModel,
  selectedMeasurementInvocationId,
  navigateToReviewMeasurements,
  dispatchLocalStorageAction,
  dispatchAccordionAction,
  sortBy,
}: {
  subjects: AccordionedSubjectViewModel[]
  compareToViewModel: CompareToViewModel
  selectedMeasurementInvocationId: number
  navigateToReviewMeasurements: (params: NavigateToParameters) => void
  dispatchLocalStorageAction: Dispatch<LocalStorageAction>
  dispatchAccordionAction: Dispatch<AccordionAction>
  sortBy?: SortBy
}): JSX.Element {
  const [transientState, dispatchTransientStateAction] = useReducer(
    transientStateReducer,
    INITIAL_STATE
  )

  // Avoid generating a new callback when the selection changes.
  const selectedMeasurementInvocationIdRef = useRef<number>(
    selectedMeasurementInvocationId
  )
  selectedMeasurementInvocationIdRef.current = selectedMeasurementInvocationId
  const subjectsRef = useRef<AccordionedSubjectViewModel[]>(subjects)
  subjectsRef.current = subjects

  const handleSubjectClick = useCallback(
    function handleSubjectClick({
      event,
      clickedMeasurementInvocationId,
    }: {
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
      clickedMeasurementInvocationId: number
    }): void {
      if (isCtrlKey(event)) {
        // Activate compare view. For now, side-by-side comparisons must use the
        // same subject. Enforce this constraint.
        const selectedSubject = subjectsRef.current.find(subject =>
          subject.iterations.some(
            iteration =>
              iteration.measurementInvocationId ===
              selectedMeasurementInvocationIdRef.current
          )
        )
        if (
          selectedSubject?.iterations.some(
            iteration =>
              iteration.measurementInvocationId ===
              clickedMeasurementInvocationId
          )
        ) {
          const [compareToInvocationId, compareFromMeasurementInvocationId] = [
            selectedMeasurementInvocationIdRef.current,
            clickedMeasurementInvocationId,
          ].sort()
          navigateToReviewMeasurements({
            measurementInvocationId: compareFromMeasurementInvocationId,
            compareToInvocationId,
          })
          return
        }
      }

      navigateToReviewMeasurements({
        measurementInvocationId: clickedMeasurementInvocationId,
      })
    },
    [navigateToReviewMeasurements]
  )

  return (
    <>
      {subjects.map(subject => {
        // For now, side-by-side comparisons must use the same subject. Enforce this constraint.
        const geometryIsSelected = subject.iterations.some(
          iteration =>
            iteration.measurementInvocationId ===
            selectedMeasurementInvocationId
        )
        const shouldShowMeasurerVersion =
          transientState.showMeasurerVersionForSubjectId === subject.subjectId

        return (
          <SubjectRow key={`${subject.subjectId}`}>
            {subject.visibleIterations.map((iteration, i) => {
              const { measurementInvocationId } = iteration.item

              return (
                <DisplayInvocation
                  key={`${measurementInvocationId}`}
                  isLatest={i === 0}
                  isSelected={
                    measurementInvocationId === selectedMeasurementInvocationId
                  }
                  isSelectedForComparison={
                    measurementInvocationId ===
                    compareToViewModel.invocation?.measurementInvocationId
                  }
                  measurerVersion={
                    shouldShowMeasurerVersion
                      ? iteration.item.measurerVersion
                      : undefined
                  }
                  {...{
                    subject,
                    iteration,
                    geometryIsSelected,
                    handleSubjectClick,
                    dispatchTransientStateAction,
                    dispatchLocalStorageAction,
                    dispatchAccordionAction,
                    sortBy,
                  }}
                />
              )
            })}
          </SubjectRow>
        )
      })}
    </>
  )
}
