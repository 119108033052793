import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Button } from '@unpublished/common-components'
import React, { ComponentType, ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'

export function LoginButton(): JSX.Element {
  const { loginWithRedirect } = useAuth0()

  return <Button onClick={loginWithRedirect}>Log In</Button>
}

export function LogoutButton(): JSX.Element {
  const { logout } = useAuth0()

  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </Button>
  )
}

export function UserProfile(): JSX.Element | null {
  const { user, isLoading } = useAuth0()

  if (isLoading) {
    return <div>Loading &hellip;</div>
  } else if (user) {
    return (
      <div>
        <p>Logged in as</p>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <LogoutButton />
      </div>
    )
  } else {
    return (
      <div>
        <p>You are not logged in.</p>
        <LoginButton />
      </div>
    )
  }
}

export function WhenAuthenticated({
  children,
}: {
  children?: ReactNode
}): JSX.Element | null {
  const { isAuthenticated } = useAuth0()
  if (isAuthenticated) {
    return <>{children}</>
  } else {
    return null
  }
}

export function WhenUnauthenticated({
  children,
}: {
  children?: ReactNode
}): JSX.Element | null {
  const { isLoading, isAuthenticated } = useAuth0()
  if (isLoading || isAuthenticated) {
    return null
  } else {
    return <>{children}</>
  }
}

// Use the router's history module to replace the URL.
// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app
type OnRedirectCallback = (appState: any) => void
export function makeOnRedirectCallback(
  navigate: NavigateFunction
): OnRedirectCallback {
  return function onRedirectCallback(appState: any): void {
    navigate(appState?.returnTo || window.location.pathname, {
      replace: true,
    })
  }
}

export function createRender(
  authEnabled: boolean
): (Component: ComponentType) => JSX.Element {
  return function render(Component: ComponentType): JSX.Element {
    const MaybeWrappedComponent = authEnabled
      ? withAuthenticationRequired(Component)
      : Component
    return <MaybeWrappedComponent />
  }
}
