import { intersectMeshWorld, Raycaster, Vector2 } from '@unpublished/four'
import React, { useRef } from 'react'
import * as THREE from 'three'

import { useGetCanvasContextState } from '../canvas/canvas-context'

const raycaster = new Raycaster()

// Provide the inverse of the main object's matrixWorld. The hook recomputes the
// inverse matrix every time it changes.
//
// Note that the hook does not itself force a re-render when matrixWorld
// changes. This is the caller's responsibliity.
//
// TODO: Move the main object's cached matrix inverse to the context, so that
// it's shared between different instances of the hook.
export function useMainObjectMatrixWorldInverse(): THREE.Matrix4 | undefined {
  const { mainObject } = useGetCanvasContextState()()

  // useRef with lazy initializer.
  const lastMatrixWorld =
    useRef<THREE.Matrix4>() as React.MutableRefObject<THREE.Matrix4>
  const lastMatrixWorldInverse =
    useRef<THREE.Matrix4>() as React.MutableRefObject<THREE.Matrix4>
  if (!lastMatrixWorld.current) {
    lastMatrixWorld.current = new THREE.Matrix4().identity()
    lastMatrixWorldInverse.current = new THREE.Matrix4().identity()
  }

  if (!mainObject) {
    return undefined
  }

  const { matrixWorld } = mainObject

  if (!lastMatrixWorld.current.equals(matrixWorld)) {
    lastMatrixWorld.current.copy(matrixWorld)
    // This is the slow operation.
    lastMatrixWorldInverse.current.copy(matrixWorld).invert()
  }

  return lastMatrixWorldInverse.current
}

export function useIntersectMainObject(): (
  canvasCoords: Vector2
) => THREE.Intersection | undefined {
  const getCanvasContextState = useGetCanvasContextState()
  const mainObjectMatrixWorldInverse = useMainObjectMatrixWorldInverse()

  return function intersectMainObject(canvasCoords: Vector2) {
    const { camera, canvas, mainObject } = getCanvasContextState()

    if (camera && canvas && mainObject) {
      const intersection = intersectMeshWorld({
        raycaster,
        canvasCoords,
        canvasSize: canvas.getSize(),
        mesh: mainObject,
        camera,
      })

      // Convert to mesh's coordinate system.
      if (intersection) {
        if (!mainObjectMatrixWorldInverse) {
          throw Error('Expected main object to be set')
        }
        intersection.point.applyMatrix4(mainObjectMatrixWorldInverse)
      }

      return intersection
    }
  }
}
