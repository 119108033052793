import { Camera, useFrame, useThree } from '@react-three/fiber'
import CameraControls from 'camera-controls'
import React, { useContext, useEffect, useImperativeHandle } from 'react'
import * as THREE from 'three'

import { ReactCanvasContext } from '../canvas/canvas-context'
// @ts-ignore
import { ArcballControls } from './ArcballControls.js'
import {
  ArcballControlAdapter,
  ControlAdapter,
  ControlType,
  YomotsuControlAdapter,
} from './control-adapter'

CameraControls.install({ THREE })

export type ControlKind = 'orbit' | 'arcball'

interface CacheKey {
  camera: Camera
  domElement: HTMLCanvasElement
  kind: ControlKind
}

interface CachedItem {
  controls: ControlType
  controlAdapter: ControlAdapter
}

class ControlsCache {
  private instances: (CacheKey & CachedItem)[]

  constructor() {
    this.instances = []
  }

  getOrCreate({
    camera,
    domElement,
    kind,
  }: {
    camera: Camera
    domElement: HTMLCanvasElement
    kind: ControlKind
  }): CachedItem {
    let instance = this.instances.find(
      thisInstance =>
        thisInstance.camera.uuid === camera.uuid &&
        thisInstance.domElement === domElement &&
        thisInstance.kind === kind
    )
    if (instance) {
      const { controls, controlAdapter } = instance
      return { controls, controlAdapter }
    } else {
      let controls: ControlType
      let controlAdapter: ControlAdapter
      switch (kind) {
        case 'orbit':
          controls = new CameraControls(camera, domElement)
          controlAdapter = new YomotsuControlAdapter({ camera, controls })
          break
        case 'arcball':
          controls = new ArcballControls(camera, domElement)
          controlAdapter = new ArcballControlAdapter({ camera, controls })
          break
        default:
          throw Error(`Not very kind: ${kind}`)
      }
      this.instances.push({
        camera,
        domElement,
        kind,
        controls,
        controlAdapter,
      })
      return { controls, controlAdapter }
    }
  }
}

const cache = new ControlsCache()

interface Props {
  kind: 'orbit' | 'arcball'
}

export const Controls = React.forwardRef<ControlAdapter | undefined, Props>(
  ({ kind }: Props, ref: React.Ref<ControlAdapter | undefined>) => {
    const camera = useThree(state => state.camera)
    const domElement = useThree(state => state.gl.domElement)
    const { controls, controlAdapter } =
      camera && domElement
        ? cache.getOrCreate({ camera, domElement, kind })
        : { controls: undefined, controlAdapter: undefined }

    const canvasContext = useContext(ReactCanvasContext)

    useImperativeHandle<ControlAdapter | undefined, ControlAdapter | undefined>(
      ref,
      () => controlAdapter,
      [controlAdapter]
    )

    useEffect(() => {
      if (canvasContext && controlAdapter) {
        return controlAdapter.bindToContext(canvasContext)
      }
    }, [canvasContext, controlAdapter])

    useFrame((_, delta) => {
      if (controlAdapter) {
        controlAdapter.update(delta)
      }
    })

    // Reset in case an instance of Controls is reused for a different
    // `<Controls />` instance.
    useEffect(() => {
      if (controlAdapter) {
        // controlAdapter.enable()
      }
    }, [controlAdapter])

    function dispose(): void {
      if (controlAdapter) {
        console.log('disposing')
        // controlAdapter.disable()
      }
    }

    return <primitive object={controls} dispose={dispose} />
  }
)
