import { gql, useQuery } from '@apollo/client'
import { STATISTICAL_TEXT } from '@unpublished/common-components'
import _ from 'lodash'
import React, { Fragment, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import {
  Gender,
  GENDER_OPTIONS,
  sortAlphaNum,
  uniqueNonNullValues,
} from '../common/data-transforms'
import {
  DatasetDetailsForViewSubjectsNavigationQuery,
  DatasetDetailsForViewSubjectsNavigationQueryVariables,
} from '../common/generated'
import firstLast from '../common/images/first-last.svg'
import nextPrevious from '../common/images/next-previous.svg'
import { StyledIcon } from '../common/styled-icon'

// Grid, lays out each element in a grid cell from left to right,
// and then top to bottom.  In this case we have one empty div,
// which functions as a placeholder in the upper right corner.
const SubjectNavContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 150px 100px;
  grid-template-rows: auto auto auto;
  justify-content: center;
`
const NextSubjectContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-evenly;
`
const PreviousSubjectContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-evenly;
`
const NavLabel = styled.div`
  ${STATISTICAL_TEXT};
  text-align: right;
  padding: 15px;
`
type NavAction =
  | {
      type: 'setGender'
      gender: Gender
    }
  | {
      type: 'setSubject'
      subjectID: string
    }
  | {
      type: 'setPose'
      pose: number
    }

export function DatasetSubjectNavigation({
  selectedDataset,
  selectedSubjectName,
  selectedPoseId,
  onNavChange,
}: {
  selectedSubjectName: string
  selectedDataset: number
  selectedPoseId: number
  onNavChange: ({
    updatedPose,
    updatedSubjectName,
  }: {
    updatedPose: number
    updatedSubjectName: string
  }) => void
}): JSX.Element {
  const { error, data } = useQuery<
    DatasetDetailsForViewSubjectsNavigationQuery,
    DatasetDetailsForViewSubjectsNavigationQueryVariables
  >(
    gql`
      query DatasetDetailsForViewSubjectsNavigation($datasetId: Int!) {
        datasetById(id: $datasetId) {
          bodyPart
        }
        allSubjects(condition: { datasetId: $datasetId }) {
          nodes {
            gender
            name
            id
            geometrySeriesBySubjectId {
              nodes {
                poseTypeId
                poseTypeByPoseTypeId {
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        datasetId: selectedDataset,
      },
    }
  )

  const genderOptions = uniqueNonNullValues(
    data?.allSubjects.nodes.flatMap(subject => subject.gender)
  ).map(gender => ({
    value: gender as Gender,
    label: GENDER_OPTIONS[gender as Gender],
  }))

  const poseOptions = _.uniqBy(
    data?.allSubjects.nodes.flatMap(
      node => node.geometrySeriesBySubjectId.nodes
    ),
    'poseTypeId'
  ).map(geometrySeries => ({
    value: geometrySeries.poseTypeId,
    label: geometrySeries.poseTypeByPoseTypeId.name,
  }))

  const gender =
    data?.allSubjects.nodes.find(
      subject => subject.name === selectedSubjectName
    )?.gender ?? null

  const poseIsValid = poseOptions
    .flatMap(pose => pose.value)
    .includes(selectedPoseId)

  const subjectOptions = poseIsValid
    ? data?.allSubjects.nodes
        .filter(node => node.gender === gender)
        .filter(node =>
          node.geometrySeriesBySubjectId.nodes
            .flatMap(geometrySeries => geometrySeries.poseTypeId)
            .includes(selectedPoseId)
        )
        .map(subject => subject.name)
        .sort(sortAlphaNum)
        .map(subject => ({
          value: subject,
          label: subject,
        })) ?? []
    : []

  const selectedSubjectIndex = subjectOptions.findIndex(
    subject => subject.value === selectedSubjectName
  )
  const nextSubjectID: string | undefined =
    subjectOptions[selectedSubjectIndex + 1]?.value
  const previousSubjectID: string | undefined =
    subjectOptions[selectedSubjectIndex - 1]?.value

  /*
  The hierarchy proceeds Gender -> Pose -> Subject. The gender is inferred
  from the specified subject. When the gender is changed, the nav keep the
  selected pose whenever possible, picking the first subject for that pose.
  When the pose is changed, the nav keeps the selected subject whenever
  possible. If the subject doesn't exist the nav can't infer the gender.
  It shows the pose when possible but leaves the rest for the user to fill in. 

  Making navigation changes is the responsibility of the caller's onNavChange
  function. This could be done by changing the URL parameters.
  */
  function handleNavigationAction(action: NavAction): void {
    if (!data) {
      throw Error('there should be data')
    }
    switch (action.type) {
      case 'setPose':
        onNavChange({
          updatedPose: action.pose,
          updatedSubjectName: selectedSubjectName,
        })
        break
      case 'setSubject':
        onNavChange({
          updatedPose: selectedPoseId,
          updatedSubjectName: action.subjectID,
        })
        break
      case 'setGender':
        const firstSubjectInGender = data.allSubjects.nodes.filter(
          node => node.gender === action.gender
        )[0]
        if (!firstSubjectInGender) {
          throw Error('gender should have subjects')
        }
        onNavChange({
          updatedPose: selectedPoseId,
          updatedSubjectName: firstSubjectInGender.name,
        })
        break
    }
  }

  function onKeydown(e: KeyboardEvent): void {
    if (e.key === 'ArrowLeft' && previousSubjectID) {
      handleNavigationAction({
        type: 'setSubject',
        subjectID: previousSubjectID,
      })
    }
    if (e.key === 'ArrowRight' && nextSubjectID) {
      handleNavigationAction({
        type: 'setSubject',
        subjectID: nextSubjectID,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeydown)
    return () => window.removeEventListener('keydown', onKeydown)
  })

  return (
    <div>
      {error && <p>Oh no! {error.message}</p>}
      <SubjectNavContainer>
        {data?.datasetById.bodyPart === 'BODY' && (
          <Fragment>
            <NavLabel>gender</NavLabel>
            <Select
              name="gender"
              id="gender"
              value={genderOptions.filter(option => option.value === gender)}
              onChange={(value, { action }) => {
                if (action === 'select-option') {
                  handleNavigationAction({
                    type: 'setGender',
                    gender: (value as typeof genderOptions[0]).value,
                  })
                }
              }}
              options={genderOptions}
            />
            <div />
          </Fragment>
        )}
        <NavLabel>pose</NavLabel>
        <Select
          name="poses"
          key="poses"
          value={
            poseOptions.find(option => option.value === selectedPoseId) ?? null
          }
          onChange={(value, { action }) => {
            if (action === 'select-option') {
              handleNavigationAction({
                type: 'setPose',
                pose: (value as typeof poseOptions[0]).value,
              })
            }
          }}
          options={poseOptions}
        />
        <div />
        <PreviousSubjectContainer>
          <StyledIcon
            src={firstLast}
            disabled={!previousSubjectID}
            $rotate
            onClick={() =>
              handleNavigationAction({
                type: 'setSubject',
                subjectID: subjectOptions[0].value,
              })
            }
          />
          <StyledIcon
            src={nextPrevious}
            disabled={!previousSubjectID}
            $rotate
            onClick={() =>
              handleNavigationAction({
                type: 'setSubject',
                subjectID: previousSubjectID,
              })
            }
          />
        </PreviousSubjectContainer>
        <Select
          name="subjects"
          key="subjects"
          value={
            subjectOptions?.filter(
              option => option.value === selectedSubjectName
            ) ?? []
          }
          onChange={(value, { action }) => {
            if (action === 'select-option') {
              handleNavigationAction({
                type: 'setSubject',
                subjectID: (value as typeof subjectOptions[0]).value,
              })
            }
          }}
          options={subjectOptions}
        />
        <NextSubjectContainer>
          <StyledIcon
            src={nextPrevious}
            disabled={!nextSubjectID}
            onClick={() =>
              handleNavigationAction({
                type: 'setSubject',
                subjectID: nextSubjectID,
              })
            }
          />
          <StyledIcon
            src={firstLast}
            disabled={!nextSubjectID}
            onClick={() =>
              handleNavigationAction({
                type: 'setSubject',
                subjectID: subjectOptions.slice(-1)[0].value,
              })
            }
          />
        </NextSubjectContainer>
      </SubjectNavContainer>
    </div>
  )
}
