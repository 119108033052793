import { gql } from '@apollo/client'

import {
  ComparePerformanceLatestIterationQuery,
  ComparePerformanceSpecificIterationQuery,
} from '../../common/generated'
import {
  MeasurementPerformance,
  transformMeasurementPerformance,
} from '../comparison'

export const COMPARE_PERFORMANCE_LATEST_ITERATION_QUERY = gql`
  query ComparePerformanceLatestIteration(
    $measurementStudyId: Int!
    $rightMeasurementInvocationIteration: Int!
  ) {
    allLatestIterationPerformances(
      condition: {
        measurementStudyId: $measurementStudyId
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
      orderBy: MEASUREMENT_NAME_ASC
    ) {
      nodes {
        measurementName
        leftMeasurementInvocationIteration
        leftNumInvocations
        leftMedianDurationSeconds
        leftNinetiethPctDurationSeconds
        leftMaxDurationSeconds
        rightMeasurementInvocationIteration
        rightNumInvocations
        rightMedianDurationSeconds
        rightNinetiethPctDurationSeconds
        rightMaxDurationSeconds
      }
    }
  }
`

export const COMPARE_PERFORMANCE_SPECIFIC_ITERATION_QUERY = gql`
  query ComparePerformanceSpecificIteration(
    $measurementStudyId: Int!
    $leftMeasurementInvocationIteration: Int!
    $rightMeasurementInvocationIteration: Int!
  ) {
    allIterationPerformances(
      condition: {
        measurementStudyId: $measurementStudyId
        leftMeasurementInvocationIteration: $leftMeasurementInvocationIteration
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
      orderBy: MEASUREMENT_NAME_ASC
    ) {
      nodes {
        measurementName
        leftMeasurementInvocationIteration
        leftNumInvocations
        leftMedianDurationSeconds
        leftNinetiethPctDurationSeconds
        leftMaxDurationSeconds
        rightMeasurementInvocationIteration
        rightNumInvocations
        rightMedianDurationSeconds
        rightNinetiethPctDurationSeconds
        rightMaxDurationSeconds
      }
    }
  }
`

export function createMeasurementViewModel({
  latestIterationData,
  specificIterationData,
}: {
  latestIterationData?: ComparePerformanceLatestIterationQuery
  specificIterationData?: ComparePerformanceSpecificIterationQuery
}): {
  measurements: MeasurementPerformance[]
} {
  return {
    measurements: transformMeasurementPerformance(
      latestIterationData?.allLatestIterationPerformances.nodes ??
        specificIterationData?.allIterationPerformances.nodes ??
        []
    ),
  }
}
