import { isEqualWith, omit, pick } from 'lodash'

export function isShallowEqual(first: any, second: any): boolean {
  return isEqualWith(first, second, (firstValue, secondValue, index) =>
    index === undefined ? undefined : Object.is(firstValue, secondValue)
  )
}

export function isShallowEqualPicking(
  first: any,
  second: any,
  keysToPick: string[]
): boolean {
  return isShallowEqual(pick(first, keysToPick), pick(second, keysToPick))
}

export function isShallowEqualOmitting(
  first: any,
  second: any,
  keysToOmit: string[],
  { debug = false }: { debug?: boolean } = {}
): boolean {
  if (debug) {
    for (const key of Object.keys(omit(first, keysToOmit))) {
      console.log(
        `Object.is(first.${key}, second.${key}])`,
        Object.is((first as any)[key], (second as any)[key])
      )
    }
  }

  return isShallowEqual(omit(first, keysToOmit), omit(second, keysToOmit))
}
