import React, { ChangeEvent, ReactNode } from 'react'

export function CheckboxToggle({
  name,
  checked,
  setChecked,
  disabled = false,
  children,
}: {
  name: string
  checked: boolean
  setChecked: (checked: boolean) => void
  disabled?: boolean
  children?: ReactNode
}): JSX.Element {
  function flatShadingChange({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>): void {
    setChecked(checked)
  }

  return (
    <form>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={flatShadingChange}
      />
      <label htmlFor={name}>{children}</label>
    </form>
  )
}
