import { gql } from '@apollo/client'
import { uniq } from 'lodash'
import semverCompareBuild from 'semver/functions/compare-build'

import { CreateNewMeasurementStudyQuery } from '../../../common/generated'
import { PosesAndTopologiesPair } from '../../../common/select-pose-topology'
import { Option } from '../../../common/toggleable-button-list'

export const CREATE_NEW_MEASUREMENT_STUDY_QUERY = gql`
  query CreateNewMeasurementStudy {
    bodyPartType: __type(name: "BodyPartType") {
      enumValues {
        name
      }
    }
    allDeployableMeasurerVersions {
      versions {
        measurerName
        tagName
      }
    }
    allMeasurers {
      nodes {
        id
        measurerName: name
        repoSlug
        functionName
      }
    }
    allPoseTypes {
      nodes {
        name
      }
    }
    topologyType: __type(name: "TopologyType") {
      enumValues {
        name
      }
    }
  }
`

interface ViewModel {
  allBodyPartsOptions: Option<string>[]
  measurerOptions: Option<
    CreateNewMeasurementStudyQuery['allMeasurers']['nodes'][0]
  >[]
  measurerVersionOptions: Option<string>[]
  posesAndTopologiesPair: PosesAndTopologiesPair
}

export function createViewModel({
  data,
  selectedMeasurer,
}: {
  data?: CreateNewMeasurementStudyQuery
  selectedMeasurer?: string
}): ViewModel {
  const allDeployableMeasurerVersions = data?.allDeployableMeasurerVersions
  const allMeasurers = data?.allMeasurers
  return {
    allBodyPartsOptions:
      data?.bodyPartType?.enumValues?.map(({ name: bodyPart }) => ({
        label: bodyPart.toLowerCase(),
        value: bodyPart,
      })) || [],
    measurerOptions:
      allMeasurers?.nodes.map(measurer => ({
        label: measurer.measurerName,
        value: measurer,
      })) || [],
    measurerVersionOptions:
      selectedMeasurer && allDeployableMeasurerVersions
        ? Array.from(
            new Set(
              allDeployableMeasurerVersions.versions
                .filter(version => version.measurerName === selectedMeasurer)
                .map(version => version.tagName)
            )
          )
            .sort(semverCompareBuild)
            .reverse()
            .map(tagName => ({ label: tagName, value: tagName }))
        : [],
    posesAndTopologiesPair: [
      uniq(data?.allPoseTypes.nodes.map(poseType => poseType.name) || []),
      uniq(
        data?.topologyType?.enumValues?.map(
          topologyType => topologyType.name
        ) || []
      ),
    ],
  }
}
