import { FlexRow } from '@unpublished/common-components'
import styled from 'styled-components'

export const DivWithSizedText = styled(FlexRow)`
  font-size: 11px;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0px 3.5px;
  }
`
