import {
  FaceGroupsForThreejs,
  FaceGroupsForThreejsData,
} from '@unpublished/four'
import { Asset } from '@unpublished/victorinox'

export function createFloat32ArrayAsset(url: string): Asset<Float32Array> {
  return new Asset(async () => {
    const coordinates = await (await fetch(url)).json()

    if (!Array.isArray(coordinates)) {
      throw Error(`Unable to load array asset from ${url}`)
    }

    return Float32Array.from(coordinates)
  })
}

export function createFaceGroupAsset(url: string): Asset<FaceGroupsForThreejs> {
  return new Asset(async () => {
    const result: FaceGroupsForThreejsData = await (await fetch(url)).json()
    return {
      ...result,
      faceOrdering: Int32Array.from(result.faceOrdering),
    }
  })
}
