// Internal class to represent Euclidean coordinates. Externally, use `Point`
// and `Vector` which expose appropriate high-level methods for their types.
export class Coords {
    constructor(coords) {
        this.coords = coords;
    }
}
// Helper functions which:
// - Avoid repeating similar code to produce points.
// - Avoid creating any extra copies.
export function addCoords(first, second, Constructor) {
    const [x1, y1, z1] = first.coords;
    const [x2, y2, z2] = second.coords;
    return new Constructor([x1 + x2, y1 + y2, z1 + z2]);
}
export function subtractCoords(first, second, Constructor) {
    const [x1, y1, z1] = first.coords;
    const [x2, y2, z2] = second.coords;
    return new Constructor([x1 - x2, y1 - y2, z1 - z2]);
}
export function multiplyCoordsByScalar(first, scalar, Constructor) {
    const [x, y, z] = first.coords;
    return new Constructor([scalar * x, scalar * y, scalar * z]);
}
export function divideCoordsByScalar(first, scalar, Constructor) {
    const [x, y, z] = first.coords;
    return new Constructor([x / scalar, y / scalar, z / scalar]);
}
