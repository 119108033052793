import { gql } from '@apollo/client'
import { groupBy } from 'lodash'

import { DatasetId } from '../../common/choose-datasets'
import { Gender } from '../../common/data-transforms'
import { AddGeometriesToStudyQuery } from '../../common/generated'
import { DatasetIdToGeometryIdsMap } from '../../common/male-female-toggleable-button-list-table'
import {
  Pose,
  PoseAndTopologyPair,
  Topology,
} from '../../common/select-pose-topology'
import { GeometryId } from '../measure-again/view-model'

export const ADD_GEOMETRIES_TO_STUDY_QUERY = gql`
  query AddGeometriesToStudy($selectedMeasurementStudy: Int!) {
    allDatasets {
      nodes {
        id
        name
        bodyPart
        subjectsByDatasetId {
          nodes {
            id
            name
            gender
            geometrySeriesBySubjectId {
              nodes {
                topology
                poseTypeByPoseTypeId {
                  id
                  name
                }
                geometriesByGeometrySeriesId {
                  nodes {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    measurementStudyById(id: $selectedMeasurementStudy) {
      name
      commitByHeadCommitId {
        commitsGeometriesLookupsByCommitId {
          nodes {
            geometryId
            geometryByGeometryId {
              geometrySeriesByGeometrySeriesId {
                topology
                poseTypeByPoseTypeId {
                  bodyPart
                  name
                }
                subjectBySubjectId {
                  gender
                }
              }
            }
          }
        }
      }
    }
  }
`

type PoseAndTopologyLabel = string
export type SubjectName = string
type PoseAndTopologyPairToGeometryIds = Record<
  PoseAndTopologyLabel,
  GeometryId[]
>
type DatasetIdToPoseAndTopologyPairToGeometryIds = Record<
  DatasetId,
  PoseAndTopologyPairToGeometryIds
>
// we will also need a way to look up subject information from a given GeometryId
type Subject =
  AddGeometriesToStudyQuery['allDatasets']['nodes'][number]['subjectsByDatasetId']['nodes'][number]
type GeometryIdToSubjectLookup = Record<GeometryId, Subject>

function getStudyBodyPart(data: AddGeometriesToStudyQuery): string {
  return data.measurementStudyById.commitByHeadCommitId
    .commitsGeometriesLookupsByCommitId.nodes[0].geometryByGeometryId
    .geometrySeriesByGeometrySeriesId.poseTypeByPoseTypeId.bodyPart
}

function getGeometryIdToSubjectLookup(
  data: AddGeometriesToStudyQuery
): GeometryIdToSubjectLookup {
  return Object.fromEntries(
    data.allDatasets.nodes.flatMap(dataset =>
      dataset.subjectsByDatasetId.nodes.flatMap(subject =>
        subject.geometrySeriesBySubjectId.nodes.flatMap(geometrySeries =>
          geometrySeries.geometriesByGeometrySeriesId.nodes.map(geometry => [
            geometry.id,
            subject,
          ])
        )
      )
    )
  )
}

const LABEL_SEPERATOR = ' — '

function poseAndTopologyPairToLabel(
  pair: PoseAndTopologyPair
): PoseAndTopologyLabel {
  return pair.join(LABEL_SEPERATOR)
}

function poseAndTopologyLabelToPair(
  label: PoseAndTopologyLabel
): PoseAndTopologyPair {
  return label.split(LABEL_SEPERATOR) as PoseAndTopologyPair
}

function getDatasetIdToPoseAndTopologyPairToGeometryIds(
  data: AddGeometriesToStudyQuery
): DatasetIdToPoseAndTopologyPairToGeometryIds {
  const datasetEntries = data.allDatasets.nodes.map(dataset => {
    const geometryIdPoseAndTopologyLabelPairs =
      dataset.subjectsByDatasetId.nodes.flatMap(subject =>
        subject.geometrySeriesBySubjectId.nodes.flatMap(geometrySeries =>
          geometrySeries.geometriesByGeometrySeriesId.nodes.flatMap(
            geometry => ({
              geometryId: geometry.id,
              poseAndTopologyLabel: poseAndTopologyPairToLabel([
                geometrySeries.poseTypeByPoseTypeId.name,
                geometrySeries.topology || '',
              ]),
            })
          )
        )
      )
    const poseAndTopologyLabels = groupBy(
      geometryIdPoseAndTopologyLabelPairs,
      'poseAndTopologyLabel'
    )

    const poseAndTopologyPairToGeometryIds = Object.fromEntries(
      Object.entries(poseAndTopologyLabels).map(([key, value]) => [
        key,
        value.map(v => v.geometryId),
      ])
    )

    return [dataset.id, poseAndTopologyPairToGeometryIds]
  }) as [DatasetId, PoseAndTopologyPairToGeometryIds][]
  return Object.fromEntries(datasetEntries)
}

function getAllExistingGeometriesForMeasurementStudy({
  gender,
  data,
  topology,
  poseType,
}: {
  gender: Gender
  data: AddGeometriesToStudyQuery
  topology: Topology
  poseType: Pose
}): GeometryId[] {
  return data.measurementStudyById.commitByHeadCommitId.commitsGeometriesLookupsByCommitId.nodes
    .filter(
      commit =>
        commit.geometryByGeometryId.geometrySeriesByGeometrySeriesId
          .topology === topology &&
        commit.geometryByGeometryId.geometrySeriesByGeometrySeriesId
          .poseTypeByPoseTypeId.name === poseType &&
        commit.geometryByGeometryId.geometrySeriesByGeometrySeriesId
          .subjectBySubjectId.gender === gender
    )
    .map(commit => commit.geometryId)
}

function getGeometriesToAdd({
  datasetId,
  gender,
  data,
  topology,
  poseType,
  existingSubjectNames,
}: {
  datasetId: DatasetId
  gender: Gender
  data: AddGeometriesToStudyQuery
  topology: Topology
  poseType: Pose
  existingSubjectNames: SubjectName[]
}): SubjectNameGeometryIdPair[] {
  return data.allDatasets.nodes
    .filter(dataset => dataset.id === datasetId)
    .flatMap(dataset =>
      dataset.subjectsByDatasetId.nodes
        .filter(
          subject =>
            subject.gender === gender &&
            !existingSubjectNames.includes(subject.name)
        )
        .flatMap(subject =>
          subject.geometrySeriesBySubjectId.nodes
            .filter(
              geometrySeries =>
                geometrySeries.topology === topology &&
                geometrySeries.poseTypeByPoseTypeId.name === poseType
            )
            .flatMap(geometrySeries =>
              geometrySeries.geometriesByGeometrySeriesId.nodes.map(
                geometry =>
                  [subject.name, geometry.id] as SubjectNameGeometryIdPair
              )
            )
        )
    )
}

function mapGeometryIdToSubjectName(
  geometryIdToSubjectLookup: GeometryIdToSubjectLookup,
  geometryId: GeometryId
): SubjectName {
  return geometryIdToSubjectLookup[geometryId].name
}

export type SubjectNameGeometryIdPair = [SubjectName, GeometryId]

export interface ExistingGeometries {
  male: SubjectName[]
  female: SubjectName[]
}

export interface GeometriesToAdd {
  male: SubjectNameGeometryIdPair[]
  female: SubjectNameGeometryIdPair[]
}

export interface GeometriesState {
  existingGeometries: ExistingGeometries
  geometriesToAdd: GeometriesToAdd
}

export type GeometriesStateGroupedByPoseAndTopologyLabel = Record<
  PoseAndTopologyLabel,
  GeometriesState
>

export interface ViewModel {
  measurementStudyName?: string
  studyBodyPart: string
  datasetToExistingGeometriesCount: Record<DatasetId, number>
  datasetToAddedGeometriesCount: Record<DatasetId, number | undefined>
  geometriesStateGroupedByPoseAndTopologyLabel: GeometriesStateGroupedByPoseAndTopologyLabel
  allAddedGeometriesCount: number
  allGeometriesToAdd: GeometryId[]
}

function getGeometriesStateGroupedByPoseAndTopologyLabel({
  data,
  datasetId,
  geometryIdToSubjectLookup,
  datasetIdToPoseAndTopologyPairToGeometriesMap,
}: {
  data: AddGeometriesToStudyQuery
  datasetId?: DatasetId
  geometryIdToSubjectLookup: GeometryIdToSubjectLookup
  datasetIdToPoseAndTopologyPairToGeometriesMap: DatasetIdToPoseAndTopologyPairToGeometryIds
}): GeometriesStateGroupedByPoseAndTopologyLabel {
  function getExistingGeometries(
    gender: Gender,
    poseType: Pose,
    topology: Topology
  ): SubjectName[] {
    return getAllExistingGeometriesForMeasurementStudy({
      gender,
      data,
      poseType,
      topology,
    })
      .filter(geometryId => geometryIdToSubjectLookup[geometryId])
      .map(geometryId =>
        mapGeometryIdToSubjectName(geometryIdToSubjectLookup, geometryId)
      )
  }

  if (!datasetId) {
    return {}
  }

  const poseAndTopologyPairToGeometriesMap =
    datasetIdToPoseAndTopologyPairToGeometriesMap[datasetId]

  return Object.fromEntries(
    Object.entries(poseAndTopologyPairToGeometriesMap)
      .map(([poseAndTopologyLabel, geometries]) => {
        const [poseType, topology] =
          poseAndTopologyLabelToPair(poseAndTopologyLabel)
        const existingGeometriesMale = getExistingGeometries(
          'M',
          poseType,
          topology
        )
        const existingGeometriesFemale = getExistingGeometries(
          'F',
          poseType,
          topology
        )
        return [
          poseAndTopologyLabel,
          {
            existingGeometries: {
              male: existingGeometriesMale,
              female: existingGeometriesFemale,
            },
            geometriesToAdd: {
              male: getGeometriesToAdd({
                existingSubjectNames: existingGeometriesMale,
                data,
                datasetId,
                gender: 'M',
                poseType,
                topology,
              }),
              female: getGeometriesToAdd({
                existingSubjectNames: existingGeometriesFemale,
                datasetId,
                data,
                gender: 'F',
                poseType,
                topology,
              }),
            },
          },
        ] as [PoseAndTopologyLabel, GeometriesState]
      })
      .filter(
        ([k, v]) =>
          v.existingGeometries.male.length ||
          v.existingGeometries.female.length ||
          v.geometriesToAdd.male.length ||
          v.geometriesToAdd.female.length
      )
  )
}

function getDatasetToAddedGeometriesCount(
  datasetIdToPoseAndTopologyPairToGeometriesMap: DatasetIdToPoseAndTopologyPairToGeometryIds,
  selectedMaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap,
  selectedFemaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap
): Record<DatasetId, number | undefined> {
  return Object.fromEntries(
    Object.entries(datasetIdToPoseAndTopologyPairToGeometriesMap).map(
      ([_datasetId, poseAndTopologyPairToGeometryIdsMap]) => {
        const datasetId = parseInt(_datasetId)
        return [
          datasetId,
          selectedMaleSubjectsByDatasetId[datasetId] &&
            selectedFemaleSubjectsByDatasetId[datasetId] &&
            selectedMaleSubjectsByDatasetId[datasetId].size +
              selectedFemaleSubjectsByDatasetId[datasetId].size,
        ]
      }
    )
  )
}

function getDatasetToExistingGeometriesCount(
  datasetIdToPoseAndTopologyPairToGeometriesMap: DatasetIdToPoseAndTopologyPairToGeometryIds,
  data: AddGeometriesToStudyQuery,
  geometryIdToSubjectLookup: GeometryIdToSubjectLookup
): Record<DatasetId, number> {
  return Object.fromEntries(
    Object.entries(datasetIdToPoseAndTopologyPairToGeometriesMap).map(
      ([datasetId, poseAndTopologyPairToGeometryIdsMap]) => [
        datasetId,
        Object.entries(
          getGeometriesStateGroupedByPoseAndTopologyLabel({
            data,
            datasetId: parseInt(datasetId),
            geometryIdToSubjectLookup,
            datasetIdToPoseAndTopologyPairToGeometriesMap,
          })
        )
          .map(
            ([
              label,
              {
                existingGeometries: { male, female },
              },
            ]) => male.length + female.length
          )
          .reduce((sum, next) => sum + next, 0),
      ]
    )
  )
}

function countGeometriesAdded(
  geometriesAdded: DatasetIdToGeometryIdsMap
): number {
  return Object.values(geometriesAdded)
    .flatMap(selectedGeometries => selectedGeometries.size)
    .reduce((sum, l) => sum + l, 0)
}

function getAllGeometriesToAdd(
  geometriesAdded: DatasetIdToGeometryIdsMap
): GeometryId[] {
  return Object.values(geometriesAdded).flatMap(selectedGeometries =>
    Array.from(selectedGeometries)
  )
}

export function createViewModel({
  data,
  datasetIdBeingEdited,
  selectedMaleSubjectsByDatasetId,
  selectedFemaleSubjectsByDatasetId,
}: {
  data: AddGeometriesToStudyQuery
  datasetIdBeingEdited?: DatasetId
  selectedMaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap
  selectedFemaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap
}): ViewModel {
  const datasetIdToPoseAndTopologyPairToGeometriesMap =
    getDatasetIdToPoseAndTopologyPairToGeometryIds(data)
  const geometryIdToSubjectLookup = getGeometryIdToSubjectLookup(data)
  // get all of the existing male and female geometries
  return {
    measurementStudyName: data.measurementStudyById?.name,
    studyBodyPart: getStudyBodyPart(data),
    geometriesStateGroupedByPoseAndTopologyLabel:
      getGeometriesStateGroupedByPoseAndTopologyLabel({
        data,
        datasetId: datasetIdBeingEdited,
        geometryIdToSubjectLookup,
        datasetIdToPoseAndTopologyPairToGeometriesMap,
      }),
    datasetToExistingGeometriesCount: getDatasetToExistingGeometriesCount(
      datasetIdToPoseAndTopologyPairToGeometriesMap,
      data,
      geometryIdToSubjectLookup
    ),
    datasetToAddedGeometriesCount: getDatasetToAddedGeometriesCount(
      datasetIdToPoseAndTopologyPairToGeometriesMap,
      selectedMaleSubjectsByDatasetId,
      selectedFemaleSubjectsByDatasetId
    ),
    allGeometriesToAdd: getAllGeometriesToAdd(
      selectedMaleSubjectsByDatasetId
    ).concat(getAllGeometriesToAdd(selectedFemaleSubjectsByDatasetId)),
    allAddedGeometriesCount:
      countGeometriesAdded(selectedMaleSubjectsByDatasetId) +
      countGeometriesAdded(selectedFemaleSubjectsByDatasetId),
  }
}
