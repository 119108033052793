export function maybePluralize(
  singular: string,
  count: number,
  plural?: string
): string {
  if (plural === undefined) {
    plural = singular.endsWith('y')
      ? `${singular.slice(0, -1)}ies`
      : `${singular}s`
  }
  return count === 1 ? singular : plural
}
