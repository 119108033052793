import { SupportedFeatures } from '@curvewise/armscyence-types-measure-body'
import Ajv from 'ajv'

const ajv = new Ajv({ removeAdditional: true }).addSchema(
  require('../../../../../node_modules/@curvewise/armscyence-types-measure-body/build/generated/schema.json')
)

export function isSupportedFeatures(data: any): data is SupportedFeatures {
  return ajv.validate('#/definitions/SupportedFeatures', data) as boolean
}

export function assertSupportedFeatures(data: any): void {
  if (!isSupportedFeatures(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}
