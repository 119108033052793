import React, { forwardRef, Fragment, useImperativeHandle } from 'react'
import styled from 'styled-components'

import alertCircleIcon from '../common/images/alert-circle.svg'
import checkCircle from '../common/images/check-circle.svg'
import checkingScaleIcon from '../common/images/checking-scale.svg'
import pending from '../common/images/pending.svg'
import verifiedIcon from '../common/images/verified.svg'
import { Checks, CheckState } from './view-model'

const StatusIcon = styled.img`
  vertical-align: middle;
  margin-right: 0.5em;
`

function iconForState(state: CheckState): string {
  switch (state) {
    case CheckState.Ready:
      return verifiedIcon
    case CheckState.HasIssues:
      return alertCircleIcon
    case CheckState.CheckPending:
      return checkingScaleIcon
    case CheckState.MatchedImage:
      return checkCircle
    case CheckState.UnmatchedImage:
      return pending
  }
}

export function CheckStatusCell({
  value: checks,
}: {
  value: Checks
}): JSX.Element {
  const { state, summary } = checks
  return (
    <span>
      <StatusIcon src={iconForState(state)} /> {summary}
    </span>
  )
}

// Use &&& to override some sort of silly styles in the ag-grid custom-tooltip.
// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const TooltipContainer = styled.div`
  width: 350px;

  h4 {
    margin: 12px 5px;
  }

  p {
    line-height: 1.5;
  }

  &&& {
    p {
      white-space: normal;
    }

    p:first-of-type {
      font-weight: inherit;
    }
  }
`

export const CheckStatusTooltip = forwardRef(
  ({ value: checks }: { value: Checks }, ref: any) => {
    useImperativeHandle(ref, () => ({
      getReactContainerClasses() {
        return ['custom-tooltip']
      },
    }))

    const { state, issues } = checks

    switch (state) {
      case CheckState.CheckPending:
        return (
          <TooltipContainer>
            The geometry check is in progress.
          </TooltipContainer>
        )
      case CheckState.Ready:
        return (
          <TooltipContainer>The geometry is ready to import.</TooltipContainer>
        )
      case CheckState.MatchedImage:
        return (
          <TooltipContainer>
            Image has been matched to geometry.
          </TooltipContainer>
        )
      case CheckState.UnmatchedImage:
        return (
          <TooltipContainer>No geometry selected for image.</TooltipContainer>
        )
      case CheckState.HasIssues:
        return (
          <TooltipContainer>
            {issues.map(({ title, explanation }) => (
              <Fragment key={title}>
                <h4>{title}</h4>
                <p>{explanation}</p>
              </Fragment>
            ))}
          </TooltipContainer>
        )
    }
  }
)
