import React, { useMemo } from 'react'
import Select from 'react-select'

import { IconOption } from '../common/common-components'
import { stopPropagationOfKeyboardShortcuts } from './review-measurements/use-keyboard-shortcuts'

export type MeasurementInvocationIteration = number

export const LATEST_ITERATION = -1

export function IterationPicker({
  iterationChoices,
  selectedIteration,
  onChange,
  onClear,
  isClearable = false,
  includeLatest = false,
}: {
  iterationChoices: MeasurementInvocationIteration[]
  selectedIteration?: MeasurementInvocationIteration
  onChange: (iteration: MeasurementInvocationIteration) => void
  onClear?: () => void
  isClearable?: boolean
  includeLatest?: boolean
}): JSX.Element {
  const choices = useMemo(
    () =>
      (includeLatest
        ? [{ value: LATEST_ITERATION, label: 'Latest' }]
        : []
      ).concat(
        iterationChoices.map((iteration: MeasurementInvocationIteration) => ({
          value: iteration,
          label: `i${iteration}`,
        }))
      ),
    [iterationChoices, includeLatest]
  )

  return (
    <Select
      isClearable={isClearable}
      onKeyDown={e => stopPropagationOfKeyboardShortcuts(e)}
      onChange={(option: any, { action }) => {
        if (option && action === 'select-option') {
          onChange(option.value)
        } else if (action === 'clear' && onClear !== undefined) {
          onClear()
        }
      }}
      value={choices.find(option => option.value === selectedIteration) ?? null}
      options={choices}
      components={{ Option: IconOption }}
    />
  )
}
