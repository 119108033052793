import { Coords, addCoords, divideCoordsByScalar, multiplyCoordsByScalar, subtractCoords, } from './coords';
/* Represent a vector in 3-space. */
export class Vector extends Coords {
    get lengthSquared() {
        const [x, y, z] = this.coords;
        return Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2);
    }
    get length() {
        return Math.sqrt(this.lengthSquared);
    }
    timesScalar(scalar) {
        return multiplyCoordsByScalar(this, scalar, Vector);
    }
    dividedByScalar(scalar) {
        return divideCoordsByScalar(this, scalar, Vector);
    }
    negated() {
        return this.timesScalar(-1);
    }
    normalized() {
        if (this.length === 0) {
            throw Error("Can't normalize the zero vector");
        }
        return divideCoordsByScalar(this, this.length, Vector);
    }
    dot(other) {
        const [x1, y1, z1] = this.coords;
        const [x2, y2, z2] = other.coords;
        return x1 * x2 + y1 * y2 + z1 * z2;
    }
    cross(other) {
        const [x1, y1, z1] = this.coords;
        const [x2, y2, z2] = other.coords;
        return new Vector([y1 * z2 - z1 * y2, z1 * x2 - x1 * z2, x1 * y2 - y1 * x2]);
    }
    add(otherVector) {
        return addCoords(this, otherVector, Vector);
    }
    subtract(otherVector) {
        return subtractCoords(this, otherVector, Vector);
    }
}
Vector.ZERO = new Vector([0, 0, 0]);
Vector.BASIS = {
    X: new Vector([1, 0, 0]),
    Y: new Vector([0, 1, 0]),
    Z: new Vector([0, 0, 1]),
};
