import { gql } from '@apollo/client'
import { Side } from '@curvewise/common-types'

import {
  Gender,
  humanizeTopology,
  unabbreviateUnits,
} from '../common/data-transforms'
import {
  BodyPartType,
  ContentPartsFragment,
  SetPropertiesQuery,
  TopologyType,
  UnitsType,
} from '../common/generated'
import { StoredProperties } from './local-storage-state'

export const SET_PROPERTIES_QUERY = gql`
  fragment ContentParts on GoldilocksUploadBucketListResponseContents {
    Key
    LastModified
    ETag
  }

  query SetProperties($datasetId: Int!) {
    datasetById(id: $datasetId) {
      name
      activeTopology
      units
      bodyPart
      subjectsByDatasetId {
        nodes {
          name
          gender
          geometrySeriesBySubjectId {
            nodes {
              poseTypeId
              side
            }
          }
        }
      }
    }
    uploadBucketList {
      Contents {
        ...ContentParts
      }
    }
    allPoseTypes {
      nodes {
        id
        name
        bodyPart
      }
    }
    allCheckedUploads {
      nodes {
        nodeId
        eTag
        isValidObj
        topology
        predictedBodyUnits
        predictedHandUnits
      }
    }
  }
`

interface DatasetConstraints {
  topology: TopologyType | null
  units: UnitsType | null
  bodyPart: BodyPartType | null
}

export enum CheckState {
  CheckPending,
  Ready,
  HasIssues,
  MatchedImage,
  UnmatchedImage,
}

interface Issue {
  title: string
  explanation: string
}

interface BaseChecks {
  state: CheckState
  issues: Issue[]
  summary: string
}

interface GeometryChecks extends BaseChecks {
  isValidObj: boolean | null
  topology: TopologyType | null
  predictedBodyUnits: UnitsType | null
  predictedHandUnits: UnitsType | null
  genderIsValid: boolean | null
  alreadyUploaded: boolean | null
  texture?: string
}

interface ImageChecks extends BaseChecks {
  isMatched: boolean
}

export type Checks = GeometryChecks | ImageChecks

export enum FileType {
  Image,
  Geometry,
}

interface BaseUploadedFile {
  Key: string
  ETag: string
  LastModified: number
  gender?: Gender
  pose?: number
  subjectId?: string
  side?: Side
  texture?: string
  textureOptions?: string[]
}

interface UploadedGeometryFile extends BaseUploadedFile {
  type: FileType.Geometry
  checks: GeometryChecks
}

interface UploadedImageFile extends BaseUploadedFile {
  type: FileType.Image
  checks: ImageChecks
}

export type UploadedFile = UploadedGeometryFile | UploadedImageFile

export interface ViewModel extends DatasetConstraints {
  datasetName?: string
  poseChoices: SetPropertiesQuery['allPoseTypes']['nodes']
  files: UploadedFile[]
}

export function createViewModel({
  serializedSavedProperties,
  setPropertiesData,
}: {
  serializedSavedProperties: StoredProperties[]
  setPropertiesData?: SetPropertiesQuery
}): ViewModel {
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'dds']

  const uploadBucketData = setPropertiesData?.uploadBucketList?.Contents ?? []
  const allImages = uploadBucketData
    .map(item => item.Key)
    .filter(key => validImageExtensions.some(ext => key.endsWith(ext)))

  const datasetConstraints = {
    topology: setPropertiesData?.datasetById.activeTopology ?? null,
    units: setPropertiesData?.datasetById.units ?? null,
    bodyPart: setPropertiesData?.datasetById.bodyPart ?? null,
  }

  const poseChoices =
    setPropertiesData?.allPoseTypes.nodes.filter(
      poseType => poseType.bodyPart === setPropertiesData?.datasetById.bodyPart
    ) || []

  const datasetName = setPropertiesData?.datasetById.name

  const uploadedFilesWithStoredProperties = uploadBucketData.map(
    uploadedFile => ({
      uploadedFile,
      foundProperties:
        serializedSavedProperties.find(row => row.Key === uploadedFile.Key) ??
        ({} as StoredProperties),
    })
  )

  const files = uploadedFilesWithStoredProperties.map(function generate({
    uploadedFile,
    foundProperties,
  }: {
    uploadedFile: ContentPartsFragment
    foundProperties: StoredProperties
  }): UploadedFile {
    const { gender, pose, subjectId, texture, side } = foundProperties
    const { ETag, Key, LastModified } = uploadedFile
    const type = validImageExtensions.some(ext => Key.endsWith(ext))
      ? FileType.Image
      : FileType.Geometry

    const commonProperties = {
      ETag,
      Key,
      gender,
      pose,
      side,
      subjectId,
      texture,
      LastModified,
    }
    if (type === FileType.Image) {
      const isMatched = uploadedFilesWithStoredProperties.some(
        row => row.foundProperties.texture === Key
      )
      let state, summary
      if (isMatched) {
        state = CheckState.MatchedImage
        summary = 'Assigned'
      } else {
        state = CheckState.UnmatchedImage
        summary = 'Unassigned'
      }
      return {
        type,
        ...commonProperties,
        checks: { isMatched, issues: [], state, summary },
      }
    } else {
      const foundCheckUpload =
        setPropertiesData?.allCheckedUploads?.nodes?.find(
          r => r.eTag === normalizeETag(ETag)
        )
      const existingSubject =
        setPropertiesData?.datasetById.subjectsByDatasetId.nodes.find(
          r => r.name === subjectId
        )
      const textureOptions = ['(none)'].concat(
        allImages.filter(
          image =>
            // already picked, or not matched by any other file
            image === texture ||
            !uploadedFilesWithStoredProperties.some(
              uploadedFile => uploadedFile.foundProperties.texture === image
            )
        )
      )
      if (foundCheckUpload) {
        const { isValidObj, topology, predictedBodyUnits, predictedHandUnits } =
          foundCheckUpload
        const poseAndSubjectIDExistInLocalProperties =
          pose !== undefined && subjectId !== undefined
        let genderIsValid = null
        if (datasetConstraints.bodyPart === 'BODY') {
          genderIsValid = !existingSubject || existingSubject?.gender === gender
        }
        const alreadyUploaded =
          existingSubject !== undefined &&
          existingSubject.name !== null &&
          poseAndSubjectIDExistInLocalProperties &&
          existingSubject.name === subjectId &&
          existingSubject.geometrySeriesBySubjectId.nodes.some(
            geometrySeries => geometrySeries.poseTypeId === pose
          )
        const checks = {
          isValidObj,
          topology,
          predictedBodyUnits,
          predictedHandUnits,
          alreadyUploaded,
          texture,
          genderIsValid,
        }

        let issues: Issue[] = []

        if (checks.isValidObj === false) {
          issues.push({
            title: 'Not an OBJ',
            explanation: 'The file uploaded is not a valid OBJ file.',
          })
        } else if (checks.isValidObj === true) {
          if (
            datasetConstraints.topology &&
            datasetConstraints.topology !== checks.topology
          ) {
            const error = `The geometry uploaded does not conform to the ${humanizeTopology(
              datasetConstraints.topology
            )} topology, which is the expected topology for this dataset.`
            const observation = checks.topology
              ? `The topology detected was ${humanizeTopology(
                  checks.topology
                )}.`
              : 'The topology could not be detected.'
            issues.push({
              title: 'Incorrect topology',
              explanation: `${error} ${observation}`,
            })
          }

          const predictedUnits =
            datasetConstraints.bodyPart === BodyPartType.Body
              ? checks.predictedBodyUnits
              : datasetConstraints.bodyPart === BodyPartType.Hand
              ? checks.predictedHandUnits
              : null
          if (
            datasetConstraints.units &&
            datasetConstraints.units !== predictedUnits
          ) {
            const error = `The geometry uploaded does not appear to be scaled in ${unabbreviateUnits(
              datasetConstraints.units
            )}, which are the expected units for this dataset.`
            const observation = predictedUnits
              ? `It appears to be scaled in ${unabbreviateUnits(
                  predictedUnits
                )}.`
              : 'The scale could not be detected.'
            issues.push({
              title: 'Incorrect scale',
              explanation: `${error} ${observation}`,
            })
          }
          if (checks.genderIsValid === false) {
            issues.push({
              title: 'Conflicting gender',
              explanation:
                'The subject was added to the dataset under a different gender.',
            })
          }

          if (checks.alreadyUploaded) {
            issues.push({
              title: 'Already imported',
              explanation:
                'A geometry was already added for this subject and pose.',
            })
          }
        }

        let state: CheckState
        let summary: string
        if (checks.isValidObj === null) {
          state = CheckState.CheckPending
          summary = 'Checking…'
        } else if (issues.length === 1) {
          state = CheckState.HasIssues
          summary = issues[0].title
        } else if (issues.length > 1) {
          state = CheckState.HasIssues
          summary = 'Multiple issues'
        } else if (checks.texture && checks.texture !== '(none)') {
          state = CheckState.Ready
          summary = 'Texture Ready'
        } else {
          state = CheckState.Ready
          summary = 'Ready'
        }

        return {
          type,
          ...commonProperties,
          checks: { ...checks, issues, state, summary },
          textureOptions,
        }
      } else {
        // Represent "no checks found" with a non-null placeholder because
        // ag-grid's custom tooltip does not support three-valued logic, which is
        // to say, null/undefined values are ignored, and no tooltip is generated.
        return {
          type,
          ...commonProperties,
          checks: {
            state: CheckState.CheckPending,
            issues: [],
            summary: 'Checking…',
            isValidObj: null,
            topology: null,
            predictedBodyUnits: null,
            predictedHandUnits: null,
            genderIsValid: null,
            alreadyUploaded: null,
          },
          textureOptions,
        }
      }
    }
  })
  return {
    ...datasetConstraints,
    datasetName,
    files,
    poseChoices,
  }
}

// The S3 API returns the ETag surrounded by quotes, so we slice them off here.
export function normalizeETag(eTag: string): string {
  return eTag.replace(/"/g, '')
}

export function matches(
  first: { Key: string; ETag: string },
  second: { Key: string; ETag: string }
): boolean {
  return (
    Boolean(first.Key) &&
    Boolean(first.ETag) &&
    Boolean(second.Key) &&
    Boolean(second.ETag) &&
    first.Key === second.Key &&
    normalizeETag(first.ETag) === normalizeETag(second.ETag)
  )
}
