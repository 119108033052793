import { gql, useQuery } from '@apollo/client'
import {
  Button,
  TABLE_BACKGROUND,
  TABLE_BACKGROUND_SELECTED,
} from '@unpublished/common-components'
import { checkMatch, EXTRACT_SUBJECT_ID_REGEXES } from '@unpublished/victorinox'
import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import {
  Breadcrumb,
  ErrorText,
  RightAlignedButton,
} from '../common/common-components'
import { uniqueValues } from '../common/data-transforms'
import {
  ViewUploadContentsAndDatabaseNameQuery,
  ViewUploadContentsAndDatabaseNameQueryVariables,
} from '../common/generated'
import { useNumericParam } from '../common/use-numeric-param'
import { matches } from '../import/view-model'
import { useUploadBucketProperties } from './local-storage-state'

const FixedWidthPageContainer = styled.div`
  width: 950px;
  height: 100vh;
  margin: 32px;
`
const GeometriesHeader = styled.div`
  color: #b3b3b3;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`

const AssignSubjectIDHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`

const BucketListContainer = styled.div`
  background: #f5f5f5;
  border: 1px solid #cccccc;
  padding: 12px;
  margin: 0px 22px 22px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-height: 200px;
  overflow: scroll;
  div {
    width: 33%;
    padding: 10px;
    color: gray;
  }
`
const AssignSubjectIDContainer = styled.div`
  width: calc(100% + 22px);
  display: flex;
`

const ParsedContainer = styled.div<DivProps>`
  border: 1px solid #000000;
  padding: 12px;
  margin: 0px 22px 22px 0px;
  h3 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 5px;
  }
  p {
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    margin: 5px;
  }
  ${({ selected }) =>
    selected ? TABLE_BACKGROUND_SELECTED : TABLE_BACKGROUND};
`

const SuggestedContentContainer = styled(ParsedContainer)`
  width: 30%;
  ul {
    padding-top: 35.5px;
  }
`
const CustomContentContainer = styled(ParsedContainer)`
  width: 40%;
  input {
    display: block;
    margin: auto;
    font-size: 14px;
  }
`
const SimpleSelect = styled.select`
  font-size: 14px;
  font-weight: 500;
`
const InteriorDiv = styled.div`
  overflow: scroll;
  max-height: 300px;
  width: 100%;
  ul {
    list-style-type: none;
    padding: 5px;
  }
`

const ValidatedInput = styled.input<InputProps>`
  border: ${({ invalid }) => (invalid ? '2px solid red' : '')};
`

type RegexKey = 'baseFilename' | 'firstDirectory' | 'lastDirectory'
type ParsingOptions = 'directory' | 'filename' | 'custom'

interface DivProps {
  selected: boolean
}

interface InputProps {
  invalid: boolean
}

function parseFilenames(
  data: string[] | undefined,
  regex: RegExp
): { matches: string[]; hasExtraCaptureGroups: boolean } {
  if (!data) {
    // We actually don't know if there are extra capture groups, so[] assume not.
    return { matches: [], hasExtraCaptureGroups: false }
  }
  const matchResults = data.map(key => checkMatch(key, regex))
  return {
    matches: matchResults
      .map(({ match }) => match)
      .filter(match => match !== undefined) as string[],
    hasExtraCaptureGroups: matchResults.some(
      ({ hasExtraCaptureGroups }) => hasExtraCaptureGroups
    ),
  }
}

function DirectorySelector({
  state,
  setState,
}: {
  state: RegexKey
  setState: (value: RegexKey) => void
}): JSX.Element {
  return (
    <SimpleSelect
      onChange={e => setState(e.target.value as RegexKey)}
      value={state}
    >
      <option value="firstDirectory">First</option>
      <option value="lastDirectory">Last</option>
    </SimpleSelect>
  )
}

export function AutoAssignSubjectID(): JSX.Element {
  const selectedDataset = useNumericParam('selectedDataset')
  const navigate = useNavigate()
  const { loading, error, data } = useQuery<
    ViewUploadContentsAndDatabaseNameQuery,
    ViewUploadContentsAndDatabaseNameQueryVariables
  >(
    gql`
      query ViewUploadContentsAndDatabaseName($id: Int!) {
        datasetById(id: $id) {
          name
        }
        uploadBucketList {
          Contents {
            Key
            LastModified
            ETag
          }
        }
      }
    `,
    { variables: { id: selectedDataset } }
  )

  const [directoryPosition, setDirectoryPosition] =
    useLocalStorageState<RegexKey>('autoassignIDs:directoryposition', {
      defaultValue: 'firstDirectory',
    })
  const [regexString, setRegexString] = useLocalStorageState(
    'autoassignIDs:regexString',
    { defaultValue: '^([^/]+)/' }
  )
  const [compiledRegex, setCompiledRegex] = useState(() => {
    try {
      return new RegExp(regexString)
    } catch {
      return undefined
    }
  })
  const [selectedParsing, setSelectedParsing] =
    useLocalStorageState<ParsingOptions>('autoassignIDs:selectedParsing', {
      defaultValue: 'directory',
    })

  const [serializedSavedProperties, setSerializedSavedProperties] =
    useUploadBucketProperties()

  function selectedRegex(selectedParsing: ParsingOptions): RegExp | undefined {
    switch (selectedParsing) {
      case 'directory':
        return EXTRACT_SUBJECT_ID_REGEXES[directoryPosition]
      case 'filename':
        return EXTRACT_SUBJECT_ID_REGEXES.baseFilename
      case 'custom':
        return compiledRegex
      default:
        return undefined
    }
  }

  function handleRegexInput(input: string): void {
    try {
      const regexInput = new RegExp(input)
      setCompiledRegex(regexInput)
      setRegexString(input)
    } catch (e) {
      if (e instanceof SyntaxError) {
        setCompiledRegex(undefined)
        setRegexString(input)
      } else {
        throw e
      }
    }
  }

  const directoryMatches = useMemo(
    () =>
      parseFilenames(
        data?.uploadBucketList?.Contents.map(item => item.Key),
        EXTRACT_SUBJECT_ID_REGEXES[directoryPosition]
      ).matches,
    [directoryPosition, data?.uploadBucketList?.Contents]
  )

  const filenameMatches = useMemo(
    () =>
      parseFilenames(
        data?.uploadBucketList?.Contents.map(item => item.Key),
        EXTRACT_SUBJECT_ID_REGEXES.baseFilename
      ).matches,
    [data?.uploadBucketList?.Contents]
  )
  const customMatches = useMemo(
    () =>
      compiledRegex
        ? parseFilenames(
            data?.uploadBucketList?.Contents.map(item => item.Key),
            compiledRegex
          )
        : { matches: [], hasExtraCaptureGroups: false },
    [compiledRegex, data?.uploadBucketList?.Contents]
  )

  const matchesForSelectedParsing =
    {
      directory: directoryMatches,
      filename: filenameMatches,
      custom: customMatches.matches,
    }[selectedParsing] ?? []

  function performAssignSubjectIds(): void {
    if (!data?.uploadBucketList?.Contents) {
      throw Error('Bucket contents is required')
    }

    const regex = selectedRegex(selectedParsing)
    if (!regex) {
      throw Error('Regex is required')
    }

    const newProperties = [
      ...serializedSavedProperties.map(item => ({ ...item })),
    ]

    data.uploadBucketList.Contents.forEach(uploadedGeometry => {
      const subjectId = checkMatch(uploadedGeometry.Key, regex)?.match

      const existing = newProperties.find(p => matches(p, uploadedGeometry))
      if (existing) {
        existing.subjectId = subjectId
      } else {
        const { Key, ETag } = uploadedGeometry
        newProperties.push({ Key, ETag, subjectId })
      }
    })

    setSerializedSavedProperties(newProperties)

    navigate(`/import/${selectedDataset}/set-properties`)
  }

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to={`/import/${selectedDataset}/set-properties`}>Import</Link>{' '}
        {'>'} Assign Subject IDs
      </Breadcrumb>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {data?.uploadBucketList && (
        <div>
          <h1>Importing to {data.datasetById.name}</h1>
          <GeometriesHeader>
            {data.uploadBucketList.Contents.length} geometries
          </GeometriesHeader>
          <BucketListContainer>
            {data.uploadBucketList.Contents.map(item => (
              <div key={item.Key}>{item.Key}</div>
            ))}
          </BucketListContainer>
          <AssignSubjectIDHeader>Assign Subject IDs</AssignSubjectIDHeader>
          <AssignSubjectIDContainer>
            <SuggestedContentContainer
              id={'directory'}
              selected={selectedParsing === 'directory'}
              onClick={e =>
                setSelectedParsing(e.currentTarget.id as ParsingOptions)
              }
            >
              <h3>
                Use{' '}
                <DirectorySelector
                  state={directoryPosition}
                  setState={setDirectoryPosition}
                />{' '}
                Directory Name
              </h3>
              <p>
                Matched {directoryMatches.length} of{' '}
                {data.uploadBucketList.Contents.length} geometries
              </p>
              <p>{uniqueValues(directoryMatches).length} unique subject IDs</p>
              <InteriorDiv>
                <ul>
                  {directoryMatches.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </InteriorDiv>
            </SuggestedContentContainer>
            <SuggestedContentContainer
              id={'filename'}
              selected={selectedParsing === 'filename'}
              onClick={e =>
                setSelectedParsing(e.currentTarget.id as ParsingOptions)
              }
            >
              <h3>Use Filename</h3>
              <p>
                Matched {filenameMatches.length} of{' '}
                {data.uploadBucketList.Contents.length} geometries
              </p>
              <p>{uniqueValues(filenameMatches).length} unique subject IDs</p>
              <InteriorDiv>
                <ul>
                  {filenameMatches.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </InteriorDiv>
            </SuggestedContentContainer>
            <CustomContentContainer
              id={'custom'}
              selected={selectedParsing === 'custom'}
              onClick={e =>
                setSelectedParsing(e.currentTarget.id as ParsingOptions)
              }
            >
              <h3>Use custom Regular expression</h3>
              <ValidatedInput
                value={regexString}
                onChange={e => handleRegexInput(e.target.value)}
                invalid={
                  compiledRegex === undefined ||
                  customMatches.hasExtraCaptureGroups
                }
              />
              <p>
                Matched {customMatches.matches.length} of{' '}
                {data.uploadBucketList.Contents.length} geometries
              </p>
              <p>
                {uniqueValues(customMatches.matches).length} unique subject IDs
              </p>
              {customMatches.hasExtraCaptureGroups && (
                <ErrorText>
                  Regex contains more than one capture group. At most one
                  capture group is expected and only the first one will be used.
                </ErrorText>
              )}
              <InteriorDiv>
                <ul>
                  {customMatches.matches.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </InteriorDiv>
            </CustomContentContainer>
          </AssignSubjectIDContainer>
          <div>
            <Button onClick={() => navigate(`/`)}>Cancel</Button>
            <RightAlignedButton
              disabled={matchesForSelectedParsing.length === 0}
              onClick={performAssignSubjectIds}
            >
              Assign All
            </RightAlignedButton>
            <RightAlignedButton
              onClick={() =>
                navigate(`/import/${selectedDataset}/set-properties`)
              }
            >
              Skip for now
            </RightAlignedButton>
          </div>
        </div>
      )}
    </FixedWidthPageContainer>
  )
}
