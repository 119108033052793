import { gql } from '@apollo/client'
import { groupBy, mapValues } from 'lodash'

import {
  ViewMeasurementStudyIterationQuery,
  ViewMeasurementStudyQuery,
} from '../../common/generated'
import { hashKeyForLabel } from '../labels'
import { MeasurerVersion, transformMeasurerVersion } from '../measurer-version'

export const VIEW_MEASUREMENT_STUDY_QUERY = gql`
  query ViewMeasurementStudy($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      id
      name
      isArchived
      commitByHeadCommitId {
        created
      }
      reviewStateCounts: measurementInvocationReviewStateCountsByMeasurementStudyId(
        orderBy: MEASUREMENT_NAME_ASC
      ) {
        nodes {
          measurementName
          measurementInvocationIteration
          gender
          errorCount
          failureCount
          needReviewCount
          newCount
          startedCount
          successCount
        }
      }
      labelCounts: measurementStudyLatestLabelCountsByMeasurementStudyId {
        nodes {
          gender
          measurementName
          labelCount
          isFailure
          name: labelName
        }
      }
    }
  }
`

export const VIEW_MEASUREMENT_STUDY_ITERATION_QUERY = gql`
  query ViewMeasurementStudyIteration($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      id
      iterations: measurementInvocationIterationsByMeasurementStudyId(
        orderBy: MEASUREMENT_INVOCATION_ITERATION_DESC
      ) {
        nodes {
          measurementInvocationIteration
          commitByMeasurementInvocationCommitId {
            created
            measurerVersionByMeasurerVersionId {
              measurerByMeasurerId {
                name
                functionName
                repoSlug
              }
              sha1
              tagName
              commitDate
              commitMessage
            }
          }
        }
      }
    }
  }
`

interface ReviewStateCounts {
  newCount: number
  startedCount: number
  errorCount: number
  needReviewCount: number
  successCount: number
  failureCount: number
}

export function createViewModel({
  data,
  iterationData,
  selectedGenders,
}: {
  data?: ViewMeasurementStudyQuery
  iterationData?: ViewMeasurementStudyIterationQuery
  selectedGenders: Set<string>
}): {
  name?: string
  lastModified: any
  isArchived?: boolean
  measurements: {
    measurementName: string
    measurementInvocationIteration: number
    reviewStateCounts: ReviewStateCounts
    labels: {
      name: string | null
      isFailure: boolean
      labelCount: number
    }[]
  }[]
  iterations: {
    measurementInvocationIteration: number
    created: Date
    measurerName: string
    measurerVersion: MeasurerVersion
  }[]
} {
  function genderMatches({ gender }: { gender: string | null }): boolean {
    return !gender || selectedGenders.has(gender)
  }

  // Filter by gender, group by measurement name, group by unique label, and
  // then (potentially) summarize across genders.
  const labelCountsGroupedByMeasurement = mapValues(
    groupBy(
      data?.measurementStudyById.labelCounts.nodes.filter(genderMatches),
      'measurementName'
    ),
    labelCountsForMeasurement => {
      const countsGroupedByLabel = groupBy(
        labelCountsForMeasurement,
        hashKeyForLabel
      )
      return Object.values(countsGroupedByLabel).map(countsForLabel => {
        const [first, ...rest] =
          countsForLabel as ViewMeasurementStudyQuery['measurementStudyById']['labelCounts']['nodes']
        return rest.reduce(
          (accum, current) => ({
            ...current,
            labelCount: (accum.labelCount ?? 0) + (current.labelCount ?? 0),
          }),
          first as ViewMeasurementStudyQuery['measurementStudyById']['labelCounts']['nodes'][0]
        ) as ViewMeasurementStudyQuery['measurementStudyById']['labelCounts']['nodes'][0]
      })
    }
  ) as {
    [measurementName: string]: {
      name: string | null
      isFailure: boolean
      labelCount: number
    }[]
  }

  // Filter by gender, group by measurment name, then (potentially) summarize
  // across genders.
  const measurements = Object.entries(
    groupBy(
      data?.measurementStudyById.reviewStateCounts.nodes.filter(genderMatches),
      'measurementName'
    )
  ).map(([measurementName, countsForMeasurement]) => {
    const reviewStateCounts = countsForMeasurement.reduce(
      (accum, current) => ({
        newCount: accum.newCount + (current.newCount ?? 0),
        startedCount: accum.startedCount + (current.startedCount ?? 0),
        errorCount: accum.errorCount + (current.errorCount ?? 0),
        needReviewCount: accum.needReviewCount + (current.needReviewCount ?? 0),
        successCount: accum.successCount + (current.successCount ?? 0),
        failureCount: accum.failureCount + (current.failureCount ?? 0),
      }),
      {
        newCount: 0,
        startedCount: 0,
        errorCount: 0,
        needReviewCount: 0,
        successCount: 0,
        failureCount: 0,
      }
    )

    return {
      measurementName,
      measurementInvocationIteration: countsForMeasurement[0]
        .measurementInvocationIteration as number,
      reviewStateCounts,
      labels: labelCountsGroupedByMeasurement[measurementName] ?? [],
    }
  })

  const iterations =
    iterationData?.measurementStudyById.iterations.nodes.map(iteration => {
      const { measurementInvocationIteration } = iteration
      if (measurementInvocationIteration === null) {
        throw Error('measurementInvocationIteration should not be null')
      }

      const measurerVersion =
        iteration.commitByMeasurementInvocationCommitId
          .measurerVersionByMeasurerVersionId

      return {
        measurementInvocationIteration,
        created: iteration.commitByMeasurementInvocationCommitId.created,
        measurerName: measurerVersion.measurerByMeasurerId.name,
        measurerVersion: transformMeasurerVersion(measurerVersion),
      }
    }) ?? []

  return {
    name: data?.measurementStudyById?.name,
    isArchived: data?.measurementStudyById?.isArchived,
    lastModified: data?.measurementStudyById?.commitByHeadCommitId?.created,
    measurements,
    iterations,
  }
}
