import { gql, useMutation, useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  RightAlignedButton,
} from '../common/common-components'
import { humanizeTopology, mappedAxes } from '../common/data-transforms'
import { BasicContainer } from '../common/flex-table'
import {
  AxisType,
  BodyPartType,
  CreateDatasetMutation,
  CreateDatasetMutationVariables,
  DatasetVarTypesQuery,
  TopologyType,
  UnitsType,
} from '../common/generated'
import { LIST_DATASETS_QUERY } from './choose-dataset'

const Container = styled(BasicContainer)`
  width: 699px;
`
const Form = styled.form`
  padding-left: 120px;
  padding-bottom: 100px;
  padding-top: 50px;
  width: 550px;
  display: table;
  font-size: 16px;
  div {
    display: table-row;
  }
  label,
  input,
  select {
    display: table-cell;
    margin: 10px;
    height: 25px;
    min-width: 150px;
  }
`
const BottomAlignedError = styled.text`
  float: bottom;
  color: red;
  position: relative;
  bottom: 20px;
  margin: 20px;
`
const axisKeys = Object.keys(mappedAxes).map(key => ({
  name: key,
}))

interface State {
  name: string
  bodyPart?: BodyPartType
  topology?: TopologyType | 'no-topology'
  units?: UnitsType
  shouldCreate: boolean
  isCreating: boolean
  superiorAxis?: AxisType
  anteriorAxis?: AxisType
}

type Action =
  | {
      type: 'setValue'
      name: keyof State
      value: string
    }
  | { type: 'create' }
  | { type: 'creating' }
  | { type: 'createFailed' }

const initialFormState: State = {
  name: '',
  shouldCreate: false,
  isCreating: false,
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setValue':
      let updatedState = { ...state, [action.name]: action.value }
      if (action.name === 'bodyPart' && action.value === BodyPartType.Hand) {
        updatedState = {
          ...updatedState,
          topology: 'no-topology',
          superiorAxis: undefined,
          anteriorAxis: undefined,
        }
      }
      return updatedState
    case 'create':
      return { ...state, shouldCreate: true }
    case 'creating':
      return { ...state, shouldCreate: false, isCreating: true }
    case 'createFailed':
      return { ...state, shouldCreate: false }
    default:
      return state
  }
}

export function NewDataset(): JSX.Element {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery<DatasetVarTypesQuery>(
    gql`
      query DatasetVarTypes {
        bodyPartType: __type(name: "BodyPartType") {
          name
          description
          enumValues {
            name
            description
          }
        }
        unitsType: __type(name: "UnitsType") {
          name
          description
          enumValues {
            name
            description
          }
        }
        TopologyType: __type(name: "TopologyType") {
          name
          description
          enumValues {
            name
            description
          }
        }
      }
    `
  )

  function createSelect({
    selectOptions,
    name,
    disabled,
  }: {
    selectOptions: { name: string }[] | null | undefined
    name: keyof State
    disabled?: boolean
  }): JSX.Element {
    if (selectOptions === null || selectOptions === undefined) {
      throw Error('type should exist')
    } else {
      return (
        <select
          disabled={disabled}
          placeholder=""
          required
          id={name}
          value={
            state[name] === undefined
              ? 'placeholderForEmptyValue'
              : state[name]?.toString()
          }
          onChange={e =>
            dispatch({
              type: 'setValue',
              name,
              value: e.currentTarget.value,
            })
          }
        >
          <option value="placeholderForEmptyValue" disabled hidden />
          {selectOptions.map(item => (
            <option value={item.name} key={item.name}>
              {item.name.toLowerCase()}
            </option>
          ))}
        </select>
      )
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialFormState)
  const validatedState = state as Required<State>

  function isValid(formState: State): formState is Required<State> {
    const universallyRequiredAttributes =
      formState.name !== '' &&
      formState.units !== undefined &&
      formState.bodyPart !== undefined
    if (!universallyRequiredAttributes) {
      return false
    }
    switch (formState.bodyPart) {
      case BodyPartType.Hand:
        return (
          (formState.topology === 'no-topology' ||
            formState.topology === undefined) &&
          formState.anteriorAxis === undefined &&
          formState.superiorAxis === undefined
        )
      default:
        return (
          formState.topology !== undefined &&
          formState.anteriorAxis !== undefined &&
          formState.superiorAxis !== undefined
        )
    }
  }

  const [createDataset, { error: createDatabaseError }] = useMutation<
    CreateDatasetMutation,
    CreateDatasetMutationVariables
  >(
    gql`
      mutation CreateDataset($input: CreateDatasetInput!) {
        createDataset(input: $input) {
          dataset {
            id
          }
        }
      }
    `,
    {
      variables: {
        input: {
          dataset: {
            name: validatedState.name.trim(),
            units: validatedState.units,
            bodyPart: validatedState.bodyPart,
            superiorDirection: mappedAxes[validatedState.superiorAxis],
            anteriorDirection: mappedAxes[validatedState.anteriorAxis],
            activeTopology:
              validatedState.topology === 'no-topology'
                ? null
                : validatedState.topology,
          },
        },
      },
      refetchQueries: [LIST_DATASETS_QUERY],
      onCompleted(data: CreateDatasetMutation) {
        navigate(`/import/${data.createDataset?.dataset?.id}`)
      },
      onError(error) {
        dispatch({ type: 'createFailed' })
      },
    }
  )

  useEffect(() => {
    if (state.shouldCreate) {
      dispatch({ type: 'creating' })
      createDataset()
    }
  }, [state.shouldCreate, createDataset])

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} <Link to="/import">Import</Link> {'>'}{' '}
        New Dataset
      </Breadcrumb>
      <h1>Import</h1>
      <h2>New dataset</h2>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {data && (
        <Container>
          <Form>
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Name of dataset"
                required
                value={state.name}
                onChange={e =>
                  dispatch({
                    type: 'setValue',
                    name: 'name',
                    value: e.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="select-group">
              <label htmlFor="bodyPart">Body Part</label>
              {createSelect({
                selectOptions: data.bodyPartType?.enumValues,
                name: 'bodyPart',
              })}
            </div>
            <div className="select-group">
              <label htmlFor="topology">Topology</label>
              <select
                disabled={state.bodyPart === BodyPartType.Hand}
                placeholder=""
                required
                value={
                  state.topology === undefined
                    ? 'placeholderForEmptyValue'
                    : state.topology
                }
                onChange={e =>
                  dispatch({
                    type: 'setValue',
                    name: 'topology',
                    value: e.currentTarget.value,
                  })
                }
              >
                <option value="placeholderForEmptyValue" disabled hidden />
                <option value={'no-topology'}>None</option>
                {data.TopologyType?.enumValues?.map(item => (
                  <option value={item.name} key={item.name}>
                    {humanizeTopology(item.name)}
                  </option>
                ))}
              </select>
            </div>
            <div className="select-group">
              <label htmlFor="units">Units</label>
              {createSelect({
                selectOptions: data.unitsType?.enumValues,
                name: 'units',
              })}
            </div>
            <div className="select-group">
              <label htmlFor="superiorAxis">Proximal (up) direction</label>
              {createSelect({
                selectOptions: axisKeys,
                name: 'superiorAxis',
                disabled: state.bodyPart === BodyPartType.Hand,
              })}
            </div>
            <div className="select-group">
              <label htmlFor="anteriorAxis">Anterior (look) direction</label>
              {createSelect({
                selectOptions: axisKeys,
                name: 'anteriorAxis',
                disabled: state.bodyPart === BodyPartType.Hand,
              })}
            </div>
          </Form>
          <BottomAlignedError>
            {createDatabaseError && `Error: ${createDatabaseError}`}
          </BottomAlignedError>
        </Container>
      )}
      <FixedWidthFooterNavContainer>
        <Button onClick={() => navigate('/import')}>Back</Button>
        <span />
        <RightAlignedButton
          disabled={!isValid(state)}
          onClick={() => dispatch({ type: 'create' })}
        >
          Create Dataset
        </RightAlignedButton>
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
