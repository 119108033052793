import { addCoords, divideCoordsByScalar, multiplyCoordsByScalar, Coords, } from './coords';
import { Segment } from './segment';
import { Point } from './point';
/* Represent a polygonal chain in 3-space. */
export class Polyline {
    constructor({ vertices, isClosed, }) {
        if (vertices.length < 1) {
            throw Error('A polyline needs at least one vertex');
        }
        this.vertices = vertices;
        this.isClosed = isClosed;
    }
    static fromData({ vertices, isClosed }) {
        return new this({
            vertices: vertices.map(coords => new Point(coords)),
            isClosed,
        });
    }
    static fromLegacyData({ vertices, is_closed: isClosed, }) {
        return this.fromData({ vertices, isClosed });
    }
    get edges() {
        const openEdges = Array.from(Array(this.vertices.length - 1).keys()).map(first => [first, first + 1]);
        if (this.isClosed) {
            return openEdges.concat([[this.vertices.length - 1, 0]]);
        }
        else {
            return openEdges;
        }
    }
    get length() {
        return this.edges.reduce((accum, [firstIndex, secondIndex]) => accum +
            this.vertices[firstIndex].euclideanDistance(this.vertices[secondIndex]), 0);
    }
    get segments() {
        return this.edges.map(([firstIndex, secondIndex]) => new Segment(this.vertices[firstIndex], this.vertices[secondIndex]));
    }
    get pathCentroid() {
        if (this.vertices.length === 1) {
            return this.vertices[0];
        }
        // Computed the mean of the segment centroids, weighted by their length.
        const { sum, totalWeight } = this.segments.reduce(({ sum, totalWeight }, thisSegment) => ({
            sum: addCoords(sum, multiplyCoordsByScalar(thisSegment.midpoint, thisSegment.length, Coords), Coords),
            totalWeight: totalWeight + length,
        }), { sum: new Coords([0, 0, 0]), totalWeight: 0 });
        return divideCoordsByScalar(sum, totalWeight, Point);
    }
    nearest(toPoint) {
        if (this.vertices.length === 1) {
            return this.vertices[0];
        }
        return this.segments.reduce(({ resultPoint, resultDistanceSquared }, thisSegment) => {
            const thisNearest = thisSegment.nearest(toPoint);
            const thisDistanceSquared = thisNearest.euclideanDistanceSquared(toPoint);
            if (thisDistanceSquared < resultDistanceSquared) {
                return {
                    resultPoint: thisNearest,
                    resultDistanceSquared: thisDistanceSquared,
                };
            }
            else {
                return { resultPoint, resultDistanceSquared };
            }
        }, { resultPoint: Point.origin, resultDistanceSquared: Infinity }).resultPoint;
    }
}
