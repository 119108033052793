import { Coords, addCoords, subtractCoords } from './coords';
import { Vector } from './vector';
/* Represent a point in 3-space. */
export class Point extends Coords {
    subtractPoint(otherPoint) {
        return subtractCoords(this, otherPoint, Vector);
    }
    addVector(vector) {
        return addCoords(this, vector, Point);
    }
    subtractVector(vector) {
        return subtractCoords(this, vector, Point);
    }
    euclideanDistanceSquared(otherPoint) {
        const [x1, y1, z1] = this.coords;
        const [x2, y2, z2] = otherPoint.coords;
        return Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2) + Math.pow((z2 - z1), 2);
    }
    euclideanDistance(otherPoint) {
        return Math.sqrt(this.euclideanDistanceSquared(otherPoint));
    }
}
Point.origin = new Point([0, 0, 0]);
