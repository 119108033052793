export function setUnion<T>(first: Set<T>, second: Set<T>): Set<T> {
  const union = new Set<T>(first)
  second.forEach(elem => union.add(elem))
  return union
}

export function setDifference<T>(first: Set<T>, second: Set<T>): Set<T> {
  let difference = new Set(first)
  second.forEach(elem => difference.delete(elem))
  return difference
}

export function setsAreEqual<T>(mySet1: Set<T>, mySet2: Set<T>): boolean {
  return (
    setDifference(mySet1, mySet2).size === 0 &&
    setDifference(mySet2, mySet1).size === 0
  )
}
