import { Nudging, useNudge, usePick } from '@unpublished/scene'
import { Vector3 } from 'polliwog-types'
import { Dispatch } from 'react'

import { AnnotationDeltaId, PickedPointAction } from './picked-point-reducer'

export function usePoints<T extends AnnotationDeltaId>({
  currentPoint,
  nudging,
  selectedAnnotationId,
  dispatch,
}: {
  currentPoint?: Vector3
  nudging?: Nudging
  selectedAnnotationId?: T
  dispatch: Dispatch<PickedPointAction<T>>
}): {
  onMouseMove: (event: MouseEvent) => void
  onDoubleClick: (event: MouseEvent) => void
  lastHoveredPointOnMesh?: Vector3
} {
  function dispatchUpdatePoint(point: Vector3): void {
    if (selectedAnnotationId === undefined) {
      throw Error('selected annotationID should be set')
    } else {
      dispatch({
        type: 'update-delta',
        id: selectedAnnotationId,
        point,
      })
    }
  }
  const { onMouseMove, onDoubleClick, lastHoveredPointOnMesh } = usePick({
    isEnabled: true,
    didPickPoint: dispatchUpdatePoint,
  })
  useNudge({
    currentPoint,
    state: nudging,
    updatePoint: dispatchUpdatePoint,
  })

  return { onMouseMove, onDoubleClick, lastHoveredPointOnMesh }
}
