import { ApolloError, gql, useQuery } from '@apollo/client'
import { preloadDefaultAssets, useObjLoader } from '@unpublished/scene'
import { setsAreEqual } from '@unpublished/victorinox'
import { Polyline } from 'hyla'
import { useEffect, useState } from 'react'
import * as THREE from 'three'

import {
  ReviewMeasurementsMeasurementInvocationCurveQuery,
  ReviewMeasurementsMeasurementInvocationCurveQueryVariables,
} from '../../common/generated'
import { useMemoizedPolyline } from '../../common/use-memoized-polyline'
import { GeometryViewModel } from './view-model'

const REVIEW_MEASUREMENTS_MEASUREMENT_INVOCATION_CURVE_QUERY = gql`
  query ReviewMeasurementsMeasurementInvocationCurve(
    $measurementInvocationId: Int!
  ) {
    measurementInvocationById(id: $measurementInvocationId) {
      id
      curveByCurveId {
        id
        tapeWidth
        isClosed
        vertices
      }
    }
  }
`

function useOptimisticLoading({
  allMeasurementInvocationIds,
  enabled,
}: {
  allMeasurementInvocationIds: number[]
  enabled: boolean
}): void {
  const [
    iterationsWeHaveOptimisticallyLoaded,
    setIterationsWeHaveOptimisticallyLoaded,
  ] = useState<Set<number>>(new Set<number>())

  // initialize the state - the current iteration
  // then step through the iteration and load the curves sequentially

  const [
    previousSetOfAllMeasurementInvocationIds,
    setPreviousSetOfAllMeasurementInvocationIds,
  ] = useState<Set<number>>(new Set<number>())

  const [iterationToOptimisticallyLoad, setIterationToOptimisticallyLoad] =
    useState<number>()

  // initialize the first measurement invocation to load
  useEffect(() => {
    const setOfAllMeasurementInvocationIds = new Set(
      allMeasurementInvocationIds
    )
    if (
      allMeasurementInvocationIds &&
      allMeasurementInvocationIds.length &&
      !setsAreEqual(
        setOfAllMeasurementInvocationIds,
        previousSetOfAllMeasurementInvocationIds
      )
    ) {
      setPreviousSetOfAllMeasurementInvocationIds(
        setOfAllMeasurementInvocationIds
      )
      setIterationToOptimisticallyLoad(allMeasurementInvocationIds[0])
    }
  }, [allMeasurementInvocationIds, previousSetOfAllMeasurementInvocationIds])

  function optimisticallyLoadNextIterationCurves(): void {
    if (iterationToOptimisticallyLoad === undefined) return

    // add the iteration we just loaded to the set
    const newSet = new Set(iterationsWeHaveOptimisticallyLoaded)
    newSet.add(iterationToOptimisticallyLoad)
    setIterationsWeHaveOptimisticallyLoaded(newSet)

    // choose the next iteration
    let nextIterationIndex =
      allMeasurementInvocationIds.indexOf(iterationToOptimisticallyLoad) + 1
    while (nextIterationIndex < allMeasurementInvocationIds.length) {
      if (
        !iterationsWeHaveOptimisticallyLoaded.has(
          allMeasurementInvocationIds[nextIterationIndex]
        )
      ) {
        setIterationToOptimisticallyLoad(
          allMeasurementInvocationIds[nextIterationIndex]
        )
        break
      } else {
        nextIterationIndex++
      }
    }
  }

  // ignore the query response, as this is just to load optimistically and cache the data
  useQuery<
    ReviewMeasurementsMeasurementInvocationCurveQuery,
    ReviewMeasurementsMeasurementInvocationCurveQueryVariables
  >(REVIEW_MEASUREMENTS_MEASUREMENT_INVOCATION_CURVE_QUERY, {
    variables: {
      measurementInvocationId: iterationToOptimisticallyLoad as number,
    },
    // this will wait until the selected measurement invocation is loaded
    // and we have initialized the curves to load
    skip: !enabled || iterationToOptimisticallyLoad === undefined,
    onCompleted: optimisticallyLoadNextIterationCurves,
    // on error, ignore the error and cease optimistically loading
  })
}

export interface LoadedGeometry {
  curveData:
    | ReviewMeasurementsMeasurementInvocationCurveQuery['measurementInvocationById']['curveByCurveId']
    | null
  curve?: Polyline
  curveLoadingError?: ApolloError
  body?: THREE.BufferGeometry
  bodyLoadingErrorMessage?: string
}

export function useGeometryLoading({
  selectedMeasurementInvocationId,
  compareToMeasurementInvocationId,
  selectedGeometry,
  compareToGeometry,
  shouldLoadBody,
  allMeasurementInvocationIds,
  enableOptimisticLoading,
}: {
  selectedMeasurementInvocationId?: number
  compareToMeasurementInvocationId?: number
  selectedGeometry?: GeometryViewModel
  compareToGeometry?: GeometryViewModel
  shouldLoadBody: boolean
  allMeasurementInvocationIds: number[]
  enableOptimisticLoading: boolean
}): {
  selectionGeometry: LoadedGeometry
  compareToGeometry: LoadedGeometry
  comparingToSameBody: boolean
} {
  const fetchPolicy = enableOptimisticLoading ? 'cache-first' : 'no-cache'

  const curveQuery = useQuery<
    ReviewMeasurementsMeasurementInvocationCurveQuery,
    ReviewMeasurementsMeasurementInvocationCurveQueryVariables
  >(REVIEW_MEASUREMENTS_MEASUREMENT_INVOCATION_CURVE_QUERY, {
    variables: {
      measurementInvocationId: selectedMeasurementInvocationId as number,
    },
    skip: selectedMeasurementInvocationId === undefined,
    fetchPolicy,
  })

  const compareToCurveQuery = useQuery<
    ReviewMeasurementsMeasurementInvocationCurveQuery,
    ReviewMeasurementsMeasurementInvocationCurveQueryVariables
  >(REVIEW_MEASUREMENTS_MEASUREMENT_INVOCATION_CURVE_QUERY, {
    variables: {
      measurementInvocationId: compareToMeasurementInvocationId as number,
    },
    skip: compareToMeasurementInvocationId === undefined,
    fetchPolicy,
  })

  const curve = useMemoizedPolyline(
    curveQuery.data?.measurementInvocationById.curveByCurveId
  )
  const compareToCurve = useMemoizedPolyline(
    compareToCurveQuery.data?.measurementInvocationById.curveByCurveId
  )

  const bodyLoader = useObjLoader({
    s3Key: selectedGeometry?.s3Key,
    signedURL: selectedGeometry?.signedURL,
    enabled: shouldLoadBody,
  })
  const comparingToSameBody =
    compareToGeometry?.s3Key === selectedGeometry?.s3Key
  const compareToBodyLoader = useObjLoader({
    s3Key: compareToGeometry?.s3Key,
    signedURL: compareToGeometry?.signedURL,
    enabled: shouldLoadBody && !comparingToSameBody,
  })

  useOptimisticLoading({
    allMeasurementInvocationIds,
    enabled: enableOptimisticLoading && Boolean(curveQuery.data),
  })

  useEffect(preloadDefaultAssets, [])

  return {
    selectionGeometry: {
      curveData:
        curveQuery.data?.measurementInvocationById.curveByCurveId ?? null,
      curve,
      curveLoadingError: curveQuery.error,
      body: bodyLoader.body,
      bodyLoadingErrorMessage: bodyLoader.errorMessage,
    },
    compareToGeometry: {
      curveData:
        compareToCurveQuery.data?.measurementInvocationById.curveByCurveId ??
        null,
      curve: compareToCurve,
      curveLoadingError: compareToCurveQuery.error,
      body: comparingToSameBody ? bodyLoader.body : compareToBodyLoader.body,
      bodyLoadingErrorMessage: compareToBodyLoader.errorMessage,
    },
    comparingToSameBody,
  }
}
