import { useParams } from 'react-router-dom'

// TODO: Rename to `useNumericParam()` or generalize to floats.
export function useNumericParam(
  name: string,
  { safe }: { safe?: boolean } = { safe: true }
): number {
  const params = useParams()
  const asString = params[name]
  if (asString) {
    const maybeReturn = Number.parseInt(asString)
    if (isNaN(maybeReturn)) {
      throw new Error(`Expected ${name} to parse to a number`)
    }
    return maybeReturn
  } else if (safe) {
    throw Error(
      `Expected a ${name} parameter, got ${Object.keys(params).join(', ')}`
    )
  } else {
    return undefined as unknown as number
  }
}

// TODO: Rename to `useOptionalIntegerParam()` or generalize to floats.
export function useOptionalNumericParam(name: string): number | undefined {
  const asString = useParams()[name]

  if (asString === undefined) {
    return undefined
  }

  const asNumber = Number.parseInt(asString)
  if (Number.isNaN(asNumber)) {
    return undefined
  }

  return asNumber
}
