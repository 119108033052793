import { canvasCoordsForMouseEvent } from '@unpublished/victorinox'
import { isEqual } from 'lodash'
import { Vector3 } from 'polliwog-types'
import { useState } from 'react'

import { useIntersectMainObject } from './use-intersect-main-object'

export function usePick({
  isEnabled,
  didPickPoint,
}: {
  isEnabled: boolean
  didPickPoint: (point: Vector3) => void
}): {
  onMouseMove: (event: MouseEvent) => void
  onDoubleClick: (event: MouseEvent) => void
  lastHoveredPointOnMesh?: Vector3
} {
  const intersectMainObject = useIntersectMainObject()

  const [lastHoveredPointOnMesh, setLastHoveredPointOnMesh] =
    useState<Vector3>()

  function onMouseMove(event: MouseEvent): void {
    if (!isEnabled) {
      return
    }

    const intersectionPoint = intersectMainObject(
      canvasCoordsForMouseEvent(event)
    )?.point.toArray()

    if (!isEqual(lastHoveredPointOnMesh, intersectionPoint)) {
      setLastHoveredPointOnMesh(intersectionPoint)
    }
  }

  function onDoubleClick(event: MouseEvent): void {
    if (isEnabled && lastHoveredPointOnMesh) {
      didPickPoint(lastHoveredPointOnMesh)
    }
  }

  return {
    onMouseMove,
    onDoubleClick,
    lastHoveredPointOnMesh,
  }
}
