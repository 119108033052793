import { gql } from '@apollo/client'

import {
  WhatsChangedLatestIterationQuery,
  WhatsChangedSpecificIterationQuery,
} from '../../common/generated'
import {
  MeasurementPerformance,
  PATH_DIFFERENCE_RATIO_EPSILON,
  transformMeasurementPerformance,
} from '../comparison'

export const WHATS_CHANGED_LATEST_ITERATION_QUERY = gql`
  query WhatsChangedLatestIteration(
    $measurementStudyId: Int!
    $rightMeasurementInvocationIteration: Int!
  ) {
    iterations: allLatestIterationPerformances(
      condition: {
        measurementStudyId: $measurementStudyId
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
      orderBy: MEASUREMENT_NAME_ASC
    ) {
      nodes {
        measurementName
        leftMeasurementInvocationIteration
        leftNumInvocations
        leftMedianDurationSeconds
        leftNinetiethPctDurationSeconds
        leftMaxDurationSeconds
        rightMeasurementInvocationIteration
        rightNumInvocations
        rightMedianDurationSeconds
        rightNinetiethPctDurationSeconds
        rightMaxDurationSeconds
      }
    }
    subjects: allLatestSubjectChanges(
      condition: {
        measurementStudyId: $measurementStudyId
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
    ) {
      nodes {
        measurementName
        valueDifferenceRatios
        pathDifferenceRatios
        numMissingComparisonMetrics
      }
    }
  }
`

export const WHATS_CHANGED_SPECIFIC_ITERATION_QUERY = gql`
  query WhatsChangedSpecificIteration(
    $measurementStudyId: Int!
    $leftMeasurementInvocationIteration: Int!
    $rightMeasurementInvocationIteration: Int!
  ) {
    iterations: allIterationPerformances(
      condition: {
        measurementStudyId: $measurementStudyId
        leftMeasurementInvocationIteration: $leftMeasurementInvocationIteration
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
      orderBy: MEASUREMENT_NAME_ASC
    ) {
      nodes {
        measurementName
        leftMeasurementInvocationIteration
        leftNumInvocations
        leftMedianDurationSeconds
        leftNinetiethPctDurationSeconds
        leftMaxDurationSeconds
        rightMeasurementInvocationIteration
        rightNumInvocations
        rightMedianDurationSeconds
        rightNinetiethPctDurationSeconds
        rightMaxDurationSeconds
      }
    }
    subjects: allSubjectChanges(
      condition: {
        measurementStudyId: $measurementStudyId
        leftMeasurementInvocationIteration: $leftMeasurementInvocationIteration
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
    ) {
      nodes {
        measurementName
        valueDifferenceRatios
        pathDifferenceRatios
        numMissingComparisonMetrics
      }
    }
  }
`

export function createViewModel({
  latestIterationData,
  specificIterationData,
}: {
  latestIterationData?: WhatsChangedLatestIterationQuery
  specificIterationData?: WhatsChangedSpecificIterationQuery
}): {
  measurements: {
    measurementName: string
    performance: MeasurementPerformance
    numChangedCurves: number
    pathDifferencePercents: number[]
    numMissingComparisonMetrics: number
    numValueDifferences: number
    valueDifferencePercents: number[]
  }[]
} {
  const performance = transformMeasurementPerformance(
    (latestIterationData ?? specificIterationData)?.iterations.nodes ?? []
  )
  const subjectChanges = ((latestIterationData ?? specificIterationData)
    ?.subjects.nodes ?? []) as [
    | WhatsChangedLatestIterationQuery['subjects']['nodes'][0]
    | WhatsChangedSpecificIterationQuery['subjects']['nodes'][0]
  ]

  return {
    measurements: performance.map(item => {
      const theseSubjectChanges = subjectChanges.find(
        subject => subject.measurementName === item.measurementName
      )
      const pathDifferencePercents = (
        (theseSubjectChanges?.pathDifferenceRatios ?? []) as number[]
      ).map(ratio => (ratio < PATH_DIFFERENCE_RATIO_EPSILON ? 0 : 100 * ratio))
      const valueDifferencePercents = (
        (theseSubjectChanges?.valueDifferenceRatios ?? []) as number[]
      ).map(ratio => 100 * ratio)

      return {
        measurementName: item.measurementName,
        performance: item,
        numChangedCurves: pathDifferencePercents.reduce(
          (accum, current) => accum + (current > 0 ? 1 : 0),
          0
        ),
        pathDifferencePercents,
        numMissingComparisonMetrics:
          theseSubjectChanges?.numMissingComparisonMetrics ?? 0,
        numValueDifferences: valueDifferencePercents.reduce(
          (accum, current) => accum + (current !== 0 ? 1 : 0),
          0
        ),
        valueDifferencePercents,
      }
    }),
  }
}
