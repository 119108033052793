import * as React from 'react'

function LightRing({
  count,
  startingAngle,
  y,
  totalIntensity,
  a,
  b,
}: {
  count: number
  startingAngle: number
  y: number
  totalIntensity: number
  a: number
  b: number
}): JSX.Element {
  const startingAngleRad = (startingAngle / 180.0) * Math.PI
  const angles = []
  for (let i = 0; i < count; ++i) {
    angles.push(i * ((2.0 * Math.PI) / count) + startingAngleRad)
  }
  const intensity = totalIntensity / count
  return (
    <>
      {angles.map(angle => (
        <directionalLight
          key={angle.toString()}
          name="fill"
          intensity={intensity}
          position={[a * Math.cos(angle), y, b * Math.sin(angle)]}
        />
      ))}
    </>
  )
}

export function Lighting({
  totalIntensity,
}: {
  totalIntensity: number
}): JSX.Element {
  return (
    <group>
      <ambientLight
        name="ambient"
        color={0x101010}
        intensity={0.71 * totalIntensity}
      />
      <directionalLight
        name="fill"
        intensity={0.107 * totalIntensity}
        position={[0, -1, 0]}
      />
      <LightRing
        count={3}
        startingAngle={60}
        y={0.5}
        totalIntensity={totalIntensity}
        a={1.0}
        b={1.0}
      />
      <LightRing
        count={3}
        startingAngle={60 + 90}
        y={-0.25}
        totalIntensity={0.8 * totalIntensity}
        a={1.0}
        b={1.0}
      />
    </group>
  )
}
