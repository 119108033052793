import { gql } from '@apollo/client'

import { Gender, mapGenders, uniqueValues } from '../../common/data-transforms'
import {
  AxisType,
  BodyPartType,
  TopologyType,
  UnitsType,
  ViewDataSetsWithDetailsQuery,
} from '../../common/generated'

export const VIEW_DATASET_WITH_DETAIL_QUERY = gql`
  query ViewDataSetsWithDetails($datasetId: Int!) {
    datasetById(id: $datasetId) {
      superiorDirection
      anteriorDirection
      bodyPart
      id
      name
      units
      activeTopology
      lastModified
      subjectsByDatasetId(orderBy: NAME_ASC) {
        nodes {
          gender
          name
          id
          geometrySeriesBySubjectId {
            nodes {
              poseTypeId
              poseTypeByPoseTypeId {
                name
              }
              topology
              geometriesByGeometrySeriesId {
                nodes {
                  version
                  geometryScreenshotInvocationsByGeometryId {
                    nodes {
                      s3Key
                      signedURL
                    }
                  }
                }
              }
            }
          }
        }
      }
      geometryAnnotationTypesByDatasetId {
        nodes {
          id
          name
          geometryAnnotationsByGeometryAnnotationTypeId {
            nodes {
              id
              point
            }
          }
        }
      }
    }
  }
`

export interface SubjectViewModel {
  id: number
  name: string
  gender?: Gender
  geometrySeries: {
    slug: string
    poseType: {
      id: number
      name: string
    }
    topology?: TopologyType
    latestGeometry: {
      screenshotUrl?: string
    }
  }[]
}

export interface GeometryAnnotationTypeViewModel {
  id: number
  name: string
  count: number
}

export interface ViewModel {
  dataset?: {
    name: string
    bodyPartType: BodyPartType
    superiorDirection?: AxisType
    anteriorDirection?: AxisType
    activeTopology?: TopologyType
    units: UnitsType
    lastModified: any
  }
  poseNames: string[]
  topologies: (string | undefined)[]
  allSubjects: SubjectViewModel[]
  subjectsByGender: {
    gender: Gender
    subjects: SubjectViewModel[]
  }[]
  annotationTypes: GeometryAnnotationTypeViewModel[]
  shouldRenderPose: boolean
  shouldRenderTopology: boolean
}

function slugForGeometrySeries({
  poseTypeId,
  topology,
}: {
  poseTypeId: number
  topology: TopologyType | null | undefined
}): string {
  return `geometrySeries_${poseTypeId}_${topology ?? 'None'}`
}

export function createViewModel(
  data?: ViewDataSetsWithDetailsQuery
): ViewModel {
  const allSubjects =
    data?.datasetById.subjectsByDatasetId.nodes.map(subjectNode => ({
      id: subjectNode.id,
      name: subjectNode.name,
      gender: subjectNode.gender ? (subjectNode.gender as Gender) : undefined,
      geometrySeries:
        subjectNode.geometrySeriesBySubjectId.nodes.map(
          geometrySeriesNode =>
            ({
              slug: slugForGeometrySeries(geometrySeriesNode),
              poseType: {
                id: geometrySeriesNode.poseTypeId,
                name: geometrySeriesNode.poseTypeByPoseTypeId.name,
              },
              topology: geometrySeriesNode.topology ?? undefined,
              latestGeometry: {
                screenshotUrl:
                  geometrySeriesNode.geometriesByGeometrySeriesId.nodes.reduce(
                    (latestNode, currentNode) =>
                      currentNode.version > latestNode.version
                        ? currentNode
                        : latestNode
                  ).geometryScreenshotInvocationsByGeometryId.nodes[0]
                    ?.signedURL ?? undefined,
              },
            } ?? [])
        ) ?? [],
    })) ?? []

  const subjectsByGender = mapGenders(gender => ({
    gender,
    subjects: allSubjects.filter(subject => subject.gender === gender),
  })).filter(item => item.subjects.length > 0)

  const poseNames = uniqueValues(
    allSubjects.flatMap(subject =>
      subject.geometrySeries.map(geometrySeries => geometrySeries.poseType.name)
    )
  )

  const topologies = uniqueValues(
    allSubjects.flatMap(subject =>
      subject.geometrySeries.map(geometrySeries => geometrySeries.topology)
    )
  )

  return {
    dataset: data
      ? {
          name: data.datasetById.name,
          bodyPartType: data.datasetById.bodyPart,
          superiorDirection: data.datasetById.superiorDirection ?? undefined,
          anteriorDirection: data.datasetById.anteriorDirection ?? undefined,
          activeTopology: data.datasetById.activeTopology ?? undefined,
          units: data.datasetById.units,
          lastModified: data.datasetById.lastModified,
        }
      : undefined,
    poseNames,
    topologies,
    allSubjects,
    subjectsByGender,
    annotationTypes:
      data?.datasetById.geometryAnnotationTypesByDatasetId.nodes.map(node => ({
        id: node.id,
        name: node.name,
        count: node.geometryAnnotationsByGeometryAnnotationTypeId.nodes.filter(
          node => node.point !== null
        ).length,
      })) ?? [],
    shouldRenderPose: poseNames.length > 1,
    shouldRenderTopology: topologies.length > 1,
  }
}
