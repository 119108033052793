import { gql, useQuery } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { FlexRow } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  IterationDisplay,
} from '../../common/common-components'
import {
  ROW_HEIGHT,
  TABLE_BACKGROUND_AND_BORDER,
} from '../../common/common-styles'
import {
  ComparePerformanceHeaderQuery,
  ComparePerformanceHeaderQueryVariables,
  ComparePerformanceLatestIterationQuery,
  ComparePerformanceLatestIterationQueryVariables,
  ComparePerformanceSpecificIterationQuery,
  ComparePerformanceSpecificIterationQueryVariables,
  SubjectPerformanceQuery,
  SubjectPerformanceQueryVariables,
} from '../../common/generated'
import { useNumericParam } from '../../common/use-numeric-param'
import { MeasurementPerformance } from '../comparison'
import {
  useIterationQueries,
  usePickCompareIterations,
} from '../use-pick-compare-iterations'
import {
  COMPARE_PERFORMANCE_LATEST_ITERATION_QUERY,
  COMPARE_PERFORMANCE_SPECIFIC_ITERATION_QUERY,
  createMeasurementViewModel,
} from './measurement-view-model'
import {
  createSubjectViewModel,
  SUBJECT_PERFORMANCE_QUERY,
} from './subject-view-model'

const FixedWidthTable = styled.table<{ width?: string }>`
  width: ${({ width }) => width ?? '800px'};
  border-collapse: collapse;
  ${TABLE_BACKGROUND_AND_BORDER}
`
const TableRow = styled.tr`
  ${ROW_HEIGHT};
  border-bottom: 1px solid black;
  > td,
  th {
    vertical-align: middle;
    padding: 0px 10px;
    text-align: center;
  }
`

const BottomCaption = styled.div`
  display: table-caption;
  caption-side: bottom;
  ${TABLE_BACKGROUND_AND_BORDER}
  border-top: none;
  border-collapse: collapse;
  padding: 10px;
`

const FormattedDiff = styled.div<{ withMarginBottom?: boolean }>`
  font-size: 11px;
  ${({ withMarginBottom }) => (withMarginBottom ? 'margin-bottom: 1em;' : '')}
`

const FormattedDiffFaster = styled(FormattedDiff)`
  color: #75b236;
`

const FormattedDiffSlower = styled(FormattedDiff)`
  color: #f56262;
`

const LeftStatContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 0.25em;
`

const NumberOfInvocations = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

function Stat({ stat }: { stat: number | undefined }): JSX.Element {
  return <div>{stat ? stat.toFixed(2) : ''}</div>
}

function LeftStat({ stat }: { stat: number | undefined }): JSX.Element {
  return (
    <LeftStatContainer>
      <Stat stat={stat} />
    </LeftStatContainer>
  )
}

function Diff({
  diff,
  withMarginBottom,
}: {
  diff?: number
  withMarginBottom?: boolean
}): JSX.Element | null {
  if (diff === undefined) return null

  if (diff === 0) {
    return (
      <FormattedDiff withMarginBottom={withMarginBottom}>
        No difference
      </FormattedDiff>
    )
  } else if (diff < 0) {
    return (
      <FormattedDiffFaster withMarginBottom={withMarginBottom}>
        {Math.abs(diff).toFixed(2)} faster
      </FormattedDiffFaster>
    )
  } else {
    return (
      <FormattedDiffSlower withMarginBottom={withMarginBottom}>
        {diff.toFixed(2)} slower
      </FormattedDiffSlower>
    )
  }
}

function MeasurementPerformanceRow({
  measurementStudyId,
  rightIteration,
  measurementPerformance: {
    measurementName,
    leftMeasurementInvocationIteration,
    left,
    right,
    diff,
  },
  usingLatestIteration,
  onlyShowRegressions,
  onlyShowSlowMeasurements,
}: {
  measurementStudyId: number
  rightIteration?: number
  measurementPerformance: MeasurementPerformance
  usingLatestIteration: boolean
  onlyShowRegressions: boolean
  onlyShowSlowMeasurements: boolean
}): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  function toggleIsExpanded(): void {
    setIsExpanded(!isExpanded)
  }

  const subjectQuery = useQuery<
    SubjectPerformanceQuery,
    SubjectPerformanceQueryVariables
  >(SUBJECT_PERFORMANCE_QUERY, {
    variables: {
      measurementStudyId,
      measurementName,
      leftMeasurementInvocationIteration:
        leftMeasurementInvocationIteration as number,
      rightMeasurementInvocationIteration: rightIteration as number,
    },
    skip:
      !isExpanded ||
      leftMeasurementInvocationIteration === null ||
      rightIteration === undefined,
  })

  const subjectViewModel = createSubjectViewModel(subjectQuery.data)

  // skip the row if:
  //   onlyShowRegressions is enabled, AND
  //   the diff does not exist, OR
  //   all the diffs are < 1s
  if (
    onlyShowRegressions &&
    (!diff ||
      (diff?.diffMedian < 1 && // skip the row
        diff?.diffNinetiethPercentile < 1 &&
        diff?.diffMax < 1))
  )
    return <></>

  // skip the row if:
  //   onlyShowSlowMeasurements is enabled, AND
  //   the median, ninetieth percentile and max are all < 25s
  if (
    onlyShowSlowMeasurements &&
    (left?.median || 0) < 25 && // skip the row
    (left?.ninetiethPercentile || 0) < 25 &&
    (left?.max || 0) < 25
  )
    return <></>

  return (
    <>
      <TableRow>
        <td>{humanizeMeasurementName({ name: measurementName, index: 0 })}</td>
        <td>
          {left && (
            <NumberOfInvocations onClick={toggleIsExpanded}>
              {left.numInvocations} measured
            </NumberOfInvocations>
          )}
        </td>
        {usingLatestIteration && (
          <td>
            <IterationDisplay iteration={leftMeasurementInvocationIteration} />
          </td>
        )}
        <td>
          <LeftStat stat={left?.median} />{' '}
          <Diff diff={diff?.diffMedian} withMarginBottom={true} />
        </td>
        <td>
          <LeftStat stat={left?.ninetiethPercentile} />{' '}
          <Diff diff={diff?.diffNinetiethPercentile} withMarginBottom={true} />
        </td>
        <td>
          <LeftStat stat={left?.max} />{' '}
          <Diff diff={diff?.diffMax} withMarginBottom={true} />
        </td>
        <td>
          {right && (
            <NumberOfInvocations onClick={toggleIsExpanded}>
              {right.numInvocations} measured
            </NumberOfInvocations>
          )}
        </td>
        <td>
          <Stat stat={right?.median} />
        </td>
        <td>
          <Stat stat={right?.ninetiethPercentile} />
        </td>
        <td>
          <Stat stat={right?.max} />
        </td>
      </TableRow>
      {isExpanded &&
        subjectViewModel.map(({ subjectName, left, right, diff }) => (
          <TableRow key={subjectName}>
            <td />
            <td />
            <td />
            <td>
              {diff && diff.durationSecondsDifference > 0 ? (
                <Diff diff={diff.durationSecondsDifference} />
              ) : (
                ''
              )}
            </td>
            <td>{left?.durationSeconds ?? ''}</td>
            <td>
              {diff && diff?.durationSecondsDifference < 0 ? (
                <Diff diff={diff.durationSecondsDifference} />
              ) : (
                ''
              )}
            </td>
            <td>{subjectName}</td>
            <td />
            <td>{right?.durationSeconds ?? ''}</td>
            <td />
          </TableRow>
        ))}
    </>
  )
}

export const HEADER_QUERY = gql`
  query ComparePerformanceHeader($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      name
    }
  }
`

export function ComparePerformance(): JSX.Element {
  const selectedStudy = useNumericParam('selectedStudy')
  const {
    leftIteration,
    rightIteration,
    usingLatestIteration,
    renderLeftIterationPicker,
    renderRightIterationPicker,
  } = usePickCompareIterations({ measurementStudyId: selectedStudy })

  const headerQuery = useQuery<
    ComparePerformanceHeaderQuery,
    ComparePerformanceHeaderQueryVariables
  >(HEADER_QUERY, { variables: { measurementStudyId: selectedStudy } })
  const [onlyShowRegressions, setOnlyShowRegressions] = useState<boolean>(false)
  const [onlyShowSlowMeasurements, setOnlyShowSlowMeasurements] =
    useState<boolean>(false)

  const iterationQueries = useIterationQueries<
    ComparePerformanceLatestIterationQuery,
    ComparePerformanceLatestIterationQueryVariables,
    ComparePerformanceSpecificIterationQuery,
    ComparePerformanceSpecificIterationQueryVariables
  >({
    latestIterationQuery: COMPARE_PERFORMANCE_LATEST_ITERATION_QUERY,
    specificIterationQuery: COMPARE_PERFORMANCE_SPECIFIC_ITERATION_QUERY,
    measurementStudyId: selectedStudy,
    rightIteration,
    leftIteration,
  })

  const measurementStudyName = headerQuery.data?.measurementStudyById.name

  const measurementViewModel = createMeasurementViewModel(iterationQueries.data)

  const commonRowAttrs = {
    measurementStudyId: selectedStudy,
    rightIteration,
    usingLatestIteration,
  }

  return (
    <FixedWidthPageContainer>
      <FlexRow>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${selectedStudy}`}>{measurementStudyName}</Link>{' '}
          {'>'} Compare performance
        </Breadcrumb>
      </FlexRow>
      {headerQuery.error && <p>Oh no! {headerQuery.error.message}</p>}
      {iterationQueries.error && <p>Oh no! {iterationQueries.error.message}</p>}
      <h1>{measurementStudyName}</h1>
      <h2>Compare performance</h2>
      <div>
        <input
          type="checkbox"
          value="checked"
          onChange={event => setOnlyShowRegressions(event.target.checked)}
        />{' '}
        Only show performance regressions
      </div>
      <div>
        <input
          type="checkbox"
          value="checked"
          onChange={event => setOnlyShowSlowMeasurements(event.target.checked)}
        />{' '}
        Only show slow measurements
      </div>
      <FixedWidthTable>
        <thead>
          <TableRow>
            <th />
            <th>{renderLeftIterationPicker()}</th>
            {usingLatestIteration && <th />}
            <th>Median</th>
            <th>90th pct</th>
            <th>Max</th>
            <th>{renderRightIterationPicker()}</th>
            <th>Median</th>
            <th>90th pct</th>
            <th>Max</th>
          </TableRow>
        </thead>
        {iterationQueries.loading && (
          <BottomCaption>Loading&hellip;</BottomCaption>
        )}
        <BottomCaption>All times in seconds</BottomCaption>
        <tbody>
          {measurementViewModel.measurements.map(item => (
            <MeasurementPerformanceRow
              key={item.measurementName}
              measurementPerformance={item}
              onlyShowRegressions={onlyShowRegressions}
              onlyShowSlowMeasurements={onlyShowSlowMeasurements}
              {...commonRowAttrs}
            />
          ))}
        </tbody>
      </FixedWidthTable>
    </FixedWidthPageContainer>
  )
}
