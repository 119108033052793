import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, FlexRow } from '@unpublished/common-components'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  SpanIndentedWrappingFlexDiv,
} from '../../common/common-components'
import { FixedWidthTable, TableRow } from '../../common/flex-table'
import {
  DoRegenerateViewsMutation,
  DoRegenerateViewsMutationVariables,
  RegenerateViewsQuery,
  RegenerateViewsQueryVariables,
} from '../../common/generated'
import {
  PromptActionButton,
  PromptContainer,
  PromptTextContainer,
} from '../../common/prompt'
import { ToggleableButtonList } from '../../common/toggleable-button-list'
import { useNumericParam } from '../../common/use-numeric-param'
import { IterationPicker } from '../iteration-picker'
import { MeasurementName } from '../measure-again/view-model'
import { createViewModel, REGENERATE_VIEWS_QUERY } from './view-model'

type MeasurementInvocationIteration = number

const SelectContainer = styled.div`
  width: 15em;
  display: inline-block;
`

export function RegenerateViews(): JSX.Element {
  const navigate = useNavigate()

  const selectedStudy = useNumericParam('selectedStudy')

  const [selectedMeasurementNames, setSelectedMeasurementNames] = useState<
    Set<MeasurementName>
  >(new Set())

  const [
    selectedMeasurementInvocationIteration,
    setSelectedMeasurementInvocationIteration,
  ] = useState<MeasurementInvocationIteration>()

  const { data } = useQuery<
    RegenerateViewsQuery,
    RegenerateViewsQueryVariables
  >(REGENERATE_VIEWS_QUERY, {
    variables: {
      measurementStudyId: selectedStudy,
    },
  })

  const viewModel = data
    ? createViewModel({
        data,
        selectedMeasurementInvocationIteration,
        selectedMeasurementNames,
      })
    : undefined

  useEffect(() => {
    if (
      data &&
      !selectedMeasurementInvocationIteration &&
      data.measurementStudy.measurementInvocationIterationsByMeasurementStudyId
        .nodes.length &&
      data.measurementStudy.measurementInvocationIterationsByMeasurementStudyId
        .nodes[0].measurementInvocationIteration !== null
    ) {
      setSelectedMeasurementInvocationIteration(
        data.measurementStudy
          .measurementInvocationIterationsByMeasurementStudyId.nodes[0]
          .measurementInvocationIteration
      )
    }
  }, [data, selectedMeasurementInvocationIteration])

  function goToStudy(): void {
    navigate(`/studies/${selectedStudy}`)
  }

  const [regenerateViews, { error: regenerateViewsError, loading }] =
    useMutation<DoRegenerateViewsMutation, DoRegenerateViewsMutationVariables>(
      gql`
        mutation DoRegenerateViews($input: RegenerateViewsInput!) {
          regenerateViews(input: $input) {
            numberOfTranslatedViewsDeleted
          }
        }
      `,
      {
        variables: {
          input: {
            measurementNames: Array.from(selectedMeasurementNames),
            measurementStudyId: selectedStudy,
            measurementInvocationIteration:
              selectedMeasurementInvocationIteration || 0,
          },
        },
      }
    )

  const canRegenerateViews = !loading && selectedMeasurementNames.size > 0

  async function handleRegenerateViews(): Promise<void> {
    await regenerateViews()
    goToStudy()
  }

  return (
    <FixedWidthPageContainer>
      <FlexRow>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${selectedStudy}`}>
            {viewModel?.measurementStudyName}
          </Link>{' '}
          {'>'} Regenerate Views
        </Breadcrumb>
      </FlexRow>
      <h1>{viewModel?.measurementStudyName}</h1>
      {viewModel ? (
        <>
          <h2>Regenerate Views</h2>
          <FixedWidthTable>
            <TableRow>
              <SpanIndentedWrappingFlexDiv>
                <span>Iteration</span>
                <span>
                  <SelectContainer>
                    <IterationPicker
                      iterationChoices={
                        viewModel.measurementInvocationIterationOptionsForMeasurementStudy
                      }
                      selectedIteration={selectedMeasurementInvocationIteration}
                      onChange={setSelectedMeasurementInvocationIteration}
                    />
                  </SelectContainer>
                </span>
              </SpanIndentedWrappingFlexDiv>
            </TableRow>
          </FixedWidthTable>{' '}
          <h2>Choose measurements</h2>
          {viewModel.measurementNamesForSelectedMeasurementInvocationIteration &&
            selectedMeasurementNames && (
              <ToggleableButtonList
                options={
                  viewModel.measurementNamesForSelectedMeasurementInvocationIteration
                }
                onChange={setSelectedMeasurementNames}
                selectedOptionValues={selectedMeasurementNames}
              />
            )}
          <PromptContainer>
            <PromptTextContainer>
              {viewModel?.numScreenshotsToRegenerate} views will be regenerated
              for {viewModel?.numSelectedMeasurementInvocations} selected
              measurement invocations
            </PromptTextContainer>
            <span>
              <Button onClick={goToStudy} disabled={!canRegenerateViews}>
                No, don't regenerate
              </Button>
              <PromptActionButton
                onClick={handleRegenerateViews}
                disabled={!canRegenerateViews}
              >
                Regenerate
              </PromptActionButton>
            </span>
          </PromptContainer>
        </>
      ) : (
        <>
          <div>Loading&hellip;</div>
          <div>
            <Button onClick={goToStudy}>Cancel</Button>
          </div>
        </>
      )}
      {regenerateViewsError && <p>Error: {regenerateViewsError.message}</p>}
    </FixedWidthPageContainer>
  )
}
