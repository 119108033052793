import React, { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { FixedWidthTable, TableRow } from './flex-table'
import { Option } from './toggleable-button-list'

const StyledSelect = styled(Select)`
  width: 100%;
` as typeof Select

const StyledTable = styled.table`
  width: 100%;
`

const StyledTh = styled.th`
  width: 50%;
`

interface SelectedPoseAndTopologyState {
  selectedPose?: string
  setSelectedPose: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedTopology?: string
  setSelectedTopology: React.Dispatch<React.SetStateAction<string | undefined>>
}

export type Pose = string | undefined
export type Topology = string | undefined

export type PosesAndTopologiesPair = [Pose[], Topology[]]

export type PoseAndTopologyPair = [Pose, Topology]

interface SelectAllPosesAndTopologiesComponentInput
  extends SelectedPoseAndTopologyState {
  posesAndTopologiesPair: PosesAndTopologiesPair
}

export function useSelectedPoseAndTopologyState(): SelectedPoseAndTopologyState {
  const [selectedPose, setSelectedPose] = useState<string>()
  const [selectedTopology, setSelectedTopology] = useState<string>()
  return {
    selectedPose,
    setSelectedPose,
    selectedTopology,
    setSelectedTopology,
  }
}

function nameToOption(name: string): Option<string> {
  return {
    label: name,
    value: name,
  }
}

function pairsToOptions([poseTypes, topologyTypes]: PosesAndTopologiesPair): [
  Option<string>[],
  Option<string>[]
] {
  return [
    (poseTypes.filter(p => !!p) as string[]).map(nameToOption),
    (topologyTypes.filter(t => !!t) as string[]).map(nameToOption),
  ]
}

export function SelectAllPosesAndTopologiesComponent({
  selectedPose,
  setSelectedPose,
  selectedTopology,
  setSelectedTopology,
  posesAndTopologiesPair,
}: SelectAllPosesAndTopologiesComponentInput): JSX.Element {
  const [currentPoseOptions, currentTopologyOptions] = pairsToOptions(
    posesAndTopologiesPair
  )
  return (
    <>
      <h2>Choose Pose and Topology</h2>
      <FixedWidthTable>
        <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
          <StyledTable>
            <thead>
              <StyledTh>Poses</StyledTh>
              <StyledTh>Topologies</StyledTh>
            </thead>
            <tbody>
              <td>
                <StyledSelect
                  name="poses"
                  key="poses"
                  value={currentPoseOptions?.filter(
                    option => option.value === selectedPose
                  )}
                  onChange={(option: any, { action }) => {
                    if (option && action === 'select-option') {
                      setSelectedPose(option.value)
                    }
                  }}
                  options={currentPoseOptions}
                />
              </td>
              <td>
                <StyledSelect
                  name="topologies"
                  key="topologies"
                  value={currentTopologyOptions?.filter(
                    option => option.value === selectedTopology
                  )}
                  onChange={(option: any, { action }) => {
                    if (option && action === 'select-option') {
                      setSelectedTopology(option.value)
                    }
                  }}
                  options={currentTopologyOptions}
                />
              </td>
            </tbody>
          </StyledTable>
        </TableRow>
      </FixedWidthTable>
    </>
  )
}
