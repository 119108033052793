import { gql, useMutation } from '@apollo/client'
import humanizeString from 'humanize-string'
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  CreateNewAnnotationTypesMutation,
  CreateNewAnnotationTypesMutationVariables,
  DeleteAndCreateNewAnnotationTypesMutation,
  DeleteAndCreateNewAnnotationTypesMutationVariables,
  DeleteAnnotationTypesMutation,
  DeleteAnnotationTypesMutationVariables,
} from '../../common/generated'
import { GeometryAnnotationTypeViewModel } from './view-model'

const TextButton = styled.span`
  cursor: pointer;
  font-size: 12px;
  color: #999999;
  margin-left: 1em;
`

const AnnotationTypesListItemLabel = styled.span<{
  isRemoved: boolean
  isNew: boolean
}>`
  ${({ isRemoved }) =>
    isRemoved &&
    css`
      color: red;
    `}
  ${({ isNew }) =>
    isNew &&
    css`
      color: green;
    `}
`

const AnnotationTypesListItemInput = styled.input`
  :invalid {
    border: 1px solid red;
  }
`

const AnnotationTypesListItemSubmit = styled.input`
  margin-left: 1em;
`

function AnnotationTypesListItem({
  name,
  geometryAnnotationsCount,
  isEditingAnnotations,
  isRemoved,
  isNew,
  removeHandler,
  undoRemoveHandler,
}: {
  name: string
  geometryAnnotationsCount: number
  isEditingAnnotations: boolean
  isRemoved: boolean
  isNew: boolean
  removeHandler: () => void
  undoRemoveHandler?: () => void
}): JSX.Element {
  return (
    <li>
      <AnnotationTypesListItemLabel isRemoved={isRemoved} isNew={isNew}>
        {`${humanizeString(name)} (${geometryAnnotationsCount})`}
      </AnnotationTypesListItemLabel>
      {isEditingAnnotations && geometryAnnotationsCount === 0 ? (
        isRemoved ? (
          <TextButton onClick={undoRemoveHandler}>Undo remove</TextButton>
        ) : (
          <TextButton onClick={removeHandler}>Remove</TextButton>
        )
      ) : undefined}
    </li>
  )
}

function useUpdateAnnotationTypesMutation({
  onMutationCompleted,
  onMutationError,
  newAnnotationTypeNames,
  removedAnnotationTypeIds,
  datasetId,
}: {
  onMutationCompleted: () => void
  onMutationError: (e: Error) => void
  newAnnotationTypeNames: string[]
  removedAnnotationTypeIds: number[]
  datasetId: number
}): () => void {
  // we use three different mutations here as a workaround to the behavior in the mn* bulk mutations, such that they throw an error when given an empty array
  // so we define a mutation in the case that:
  // 1. only newAnnotationTypeNames is populated
  // 2. only removedAnnotationTypeIds is populated
  // 3. both newAnnotationTypeNames and removedAnnotationTypeIds are populated

  const [createNewAnnotationTypes] = useMutation<
    CreateNewAnnotationTypesMutation,
    CreateNewAnnotationTypesMutationVariables
  >(
    gql`
      mutation CreateNewAnnotationTypes(
        $createInput: mnCreateGeometryAnnotationTypeInput!
      ) {
        mnCreateGeometryAnnotationType(input: $createInput) {
          __typename
        }
      }
    `,
    {
      variables: {
        createInput: {
          mnGeometryAnnotationType: newAnnotationTypeNames.map(name => ({
            name,
            datasetId,
          })),
        },
      },
      onCompleted: onMutationCompleted,
      onError: onMutationError,
    }
  )

  const [deleteAnnotationTypes] = useMutation<
    DeleteAnnotationTypesMutation,
    DeleteAnnotationTypesMutationVariables
  >(
    gql`
      mutation DeleteAnnotationTypes(
        $deleteInput: mnDeleteGeometryAnnotationTypeByIdInput!
      ) {
        mnDeleteGeometryAnnotationTypeById(input: $deleteInput) {
          __typename
        }
      }
    `,
    {
      variables: {
        deleteInput: {
          mnGeometryAnnotationTypePatch: removedAnnotationTypeIds.map(id => ({
            id,
          })),
        },
      },
      onCompleted: onMutationCompleted,
      onError: onMutationError,
    }
  )

  const [deleteAndCreateNewAnnotationTypes] = useMutation<
    DeleteAndCreateNewAnnotationTypesMutation,
    DeleteAndCreateNewAnnotationTypesMutationVariables
  >(
    gql`
      mutation DeleteAndCreateNewAnnotationTypes(
        $deleteInput: mnDeleteGeometryAnnotationTypeByIdInput!
        $createInput: mnCreateGeometryAnnotationTypeInput!
      ) {
        mnDeleteGeometryAnnotationTypeById(input: $deleteInput) {
          __typename
        }
        mnCreateGeometryAnnotationType(input: $createInput) {
          __typename
        }
      }
    `,
    {
      variables: {
        createInput: {
          mnGeometryAnnotationType: newAnnotationTypeNames.map(name => ({
            name,
            datasetId,
          })),
        },
        deleteInput: {
          mnGeometryAnnotationTypePatch: removedAnnotationTypeIds.map(id => ({
            id,
          })),
        },
      },
      onCompleted: onMutationCompleted,
      onError: onMutationError,
    }
  )

  function updateAnnotationTypes(): void {
    if (newAnnotationTypeNames.length && removedAnnotationTypeIds.length) {
      deleteAndCreateNewAnnotationTypes()
    } else if (newAnnotationTypeNames.length) {
      createNewAnnotationTypes()
    } else if (removedAnnotationTypeIds.length) {
      deleteAnnotationTypes()
    } else {
      onMutationCompleted()
    }
  }

  return updateAnnotationTypes
}

export function AnnotationTypesList({
  annotationTypes,
  datasetId,
  onSaveSuccess,
}: {
  annotationTypes: GeometryAnnotationTypeViewModel[]
  datasetId: number
  onSaveSuccess: () => void
}): JSX.Element {
  const [isEditingAnnotations, setIsEditingAnnotations] =
    useState<boolean>(false)
  const [newAnnotationTypeNames, setNewAnnotationTypeNames] = useState<
    string[]
  >([])
  const [removedAnnotationTypeIds, setRemovedAnnotationTypeIds] = useState<
    number[]
  >([])
  const [newAnnotationTypeNameValue, setNewAnnotationTypeNameValue] =
    useState<string>('')
  const [
    newAnnotationTypeNameValueIsValid,
    setNewAnnotationTypeNameValueIsValid,
  ] = useState<boolean>(false)

  const annotationTypesListItemInputRef = useRef<HTMLInputElement>(null)
  const annotationTypesListItemFormRef = useRef<HTMLFormElement>(null)

  function onMutationCompleted(): void {
    setIsEditingAnnotations(false)
    setNewAnnotationTypeNames([])
    setRemovedAnnotationTypeIds([])
    setNewAnnotationTypeNameValue('')
    onSaveSuccess()
  }

  function onMutationError(error: Error): void {
    alert(`Error: ${error.message}`)
  }

  const updateAnnotationTypes = useUpdateAnnotationTypesMutation({
    onMutationCompleted,
    onMutationError,
    newAnnotationTypeNames,
    removedAnnotationTypeIds,
    datasetId,
  })

  function removeAnnotationTypeId(annotationTypeId: number): void {
    setRemovedAnnotationTypeIds(
      removedAnnotationTypeIds.concat(annotationTypeId)
    )
  }

  function undoRemoveAnnotationTypeId(annotationTypeId: number): void {
    setRemovedAnnotationTypeIds(
      removedAnnotationTypeIds.filter(id => id !== annotationTypeId)
    )
  }

  function removeNewAnnotationTypeName(
    newAnnotationTypeNameIndex: number
  ): void {
    setNewAnnotationTypeNames(
      newAnnotationTypeNames
        .slice(0, newAnnotationTypeNameIndex)
        .concat(newAnnotationTypeNames.slice(newAnnotationTypeNameIndex + 1))
    )
  }

  function normalizeNewAnnotationTypeNameValue(
    annotationTypeNameValue: string
  ): string {
    return annotationTypeNameValue.toLowerCase().replaceAll(' ', '_')
  }

  function addNewAnnotationTypeName(): void {
    const normalizedNewAnnotationTypeNameValue =
      normalizeNewAnnotationTypeNameValue(newAnnotationTypeNameValue)
    if (newAnnotationTypeNameValue) {
      setNewAnnotationTypeNames(
        newAnnotationTypeNames.concat(normalizedNewAnnotationTypeNameValue)
      )
      setNewAnnotationTypeNameValue('')
    }
  }

  function validateNewAnnotationTypeNameValue(
    nextNewAnnotationTypeNameValue: string
  ): boolean {
    let isValid: boolean
    if (annotationTypesListItemInputRef.current?.validity.patternMismatch) {
      annotationTypesListItemInputRef.current?.setCustomValidity(
        'A valid name contains letters, numbers, spaces, and underscores, starts with a letter, does not have any trailing whitespace.'
      )
      isValid = false
    } else if (
      annotationTypes
        .map(item => item.name)
        .concat(newAnnotationTypeNames)
        .includes(
          normalizeNewAnnotationTypeNameValue(nextNewAnnotationTypeNameValue)
        )
    ) {
      annotationTypesListItemInputRef.current?.setCustomValidity(
        'Input must not duplicate any other entries.'
      )
      isValid = false
    } else {
      annotationTypesListItemInputRef.current?.setCustomValidity('')
      isValid = true
    }

    if (isValid) {
      // if there is already a validation message being shown, this clears it
      annotationTypesListItemInputRef.current?.blur()
      annotationTypesListItemInputRef.current?.focus()
    } else {
      annotationTypesListItemFormRef.current?.reportValidity()
    }
    setNewAnnotationTypeNameValueIsValid(isValid)
    return isValid
  }

  return (
    <>
      <h3>Annotations</h3>
      <ul>
        {annotationTypes.map(annotationType => (
          <AnnotationTypesListItem
            key={annotationType.id}
            name={annotationType.name}
            geometryAnnotationsCount={annotationType.count}
            isEditingAnnotations={isEditingAnnotations}
            isRemoved={removedAnnotationTypeIds.indexOf(annotationType.id) > -1}
            isNew={false}
            removeHandler={removeAnnotationTypeId.bind(null, annotationType.id)}
            undoRemoveHandler={undoRemoveAnnotationTypeId.bind(
              null,
              annotationType.id
            )}
          />
        ))}
        {newAnnotationTypeNames.map((name, i) => (
          <AnnotationTypesListItem
            key={name}
            name={name}
            geometryAnnotationsCount={0}
            isEditingAnnotations={isEditingAnnotations}
            isRemoved={false}
            isNew={true}
            removeHandler={removeNewAnnotationTypeName.bind(null, i)}
          />
        ))}
        {isEditingAnnotations ? (
          <li>
            <form
              ref={annotationTypesListItemFormRef}
              onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                if (
                  validateNewAnnotationTypeNameValue(newAnnotationTypeNameValue)
                ) {
                  addNewAnnotationTypeName()
                  setNewAnnotationTypeNameValueIsValid(false)
                }
              }}
            >
              <AnnotationTypesListItemInput
                ref={annotationTypesListItemInputRef}
                onChange={e => {
                  const nextNewAnnotationTypeNameValue = e.target.value
                  validateNewAnnotationTypeNameValue(
                    nextNewAnnotationTypeNameValue
                  )
                  setNewAnnotationTypeNameValue(nextNewAnnotationTypeNameValue)
                }}
                value={newAnnotationTypeNameValue}
                pattern="[a-zA-Z]([a-zA-Z0-9 _]*[a-zA-Z0-9_]+)?"
              />
              <AnnotationTypesListItemSubmit
                type="submit"
                value="Add"
                disabled={!newAnnotationTypeNameValueIsValid}
              />
            </form>
          </li>
        ) : undefined}
      </ul>
      {isEditingAnnotations ? (
        <div>
          <TextButton onClick={() => updateAnnotationTypes()}>
            Save changes
          </TextButton>
          <TextButton onClick={() => setIsEditingAnnotations(false)}>
            Cancel editing
          </TextButton>
        </div>
      ) : (
        <TextButton onClick={() => setIsEditingAnnotations(true)}>
          Add / edit
        </TextButton>
      )}
    </>
  )
}
