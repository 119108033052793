// Common code for rendering tables like the List Datasets and List Meaurement Studies pages.
import { TABLE_BACKGROUND_SELECTED } from '@unpublished/common-components'
import styled from 'styled-components'

import { ROW_HEIGHT, TABLE_BACKGROUND_AND_BORDER } from './common-styles'

export const FixedWidthTable = styled.div<{ width?: string }>`
  display: table;
  width: ${({ width }) => width ?? '800px'};
  border-collapse: collapse;
  ${TABLE_BACKGROUND_AND_BORDER}
`
export const TableRow = styled.div<{
  selected?: boolean
  doNotSizeLastColumnBasedOnItsContents?: boolean
}>`
  ${ROW_HEIGHT};
  border-bottom: 1px solid black;
  display: table-row;
  ${({ selected }) => selected && TABLE_BACKGROUND_SELECTED}};
  > div {
    display: table-cell;
    vertical-align: middle;
    padding: 0px 10px;
  }
  > div:first-child {
    padding-left: 20px;
  }
  > div:last-child {
    padding-right: 20px; 
    ${({ doNotSizeLastColumnBasedOnItsContents }) =>
      !doNotSizeLastColumnBasedOnItsContents && 'width: 0px;'}};
  }
`
export const NoWrapDiv = styled.div`
  white-space: nowrap;
`
export const WrappingFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`
export const NoWrapFlexDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;

  & > * {
    margin: 5px;
  }
`
export const FullWidthRow = styled.div<{ position: 'top' | 'bottom' }>`
  display: table-caption;
  caption-side: ${({ position }) => position};
  text-align: center;
  ${TABLE_BACKGROUND_AND_BORDER}
  border-top: ${({ position }) => position === 'bottom' && 'none'};
  border-bottom: ${({ position }) => position === 'top' && 'none'};
  border-collapse: collapse;
`
export const BasicContainer = styled.div`
  ${TABLE_BACKGROUND_AND_BORDER}
  padding: 10px;
`
