import { gql } from '@apollo/client'

import { SubjectPerformanceQuery } from '../../common/generated'

export const SUBJECT_PERFORMANCE_QUERY = gql`
  query SubjectPerformance(
    $measurementStudyId: Int!
    $measurementName: String!
    $leftMeasurementInvocationIteration: Int!
    $rightMeasurementInvocationIteration: Int!
  ) {
    allSubjectPerformances(
      condition: {
        measurementStudyId: $measurementStudyId
        measurementName: $measurementName
        leftMeasurementInvocationIteration: $leftMeasurementInvocationIteration
        rightMeasurementInvocationIteration: $rightMeasurementInvocationIteration
      }
      orderBy: SUBJECT_NAME_ASC
    ) {
      nodes {
        subjectName
        leftDurationSeconds
        rightDurationSeconds
      }
    }
  }
`

export interface SubjectPerformance {
  subjectName: string
  left?: { durationSeconds: number }
  right?: { durationSeconds: number }
  diff?: { durationSecondsDifference: number }
}

export function createSubjectViewModel(
  data?: SubjectPerformanceQuery
): SubjectPerformance[] {
  return (
    data?.allSubjectPerformances.nodes.map(subject => ({
      subjectName: subject.subjectName as string,
      left:
        subject.leftDurationSeconds === null
          ? undefined
          : { durationSeconds: subject.leftDurationSeconds },
      right:
        subject.rightDurationSeconds === null
          ? undefined
          : { durationSeconds: subject.rightDurationSeconds },
      diff:
        subject.leftDurationSeconds === null ||
        subject.rightDurationSeconds === null
          ? undefined
          : {
              durationSecondsDifference:
                subject.leftDurationSeconds - subject.rightDurationSeconds,
            },
    })) ?? []
  )
}
