import * as THREE from 'three'

import { normalizeCanvasCoords } from './transforms'
import { CanvasSize, Vector2 } from './types'

// https://stackoverflow.com/questions/63083684/how-to-use-three-js-raycaster-with-orthographiccamera-with-negative-near-plane
// Override the default Raycaster setFromCamera method in order to work around a
// limitation in the current Raycaster implementation, in order to intersect
// with objects whose z < 0 when using OrthographicCamera.
export class Raycaster extends THREE.Raycaster {
  setFromCamera(coords: THREE.Vector2, camera: THREE.Camera): void {
    // @ts-ignore
    if (camera && camera.isOrthographicCamera) {
      this.ray.origin.set(coords.x, coords.y, -1).unproject(camera)
      this.ray.direction.set(0, 0, -1).transformDirection(camera.matrixWorld)
      this.camera = camera
    } else {
      super.setFromCamera(coords, camera)
    }
  }
}

// Return results in world coordinates. It's the caller's responsibility to
// transform to mesh coordinates using the mesh's inverse world matrix. This
// also avoids recomputing the matrix inverse on every call.
export function intersectMeshWorld({
  raycaster,
  canvasCoords,
  canvasSize,
  mesh,
  camera,
}: {
  raycaster: THREE.Raycaster
  canvasCoords: Vector2
  canvasSize: CanvasSize
  mesh: THREE.Mesh
  camera: THREE.Camera
}): THREE.Intersection | undefined {
  // Convert to normalized WebGL coordinates.
  const normalizedCoords = normalizeCanvasCoords(canvasCoords, canvasSize)

  // Cast a ray through the frustum.
  raycaster.setFromCamera(new THREE.Vector2(...normalizedCoords), camera)

  // Get the list of objects the ray intersects.
  const intersectedObjects = raycaster.intersectObjects([mesh])

  // If there are any intersections, pick the first one. It's the closest.
  return intersectedObjects[0]
}
