import { IterationViewModel, SubjectViewModel } from './view-model'

export function findMeasurementInvocation(
  subjects: SubjectViewModel[],
  measurementInvocationId: number
): IterationViewModel | undefined {
  for (const subject of subjects) {
    const result = subject.iterations.find(
      iteration => iteration.measurementInvocationId === measurementInvocationId
    )
    if (result !== undefined) {
      return result
    }
  }
}
