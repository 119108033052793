import { Disc, LengthUnits, NamedPoint } from '@curvewise/common-types'
import { Stats } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { PartsToShow } from '@unpublished/four'
import { Polyline, Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import * as THREE from 'three'

import { ReactCanvasContext } from '../canvas/canvas-context'
import { Disc as DisplayDisc } from './disc'
import { Lighting } from './lighting'
import { Point, PointColor } from './point'
import { BUMP_URL, getColor, getTextureUrl } from './scene-appearance'
import { Tape } from './tape'
import { AppearanceStyle } from './tape-appearance'
import { conversionFactorToCM, eulerFromUpAndLook } from './transform'

const INVISIBLE_MATERIAL = new THREE.MeshBasicMaterial({ visible: false })

export interface ColoredPoint extends NamedPoint {
  color: PointColor
}

export interface PointsWithRenderDetails {
  points: ColoredPoint[]
  showPointLabels: boolean
}

export interface ColoredCurve {
  name?: string
  curve: Polyline
  tapeColor: string
}

export interface CurvesWithRenderDetails {
  coloredCurves: ColoredCurve[]
  showCurveLabels: boolean
}

export function MainObjectTransform({
  mainObjectGeometry,
  anteriorAxis,
  superiorAxis,
  units,
  children,
}: {
  mainObjectGeometry: THREE.BufferGeometry
  anteriorAxis?: Vector
  superiorAxis?: Vector
  units?: LengthUnits
  children: ReactNode
}): JSX.Element {
  const rotation = useMemo(() => {
    return superiorAxis && anteriorAxis
      ? eulerFromUpAndLook({ superiorAxis, anteriorAxis })
      : undefined
  }, [superiorAxis, anteriorAxis])

  const scale = useMemo(() => {
    return units ? conversionFactorToCM(units) : undefined
  }, [units])

  // TODO: Rethink the position code and what it's trying to do.
  // const position = useMemo(() => {
  //   mainObjectGeometry.computeBoundingBox()
  //   const { boundingBox } = mainObjectGeometry
  //   if (boundingBox && scale && superiorAxis) {
  //     let y = undefined
  //     if (isEqual(superiorAxis.coords, [0, 1, 0])) {
  //       y = boundingBox.min['y']
  //     } else if (isEqual(superiorAxis.coords, [0, 0, 1])) {
  //       y = boundingBox.min['z']
  //     } else {
  //       //throw Error('Axis unsupported')
  //       y = 0
  //     }
  //     return new THREE.Vector3(0, -y, 0).multiplyScalar(scale)
  //   }
  // }, [mainObjectGeometry, superiorAxis, scale])
  const position = undefined

  return (
    <group position={position} scale={scale} rotation={rotation}>
      {children}
    </group>
  )
}

function Body({
  body,
  bump,
  bumpRepeats = 1,
  bumpScale,
  cursorPosition,
  doubleSided = false,
  flatShading = false,
  loadedTexture,
  parts,
  pickPointRadiusCm,
  pointsWithRenderDetails,
  textureRepeats = 1,
  textureUrl,
  units,
}: {
  body: THREE.BufferGeometry
  bump: boolean
  bumpRepeats?: number
  bumpScale?: number
  cursorPosition?: Vector3
  doubleSided?: boolean
  flatShading?: boolean
  loadedTexture?: THREE.Texture
  parts?: PartsToShow[]
  pickPointRadiusCm?: number
  pointsWithRenderDetails?: PointsWithRenderDetails
  textureRepeats?: number
  textureUrl?: string
  units?: LengthUnits
}): JSX.Element {
  const canvasContext = useContext(ReactCanvasContext)

  const textureLoaderUrls = (textureUrl ? [textureUrl] : []).concat(
    bump ? [BUMP_URL] : []
  )
  const textures = useLoader<THREE.Texture, string[]>(
    THREE.TextureLoader,
    textureLoaderUrls
  )
  let texture: THREE.Texture | undefined
  if (loadedTexture) {
    texture = loadedTexture
  } else if (textureUrl) {
    texture = textures[textureLoaderUrls.indexOf(textureUrl)]
  }
  const bumpTexture = textures[textureLoaderUrls.indexOf(BUMP_URL)]

  useLayoutEffect(() => {
    if (texture) {
      texture.repeat.set(textureRepeats, textureRepeats)
      texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping
    }
  }, [texture, textureRepeats])
  useLayoutEffect(() => {
    if (bumpTexture) {
      bumpTexture.repeat.set(bumpRepeats, bumpRepeats)
      bumpTexture.wrapS = bumpTexture.wrapT = THREE.MirroredRepeatWrapping
    }
  }, [bumpTexture, bumpRepeats])

  // .needsUpdate must be set when bump or texture maps are turned off or when
  // doubleSided is changed.
  const bodyMaterialRef = useRef<THREE.MeshStandardMaterial>()
  if (bodyMaterialRef.current === undefined) {
    bodyMaterialRef.current = new THREE.MeshStandardMaterial()
  }
  const material = bodyMaterialRef.current
  const partsExist = parts && parts.length > 0
  useLayoutEffect(() => {
    if (material) {
      material.map = texture ?? null
      material.bumpMap = bumpTexture
      material.bumpScale = bumpScale ?? 0
      material.side =
        doubleSided || partsExist ? THREE.DoubleSide : THREE.FrontSide
      material.flatShading = flatShading
      material.needsUpdate = true
    }
  }, [
    material,
    bumpScale,
    bumpTexture,
    texture,
    bump,
    flatShading,
    doubleSided,
    partsExist,
  ])

  /*
    Expected behavior for the setMainObject and setMainObjectGeoemtry events:
      - We expect the consumer of the mainObject event to be notified when r3f
        renders the mesh into the scenegraph, and threejs renders the mesh onto
        the webgl canvas
      - We expect that there will only be one instance of the mesh per scene.
      - The mesh can change during the lifecycle of the scene. 
        Consequently, that means that the consumer of the setMainObject event
        will only be notified once, on initial render of the mesh.
        Then, when the mesh is changed, the consumer will receive a null value for
        the mesh, followed by the new mesh instance.
      - We expect that setMainObjectGeoemtry will be emitted on the initial
        render of the mesh (when setMainObject is emitted), and subsequently when
        the Body component receives new body geometry, after the body geometry has
        been rendered into the scenegraph, and rendered onto the webgl canvas.
      - There is not a guarantee about the order in which the initial
        setMainObject and setMainObjectGeometry events will be emitted.
      - Limitation: we do not currently emit these events when the transforms
        change.
  */
  const handleMeshSet = useCallback(
    (newMesh: THREE.Mesh | null) => {
      if (newMesh) {
        // then wait for the mesh to be rendered onto the canvas
        // we use requestAnimationFrame instead of useFrame from r3f, because we just need it to run once, rather on every frame
        requestAnimationFrame(() => {
          canvasContext.emit('setMainObject', newMesh)
        })
      } else {
        canvasContext.emit('setMainObject', undefined)
      }
    },
    [canvasContext]
  )

  useEffect(() => {
    if (body) {
      requestAnimationFrame(() => {
        canvasContext.emit('setMainObjectGeometry', body)
      })
    }
  }, [body, canvasContext])

  return (
    <group>
      {cursorPosition && (
        <Point
          point={cursorPosition}
          color="green"
          radiusCm={pickPointRadiusCm}
          units={units}
        />
      )}
      {pointsWithRenderDetails &&
        pointsWithRenderDetails.points.map((point, i) => (
          <Point
            key={point.name || i}
            point={point.point}
            label={
              pointsWithRenderDetails.showPointLabels
                ? point.name || `${i}`
                : undefined
            }
            color={point.color}
            radiusCm={pickPointRadiusCm}
            units={units}
          />
        ))}
      <mesh
        geometry={body}
        ref={handleMeshSet}
        material={
          partsExist
            ? parts.map(part =>
                part.isVisible ? material : INVISIBLE_MATERIAL
              )
            : material
        }
      />
    </group>
  )
}

export function Scene({
  anteriorAxis,
  body,
  bump = false,
  bumpRepeats,
  bumpScale,
  compareCurvesUsingColorGradient = false,
  compareToCurve,
  cursorPosition,
  mainCurve,
  curvesWithRenderDetails,
  doubleSided,
  flatShading,
  lightingIntensity,
  loadedTexture,
  parts,
  pickPointRadiusCm,
  pointsWithRenderDetails,
  statsClassName,
  superiorAxis,
  tapeColor,
  tapeWidth,
  texture,
  textureRepeats,
  units,
  withStats = false,
  disc,
}: {
  anteriorAxis?: Vector
  body: THREE.BufferGeometry
  bump?: boolean
  bumpRepeats?: number
  bumpScale?: number
  compareCurvesUsingColorGradient?: boolean
  compareToCurve?: Polyline
  cursorPosition?: Vector3
  mainCurve?: Polyline
  doubleSided?: boolean
  flatShading: boolean
  lightingIntensity: number
  loadedTexture?: THREE.Texture
  parts?: PartsToShow[]
  pickPointRadiusCm?: number
  curvesWithRenderDetails?: CurvesWithRenderDetails
  pointsWithRenderDetails?: PointsWithRenderDetails
  statsClassName?: string
  superiorAxis?: Vector
  tapeColor: string
  tapeWidth: number
  texture?: string
  textureRepeats?: number
  units?: LengthUnits
  withStats?: boolean
  disc?: Disc
}): JSX.Element {
  const soloColor = getColor(tapeColor)
  let curveAppearanceStyle: AppearanceStyle
  if (compareToCurve && compareCurvesUsingColorGradient) {
    curveAppearanceStyle = 'compareFromUsingGradient'
  } else if (compareToCurve) {
    curveAppearanceStyle = 'compareFromUsingSolidColor'
  } else {
    curveAppearanceStyle = 'solo'
  }

  return (
    <group>
      {withStats && <Stats className={statsClassName} />}
      <Lighting totalIntensity={lightingIntensity} />
      <MainObjectTransform
        mainObjectGeometry={body}
        anteriorAxis={anteriorAxis}
        superiorAxis={superiorAxis}
        units={units}
      >
        <Body
          parts={parts}
          loadedTexture={loadedTexture}
          // Do not pass through `textureUrl` when using a loaded texture.
          textureUrl={
            texture && !loadedTexture ? getTextureUrl(texture) : undefined
          }
          {...{
            pointsWithRenderDetails,
            cursorPosition,
            body,
            flatShading,
            doubleSided,
            // Do not pass through `textureRepeats` when using a loaded texture,
            // as this doesn't make sense.
            textureRepeats: loadedTexture ? undefined : textureRepeats,
            bump,
            bumpRepeats,
            bumpScale,
            pickPointRadiusCm,
            units,
          }}
        />
        {compareToCurve && (
          <Tape
            key="compareToFirstPass"
            curve={compareToCurve}
            tapeWidth={tapeWidth}
            appearanceStyle="compareTo"
            soloColor={soloColor}
            depthOnlyFirstPass
          />
        )}
        {mainCurve && (
          <Tape
            key="firstPass"
            curve={mainCurve}
            tapeWidth={tapeWidth}
            appearanceStyle={curveAppearanceStyle}
            compareToCurve={compareToCurve}
            soloColor={soloColor}
            depthOnlyFirstPass
          />
        )}
        {compareToCurve && (
          <Tape
            key="compareTo"
            curve={compareToCurve}
            tapeWidth={tapeWidth}
            appearanceStyle="compareTo"
            soloColor={soloColor}
          />
        )}
        {mainCurve && (
          <Tape
            curve={mainCurve}
            tapeWidth={tapeWidth}
            appearanceStyle={curveAppearanceStyle}
            compareToCurve={compareToCurve}
            soloColor={soloColor}
          />
        )}
        {disc && <DisplayDisc disc={disc} />}
        {curvesWithRenderDetails &&
          curvesWithRenderDetails.coloredCurves.map(coloredCurve => (
            <Tape
              curve={coloredCurve.curve}
              tapeWidth={tapeWidth}
              appearanceStyle={curveAppearanceStyle}
              soloColor={getColor(coloredCurve.tapeColor)}
            />
          ))}
      </MainObjectTransform>
    </group>
  )
}
