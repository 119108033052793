import {
  LegacyMeasuredBody,
  MeasuredBodyViews,
  NamedPoints,
} from '@curvewise/common-types'
import Ajv from 'ajv'

import { WerkitLegacyMeasuredBody } from './schema'

const ajv = new Ajv({ removeAdditional: true }).addSchema(
  require('./generated/schema.json')
)

export function isLegacyMeasuredBody(data: any): data is LegacyMeasuredBody {
  return ajv.validate('#/definitions/LegacyMeasuredBody', data) as boolean
}

export function assertLegacyMeasuredBody(data: any): void {
  if (!isLegacyMeasuredBody(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}

export function isWerkitLegacyMeasuredBody(
  data: any
): data is WerkitLegacyMeasuredBody {
  return ajv.validate('#/definitions/WerkitLegacyMeasuredBody', data) as boolean
}

export function assertWerkitLegacyMeasuredBody(data: any): void {
  if (!isWerkitLegacyMeasuredBody(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}

export function isMeasuredBodyViews(data: any): data is MeasuredBodyViews {
  return ajv.validate('#/definitions/MeasuredBodyViews', data) as boolean
}

export function assertMeasuredBodyViews(data: any): void {
  if (!isMeasuredBodyViews(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}

export function isNamedPoints(data: any): data is NamedPoints {
  return ajv.validate('#/definitions/NamedPoints', data) as boolean
}

export function assertNamedPoints(data: any): void {
  if (!isNamedPoints(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}
