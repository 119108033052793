import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { SortBy } from './use-query-params'

export interface NavigateToParameters {
  measurementName?: string
  // Passing the special value of `null` overrides the default
  // `compareToIteration`.
  compareToIteration?: number | null
  // Passing the special value of `null` overrides the default
  // `measurementInvoationId`.
  measurementInvocationId?: number | null
  compareToInvocationId?: number
  sortBy?: SortBy
  partsToHide?: string[]
}

// Create a convenience function for navigating to and within Review
// Measurements. The parameters passed to the hook are made the defaults, which,
// except for the measurement study, can be overridden when the function is
// invoked.
export function useNavigateToReviewMeasurements(defaults: {
  measurementStudyId: number
  measurementName?: string
  measurementInvocationId?: number
  compareToIteration?: number
  sortBy?: SortBy
  partsToHide: string[]
}): (params: NavigateToParameters) => void {
  const navigate = useNavigate()

  // Avoid generating a new callback when the defaults change.
  const defaultsRef =
    useRef<Parameters<typeof useNavigateToReviewMeasurements>[0]>()
  defaultsRef.current = defaults

  return useCallback(
    function navigateToReviewMeasurements(params: NavigateToParameters): void {
      const {
        measurementStudyId,
        measurementName,
        compareToIteration,
        measurementInvocationId,
        compareToInvocationId,
        sortBy,
        partsToHide,
      } = { ...defaultsRef.current, ...params }

      const search = new URLSearchParams()

      let pathComponents: string[] = [
        'studies',
        `${measurementStudyId}`,
        'review-measurements',
      ]
      if (
        compareToInvocationId !== undefined &&
        measurementInvocationId &&
        measurementName
      ) {
        pathComponents = pathComponents.concat([
          measurementName,
          `${measurementInvocationId}`,
          'compare',
          `${compareToInvocationId}`,
        ])
      } else if (measurementName && measurementInvocationId) {
        pathComponents = pathComponents.concat([
          measurementName,
          `${measurementInvocationId}`,
        ])
        if (compareToIteration !== undefined && compareToIteration !== null) {
          search.set('compare-to-iteration', `${compareToIteration}`)
        }
      } else if (measurementName) {
        pathComponents.push(measurementName)
        if (compareToIteration !== undefined && compareToIteration !== null) {
          search.set('compare-to-iteration', `${compareToIteration}`)
        }
      } else {
        // Nothing to add.
      }
      if (partsToHide && partsToHide.length > 0) {
        search.set('hide-parts', partsToHide.slice().sort().join('|'))
      }
      if (sortBy) {
        search.set('sorted', sortBy)
      }

      navigate({
        pathname: `/${pathComponents.join('/')}`,
        search: search.toString(),
      })
    },
    [navigate]
  )
}
