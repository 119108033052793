import { Polyline } from 'hyla'
import { useMemo } from 'react'

import { unflattenVertices } from './data-transforms'

export function useMemoizedPolyline(
  data:
    | {
        vertices: number[] | null
        isClosed: boolean | null
      }
    | undefined
    | null
): Polyline | undefined {
  return useMemo(() => {
    if (
      data?.vertices &&
      data?.vertices.length > 0 &&
      data?.isClosed !== null
    ) {
      return Polyline.fromData({
        vertices: unflattenVertices(data.vertices),
        isClosed: data.isClosed,
      })
    } else {
      return undefined
    }
  }, [data?.vertices, data?.isClosed])
}
