import { gql, useMutation, useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  ErrorText,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  GenericInput,
} from '../common/common-components'
import { capitalize } from '../common/data-transforms'
import { BasicContainer } from '../common/flex-table'
import {
  MeasurementStudyNameQuery,
  MeasurementStudyNameQueryVariables,
  RenameMeasurementStudyMutation,
  RenameMeasurementStudyMutationVariables,
} from '../common/generated'
import { useNumericParam } from '../common/use-numeric-param'
import { MEASUREMENT_STUDY_NAME_QUERY } from './common-queries'
import { VIEW_MEASUREMENT_STUDY_QUERY } from './view-study/view-model'

export const ContainerWithSpaceAround = styled(BasicContainer)`
  width: 700px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
`
const InputName = styled(GenericInput)`
  width: 40%;
`
const FooterWithSpacedContents = styled(FixedWidthFooterNavContainer)`
  display: flex;
  justify-content: space-between;
`

export function RenameMeasurementStudy(): JSX.Element {
  const navigate = useNavigate()
  const measurementStudyId = useNumericParam('selectedStudy')
  const [isRenaming, setIsRenaming] = useState(false)

  const { loading, error, data } = useQuery<
    MeasurementStudyNameQuery,
    MeasurementStudyNameQueryVariables
  >(MEASUREMENT_STUDY_NAME_QUERY, { variables: { measurementStudyId } })

  const name = data?.measurementStudyById.name
  const thisStudyLink = `/studies/${measurementStudyId}`
  const [newStudyName, setNewStudyName] = useState<string>(name ?? '')

  const newStudyNameIsInvalid =
    newStudyName === undefined ||
    data?.measurementStudyById.id === undefined ||
    newStudyName === '' ||
    newStudyName === name

  const [renameMeasurementStudy, { error: renameStudyError }] = useMutation<
    RenameMeasurementStudyMutation,
    RenameMeasurementStudyMutationVariables
  >(
    gql`
      mutation RenameMeasurementStudy(
        $input: UpdateMeasurementStudyByIdInput!
      ) {
        updateMeasurementStudyById(input: $input) {
          measurementStudy {
            name
          }
        }
      }
    `,
    {
      variables: {
        input: {
          id: measurementStudyId,
          measurementStudyPatch: { name: newStudyName },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: VIEW_MEASUREMENT_STUDY_QUERY,
          variables: { measurementStudyId },
        },
      ],
      onCompleted() {
        setIsRenaming(false)
        navigate(`/studies/${measurementStudyId}`)
      },
      onError(error) {
        setIsRenaming(false)
      },
    }
  )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <Link to={thisStudyLink}>{name}</Link>
        {'>'} Rename
      </Breadcrumb>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <h1>{capitalize(name ?? '')}</h1>
      <h2>Rename</h2>
      <ContainerWithSpaceAround>
        <div>Name</div>
        <InputName
          type="text"
          id="name"
          required
          value={newStudyName}
          onChange={e => setNewStudyName(e.currentTarget.value)}
        />
      </ContainerWithSpaceAround>
      <FooterWithSpacedContents>
        <Button onClick={() => navigate(thisStudyLink)}>Cancel</Button>
        {renameStudyError && <ErrorText>{renameStudyError.message}</ErrorText>}
        <Button
          disabled={newStudyNameIsInvalid || isRenaming}
          onClick={() => {
            setIsRenaming(true)
            renameMeasurementStudy()
          }}
        >
          {isRenaming ? 'Renaming...' : 'Rename'}
        </Button>
      </FooterWithSpacedContents>
    </FixedWidthPageContainer>
  )
}
