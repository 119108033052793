import { getFaceCount } from '@unpublished/four'
import * as THREE from 'three'

export type KnownTopology = 'meshcapade-sm4-mid' | 'mpii-human-shape-1.0'

// The OBJ loader flattens vertices, so `vertices.length` is always
// `3 * faces.length` and `vertices.faces` is `[[0, 1, 2], [3, 4, 5]]...`. So
// the only thing we can do is count the faces.

// function headMatches(faces: THREE.Face3[], expected: Vector3Values[]) {
//   debugger
//   return expected.every((face, index) => {
//     const [expectedA, expectedB, expectedC] = face
//     const { a, b, c } = faces[index]
//     return a === expectedA && b === expectedB && c === expectedC
//   })
// }

// function tailMatches(faces: THREE.Face3[], expected: Vector3Values[]) {
//   return expected
//     .slice()
//     .reverse()
//     .every((face, index) => {
//       const [expectedA, expectedB, expectedC] = face
//       const { a, b, c } = faces[faces.length - 1 - index]
//       return a === expectedA && b === expectedB && c === expectedC
//     })
// }

// const sm4Mid = {
//   head: [
//     [6891, 2, 6892],
//     [6891, 6892, 20679],
//   ] as Vector3Values[],
//   tail: [
//     [19017, 6310, 20678],
//     [19017, 20678, 27578],
//   ] as Vector3Values[],
// }

export function detectTopology(
  geometry: THREE.BufferGeometry
): KnownTopology | undefined {
  const numFaces = getFaceCount(geometry)

  if (numFaces === 55152) {
    return 'meshcapade-sm4-mid'
  } else if (numFaces === 12894) {
    return 'mpii-human-shape-1.0'
  } else {
    return undefined
  }
}

export const ALL_BODY_PART_NAMES = [
  'head',
  'neck',
  'left_shoulder',
  'right_shoulder',
  'left_torso',
  'right_torso',
  'midsection',
  'pelvis',
  'left_upper_arm',
  'right_upper_arm',
  'left_forearm',
  'right_forearm',
  'left_hand',
  'right_hand',
  'left_thigh',
  'right_thigh',
  'left_calf',
  'right_calf',
  'left_foot',
  'right_foot',
] as const

export type BodyPartName = typeof ALL_BODY_PART_NAMES[number]
