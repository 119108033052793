import { Vector3 } from 'polliwog-types'
import * as THREE from 'three'

import { CanvasSize, Vector2 } from './types'

// Convert canvas x+y coordinates to WebGL normalized device coordinates, which
// range from 0 to 1.
export function normalizeCanvasCoords(
  [x, y]: Vector2,
  { height, width }: CanvasSize
): Vector2 {
  return [
    2 * (x / width) - 1,
    -2 * (y / height) + 1, // note we flip Y
  ]
}

export function canvasCoordsToClipSpaceCoords(
  canvasCoords: Vector2,
  canvasSize: CanvasSize,
  camera: THREE.OrthographicCamera
): Vector3 {
  // https://github.com/mrdoob/three.js/blob/68daccedef9c9c325cc5f4c929fcaf05229aa1b3/src/core/Raycaster.js#L44
  const z = (camera.near + camera.far) / (camera.near - camera.far)
  return [...normalizeCanvasCoords(canvasCoords, canvasSize), z]
}
