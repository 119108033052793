import { gql, useMutation, useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  ErrorText,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  GenericInput,
} from '../common/common-components'
import { capitalize } from '../common/data-transforms'
import { BasicContainer } from '../common/flex-table'
import {
  DuplicateStudyMutation,
  DuplicateStudyMutationVariables,
  MeasurementStudyNameQuery,
  MeasurementStudyNameQueryVariables,
} from '../common/generated'
import { useNumericParam } from '../common/use-numeric-param'
import { MEASUREMENT_STUDY_NAME_QUERY } from './common-queries'
import { LIST_MEASUREMENT_STUDIES_QUERY } from './list-studies-table'

export const ContainerWithSpaceAround = styled(BasicContainer)`
  width: 700px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
`
const InputName = styled(GenericInput)`
  width: 40%;
`
const FooterWithSpacedContents = styled(FixedWidthFooterNavContainer)`
  display: flex;
  justify-content: space-between;
`

export function DuplicateMeasurementStudy(): JSX.Element {
  const navigate = useNavigate()
  const measurementStudyId = useNumericParam('selectedStudy')
  const [newStudyName, setNewStudyName] = useState<string>()
  const [isDuplicating, setIsduplicating] = useState(false)

  const { loading, error, data } = useQuery<
    MeasurementStudyNameQuery,
    MeasurementStudyNameQueryVariables
  >(MEASUREMENT_STUDY_NAME_QUERY, { variables: { measurementStudyId } })

  const newStudyNameIsInvalid =
    newStudyName === undefined ||
    data?.measurementStudyById.id === undefined ||
    newStudyName === '' ||
    newStudyName.toLowerCase() === data?.measurementStudyById.name.toLowerCase()

  const [duplicateStudy, { error: duplicateStudyError }] = useMutation<
    DuplicateStudyMutation,
    DuplicateStudyMutationVariables
  >(
    gql`
      mutation DuplicateStudy($input: DuplicateMeasurementStudyInput!) {
        duplicateMeasurementStudy(input: $input) {
          measurementStudy {
            id
          }
        }
      }
    `,
    {
      variables: {
        input: {
          measurementStudyId,
          // When the mutation runs, we know the name is not undefined.
          name: newStudyName as string,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [LIST_MEASUREMENT_STUDIES_QUERY],
      onCompleted(data) {
        setIsduplicating(false)
        navigate(
          `/studies/${data.duplicateMeasurementStudy?.measurementStudy?.id}`
        )
      },
      onError(error) {
        setIsduplicating(false)
      },
    }
  )

  const name = data?.measurementStudyById.name
  const thisStudyLink = `/studies/${measurementStudyId}`

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <Link to={thisStudyLink}>{name}</Link>
        {'>'} Duplicate
      </Breadcrumb>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <h1>{capitalize(name ?? '')}</h1>
      <h2>Duplicate study</h2>
      <ContainerWithSpaceAround>
        <div>Name</div>
        <InputName
          type="text"
          id="name"
          placeholder={`${name} copy`}
          required
          value={newStudyName}
          onChange={e => setNewStudyName(e.currentTarget.value)}
        />
      </ContainerWithSpaceAround>
      <FooterWithSpacedContents>
        <Button onClick={() => navigate(thisStudyLink)}>Cancel</Button>
        {duplicateStudyError && (
          <ErrorText>{duplicateStudyError.message}</ErrorText>
        )}
        <Button
          disabled={newStudyNameIsInvalid || isDuplicating}
          onClick={() => {
            setIsduplicating(true)
            duplicateStudy()
          }}
        >
          {isDuplicating ? 'Duplicating...' : 'Duplicate'}
        </Button>
      </FooterWithSpacedContents>
    </FixedWidthPageContainer>
  )
}
