import humanizeString from 'humanize-string'
import React, { useMemo } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { stopPropagationOfKeyboardShortcuts } from '../measurement-studies/review-measurements/use-keyboard-shortcuts'
import { NavigateToParameters } from './use-navigate-to'

const StyledSelect = styled(Select)`
  min-width: 150px;
  max-width: 350px;
  font-size: 11px;
` as typeof Select

export function PartsPicker({
  partsToHide,
  navigateToReviewMeasurements,
  allPartNames,
}: {
  partsToHide: string[]
  navigateToReviewMeasurements: (params: NavigateToParameters) => void
  allPartNames: readonly string[]
}): JSX.Element {
  const choices = useMemo(
    () =>
      allPartNames.map(name => ({
        value: name,
        label: humanizeString(name),
      })),
    [allPartNames]
  )

  return (
    <StyledSelect
      onKeyDown={stopPropagationOfKeyboardShortcuts}
      isClearable
      onChange={options => {
        navigateToReviewMeasurements({ partsToHide: options.map(i => i.value) })
      }}
      value={choices.filter(option => partsToHide.includes(option.value))}
      options={choices}
      isMulti
    />
  )
}
