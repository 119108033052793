import * as THREE from 'three'

import { getFaceCount } from './counts'

export function applyFaceVertexUvs(
  geometry: THREE.BufferGeometry,
  flattenedFaceVertexUvs: Float32Array
): void {
  const numFaces = getFaceCount(geometry)
  const expectedFlattenedFaceVertices = 6 * numFaces
  if (flattenedFaceVertexUvs.length !== expectedFlattenedFaceVertices) {
    throw Error(
      `For a mesh with ${numFaces} faces, expected ${expectedFlattenedFaceVertices} face vertices, but got ${flattenedFaceVertexUvs.length}`
    )
  }
  geometry.setAttribute(
    'uv',
    new THREE.BufferAttribute(flattenedFaceVertexUvs, 2)
  )
}
