import * as THREE from 'three'
import { Geometry } from 'three/examples/jsm/deprecated/Geometry'

export function withNormals(
  geometry: THREE.BufferGeometry
): THREE.BufferGeometry {
  const working = new Geometry().fromBufferGeometry(geometry)
  working.mergeVertices()
  working.computeFaceNormals()
  working.computeVertexNormals()
  return working.toBufferGeometry()
}
