"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var common_1 = require("./common");
function useStorageState(storage, key, defaultState) {
    if (defaultState === void 0) { defaultState = null; }
    var _a = react_1.useState(common_1.useInitialState(storage, key, defaultState)), state = _a[0], setState = _a[1];
    common_1.useStorageListener(storage, key, defaultState, setState);
    var writeError = common_1.useStorageWriter(storage, key, state);
    return [state, setState, writeError];
}
exports.default = useStorageState;
