import { NetworkStatus, QueryResult } from '@apollo/client'
import { maybePluralize } from '@unpublished/victorinox'
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  useEffect,
  useRef,
  useState,
} from 'react'

type ButtonComponentProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

function RefreshButton({
  isRefreshing,
  canRefresh,
  secondsUntilAutoRefresh,
  onClick,
  ButtonComponent,
}: {
  isRefreshing: boolean
  canRefresh: boolean
  secondsUntilAutoRefresh?: number
  onClick: () => void
  ButtonComponent: (props: ButtonComponentProps) => JSX.Element
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false)

  let actionLabel: string
  if (isRefreshing) {
    actionLabel = 'Refreshing…'
  } else if (secondsUntilAutoRefresh !== undefined && isHovered) {
    actionLabel = 'Refresh Now'
  } else if (secondsUntilAutoRefresh !== undefined) {
    actionLabel = `Refreshing in ${secondsUntilAutoRefresh} ${maybePluralize(
      'second',
      secondsUntilAutoRefresh
    )}`
  } else {
    actionLabel = 'Refresh'
  }

  return (
    <ButtonComponent
      disabled={!canRefresh}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {actionLabel}
    </ButtonComponent>
  )
}

export interface UseRefreshResult<TData, TVariables>
  extends QueryResult<TData, TVariables> {
  isInitiallyLoading: boolean
  renderRefreshButton(
    ButtonComponent: (props: ButtonComponentProps) => JSX.Element
  ): JSX.Element
  secondsUntilAutoRefresh?: number
}

export function useRefresh<TData, TVariables>(
  queryResult: QueryResult<TData, TVariables>,
  { autoRefreshIntervalSeconds }: { autoRefreshIntervalSeconds?: number } = {}
): UseRefreshResult<TData, TVariables> {
  const { loading, refetch, networkStatus } = queryResult

  const isInitiallyLoading = loading && networkStatus !== NetworkStatus.refetch
  const canRefresh = !loading
  const isRefreshing = networkStatus === NetworkStatus.refetch

  const [secondsUntilAutoRefresh, setSecondsUntilAutoRefresh] = useState<
    number | undefined
  >()
  const timeout = useRef<NodeJS.Timeout>()

  function performRefresh(): void {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = undefined
    }
    setSecondsUntilAutoRefresh(undefined)
    refetch()
    // After the refresh finishes, the `useEffect()` callback will set a new
    // timeout.
  }

  // To ensure state values from the closure are always up to date, the tick
  // callback is replaced on each invocation of the hook.
  const onTick = useRef(() => {})
  onTick.current = () => {
    if (secondsUntilAutoRefresh === 1) {
      performRefresh()
    } else if (secondsUntilAutoRefresh !== undefined) {
      setSecondsUntilAutoRefresh(secondsUntilAutoRefresh - 1)
      timeout.current = setTimeout(() => onTick.current(), 1000)
    }
  }

  // Set up a timeout if we need one.
  useEffect(() => {
    if (
      !loading &&
      autoRefreshIntervalSeconds !== undefined &&
      timeout.current === undefined
    ) {
      timeout.current = setTimeout(() => onTick.current(), 1000)
      setSecondsUntilAutoRefresh(autoRefreshIntervalSeconds)
    }
  }, [autoRefreshIntervalSeconds, loading])

  // Upon unmounting, clean up any still-running timer.
  useEffect(() => {
    return () => timeout.current && clearTimeout(timeout.current)
  }, [])

  return {
    ...queryResult,
    isInitiallyLoading,
    secondsUntilAutoRefresh,
    renderRefreshButton(
      ButtonComponent: (props: ButtonComponentProps) => JSX.Element
    ) {
      return (
        <RefreshButton
          isRefreshing={isRefreshing}
          canRefresh={canRefresh}
          secondsUntilAutoRefresh={secondsUntilAutoRefresh}
          onClick={performRefresh}
          ButtonComponent={ButtonComponent}
        />
      )
    },
  }
}
