import { gql, useQuery } from '@apollo/client'
import { Button, FlexRow, StatisticalDiv } from '@unpublished/common-components'
import { maybePluralize } from '@unpublished/victorinox'
import { groupBy } from 'lodash'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  CenteredDiv,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  StatisticalSpan,
} from '../common/common-components'
import {
  countUnique,
  humanizeAndPluralizeBodyType,
  humanizedDurationFromNow,
} from '../common/data-transforms'
import { FixedWidthTable, NoWrapDiv, TableRow } from '../common/flex-table'
import { BodyPartType, ListDatasetsWithDetailsQuery } from '../common/generated'

function createViewModel(
  inDatasets: ListDatasetsWithDetailsQuery['allDatasets']['nodes'] | undefined
): {
  bodyPart: BodyPartType
  datasets: {
    id: number
    name: string
    lastModified: any
    subjectCount: number
    poseCount: number
  }[]
}[] {
  return inDatasets
    ? Object.entries(groupBy(inDatasets, 'bodyPart')).map(
        ([bodyPart, datasets]) => ({
          bodyPart: bodyPart as BodyPartType,
          datasets: datasets.map(dataset => {
            const { id, name, lastModified } = dataset
            return {
              id,
              name,
              lastModified,
              subjectCount: dataset.subjectsByDatasetId.nodes.length,
              poseCount: countUnique(
                dataset.subjectsByDatasetId.nodes.flatMap(subject =>
                  subject.geometrySeriesBySubjectId.nodes.map(
                    geometrySeries => geometrySeries.poseTypeId
                  )
                )
              ),
            }
          }),
        })
      )
    : []
}

const Row = styled(FlexRow)`
  align-items: center;
  div {
    padding: 1.5px 5px;
  }
`

export const LIST_DATASETS_QUERY = gql`
  query ListDatasetsWithDetails {
    allDatasets {
      nodes {
        id
        name
        bodyPart
        lastModified
        subjectsByDatasetId {
          totalCount
          nodes {
            geometrySeriesBySubjectId {
              nodes {
                poseTypeId
              }
            }
          }
        }
      }
    }
  }
`

export function ListDatasets(): JSX.Element {
  const navigate = useNavigate()

  const { loading, error, data } =
    useQuery<ListDatasetsWithDetailsQuery>(LIST_DATASETS_QUERY)

  const viewModel = createViewModel(data?.allDatasets.nodes)

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} Datasets
      </Breadcrumb>
      <h1>Datasets</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {data && viewModel.length === 0 && <div>No datasets.</div>}
      {viewModel.length > 0 && (
        <div>
          {viewModel.map(({ bodyPart, datasets }) => (
            <div key={bodyPart}>
              <h2>{humanizeAndPluralizeBodyType(bodyPart)}</h2>
              <FixedWidthTable>
                {datasets.map(dataset => (
                  <TableRow key={dataset.id}>
                    <div>{dataset.name}</div>
                    <NoWrapDiv>
                      <StatisticalDiv>
                        last modified{' '}
                        {humanizedDurationFromNow(dataset.lastModified)}
                      </StatisticalDiv>
                    </NoWrapDiv>
                    <NoWrapDiv>
                      <Row>
                        <CenteredDiv>
                          <div>
                            {dataset.subjectCount}{' '}
                            <StatisticalSpan>
                              {maybePluralize('subject', dataset.subjectCount)}
                            </StatisticalSpan>
                          </div>
                          <div>
                            {dataset.poseCount}{' '}
                            <StatisticalSpan>
                              {maybePluralize('pose', dataset.poseCount)}
                            </StatisticalSpan>
                          </div>
                        </CenteredDiv>
                      </Row>
                    </NoWrapDiv>
                    <NoWrapDiv>
                      <Button
                        onClick={() =>
                          navigate(`/datasets/${dataset.id}/subjects`)
                        }
                      >
                        View
                      </Button>
                    </NoWrapDiv>
                  </TableRow>
                ))}
              </FixedWidthTable>
            </div>
          ))}
        </div>
      )}
      <FixedWidthFooterNavContainer>
        <Button onClick={() => navigate('/import/new-dataset')}>
          + New Dataset
        </Button>
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
