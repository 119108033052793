// @ts-ignore
import envFlag from 'node-env-flag'

export function loadConfig(): {
  dataLayerUrl: string
  authEnabled: boolean
  auth0: {
    domain: string
    clientId: string
    audience: string
  }
} {
  const dataLayerUrl = process.env.REACT_APP_GOLDIE__DATA_LAYER_URL
  if (!dataLayerUrl) {
    throw Error('REACT_APP_GOLDIE__DATA_LAYER_URL is not set')
  }

  return {
    dataLayerUrl,
    authEnabled: envFlag(process.env.REACT_APP_GOLDIE__AUTH_ENABLED, true),
    auth0: {
      domain:
        process.env.REACT_APP_GOLDIE__AUTH0_DOMAIN ?? 'login.metabolize.dev',
      clientId:
        process.env.REACT_APP_GOLDIE__AUTH0_CLIENT_ID ??
        'Z2jhUe5HFRJqwtpMTdOtQsm3al0fremn',
      audience:
        process.env.REACT_APP_GOLDIE__AUTH0_AUDIENCE ??
        'https://goldie-data-layer.curvewise.com/v1',
    },
  }
}
