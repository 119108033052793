import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  GenericInput,
} from '../../../common/common-components'
import { FixedWidthTable, TableRow } from '../../../common/flex-table'
import { CreateNewMeasurementStudyQuery } from '../../../common/generated'
import {
  SelectAllPosesAndTopologiesComponent,
  useSelectedPoseAndTopologyState,
} from '../../../common/select-pose-topology'
import {
  MeasurerVersionTagId,
  PageTransitionActionType,
  TransitionAction,
} from '..'
import {
  CREATE_NEW_MEASUREMENT_STUDY_QUERY,
  createViewModel,
} from './view-model'

const NextButton = styled(Button)`
  float: right;
`

function createMeasurerVersionId(
  selectedMeasurer: CreateNewMeasurementStudyQuery['allMeasurers']['nodes'][0],
  selectedMeasurerVersionTagName: string
): MeasurerVersionTagId {
  return {
    ...selectedMeasurer,
    kind: 'tag',
    tagName: selectedMeasurerVersionTagName,
  }
}

export function CreateNewMeasurementStudyHome({
  dispatchPageTransitionAction,
}: {
  dispatchPageTransitionAction: React.Dispatch<TransitionAction>
}): JSX.Element {
  const navigate = useNavigate()
  const [newStudyName, setNewStudyName] = useState<string>()
  const [selectedBodyPart, setSelectedBodyPart] = useState<string>()
  const [selectedMeasurerName, setSelectedMeasurerName] = useState<string>()
  const [selectedMeasurer, setSelectedMeasurer] =
    useState<CreateNewMeasurementStudyQuery['allMeasurers']['nodes'][0]>()
  const [selectedMeasurerVersionTagName, setSelectedMeasurerVersionTagName] =
    useState<string>()
  const {
    selectedPose,
    setSelectedPose,
    selectedTopology,
    setSelectedTopology,
  } = useSelectedPoseAndTopologyState()

  const { loading, error, data } = useQuery<CreateNewMeasurementStudyQuery>(
    CREATE_NEW_MEASUREMENT_STUDY_QUERY
  )

  const viewModel = createViewModel({
    data,
    selectedMeasurer: selectedMeasurerName,
  })

  const { posesAndTopologiesPair } = viewModel

  if (!selectedBodyPart && viewModel.allBodyPartsOptions.length) {
    setSelectedBodyPart(viewModel.allBodyPartsOptions[0].value)
  }

  if (!selectedMeasurerName && viewModel.measurerOptions.length) {
    setSelectedMeasurerName(viewModel.measurerOptions[0].label)
    setSelectedMeasurer(viewModel.measurerOptions[0].value)
  }

  if (
    selectedMeasurerName &&
    viewModel.measurerVersionOptions.length &&
    !selectedMeasurerVersionTagName
  ) {
    setSelectedMeasurerVersionTagName(viewModel.measurerVersionOptions[0].value)
  }

  const formIsValid =
    newStudyName &&
    selectedBodyPart &&
    selectedMeasurerName &&
    selectedMeasurer &&
    selectedMeasurerVersionTagName &&
    selectedPose &&
    selectedTopology

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <span>New measurement study</span>
      </Breadcrumb>
      <h1>New measurement study</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <div>
        <h2>Choose subjects</h2>
        <FixedWidthTable>
          <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
            <div>Name</div>
            <div>
              <GenericInput
                type="text"
                id="name"
                placeholder="10 anonymous hedgehogs"
                required
                value={newStudyName}
                onChange={e => setNewStudyName(e.currentTarget.value)}
              />
            </div>
          </TableRow>
          <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
            <div>Body Part</div>
            <div>
              <Select
                name="bodyPart"
                key="bodyPart"
                value={viewModel.allBodyPartsOptions?.filter(
                  option => option.value === selectedBodyPart
                )}
                onChange={(option: any, { action }) => {
                  if (option && action === 'select-option') {
                    setSelectedBodyPart(option.value)
                  }
                }}
                options={viewModel.allBodyPartsOptions}
              />
            </div>
          </TableRow>
        </FixedWidthTable>
        <h2> Initial measurer version</h2>
        <FixedWidthTable>
          <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
            <div>
              <Select
                name="selectedMeasurer"
                key="selectedMeasurer"
                value={viewModel.measurerOptions?.filter(
                  option => option.value === selectedMeasurer
                )}
                onChange={(option: any, { action }) => {
                  if (option && action === 'select-option') {
                    setSelectedMeasurerName(option.label)
                    setSelectedMeasurer(option.value)
                  }
                }}
                options={viewModel.measurerOptions}
              />{' '}
            </div>
            <div>@ </div>
            <div>
              <Select
                name="selectedMeasurerVersion"
                key="selectedMeasurerVersion"
                value={viewModel.measurerVersionOptions?.filter(
                  option => option.value === selectedMeasurerVersionTagName
                )}
                onChange={(option: any, { action }) => {
                  if (option && action === 'select-option') {
                    setSelectedMeasurerVersionTagName(option.value)
                  }
                }}
                options={viewModel.measurerVersionOptions}
              />
            </div>
          </TableRow>
        </FixedWidthTable>
        <SelectAllPosesAndTopologiesComponent
          {...{
            selectedPose,
            setSelectedPose,
            selectedTopology,
            setSelectedTopology,
            posesAndTopologiesPair,
          }}
        />
        <FixedWidthFooterNavContainer>
          <Button onClick={() => navigate('/studies')}>Cancel</Button>
          <NextButton
            onClick={() => {
              if (
                newStudyName &&
                selectedBodyPart &&
                selectedMeasurerName &&
                selectedMeasurer &&
                selectedMeasurerVersionTagName &&
                selectedPose &&
                selectedTopology
              ) {
                dispatchPageTransitionAction({
                  type: PageTransitionActionType.HOME_GO_NEXT,
                  payload: {
                    studyName: newStudyName,
                    studyBodyPart: selectedBodyPart,
                    measurerVersion: createMeasurerVersionId(
                      selectedMeasurer,
                      selectedMeasurerVersionTagName
                    ),
                    pose: selectedPose,
                    topology: selectedTopology,
                  },
                })
              }
            }}
            disabled={!formIsValid}
          >
            Next: Choose Subjects
          </NextButton>
          ,
        </FixedWidthFooterNavContainer>
      </div>
    </FixedWidthPageContainer>
  )
}
