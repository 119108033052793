import { Disc as DiscData } from '@curvewise/common-types'
import React, { useMemo } from 'react'
import * as THREE from 'three'

const PLANE_CIRCLE_GEOMETRY = new THREE.CircleGeometry(1, 100),
  PLANE_MATERIAL_FRONT = new THREE.MeshBasicMaterial({
    color: '#39ff14',
    side: THREE.FrontSide,
  }),
  PLANE_MATERIAL_BACK = new THREE.MeshBasicMaterial({
    color: '#937b63',
    side: THREE.BackSide,
  })

export function Disc({ disc }: { disc: DiscData }): JSX.Element {
  const quaternion = useMemo(
    () =>
      new THREE.Quaternion().setFromUnitVectors(
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(...disc.unitNormal)
      ),
    [disc]
  )

  const commonAttrs = {
    position: disc.center,
    quaternion,
    geometry: PLANE_CIRCLE_GEOMETRY,
    scale: disc.radius,
  }

  return (
    <group>
      <mesh {...commonAttrs} material={PLANE_MATERIAL_FRONT} />
      <mesh {...commonAttrs} material={PLANE_MATERIAL_BACK} />
    </group>
  )
}
