import { hashKeyForLabel, Label, labelMatches } from '../labels'
import { AccordionedSubjectViewModel, IterationViewModel } from './view-model'

export type Filter =
  | { type: 'all' }
  | {
      type: 'status'
      status:
        | 'uncommitted'
        | 'error'
        | 'needsReview'
        | 'needsReviewOrUncommitted'
        | 'approved'
        | 'failure'
    }
  | { type: 'specificLabel'; label: Label }
  | { type: 'anyLabelText' }

export function hashKeyForFilter(filter: Filter): string {
  switch (filter.type) {
    case 'all':
      return 'all'
    case 'status':
      return `status: ${filter.status}`
    case 'specificLabel':
      return `specific label: ${hashKeyForLabel(filter.label)}`
    case 'anyLabelText':
      return 'any label text'
  }
}

function iterationHasUncommittedChanges(
  iteration: IterationViewModel
): boolean {
  return iteration.labels.some(
    label => label.commitState === 'added' || label.commitState === 'removed'
  )
}

function stateForIteration(
  iteration: IterationViewModel
): 'error' | 'needsReview' | 'approved' | 'failure' | undefined {
  if (iteration.stateMachineStateId === 'ERROR') {
    return 'error'
  } else if (iteration.stateMachineStateId === 'SUCCESS') {
    const selectedLabels = iteration.labels.filter(
      label =>
        label.commitState === 'committed' || label.commitState === 'added'
    )
    if (selectedLabels.length === 0) {
      return 'needsReview'
    } else if (selectedLabels.some(label => label.label.isFailure)) {
      return 'failure'
    } else {
      return 'approved'
    }
  }
}

export function applyFilter(
  accordionedSubjects: AccordionedSubjectViewModel[],
  filter: Filter
): AccordionedSubjectViewModel[] {
  switch (filter.type) {
    case 'all':
      return accordionedSubjects
    case 'status':
      return accordionedSubjects.filter(subject => {
        const latestIteration = subject.iterations[0]
        switch (filter.status) {
          case 'uncommitted':
            return iterationHasUncommittedChanges(latestIteration)
          case 'needsReviewOrUncommitted':
            return (
              iterationHasUncommittedChanges(latestIteration) ||
              stateForIteration(latestIteration) === 'needsReview'
            )
          case 'error':
          case 'needsReview':
          case 'approved':
          case 'failure':
            return stateForIteration(latestIteration) === filter.status
          default:
            throw Error(`Unknown status: ${filter.status}`)
        }
      })
    case 'specificLabel':
      return accordionedSubjects.filter(subject =>
        subject.visibleIterations.some(iteration =>
          iteration.item.labels.some(label =>
            labelMatches(label.label, filter.label)
          )
        )
      )
    case 'anyLabelText':
      return accordionedSubjects.filter(subject =>
        subject.visibleIterations.some(iteration =>
          iteration.item.labels.some(label => Boolean(label.label.name))
        )
      )
  }
}

export const FILTER_ALL: Filter = { type: 'all' }

export type FilterOption = Filter & {
  displayName?: string
}

const STATIC_FILTER_OPTIONS: FilterOption[] = [
  { type: 'all', displayName: 'All' },
  { type: 'status', status: 'uncommitted', displayName: 'Uncommitted' },
  { type: 'status', status: 'error', displayName: 'Error' },
  {
    type: 'status',
    status: 'needsReviewOrUncommitted',
    displayName: 'Need review or uncommitted',
  },
  { type: 'status', status: 'needsReview', displayName: 'Need review' },
  { type: 'status', status: 'approved', displayName: 'Approved' },
  { type: 'status', status: 'failure', displayName: 'Failure' },
  { type: 'anyLabelText', displayName: 'Any label text' },
]

export function generateFilterOptions(labelChoices: Label[]): FilterOption[] {
  return STATIC_FILTER_OPTIONS.concat(
    labelChoices.map(label => ({ type: 'specificLabel', label }))
  )
}
