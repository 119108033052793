import React, { useMemo } from 'react'
import Select from 'react-select'

// This is a light wrapper around `<Select />` which requires less boilerplate
// code for handling the simple case of lists of strings.
export function Picker({
  id,
  choices: inChoices,
  value,
  onChange,
  onClear,
  isClearable = false,
}: {
  id?: string
  choices: string[]
  value?: string
  onChange: (value: string) => void
  onClear?: () => void
  isClearable?: boolean
}): JSX.Element {
  const choices = useMemo(
    () => inChoices.map(name => ({ value: name, label: name })),
    [inChoices]
  )

  return (
    <Select
      id={id}
      isClearable={isClearable}
      onChange={(option: any, { action }) => {
        if (option && action === 'select-option') {
          onChange(option.value)
        } else if (action === 'clear' && onClear !== undefined) {
          onClear()
        }
      }}
      value={choices.find(option => option.value === value) ?? null}
      options={choices}
    />
  )
}
