import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { BOTH_SIDES } from '@curvewise/common-types'
import { Button } from '@unpublished/common-components'
import { maybePluralize } from '@unpublished/victorinox'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { format } from 'timeago.js'

import { Breadcrumb } from '../common/common-components'
import { GENDER_OPTIONS } from '../common/data-transforms'
import {
  BodyPartType,
  ImportBodyGeometryMutation,
  ImportBodyGeometryMutationVariables,
  ImportHandGeometryMutation,
  ImportHandGeometryMutationVariables,
  SetPropertiesQuery,
  SetPropertiesQueryVariables,
} from '../common/generated'
import { useForceUpdate } from '../common/use-force-update'
import { useNumericParam } from '../common/use-numeric-param'
import { useRefresh } from '../common/use-refresh'
import { CheckStatusCell, CheckStatusTooltip } from './check-status'
import {
  StoredProperties,
  useUploadBucketProperties,
} from './local-storage-state'
import {
  CheckState,
  createViewModel,
  FileType,
  matches,
  normalizeETag,
  SET_PROPERTIES_QUERY,
  UploadedFile,
} from './view-model'

const BottomButtonsContainer = styled.div`
  margin-top: 1em;
`

const HeaderTable = styled.table`
  width: 100%;
`

const Controls = styled.td`
  vertical-align: middle;
  text-align: right;
`

const SetPropertiesWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  bottom: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
`

const GridWrapper = styled.div<{ disabled: boolean }>`
  flex-grow: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

const FloatRight = styled.div`
  float: right;
`

const onColumnResized = (params: ColumnResizedEvent): void => {
  params.api.resetRowHeights()
}

const onColumnVisible = (params: ColumnVisibleEvent): void => {
  params.api.resetRowHeights()
}

export function SetProperties(): JSX.Element {
  const navigate = useNavigate()
  const datasetId = useNumericParam('selectedDataset')
  const selectedDataset = useNumericParam('selectedDataset')
  const { isInitiallyLoading, data, refetch, renderRefreshButton } = useRefresh(
    useQuery<SetPropertiesQuery, SetPropertiesQueryVariables>(
      SET_PROPERTIES_QUERY,
      {
        variables: { datasetId },
        notifyOnNetworkStatusChange: true,
      }
    )
  )

  const forceUpdate = useForceUpdate()

  const [serializedSavedProperties, setSerializedSavedProperties] =
    useUploadBucketProperties()

  const viewModel = createViewModel({
    serializedSavedProperties,
    setPropertiesData: data,
  })

  const isDatasetBodyType = viewModel.bodyPart === BodyPartType.Body
  const isDatasetHandType = viewModel.bodyPart === BodyPartType.Hand

  const [gridApi, setGridApi] = useState<GridApi>()
  const [, setGridColumnApi] = useState<ColumnApi>()

  function onGridReady(params: GridReadyEvent): void {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.api.sizeColumnsToFit()
  }

  function onCellValueChanged({
    newValue,
    data: inData,
    colDef: { field: inField },
  }: CellValueChangedEvent): void {
    if (!gridApi) {
      throw Error('gridApi should be set')
    }

    const data = inData as StoredProperties
    const field = inField as keyof StoredProperties

    const newProperties = [
      ...serializedSavedProperties.map(item => ({ ...item })),
    ]

    // set the properties of the current changed row and all the selected rows
    ;[data].concat(gridApi.getSelectedRows()).forEach(affectedRow => {
      const existing = newProperties.find(item => matches(item, affectedRow))
      if (existing) {
        ;(existing as any)[field] = newValue
      } else {
        const { Key, ETag } = affectedRow
        newProperties.push({ Key, ETag, [field]: newValue })
      }
    })

    setSerializedSavedProperties(newProperties)

    // update unmatchedImageOptions
    gridApi.setColumnDefs(columnDefs)
  }

  const uploadMoreUrl = `/import/${datasetId}`

  function uploadedFileIsGeometry({ data }: { data: UploadedFile }): boolean {
    return data.type === FileType.Geometry
  }

  const [isImporting, setIsImporting] = useState(false)
  const [importMessage, setImportMessage] = useState('')
  const selectedRows = gridApi?.getSelectedRows() as UploadedFile[]
  const canImport =
    !isImporting &&
    selectedRows?.length &&
    selectedRows.every(row => row.checks.state === CheckState.Ready)
  const importButtonLabel = isImporting
    ? 'Importing…'
    : canImport
    ? `Import ${selectedRows.length} ${maybePluralize(
        'Geometry',
        selectedRows.length,
        'Geometries'
      )}`
    : 'Import'

  const client = useApolloClient()
  async function performImport(): Promise<void> {
    if (!canImport) {
      return
    }

    setIsImporting(true)

    // Try to import the geometries, one at a time. If an error is encountered,
    // stop and refresh the table to reflect the successful imports, and show
    // the error message.
    try {
      for (const item of selectedRows) {
        const { Key, ETag, subjectId, gender, side, pose, texture } = item
        const textureS3Key = texture === '(none)' ? null : texture
        if (isDatasetBodyType) {
          if (!subjectId || !gender || pose === undefined) {
            // This is a programmer error.
            throw Error('Properties should be set on item')
          }

          await client.mutate<
            ImportBodyGeometryMutation,
            ImportBodyGeometryMutationVariables
          >({
            mutation: gql`
              mutation ImportBodyGeometry(
                $datasetId: Int!
                $subjectName: String!
                $gender: String!
                $poseTypeId: Int!
                $s3Key: String!
                $eTag: String!
                $textureS3Key: String
              ) {
                importBodyGeometry(
                  input: {
                    datasetId: $datasetId
                    subjectName: $subjectName
                    gender: $gender
                    poseTypeId: $poseTypeId
                    s3Key: $s3Key
                    eTag: $eTag
                    textureS3Key: $textureS3Key
                  }
                ) {
                  geometry {
                    id
                  }
                }
              }
            `,
            variables: {
              datasetId,
              subjectName: subjectId,
              gender,
              poseTypeId: pose,
              s3Key: Key,
              eTag: normalizeETag(ETag),
              textureS3Key,
            },
          })
        } else if (isDatasetHandType) {
          if (!subjectId || !side || pose === undefined) {
            // This is a programmer error.
            throw Error('Properties should be set on item')
          }

          await client.mutate<
            ImportHandGeometryMutation,
            ImportHandGeometryMutationVariables
          >({
            mutation: gql`
              mutation ImportHandGeometry(
                $datasetId: Int!
                $subjectName: String!
                $side: String!
                $poseTypeId: Int!
                $s3Key: String!
                $eTag: String!
                $textureS3Key: String
              ) {
                importHandGeometry(
                  input: {
                    datasetId: $datasetId
                    subjectName: $subjectName
                    side: $side
                    poseTypeId: $poseTypeId
                    s3Key: $s3Key
                    eTag: $eTag
                    textureS3Key: $textureS3Key
                  }
                ) {
                  geometry {
                    id
                  }
                }
              }
            `,
            variables: {
              datasetId,
              subjectName: subjectId,
              side,
              poseTypeId: pose,
              s3Key: Key,
              eTag: normalizeETag(ETag),
              textureS3Key,
            },
          })
        }
      }
    } catch (e) {
      setImportMessage((e as Error).message)
      await refetch()
      setIsImporting(false)
      return
    }

    // Remove saved properties of imported geometries.
    setSerializedSavedProperties(
      serializedSavedProperties.filter(
        props => !selectedRows.some(imported => matches(imported, props))
      )
    )

    setImportMessage(
      `Imported ${selectedRows.length} ${maybePluralize(
        'geometry',
        selectedRows.length,
        'geometries'
      )}`
    )

    // Flush imported items from the upload list.
    const { data } = await refetch()

    // If the list is empty, navigate to the view dataset page.
    if (!data.uploadBucketList?.Contents.length) {
      navigate(`/datasets/${selectedDataset}/subjects`)
    }

    setIsImporting(false)
  }

  const columnTypes: {
    [key: string]: ColDef
  } = {
    poseColumn: {
      editable: uploadedFileIsGeometry,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: viewModel.poseChoices.map(({ id }) => id),
      },
      refData: Object.fromEntries(
        viewModel.poseChoices.map(({ id, name }) => [id, name])
      ),
    },
    subjectIdColumn: {
      editable: uploadedFileIsGeometry,
    },
    genderColumn: {
      editable: uploadedFileIsGeometry,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: Object.keys(GENDER_OPTIONS) },
      refData: GENDER_OPTIONS,
      cellStyle: ({ data }: { data: UploadedFile }) => {
        if (data.type === FileType.Geometry) {
          return data.checks.genderIsValid === false
            ? { background: 'rgba(255, 0, 0, 0.3)' }
            : { background: 'unset' }
        }
      },
    },
    sideColumn: {
      editable: uploadedFileIsGeometry,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: BOTH_SIDES },
    },
    textureColumn: {
      editable: uploadedFileIsGeometry,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: ({ data }: { data: UploadedFile }) => ({
        values: data.textureOptions,
      }),
    },
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'File path',
      field: 'Key',
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Upload time',
      field: 'LastModified',
      valueFormatter: ({ value }: { value: string }) =>
        format(new Date(value), 'en_US'),
    },
    {
      headerName: 'Texture',
      field: 'texture',
      type: 'textureColumn',
      valueFormatter: ({
        value,
        data,
      }: {
        value: string
        data: UploadedFile
      }) => {
        if (data && data.type === FileType.Geometry) {
          return value ?? '(none)'
        } else {
          return ''
        }
      },
    },
  ]
    .concat(
      isDatasetBodyType
        ? [
            {
              headerName: 'Gender',
              field: 'gender',
              type: 'genderColumn',
            } as any, // cast to any is to work around type check error on unknown type = 'genderColumn'
          ]
        : []
    )
    .concat(
      isDatasetHandType
        ? [
            {
              headerName: 'Side',
              field: 'side',
              type: 'sideColumn',
            } as any,
          ]
        : []
    )
    .concat([
      {
        headerName: 'Pose',
        field: 'pose',
        type: 'poseColumn',
      } as any,
      {
        headerName: 'Subject ID',
        field: 'subjectId',
        type: 'subjectIdColumn',
      },
      {
        headerName: 'Status',
        field: 'checks',
        cellRenderer: 'CheckStatusCell',
        tooltipField: 'checks',
        tooltipComponent: 'CheckStatusTooltip',
      },
    ])

  return (
    <SetPropertiesWrapper>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} <Link to={uploadMoreUrl}>Import</Link>{' '}
        {'>'} Set properties
      </Breadcrumb>
      {isInitiallyLoading && <p>Loading ...</p>}
      {data && (
        <>
          <HeaderTable>
            <tbody>
              <tr>
                <td>
                  <h1>Importing to {viewModel.datasetName}</h1>
                </td>
                <Controls>
                  {renderRefreshButton(Button)}
                  <Button onClick={() => navigate(uploadMoreUrl)}>
                    Upload More
                  </Button>
                </Controls>
              </tr>
            </tbody>
          </HeaderTable>
          <GridWrapper className="ag-theme-alpine" disabled={isImporting}>
            <AgGridReact
              modules={[ClientSideRowModelModule]}
              defaultColDef={{
                sortable: true,
                wrapText: true,
                autoHeight: true,
                resizable: true,
              }}
              columnTypes={columnTypes}
              frameworkComponents={{ CheckStatusCell, CheckStatusTooltip }}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
              rowData={viewModel.files}
              immutableData
              getRowNodeId={(data: UploadedFile) => data.Key}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              onSelectionChanged={forceUpdate}
              singleClickEdit
              onColumnResized={onColumnResized}
              tooltipShowDelay={0}
              onColumnVisible={onColumnVisible}
              columnDefs={columnDefs}
              gridOptions={{
                isRowSelectable: ({ data }) =>
                  data ? uploadedFileIsGeometry({ data }) : false,
              }}
            />
          </GridWrapper>
          <BottomButtonsContainer>
            <Button
              onClick={() => navigate(`/datasets/${selectedDataset}/subjects`)}
            >
              Done
            </Button>
            <Button
              onClick={() =>
                navigate(`/import/${selectedDataset}/assign-subject-id`)
              }
            >
              Assign Subject IDs
            </Button>
            <Button disabled>Delete</Button>
            <span>{importMessage}</span>
            <FloatRight>
              <Button disabled>Replace</Button>
              <Button disabled={!canImport} onClick={performImport}>
                {importButtonLabel}
              </Button>
            </FloatRight>
          </BottomButtonsContainer>
        </>
      )}
    </SetPropertiesWrapper>
  )
}
