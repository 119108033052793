import { useState } from 'react'

import { SubjectNameGeometryIdPair } from '../measurement-studies/add-geometries/view-model'
import { DatasetId } from './choose-datasets'
import { Table } from './common-components'
import { mapNumberNodeToOption } from './data-transforms'
import { Option, ToggleableButtonList } from './toggleable-button-list'

export type DatasetIdToGeometryIdsMap = Record<DatasetId, Set<number>>

export function useMaleFemaleToggleableButtonListTableState(
  datasetIdBeingEdited?: DatasetId
): {
  selectedMaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap
  setSelectedMaleSubjects: React.Dispatch<
    React.SetStateAction<DatasetIdToGeometryIdsMap>
  >
  selectedFemaleSubjectsByDatasetId: DatasetIdToGeometryIdsMap
  setSelectedFemaleSubjects: React.Dispatch<
    React.SetStateAction<DatasetIdToGeometryIdsMap>
  >
  updateSelectedMaleSubjects: (newSet: Set<number>) => void
  updateSelectedFemaleSubjects: (newSet: Set<number>) => void
} {
  const [selectedMaleSubjectsByDatasetId, setSelectedMaleSubjects] = useState<
    Record<DatasetId, Set<number>>
  >({} as Record<DatasetId, Set<number>>)
  const [selectedFemaleSubjectsByDatasetId, setSelectedFemaleSubjects] =
    useState<Record<DatasetId, Set<number>>>(
      {} as Record<DatasetId, Set<number>>
    )

  function updateStateByDatasetId<ValueType>(
    newValue: ValueType,
    selected: Record<DatasetId, ValueType>
  ): Record<DatasetId, ValueType> {
    if (datasetIdBeingEdited === undefined)
      throw new Error('Expected datasetIndexBeingEdited to be defined')
    return {
      ...selected,
      [datasetIdBeingEdited]: newValue,
    }
  }

  function updateSelectedMaleSubjects(newSet: Set<number>): void {
    setSelectedMaleSubjects(
      updateStateByDatasetId<Set<number>>(
        newSet,
        selectedMaleSubjectsByDatasetId
      )
    )
  }
  function updateSelectedFemaleSubjects(newSet: Set<number>): void {
    setSelectedFemaleSubjects(
      updateStateByDatasetId<Set<number>>(
        newSet,
        selectedFemaleSubjectsByDatasetId
      )
    )
  }
  return {
    selectedMaleSubjectsByDatasetId,
    setSelectedMaleSubjects,
    selectedFemaleSubjectsByDatasetId,
    setSelectedFemaleSubjects,
    updateSelectedMaleSubjects,
    updateSelectedFemaleSubjects,
  }
}

function mapSubjectNameGeometryIdPairsToOptions(
  subjectNameGeometryIdPair: SubjectNameGeometryIdPair[]
): Option<number>[] {
  return subjectNameGeometryIdPair.map(
    ([subjectName, geometryId]: SubjectNameGeometryIdPair) =>
      mapNumberNodeToOption({
        name: subjectName,
        id: geometryId,
      })
  )
}

export function MaleFemaleToggleableButtonListTable({
  currentFemaleSubjectNameGeometryIdPairs,
  currentMaleSubjectNameGeometryIdPairs,
  updateSelectedFemaleSubjects,
  updateSelectedMaleSubjects,
  selectedFemaleOptionValue,
  selectedMaleOptionValue,
}: {
  currentFemaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  currentMaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  updateSelectedFemaleSubjects: (newSet: Set<number>) => void
  updateSelectedMaleSubjects: (newSet: Set<number>) => void
  selectedFemaleOptionValue: Set<number>
  selectedMaleOptionValue: Set<number>
}): JSX.Element {
  const currentFemaleSubjectOptions = mapSubjectNameGeometryIdPairsToOptions(
    currentFemaleSubjectNameGeometryIdPairs
  )
  const currentMaleSubjectOptions = mapSubjectNameGeometryIdPairsToOptions(
    currentMaleSubjectNameGeometryIdPairs
  )
  return (
    <Table>
      <thead>
        <tr>
          <th>Female</th>
          <th>Male</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ToggleableButtonList
              options={currentFemaleSubjectOptions}
              onChange={updateSelectedFemaleSubjects}
              selectedOptionValues={selectedFemaleOptionValue}
            />
          </td>
          <td>
            <ToggleableButtonList
              options={currentMaleSubjectOptions}
              onChange={updateSelectedMaleSubjects}
              selectedOptionValues={selectedMaleOptionValue}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
