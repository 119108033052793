import { createGlobalStyle } from 'styled-components'

export const TooltipGlobalStyle = createGlobalStyle`
  .custom-tooltip {
    position: absolute;
    border: 1px solid lightgray;
    pointer-events: none;
    transition: opacity 1s;
    background-color: white;
    padding: 1em;
  }

  .custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
  }

  .custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
  }

  .custom-tooltip p:first-of-type {
    font-weight: bold;
  }
`
