import { gql, useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { FixedWidthTable, TableRow } from './flex-table'
import { ChooseDatasetsQuery } from './generated'
import { Option } from './toggleable-button-list'

const ThirdColumn = styled.div`
  text-align: center;
`

const AddAnotherDatasetContainer = styled.td`
  text-align: center;
  vertical-align: middle;
`

export type DatasetId = number

export const CHOOSE_DATASETS_QUERY = gql`
  query ChooseDatasets {
    allDatasets {
      nodes {
        id
        name
        bodyPart
        subjectsByDatasetId {
          nodes {
            id
            name
            gender
            geometrySeriesBySubjectId {
              nodes {
                topology
                poseTypeByPoseTypeId {
                  id
                  name
                }
                geometriesByGeometrySeriesId {
                  nodes {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface ViewModel {
  chosenDatasets: ChooseDatasetsQuery['allDatasets']['nodes']
  allAvailableDatasetOptions: Option<number>[]
  numberOfAllSubjectsForDataset: Record<DatasetId, number>
}

type SubjectType =
  ChooseDatasetsQuery['allDatasets']['nodes'][number]['subjectsByDatasetId']['nodes'][number]

function createViewModel({
  data,
  chosenDatasetIds,
  studyBodyPart,
  selectedPose,
  selectedTopology,
}: {
  data?: ChooseDatasetsQuery
  chosenDatasetIds: DatasetId[]
  studyBodyPart: string
  selectedPose?: string
  selectedTopology?: string
}): ViewModel {
  function filterSubjectsBySelectedPoseAndTopology(
    subject: SubjectType
  ): boolean {
    return subject.geometrySeriesBySubjectId.nodes.some(
      geometrySeries =>
        (!selectedPose ||
          geometrySeries.poseTypeByPoseTypeId.name === selectedPose) &&
        (!selectedTopology || geometrySeries.topology === selectedTopology)
    )
  }

  const allDatasets = data?.allDatasets

  return {
    chosenDatasets:
      allDatasets?.nodes.filter(dataset =>
        chosenDatasetIds.includes(dataset.id)
      ) || [],
    allAvailableDatasetOptions:
      allDatasets?.nodes
        .filter(dataset => chosenDatasetIds.indexOf(dataset.id) === -1)
        .filter(dataset => dataset.bodyPart === studyBodyPart)
        .filter(dataset =>
          dataset.subjectsByDatasetId.nodes.some(
            filterSubjectsBySelectedPoseAndTopology
          )
        )
        .map(dataset => ({
          label: dataset.name,
          value: dataset.id,
        })) || [],
    numberOfAllSubjectsForDataset:
      allDatasets?.nodes.reduce((a, b) => {
        a[b.id] = b.subjectsByDatasetId.nodes.filter(
          filterSubjectsBySelectedPoseAndTopology
        ).length
        return a
      }, {} as Record<DatasetId, number>) || {},
  }
}

export function useDatasetChooserState(): [
  [DatasetId[], React.Dispatch<React.SetStateAction<DatasetId[]>>],
  [
    DatasetId | undefined,
    React.Dispatch<React.SetStateAction<DatasetId | undefined>>
  ]
] {
  // all of these data structures are indexed by datasetIndexBeingEdited
  return [useState<DatasetId[]>([] as DatasetId[]), useState<DatasetId>()]
}

export function DatasetChooser({
  selectedPose,
  selectedTopology,
  studyBodyPart,
  datasetIdBeingEdited,
  chosenDatasetIds,
  getLabelForDatasetRow,
  handlePickSubjects,
  setChosenDatasetIds,
  setDatasetIdBeingEdited,
}: {
  selectedPose?: string
  selectedTopology?: string
  studyBodyPart: string
  chosenDatasetIds: DatasetId[]
  datasetIdBeingEdited?: DatasetId
  getLabelForDatasetRow: (datasetId: DatasetId) => JSX.Element
  handlePickSubjects: (selectedDatasetId: DatasetId) => void
  setChosenDatasetIds: React.Dispatch<React.SetStateAction<DatasetId[]>>
  setDatasetIdBeingEdited: React.Dispatch<
    React.SetStateAction<DatasetId | undefined>
  >
}): JSX.Element {
  const [selectedDatasetId, setSelectedDatasetId] = useState<number>()
  const [
    userHasRequestedToAddAdditionalDataset,
    setUserHasRequestedToAddAdditionalDataset,
  ] = useState(false)

  const { loading, error, data } = useQuery<ChooseDatasetsQuery>(
    CHOOSE_DATASETS_QUERY
  )

  const viewModel = createViewModel({
    data,
    chosenDatasetIds,
    studyBodyPart,
    selectedPose,
    selectedTopology,
  })

  return error ? (
    <p>Oh no! {error.message}</p>
  ) : loading ? (
    <p>Loading ...</p>
  ) : (
    <FixedWidthTable>
      {viewModel.chosenDatasets.map(chosenDataset => (
        <TableRow
          selected={chosenDataset.id === datasetIdBeingEdited}
          onClick={() => setDatasetIdBeingEdited(chosenDataset.id)}
          doNotSizeLastColumnBasedOnItsContents={true}
        >
          <div>{chosenDataset.name}</div>
          <div />
          <ThirdColumn>
            <div>{getLabelForDatasetRow(chosenDataset.id)}</div>
          </ThirdColumn>
        </TableRow>
      ))}
      {(!chosenDatasetIds.length || userHasRequestedToAddAdditionalDataset) && (
        <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
          <div>Dataset</div>
          <div>
            <Select
              name="dataset"
              key="dataset"
              value={viewModel.allAvailableDatasetOptions?.filter(
                option => option.value === selectedDatasetId
              )}
              onChange={(option: any, { action }) => {
                if (option && action === 'select-option') {
                  setSelectedDatasetId(option.value)
                }
              }}
              options={viewModel.allAvailableDatasetOptions}
            />
          </div>
          <ThirdColumn>
            <Button
              onClick={() => {
                if (selectedDatasetId && data) {
                  const newChosenDatasetIds =
                    chosenDatasetIds.concat(selectedDatasetId)
                  setChosenDatasetIds(newChosenDatasetIds)
                  handlePickSubjects(selectedDatasetId)
                  setUserHasRequestedToAddAdditionalDataset(false)
                }
              }}
            >
              Pick Subjects
            </Button>
          </ThirdColumn>
        </TableRow>
      )}
      {chosenDatasetIds.length && !userHasRequestedToAddAdditionalDataset ? (
        <TableRow doNotSizeLastColumnBasedOnItsContents={true}>
          <AddAnotherDatasetContainer colSpan={3}>
            <Button
              onClick={() => setUserHasRequestedToAddAdditionalDataset(true)}
            >
              + Add Another Dataset
            </Button>
          </AddAnotherDatasetContainer>
        </TableRow>
      ) : undefined}
    </FixedWidthTable>
  )
}
