export type MeasurerVersion = {
  measurerName: string | null
  functionName: string | null
  repoSlug: string | null
} & (
  | { kind: 'tag'; tagName: string }
  | {
      kind: 'commit'
      sha1: string
      commitDate: Date | null
      commitMessage: string | null
    }
)

export function transformMeasurerVersion({
  measurerByMeasurerId: { name: measurerName, functionName, repoSlug },
  sha1,
  tagName,
  commitDate,
  commitMessage,
}: {
  measurerByMeasurerId: {
    name: string | null
    functionName: string | null
    repoSlug: string | null
  }
  sha1: string | null
  tagName: string | null
  commitDate: any | null
  commitMessage: string | null
}): MeasurerVersion {
  const commonAttrs = { measurerName, functionName, repoSlug }
  if (tagName !== null) {
    return { ...commonAttrs, kind: 'tag', tagName }
  } else if (sha1 !== null) {
    return {
      ...commonAttrs,
      kind: 'commit',
      sha1,
      commitDate,
      commitMessage:
        commitMessage === null ? null : commitMessage.split('\n')[0],
    }
  } else {
    throw Error(
      'Either a sha1 or tag name should be present, but this measurer version has neither'
    )
  }
}

export function measurerVersionMatches(
  first?: MeasurerVersion,
  second?: MeasurerVersion
): boolean {
  if (first === undefined && second === undefined) {
    return true
  } else if (first === undefined || second === undefined) {
    return false
  } else {
    return JSON.stringify(first) === JSON.stringify(second)
  }
}
