import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  DatasetChooser,
  useDatasetChooserState,
} from '../../../common/choose-datasets'
import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  Table,
} from '../../../common/common-components'
import { deriveGeometryIdsFromSubjects } from '../../../common/data-transforms'
import { CreateNewMeasurementStudyChooseSubjectsQuery } from '../../../common/generated'
import {
  MaleFemaleToggleableButtonListTable,
  useMaleFemaleToggleableButtonListTableState,
} from '../../../common/male-female-toggleable-button-list-table'
import { MeasurerVersionDisplay } from '../../measurer-version-display'
import { HomePagePayload, PageTransitionActionType, TransitionAction } from '..'
import {
  CREATE_NEW_MEASUREMENT_STUDY_QUERY,
  createViewModel,
  DatasetId,
} from './view-model'

const NextButton = styled(Button)`
  float: right;
`

function deriveSelectedGeometries({
  data,
  chosenDatasetIds,
  selectedPose,
  selectedTopology,
  selectedMaleSubjectsByDatasetId: selectedMaleSubjects,
  selectedFemaleSubjectsByDatasetId: selectedFemaleSubjects,
}: {
  data: CreateNewMeasurementStudyChooseSubjectsQuery
  chosenDatasetIds: number[]
  selectedPose?: string
  selectedTopology?: string
  selectedMaleSubjectsByDatasetId: Record<DatasetId, Set<number>>
  selectedFemaleSubjectsByDatasetId: Record<DatasetId, Set<number>>
}): Set<number> {
  return new Set(
    chosenDatasetIds.flatMap(chosenDatasetId => {
      const dataset = data.allDatasets.nodes.find(
        dataset => dataset.id === chosenDatasetId
      )

      if (!dataset) {
        throw new Error(`Could not find dataset from id ${chosenDatasetId}`)
      }

      return deriveGeometryIdsFromSubjects({
        subjects: dataset.subjectsByDatasetId.nodes,
        selectedFemaleSubjects: selectedFemaleSubjects[chosenDatasetId],
        selectedMaleSubjects: selectedMaleSubjects[chosenDatasetId],
        selectedPoses: new Set(selectedPose ? [selectedPose] : []),
        selectedTopologies: new Set(selectedTopology ? [selectedTopology] : []),
      })
    })
  )
}

function flatten<ValueType>(
  record: Record<DatasetId, Set<ValueType>>
): ValueType[] {
  return Array.from(
    new Set(Object.values(record).flatMap(set => Array.from(set)))
  )
}

export function CreateNewMeasurementStudyChooseSubjects({
  dispatchPageTransitionAction,
  homePagePayload: {
    studyName,
    studyBodyPart,
    measurerVersion,
    pose: selectedPose,
    topology: selectedTopology,
  },
}: {
  dispatchPageTransitionAction: React.Dispatch<TransitionAction>
  homePagePayload: HomePagePayload
}): JSX.Element {
  const navigate = useNavigate()
  const [selectedDatasetId, setSelectedDatasetId] = useState<number>()
  const [
    [chosenDatasetIds, setChosenDatasetIds],
    [datasetIdBeingEdited, setDatasetIdBeingEdited],
  ] = useDatasetChooserState()

  const {
    selectedMaleSubjectsByDatasetId,
    setSelectedMaleSubjects,
    selectedFemaleSubjectsByDatasetId,
    setSelectedFemaleSubjects,
    updateSelectedMaleSubjects,
    updateSelectedFemaleSubjects,
  } = useMaleFemaleToggleableButtonListTableState(datasetIdBeingEdited)

  function getLabelForDatasetRow(chosenDatasetId: DatasetId): JSX.Element {
    return (
      <div>
        {selectedFemaleSubjectsByDatasetId[chosenDatasetId].size +
          selectedMaleSubjectsByDatasetId[chosenDatasetId].size}{' '}
        out of {viewModel.numberOfAllSubjectsForDataset[chosenDatasetId]}{' '}
        subjects selected
      </div>
    )
  }

  function handlePickSubjects(selectedDatasetId: DatasetId): void {
    const {
      currentMaleSubjectNameGeometryIdPairs,
      currentFemaleSubjectNameGeometryIdPairs,
    } = createViewModel({
      data,
      datasetIdBeingEdited: selectedDatasetId,
      chosenDatasetIds,
      studyBodyPart,
      selectedPose,
      selectedTopology,
    })

    setSelectedMaleSubjects({
      ...selectedMaleSubjectsByDatasetId,
      [selectedDatasetId]: new Set(
        currentMaleSubjectNameGeometryIdPairs.map(subject => subject[1])
      ),
    })
    setSelectedFemaleSubjects({
      ...selectedFemaleSubjectsByDatasetId,
      [selectedDatasetId]: new Set(
        currentFemaleSubjectNameGeometryIdPairs.map(subject => subject[1])
      ),
    })
    setDatasetIdBeingEdited(selectedDatasetId)
  }

  const { loading, error, data } =
    useQuery<CreateNewMeasurementStudyChooseSubjectsQuery>(
      CREATE_NEW_MEASUREMENT_STUDY_QUERY
    )

  const selectedGeometryIds =
    data &&
    deriveSelectedGeometries({
      data,
      chosenDatasetIds,
      selectedPose,
      selectedTopology,
      selectedMaleSubjectsByDatasetId,
      selectedFemaleSubjectsByDatasetId,
    })

  const viewModel = createViewModel({
    data,
    datasetIdBeingEdited,
    chosenDatasetIds,
    studyBodyPart,
    selectedPose,
    selectedTopology,
  })

  if (
    viewModel &&
    !selectedDatasetId &&
    viewModel.allAvailableDatasetOptions.length
  ) {
    setSelectedDatasetId(viewModel.allAvailableDatasetOptions[0].value)
  }

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <a // eslint-disable-line jsx-a11y/anchor-is-valid
          href="" // TODO: clean this up - use a reusable, styled <span> instead
          onClick={() => {
            dispatchPageTransitionAction({
              type: PageTransitionActionType.GO_HOME,
            })
          }}
        >
          New Measurement Study
        </a>{' '}
        {'>'} <span>Choose Subjects</span>
      </Breadcrumb>
      <h1>New measurement study</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <Table>
        <thead>
          <tr>
            <th>Measurement Study</th>
            <th>Body part</th>
            <th>Measurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{studyName}</td>
            <td>{studyBodyPart}</td>
            <td>
              {measurerVersion.measurerName} @{' '}
              <MeasurerVersionDisplay measurerVersion={measurerVersion} />
            </td>
          </tr>
          <tr>
            <th>Pose</th>
            <th>Topology</th>
          </tr>
          <tr>
            <td>{selectedPose}</td>
            <td>{selectedTopology}</td>
          </tr>
        </tbody>
      </Table>
      <div>
        <h2>Choose subjects</h2>
        <DatasetChooser
          selectedPose={selectedPose}
          selectedTopology={selectedTopology}
          studyBodyPart={studyBodyPart}
          datasetIdBeingEdited={datasetIdBeingEdited}
          chosenDatasetIds={chosenDatasetIds}
          setChosenDatasetIds={setChosenDatasetIds}
          getLabelForDatasetRow={getLabelForDatasetRow}
          handlePickSubjects={handlePickSubjects}
          setDatasetIdBeingEdited={setDatasetIdBeingEdited}
        />
        {chosenDatasetIds.length && datasetIdBeingEdited !== undefined ? (
          <MaleFemaleToggleableButtonListTable
            currentFemaleSubjectNameGeometryIdPairs={
              viewModel.currentFemaleSubjectNameGeometryIdPairs
            }
            currentMaleSubjectNameGeometryIdPairs={
              viewModel.currentMaleSubjectNameGeometryIdPairs
            }
            updateSelectedFemaleSubjects={updateSelectedFemaleSubjects}
            updateSelectedMaleSubjects={updateSelectedMaleSubjects}
            selectedFemaleOptionValue={
              selectedFemaleSubjectsByDatasetId[datasetIdBeingEdited]
            }
            selectedMaleOptionValue={
              selectedMaleSubjectsByDatasetId[datasetIdBeingEdited]
            }
          />
        ) : undefined}

        <FixedWidthFooterNavContainer>
          <Button
            onClick={() => {
              dispatchPageTransitionAction({
                type: PageTransitionActionType.CHOOSE_SUBJECTS_GO_BACK,
              })
            }}
          >
            Back
          </Button>
          <Button onClick={() => navigate('/studies')}>Cancel</Button>
          <NextButton
            onClick={() => {
              //TODO: validate
              if (selectedGeometryIds) {
                dispatchPageTransitionAction({
                  type: PageTransitionActionType.CHOOSE_SUBJECTS_GO_NEXT,
                  payload: {
                    geometryIds: Array.from(selectedGeometryIds),
                    femaleSubjects: flatten(selectedFemaleSubjectsByDatasetId),
                    maleSubjects: flatten(selectedMaleSubjectsByDatasetId),
                    pose: selectedPose,
                    topology: selectedTopology,
                  },
                })
              }
            }}
            disabled={!(selectedGeometryIds && selectedGeometryIds.size)}
          >
            Next: Choose Measurements
          </NextButton>
        </FixedWidthFooterNavContainer>
      </div>
    </FixedWidthPageContainer>
  )
}
