import * as THREE from 'three'

export function reindex({
  array: inArray,
  faceOrdering,
  indicesPerFace,
}: {
  array: Float32Array
  faceOrdering: Int32Array
  indicesPerFace: number
}): Float32Array {
  const outArray = new Float32Array(inArray.length)
  for (let i = 0; i < faceOrdering.length; i++) {
    const firstOldI = faceOrdering[i] * indicesPerFace
    outArray.set(
      inArray.subarray(firstOldI, firstOldI + indicesPerFace),
      i * indicesPerFace
    )
  }
  return outArray
}

export interface FaceGroupsForThreejsData {
  groups: {
    name: string
    start: number
    count: number
  }[]
  faceOrdering: number[]
}

export interface FaceGroupsForThreejs {
  groups: FaceGroupsForThreejsData['groups']
  faceOrdering: Int32Array
}

export function applyFaceGroups(
  geometry: THREE.BufferGeometry,
  { groups, faceOrdering }: FaceGroupsForThreejs
): void {
  // Reorder the triangle vertices.
  const newVerts = reindex({
    array: geometry.getAttribute('position').array as Float32Array,
    faceOrdering,
    indicesPerFace: 9,
  })
  geometry.setAttribute('position', new THREE.BufferAttribute(newVerts, 3))

  // Apply the face groups.
  geometry.groups = groups.map((group, i) => ({
    materialIndex: i,
    start: group.start,
    count: group.count,
  }))
}
