import { setDifference, setUnion } from '@unpublished/victorinox'

// This module contains two _uncoupled_ parts:
//
// 1. A function for applying the accordion.
// 2. A reducer for managing the accordion's hidden / expanded state.

export interface Accordioned<ItemType> {
  item: ItemType
  nestedSuccessors: ItemType[]
  overrideNesting: boolean
}

// Given an array of items, hide items which do pass the given filter. Nest the
// hidden items underneath the nearest visible predecessor. The first item will
// always be visible. If any hidden items pass `shouldOverrideNesting`, the
// entire set of items will also be shown.
export function createAccordion<ItemType>({
  items,
  itemShouldNest,
  itemShouldOverrideNesting,
}: {
  items: ItemType[]
  itemShouldNest: (item: ItemType) => boolean
  itemShouldOverrideNesting: (item: ItemType) => boolean
}): Accordioned<ItemType>[] {
  return (
    items
      // Generate the initial accordion. Hide items which do not pass the given
      // filter by nesting them under the nearest visible predecessor.
      .reduce((initialAccordion, item, index) => {
        if (index === 0 || !itemShouldNest(item)) {
          // Show it.
          return initialAccordion.concat({ item, nestedSuccessors: [] })
        } else {
          // Nest it under the nearest visible predecessor.
          initialAccordion[initialAccordion.length - 1].nestedSuccessors.push(
            item
          )
          return initialAccordion
        }
      }, [] as { item: ItemType; nestedSuccessors: ItemType[] }[])
      // If any of the nested are pass `isExpanded`, show the whole group.
      .reduce((accum, current) => {
        const { item, nestedSuccessors } = current
        const overrideNesting = nestedSuccessors.some(item =>
          itemShouldOverrideNesting(item)
        )
        // Place the item at the top level.
        let accordion = accum.concat({
          item,
          nestedSuccessors,
          overrideNesting,
        })
        if (overrideNesting) {
          // Lift the nested successors up to the top level, too.
          accordion = accordion.concat(
            nestedSuccessors.map(item => ({
              item,
              nestedSuccessors: [],
              overrideNesting: false,
            }))
          )
        }
        return accordion
      }, [] as Accordioned<ItemType>[])
  )
}

export interface State {
  expandedItems: Set<number>
}

export type Action =
  | { type: 'expandItems'; items: number[] }
  | { type: 'hideItems'; items: number[] }
  | { type: 'reset' }

export const initialState: State = {
  expandedItems: new Set(),
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'expandItems':
      return {
        expandedItems: setUnion(state.expandedItems, new Set(action.items)),
      }
    case 'hideItems':
      return {
        expandedItems: setDifference(
          state.expandedItems,
          new Set(action.items)
        ),
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}
